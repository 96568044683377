import clsx from 'clsx';

import { useLocation } from '~src/hooks';
import { useDeleteResidenceImage } from '~src/hooks/services';
import { Image as ImageType } from '~src/types';
import { getSizedImageUrl } from '~src/utilities/convert';

import { Image, ImageProps } from './Image';
import { PLACEHOLDER_IMAGE_URL } from './imageConstants';
import { PreviewToolbar } from './PreviewToolbar';

import { thumbnail } from './ImageThumbnail.module.css';

type ImageThumbnailProps = {
  className?: string;
  image: ImageType;
};

export const ImageThumbnail = ({ className, image }: ImageThumbnailProps) => {
  const { url, residenceID } = image;

  const { parameters } = useLocation();

  const { accessCode } = parameters;

  const { deleteResidenceImage } = useDeleteResidenceImage(residenceID, accessCode);

  return (
    <div className={clsx(className, thumbnail)}>
      <Image
        decoding='async'
        fallback={PLACEHOLDER_IMAGE_URL}
        loading='lazy'
        src={getSizedImageUrl(url)}
        preview={getPreview(() => deleteResidenceImage(url))}
      />
    </div>
  );
};

const getPreview = (onDelete: () => void): ImageProps['preview'] => ({
  toolbarRender: (_, info) => <PreviewToolbar actions={info.actions} transform={info.transform} onDelete={onDelete} />,
});
