import { ChangeEvent, useState } from 'react';

import { Card, Input } from '~src/components/display';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useUpdateOfferReservations } from '~src/hooks/services';
import { Offer, OfferInput } from '~src/types';

type OfferReservationsProps = {
  offer?: Pick<Offer, 'id'> & Pick<OfferInput, 'reservations'>;
};

export const OfferReservations = ({ offer }: OfferReservationsProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const { id, reservations: offerReservations } = offer ?? {};

  const [reserverations, setReserverations] = useState(offerReservations);

  const { updateOfferReservation } = useUpdateOfferReservations();

  const handleReservationsChanged = async (newReservation: string) => {
    if (!id) {
      return;
    }

    await updateOfferReservation(id, newReservation);

    showMessage({ type: 'success', message: translate.SAVED });
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setReserverations(value);
  };

  return id ? (
    <Card title={`${translate.RESERVATION} (${translate.SHOWN_IN_PDF})`}>
      <Input.TextArea
        defaultValue={reserverations}
        onChange={handleChange}
        rows={10}
        onBlur={() => handleReservationsChanged(reserverations ?? '')}
      />
    </Card>
  ) : (
    <Card title={translate.RESERVATION} />
  );
};
