import { useEffect } from 'react';

import { Divider, Heading } from '~src/components/display';
import { ProductPagination } from '~src/components/display/Pagination/ProductPagination';
import { HeatPumpIndoorProductCard } from '~src/components/display/ProductCard/HeatPumpIndoorProductCard';
import { useLocalization, useOfferProductState } from '~src/hooks';
import { useHeatPumpIndoorUnits } from '~src/hooks/services/products/useHeatPumpIndoorUnit';

import { displayColumn } from '~src/style/shared.module.css';

export const HeatPumpIndoorProductSelector = () => {
  const translate = useLocalization();
  const { selectedHeatPumpOutdoorUnit, selectHeatPumpIndoorUnit, selectedHeatPumpIndoorUnit } = useOfferProductState();

  const { heatPumpIndoorUnits } = useHeatPumpIndoorUnits(selectedHeatPumpOutdoorUnit?.compatibleIndoorUnits);

  useEffect(
    function preselectFirstValidHeatPumpIndoorUnitOnLoad() {
      if (
        selectedHeatPumpIndoorUnit?.id &&
        heatPumpIndoorUnits?.some(({ id }) => id === selectedHeatPumpIndoorUnit.id)
      ) {
        return;
      }

      if (!heatPumpIndoorUnits.length) {
        return;
      }

      selectHeatPumpIndoorUnit(heatPumpIndoorUnits[0]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(heatPumpIndoorUnits), selectedHeatPumpOutdoorUnit]
  );

  const handleOnSelect = (selectedId?: number | string) => {
    const selectedHeatPump = heatPumpIndoorUnits?.find(({ id }) => id === selectedId);

    if (!selectedHeatPump) {
      return;
    }

    if (selectedHeatPump === selectedHeatPumpIndoorUnit) {
      selectHeatPumpIndoorUnit();
      return;
    }

    selectHeatPumpIndoorUnit(selectedHeatPump);
  };

  return (
    <div className={displayColumn}>
      <Heading>{`${translate.HEAT_PUMP} ${translate.INSIDE_PART.toLowerCase()}`}</Heading>

      <Divider fullWidth />

      <ProductPagination
        itemList={heatPumpIndoorUnits?.map(unit => (
          <HeatPumpIndoorProductCard
            key={unit.id}
            onSelect={handleOnSelect}
            product={unit}
            preferred={unit.preferred}
            selected={unit.id === selectedHeatPumpIndoorUnit?.id}
          />
        ))}
      />
    </div>
  );
};
