import { Heading, ImageThumbnail, Input, Radio, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';
import { HeatPumpOutdoorUnit } from '~src/types';

import { NoiseTable } from './NoiseTable';
import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionOutdoor = ({
  answerMap,
  heatPumpOutdoorUnit,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps & { heatPumpOutdoorUnit?: HeatPumpOutdoorUnit }) => {
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        1. {translate.OUTSIDE_PART}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_NOISE_LIMIT_OBSERVED}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('noiseLimitObserved', event?.target?.value);
          }}
          value={answerMap?.noiseLimitObserved?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>

          <Radio.Button value='false'>{translate.NO}</Radio.Button>

          <Radio.Button value='withScreen'>{translate.SITE_VISIT_ANSWER_NOISE_LIMIT_WITH_SCREEN}</Radio.Button>
        </Radio.Group>
        <NoiseTable decibel={heatPumpOutdoorUnit?.noiseLevel} />
        <SiteVisitNote
          display={answerMap?.noiseLimitObserved?.answer === 'false'}
          text={translate.SITE_VISIT_ANSWER_NOISE_LIMIT_NOTE}
        />
        <SiteVisitNote
          display={answerMap?.noiseLimitObserved?.answer === 'withScreen'}
          text={translate.SITE_VISIT_DISCLAIMER_NOISE_LIMIT}
          type='condition'
        />

        <Text>{translate.SITE_VISIT_QUESTION_ROOM_FOR_MATERIALS}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('roomForMaterials', event?.target?.value);
          }}
          value={answerMap?.roomForMaterials?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.roomForMaterials?.answer === 'false'}
          text={translate.SITE_VISIT_DISCLAIMER_MAKE_ROOM}
          type='condition'
        />

        <Text>{translate.SITE_VISIT_QUESTION_PROVIDER_FOR_FOUNDATION}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('providerForFoundation', event?.target?.value);
          }}
          value={answerMap?.providerForFoundation?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='customer'>{translate.CUSTOMER}</Radio.Button>
          <Radio.Button value='installer'>{translate.INSTALLER}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.providerForFoundation?.answer === 'installer'}
          text={translate.SITE_VISIT_DISCLAIMER_FOUNDATION}
          type='expense'
        />

        <Text>{translate.SITE_VISIT_QUESTION_DIG_DOWN_PIPES}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('digDownPipes', event?.target?.value);
          }}
          value={answerMap?.digDownPipes?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.digDownPipes?.answer === 'true'}
          text={translate.SITE_VISIT_DISCLAIMER_DIG_DOWN_PIPES}
          type='expense'
        />

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('outdoorPlacementNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.outdoorPlacementNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.outdoorPlacement)}
            type='thumbnail'
            isLoading={isUploadingImages}
          />
        </div>
      </div>
    </div>
  );
};
