import { ChangeEvent, useEffect, useState } from 'react';

import { Card, Input } from '~src/components/display';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useUpdateOfferNotes } from '~src/hooks/services';
import { Offer, OfferInput } from '~src/types';

type OfferNotesProps = {
  offer?: Pick<Offer, 'id'> & Pick<OfferInput, 'notes'>;
};

export const OfferNotes = ({ offer }: OfferNotesProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const { notes: offerNotes, id } = offer ?? {};

  const [notes, setNotes] = useState(offerNotes);

  const { updateOfferNotes } = useUpdateOfferNotes();

  useEffect(
    function synchronizeNotesWithOffer() {
      if (offerNotes && offerNotes !== notes) {
        setNotes(offerNotes);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerNotes]
  );

  const handleNotesChanged = async (newNotes: string) => {
    if (!id) {
      return;
    }

    await updateOfferNotes(id, newNotes);

    showMessage({ type: 'success', message: translate.SAVED });
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setNotes(value);
  };

  return id ? (
    <Card title={translate.OFFER_NOTES}>
      <Input.TextArea
        defaultValue={notes}
        onChange={handleChange}
        rows={10}
        onBlur={() => handleNotesChanged(notes ?? '')}
      />
    </Card>
  ) : (
    <Card title={translate.OFFER_NOTES} />
  );
};
