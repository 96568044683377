export const global = {
  BUFFER: 'Buffer',
  EMAIL: 'Email',
  MODEL: 'Model',
  PASSWORD: 'Password',
  SCOP: 'SCOP',
  STATUS: 'Status',
  UPLOAD: 'Upload',
  CURRENT_CRM: 'AC',
};
