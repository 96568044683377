import { API_URL } from '~src/config';
import { useDownloadFile } from '~src/hooks/useDownloadFile';

const FILENAME = 'OfferOverview.csv';

export const useExportOverviewCSV = (search: string, productType?: string) => {
  const csvDownloadURL = `${API_URL}/rest/v1/export-offer-overview-data?search=${search}&offerType=${productType}`;

  return useDownloadFile(FILENAME, csvDownloadURL);
};
