import { Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { TranslationRecord } from '~src/localization/translationKeys';

import { note } from './SiteVisitNote.module.css';

type SiteVisitNoteProps = {
  display: boolean;
  text: string;
  type?: 'condition' | 'expense' | 'note';
};

export const SiteVisitNote = ({ display, text, type = 'note' }: SiteVisitNoteProps) => {
  const translate = useLocalization();

  if (!display) {
    return null;
  }

  return (
    <Text className={note}>
      {getNoteTitle(translate, type)}: {text}
    </Text>
  );
};

const getNoteTitle = (translate: TranslationRecord, type: string) => {
  switch (type) {
    case 'condition': {
      return translate.SITE_VISIT_CONDITION;
    }

    case 'expense': {
      return translate.SITE_VISIT_ADD_EXPENSE;
    }

    case 'note':
    default: {
      return translate.NOTE;
    }
  }
};
