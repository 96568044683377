import { ReactNode } from 'react';

import { Card, Heading } from '~src/components/display';

import { infoBox, infoBoxBlock, infoBoxHeader, infoBoxIconWrapper, infoBoxValue } from './infoBox.module.css';

type InfoBoxProps = {
  title: string;
  subtitle?: ReactNode;
  icon?: ReactNode;
  value: string;
};

export const InfoBox = ({ title, subtitle, icon, value }: InfoBoxProps) => (
  <Card className={infoBox}>
    <Heading level={4} className={infoBoxHeader}>
      {title}
    </Heading>

    <div className={infoBoxBlock}>
      <div className={infoBoxValue}>
        {icon && <div className={infoBoxIconWrapper}>{icon}</div>}

        <Heading>{value}</Heading>
      </div>
      {typeof subtitle === 'string' ? <Heading level={3}>{subtitle}</Heading> : subtitle}
    </div>
  </Card>
);
