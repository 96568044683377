import { SiteVisit } from '~src/components/features/siteVisit/SiteVisit';
import { useLocation } from '~src/hooks';
import { useCustomer, useOfferByTag, useResidence } from '~src/hooks/services';

export const SiteVisitPage = () => {
  const { parameters } = useLocation();

  const { offerTag } = parameters ?? undefined;

  const { offer, isLoading: isLoadingOffer } = useOfferByTag(offerTag);
  const { residence, isLoading: isLoadingResidence } = useResidence(offer?.residence?.id);
  const { customer, isLoading: isLoadingCustomer } = useCustomer(residence?.customer?.id);

  const isLoading = isLoadingCustomer || isLoadingOffer || isLoadingResidence;

  return <SiteVisit offer={offer} residence={residence} customer={customer} isLoading={isLoading} />;
};
