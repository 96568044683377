import { Button, Divider, Form, Heading, Input, Text } from '~src/components/display';
import { InfoCircleOutlined } from '~src/components/display/Icons';
import { AddressSearchNew } from '~src/components/features/shared/addressSearch/AddressSearchNew';
import {
  CreatingNewResidenceState,
  useCreateResidenceState,
} from '~src/components/features/shared/residence/state/createResidenceState';
import { useLocalization, useNavigation } from '~src/hooks';
import { ResidenceInput } from '~src/types';

import { useCustomerResidenceList } from '../../hooks/useCustomerResidenceList';
import { useCreateOfferState } from '../../state/createOfferState';
import { AddressSearchExisting } from './AddressSearchExisting';

import { addressSearch } from './address.module.css';

export const AddressSearch = () => {
  const translate = useLocalization();
  const { navigateRaw } = useNavigation();

  const { residenceList, isLoading } = useCustomerResidenceList();

  const { customer, crmCustomerAddress, setResidence } = useCreateOfferState();
  const { creatingNewResidenceState, setCreatingNewResidenceState } = useCreateResidenceState();

  const updateCreatingResidenceState = (state: CreatingNewResidenceState) => {
    if (state === 'pending') {
      navigateRaw(`/create-offer/residence/`, { replace: true });
    }

    setCreatingNewResidenceState(state);
  };

  const handleSelect = (newResidence?: ResidenceInput) => {
    setResidence(newResidence);
    updateCreatingResidenceState('done');
  };

  const customerName = `${customer?.firstName} ${customer?.lastName}`;

  const shouldUseCRMAddress = !!crmCustomerAddress && residenceList.length < 1;

  const isCreatingNewResidence = shouldUseCRMAddress || creatingNewResidenceState !== 'none';

  return (
    <div className={addressSearch}>
      <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} disabled={isLoading}>
        <Form.Item label={translate.SELECTED_CUSTOMER}>
          <Input value={customerName} disabled />
        </Form.Item>

        {isCreatingNewResidence ? (
          <AddressSearchNew existingAddressSuggestion={crmCustomerAddress} onSelect={handleSelect} />
        ) : (
          <AddressSearchExisting />
        )}
      </Form>

      <div>
        <Divider orientation='center'>
          <Heading>{translate.OR}</Heading>
        </Divider>

        <Button
          disabled={isLoading}
          type='primary'
          onClick={() => {
            setResidence(undefined);
            updateCreatingResidenceState(creatingNewResidenceState === 'none' ? 'pending' : 'none');
          }}
        >
          {isCreatingNewResidence ? translate.CHOOSE_EXISTING_ADDRESS : translate.CREATE_NEW_ADDRESS}
        </Button>
      </div>

      <div>
        <InfoCircleOutlined height={16} width={16} />

        <Text>{translate.CREATE_RESIDENCE_DISCLAIMER}</Text>
      </div>
    </div>
  );
};
