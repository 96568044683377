/* eslint-disable react/jsx-props-no-spreading */

import { ChangeEvent, useEffect, useState } from 'react';

import { Card, Input } from '~src/components/display';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useUpsertCustomer } from '~src/hooks/services';
import { CustomerInput, MaybeId } from '~src/types';

type CustomerNotesProps = {
  customer?: CustomerInput & MaybeId;
  rows?: number;
  title?: string;
};

export const CustomerNotes = ({ customer, rows, title }: CustomerNotesProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const [notes, setNotes] = useState(customer?.notes);

  const { upsertCustomer } = useUpsertCustomer();

  useEffect(
    function synchronizeNotesWithCustomer() {
      if (customer?.notes && customer?.notes !== notes) {
        setNotes(customer?.notes);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer?.notes]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdate = async (newNotes: string) => {
    if (!customer?.id) {
      showMessage({ type: 'warning', message: 'Cannot update notes. No customer selected.' });
      return;
    }

    await upsertCustomer({ ...customer, notes: newNotes });

    showMessage({ type: 'success', message: translate.SAVED });
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setNotes(value);
  };

  const disabled = !customer?.id;

  return customer ? (
    <Card title={title ?? translate.CUSTOMER_NOTES}>
      <Input.TextArea
        disabled={disabled}
        onChange={handleChange}
        {...(rows ? { rows } : {})}
        value={notes}
        onBlur={() => handleUpdate(notes ?? '')}
      />
    </Card>
  ) : (
    <Card title={title ?? translate.CUSTOMER_NOTES} />
  );
};
