import { Form, Input, Select, Text } from '~src/components/display';
import { getValueAsFloat } from '~src/components/display/Form/formHelper';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { useLocalization } from '~src/hooks';
import { useEnergyOptions } from '~src/hooks/services';
import { getElectricHeatingOptions, getSecondaryHeatingOptions } from '~src/utilities/heatingType';

import {
  annualUsageFieldKey,
  efficiencyFieldKey,
  fuelSubtypeFieldKey,
  fuelTypeFieldKey,
  priceFieldKey,
  secondaryHeatingFieldKey,
  volumeUnitFieldKey,
} from '../constants';
import { updateFormFuelSubtypes } from '../helpers/updateFormFuelSubtypes';
import { useUpdateFormHeatingPricePerUnit } from '../hooks/useUpdateFormHeatingPricePerUnit';
import { ResidenceFormSection } from './ResidenceFormSection';

export const ResidenceFormSecondaryHeating = () => {
  const translate = useLocalization();

  const form = Form.useFormInstance();

  const updatePricePerUnit = useUpdateFormHeatingPricePerUnit(form, 'secondary');

  const { energyOptions } = useEnergyOptions();

  const formFuelType = Form.useWatch([secondaryHeatingFieldKey, fuelTypeFieldKey], form);

  const secondaryOptions = getSecondaryHeatingOptions(translate);

  const { efficiencyOptions, fuelSubtypeOptions, fuelSubtypeUnitOptions } =
    secondaryOptions.find(({ value }) => value === formFuelType) ?? {};

  const electricHeatingTypes = getElectricHeatingOptions(translate).map(({ value }) => value);

  const isElectricHeating = electricHeatingTypes.includes(formFuelType);

  const requiredFieldRules = useFormRequiredFieldRules();

  const fuelTypeDenominator =
    (energyOptions?.[formFuelType as keyof typeof energyOptions] as { denominator?: string })?.denominator ?? '';

  return (
    <ResidenceFormSection title={translate.SECONDARY_HEATING} columns noDivider>
      <Form.Item label={translate.SUPPLEMENTARY_HEATING} name={[secondaryHeatingFieldKey, fuelTypeFieldKey]}>
        <Select
          allowClear
          options={secondaryOptions}
          onSelect={value => {
            updateFormFuelSubtypes(translate, 'secondary', value, form);
            updatePricePerUnit({ fuelType: value });
          }}
        />
      </Form.Item>

      {!!formFuelType && (
        <>
          <Form.Item label={translate.CONDITION} name={[secondaryHeatingFieldKey, efficiencyFieldKey]}>
            <Select options={efficiencyOptions} disabled={efficiencyOptions?.length === 1} />
          </Form.Item>

          <Form.Item
            label={`${translate.PRICE}/${translate.UNIT}`}
            name={[secondaryHeatingFieldKey, priceFieldKey]}
            getValueFromEvent={getValueAsFloat}
          >
            <Input
              type='number'
              disabled={isElectricHeating}
              addonAfter={fuelTypeDenominator ? `DKK/${fuelTypeDenominator}` : 'DKK'}
            />
          </Form.Item>

          <div>
            <Form.Item
              label={`${translate.CONSUMPTION}/${translate.YEAR}`}
              name={[secondaryHeatingFieldKey, annualUsageFieldKey]}
              getValueFromEvent={getValueAsFloat}
              rules={requiredFieldRules}
            >
              <Input type='number' addonAfter={fuelTypeDenominator} />
            </Form.Item>

            {formFuelType === 'wood' && <Text>1 m3 = 700kg (0.7t)</Text>}
          </div>
        </>
      )}

      {!!fuelSubtypeOptions?.length && (
        <Form.Item label={translate.FUEL_TYPE} name={[secondaryHeatingFieldKey, fuelSubtypeFieldKey]}>
          <Select
            options={fuelSubtypeOptions}
            onSelect={value => {
              updatePricePerUnit({ fuelSubtype: value });
            }}
          />
        </Form.Item>
      )}

      {!!fuelSubtypeUnitOptions?.length && (
        <Form.Item label={translate.UNIT} name={[secondaryHeatingFieldKey, volumeUnitFieldKey]}>
          <Select
            options={fuelSubtypeUnitOptions}
            onSelect={value => {
              updatePricePerUnit({ volumeUnit: value });
            }}
          />
        </Form.Item>
      )}
    </ResidenceFormSection>
  );
};
