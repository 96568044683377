import { Roof } from '~src/types';

import { convertCentimetersToMeters, convertMetersToCentimeters } from './convert';

export const convertRoofsFromCentimetersToMeters = (roofs?: Roof[]) => roofs?.map(convertRoofFromCentimetersToMeters);

export const convertRoofFromCentimetersToMeters = <T extends Roof>(roof: T) =>
  convertRoofMeasurements(convertCentimetersToMeters, roof);

export const convertRoofsFromMetersToCentimeters = (roofs?: Roof[]) => roofs?.map(convertRoofFromMetersToCentimeters);

export const convertRoofFromMetersToCentimeters = <T extends Roof>(roof: T) =>
  convertRoofMeasurements(convertMetersToCentimeters, roof);

const convertRoofMeasurements = <T extends Roof>(
  convertFunction: (number: number) => number,
  { planeDepth, planeWidth, height, ...roof }: T
) => ({
  ...roof,
  planeDepth: convertFunction(planeDepth),
  planeWidth: convertFunction(planeWidth),
  ...(height && { height: convertFunction(height) }),
});
