import Cookies, { CookieAttributes } from 'js-cookie';

import { location } from '~src/utilities/global';

type CookieKey = 'accessToken' | 'refreshToken';

const getHostDomain = () =>
  location.hostname.includes('localhost') ? location.hostname : `${location.hostname.split('.').slice(1).join('.')}`;

const cookieOptions: Cookies.CookieAttributes = {
  domain: getHostDomain(),
  path: '/',
};

const keyWithSuffix = (key: CookieKey) => (location?.hostname?.includes('staging') ? `${key}-staging` : key);

export const setCookie = (key: CookieKey, value: string, options?: CookieAttributes) =>
  Cookies.set(keyWithSuffix(key), value, options);

export const getCookie = (key: CookieKey) => Cookies.get(keyWithSuffix(key));

export const clearCookie = (key: CookieKey) => {
  Cookies.remove(keyWithSuffix(key), cookieOptions);
  Cookies.remove(keyWithSuffix(key));
};
