import sumBy from 'lodash/sumBy';

import { Roof, SolarPanel } from '~src/types';

import { calculateMaximumSolarPanels, filterFalsy } from './calculationHelper';

type RoofSize = Pick<Roof, 'planeDepth' | 'planeWidth' | 'height' | 'maxPanelCoverage'>;

export const calculateMaximumNumberOfSolarPanels = <T extends RoofSize>(
  panel?: Pick<SolarPanel, 'size'>,
  roofs: (T | undefined)[] = [],
  roofConversion: (roof: T) => T = roof => roof
) => {
  const filteredRoofs = filterFalsy(roofs).map(roofConversion);

  if (!filteredRoofs.length || !panel) {
    return { withCoverage: 0, withoutCoverage: 0 };
  }

  const withCoverage = sumBy(filteredRoofs, roof =>
    calculateMaximumSolarPanels({ ...roof, maxPanelCoverage: roof.maxPanelCoverage ?? 100 }, panel)
  );

  const withoutCoverage = sumBy(filteredRoofs, roof =>
    calculateMaximumSolarPanels({ ...roof, maxPanelCoverage: 100 }, panel)
  );

  return { withCoverage, withoutCoverage };
};
