import { useState } from 'react';

import { OfferType, SortOrder } from '~src/types';

import { useFetchOverviewOffers } from './services/useOverview';

export const useSearchForOverviewOffers = () => {
  const [search, setSearch] = useState<string>('');
  const [showArchived, setShowArchived] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [orderBy, setOrderBy] = useState('id');
  const [orderDirection, setOrderDirection] = useState<SortOrder>('descending');
  const [productType, setProductType] = useState<OfferType | undefined>(undefined);

  const { offers, count, isLoading } = useFetchOverviewOffers({
    search,
    orderBy,
    offset: (page - 1) * pageSize,
    limit: pageSize,
    orderDirection,
    offerType: productType,
    archived: showArchived,
  });

  return {
    offers,
    count,
    search,
    page,
    pageSize,
    orderBy,
    orderDirection,
    productType,
    setSearch,
    setPage,
    setPageSize,
    setOrderBy,
    setOrderDirection,
    setProductType,
    isLoading,
    showArchived,
    setShowArchived,
  };
};
