import { Button } from '~src/components/display';
import { useNavigation } from '~src/hooks';
import { useLocalization } from '~src/hooks/useLocalization';
import { Offer } from '~src/types';

import { offerProgressControlsWrapper } from './offerProgressStages.module.css';

type SiteVisitActionsProps = {
  offer?: Pick<Offer, 'tag'>;
  isDisabled?: boolean;
};

export const SiteVisitActions = ({ offer, isDisabled }: SiteVisitActionsProps) => {
  const translate = useLocalization();
  const { navigateToSiteVisit } = useNavigation();

  if (!offer) {
    return null;
  }

  return (
    <div className={offerProgressControlsWrapper}>
      <Button
        disabled={!offer?.tag || isDisabled}
        type='primary'
        onClick={() => {
          navigateToSiteVisit(offer.tag);
        }}
      >
        {translate.OPEN_ON_SITE_VISIT_PAGE}
      </Button>
    </div>
  );
};
