export const BodilLogo = () => (
  <svg width='95' height='30' viewBox='0 0 95 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M37.699 21.4966C37.699 22.2158 37.5499 22.8736 37.2517 23.47C36.9711 24.0664 36.5764 24.575 36.0677 24.996C35.5766 25.417 34.9977 25.7415 34.3312 25.9695C33.6822 26.1976 32.9805 26.3116 32.2263 26.3116H23.3594V7.63059H32.6472C33.2963 7.63059 33.8839 7.77092 34.4101 8.05158C34.9363 8.33223 35.3836 8.70059 35.752 9.15665C36.1203 9.59517 36.401 10.1039 36.5939 10.6827C36.8044 11.244 36.9097 11.8141 36.9097 12.3929C36.9097 13.3226 36.6729 14.1821 36.1993 14.9714C35.7432 15.7608 35.0854 16.3484 34.2259 16.7343C35.2959 17.05 36.1379 17.6376 36.7518 18.4971C37.3833 19.3391 37.699 20.3389 37.699 21.4966ZM35.3047 21.0757C35.3047 20.6547 35.2258 20.2512 35.0679 19.8653C34.9276 19.4619 34.7258 19.1111 34.4627 18.8129C34.2172 18.5147 33.919 18.2779 33.5681 18.1025C33.2173 17.9271 32.8314 17.8394 32.4104 17.8394H25.7274V24.2593H32.2263C32.6648 24.2593 33.0682 24.1716 33.4366 23.9962C33.8049 23.8208 34.1295 23.5928 34.4101 23.3121C34.6908 23.0139 34.91 22.6719 35.0679 22.286C35.2258 21.9001 35.3047 21.4966 35.3047 21.0757ZM25.7274 9.68287V15.9186H31.6737C32.0947 15.9186 32.4806 15.8397 32.8314 15.6818C33.1822 15.5064 33.4804 15.2784 33.726 14.9977C33.9891 14.7171 34.1908 14.3926 34.3312 14.0242C34.489 13.6383 34.568 13.2349 34.568 12.8139C34.568 12.3754 34.4978 11.9632 34.3575 11.5773C34.2172 11.1914 34.0242 10.8581 33.7786 10.5775C33.5506 10.2968 33.2612 10.0775 32.9104 9.91967C32.5771 9.76181 32.2175 9.68287 31.8316 9.68287H25.7274Z'
      fill='#013433'
    />
    <path
      d='M49.1984 26.5747C48.1635 26.5747 47.2163 26.3818 46.3568 25.9959C45.5148 25.61 44.7869 25.0925 44.173 24.4435C43.5766 23.7945 43.1117 23.0402 42.7785 22.1807C42.4452 21.3212 42.2786 20.4179 42.2786 19.4707C42.2786 18.5059 42.4452 17.5938 42.7785 16.7343C43.1293 15.8748 43.6029 15.1205 44.1993 14.4715C44.8132 13.8225 45.5412 13.3051 46.3831 12.9192C47.2426 12.5333 48.181 12.3403 49.1984 12.3403C50.2158 12.3403 51.1542 12.5333 52.0137 12.9192C52.8732 13.3051 53.6012 13.8225 54.1976 14.4715C54.8115 15.1205 55.2851 15.8748 55.6184 16.7343C55.9692 17.5938 56.1446 18.5059 56.1446 19.4707C56.1446 20.4179 55.978 21.3212 55.6447 22.1807C55.3114 23.0402 54.8378 23.7945 54.2239 24.4435C53.6099 25.0925 52.8732 25.61 52.0137 25.9959C51.1718 26.3818 50.2333 26.5747 49.1984 26.5747ZM44.6466 19.497C44.6466 20.2161 44.7606 20.8827 44.9886 21.4966C45.2342 22.1106 45.5587 22.6456 45.9621 23.1016C46.3831 23.5577 46.8655 23.9173 47.4093 24.1804C47.9706 24.4435 48.5669 24.575 49.1984 24.575C49.8299 24.575 50.4175 24.4435 50.9613 24.1804C51.5226 23.9173 52.0137 23.5577 52.4347 23.1016C52.8557 22.628 53.1802 22.0842 53.4082 21.4703C53.6538 20.8388 53.7766 20.1635 53.7766 19.4444C53.7766 18.7427 53.6538 18.0849 53.4082 17.471C53.1802 16.8395 52.8557 16.2958 52.4347 15.8397C52.0137 15.3661 51.5226 14.9977 50.9613 14.7346C50.4175 14.4715 49.8299 14.34 49.1984 14.34C48.5669 14.34 47.9706 14.4803 47.4093 14.7609C46.8655 15.0241 46.3831 15.3924 45.9621 15.866C45.5587 16.3221 45.2342 16.8658 44.9886 17.4973C44.7606 18.1112 44.6466 18.7778 44.6466 19.497Z'
      fill='#013433'
    />
    <path
      d='M67.1371 26.5747C66.1899 26.5747 65.3128 26.3818 64.506 25.9959C63.6991 25.61 63.0062 25.0925 62.4274 24.4435C61.8485 23.7945 61.3925 23.0402 61.0592 22.1807C60.7435 21.3212 60.5856 20.4179 60.5856 19.4707C60.5856 18.5059 60.7347 17.5938 61.0329 16.7343C61.3486 15.8573 61.7784 15.0942 62.3221 14.4452C62.8834 13.7962 63.5412 13.2875 64.2955 12.9192C65.0673 12.5333 65.9092 12.3403 66.8214 12.3403C67.9264 12.3403 68.9087 12.6297 69.7682 13.2086C70.6453 13.7874 71.3381 14.4891 71.8468 15.3135V7.10437H74.1622V23.47C74.1622 24.0137 74.4078 24.2944 74.8989 24.3119V26.3116C74.706 26.3467 74.5306 26.373 74.3727 26.3905C74.2324 26.4081 74.1008 26.4168 73.978 26.4168C73.4518 26.4168 73.0308 26.2853 72.7151 26.0222C72.3993 25.7415 72.2327 25.4082 72.2152 25.0223L72.1625 23.7331C71.6188 24.6277 70.8908 25.3293 69.9787 25.838C69.0841 26.3291 68.1369 26.5747 67.1371 26.5747ZM67.6896 24.575C68.1106 24.575 68.5404 24.4961 68.9789 24.3382C69.4349 24.1804 69.8559 23.9611 70.2418 23.6805C70.6453 23.3998 70.9873 23.0753 71.268 22.7069C71.5662 22.321 71.7591 21.9176 71.8468 21.4966V17.5499C71.6889 17.1114 71.4521 16.6992 71.1364 16.3133C70.8207 15.9099 70.4611 15.5678 70.0576 15.2872C69.6717 14.989 69.2508 14.7609 68.7947 14.6031C68.3562 14.4277 67.9177 14.34 67.4791 14.34C66.795 14.34 66.1723 14.4891 65.611 14.7873C65.0673 15.0679 64.5937 15.4538 64.1902 15.945C63.8043 16.4186 63.5061 16.9711 63.2957 17.6026C63.0852 18.2165 62.9799 18.848 62.9799 19.497C62.9799 20.1811 63.1027 20.8388 63.3483 21.4703C63.5938 22.0842 63.9271 22.6192 64.3481 23.0753C64.7691 23.5314 65.2602 23.8997 65.8215 24.1804C66.4004 24.4435 67.0231 24.575 67.6896 24.575Z'
      fill='#013433'
    />
    <path
      d='M80.6345 26.3116V12.5771H82.9499V26.3116H80.6345ZM80.6345 9.99861V7.10437H82.9499V9.99861H80.6345Z'
      fill='#013433'
    />
    <path
      d='M89.2045 7.10437H91.5199V22.7596C91.5199 23.2683 91.6515 23.6717 91.9146 23.9699C92.1953 24.2505 92.5811 24.3909 93.0723 24.3909C93.2652 24.3909 93.4933 24.3646 93.7564 24.3119C94.0195 24.2418 94.2651 24.1628 94.4931 24.0751L94.8615 25.9432C94.4755 26.1011 94.0195 26.2327 93.4933 26.3379C92.967 26.4431 92.5022 26.4958 92.0988 26.4958C91.2042 26.4958 90.4938 26.2414 89.9676 25.7327C89.4589 25.2241 89.2045 24.5137 89.2045 23.6015V7.10437Z'
      fill='#013433'
    />
    <path
      d='M13.7159 21.3431C16.7118 13.4498 13.4439 4.34184 5.81317 0.265726C5.64135 0.173496 5.46868 0.084919 5.29518 0L4.69972 1.11773C4.87407 1.20208 5.04672 1.28644 5.21854 1.38176C12.0828 5.04875 15.1317 13.1132 12.7549 20.2709C12.2233 19.7257 11.6564 19.2162 11.0578 18.7457C12.6118 12.7564 9.93847 6.2407 4.24912 3.20218C4.08067 3.11023 3.9038 3.0225 3.7303 2.93898L3.13483 4.05586C3.31002 4.14022 3.48267 4.22458 3.65533 4.31821C8.66668 6.99569 11.0999 12.6434 9.95026 17.946C9.34331 17.5434 8.71117 17.1802 8.05774 16.8587C8.37645 14.7351 8.03123 12.5649 7.06944 10.6457C6.10765 8.72646 4.57642 7.1524 2.68591 6.13948C2.51353 6.04669 2.33975 5.95979 2.16456 5.87881L1.56825 6.99822C1.74428 7.07668 1.91864 7.16694 2.0913 7.25129C3.69907 8.11267 5.01204 9.43707 5.86063 11.0534C6.70921 12.6698 7.05441 14.5039 6.85164 16.3188C6.19546 16.057 5.52342 15.8369 4.83953 15.66C4.90811 14.3215 4.59495 12.9912 3.93663 11.8244C3.2783 10.6576 2.30203 9.70255 1.12187 9.07087C0.948934 8.97921 0.774009 8.89485 0.597138 8.8178L0 9.93974C0.179972 10.0131 0.355964 10.0959 0.527245 10.1878C1.46458 10.6892 2.24586 11.4396 2.78535 12.3565C3.32483 13.2735 3.60166 14.3216 3.58544 15.3858C2.59411 15.2083 1.58902 15.1191 0.581983 15.1192C0.387144 15.1192 0.193155 15.1226 0 15.1294V16.3947C0.192874 16.3871 0.387425 16.3837 0.581983 16.3837C2.78925 16.3815 4.97207 16.8466 6.98725 17.7486L7.77054 18.1704C7.77054 18.1578 7.77727 18.146 7.78064 18.1333C10.0273 19.2931 11.9631 20.9761 13.4254 23.0409C14.8876 25.1056 15.8336 27.4919 16.1837 29.9989H17.4589C17.0528 26.8174 15.7551 23.8162 13.7159 21.3431Z'
      fill='url(#paint0_linear_136:2604)'
    />
    <path
      d='M0.585889 18.4513C0.390489 18.4513 0.196499 18.4555 0.00390625 18.4639V19.7293C0.196499 19.7197 0.390489 19.7152 0.585889 19.7158C6.70226 19.7158 11.7995 24.1639 12.8245 29.9997H14.1047C13.0629 23.4638 7.39627 18.4513 0.585889 18.4513Z'
      fill='#FFA346'
    />
    <path
      d='M0.585889 21.7819C0.390489 21.7819 0.196499 21.7872 0.00390625 21.7979V23.0632C0.195938 23.0497 0.390489 23.0438 0.585889 23.0438C2.62398 23.0466 4.6024 23.7331 6.20536 24.9938C7.80832 26.2545 8.94341 28.0166 9.42945 29.999H10.7257C10.2273 27.6751 8.94938 25.5923 7.10448 24.0972C5.25958 22.6021 2.95906 21.785 0.585889 21.7819Z'
      fill='#FFA346'
    />
    <path
      d='M0.585889 25.1073C0.389647 25.1073 0.195938 25.1157 0.00390625 25.1309V26.4022C1.2501 26.2757 2.50358 26.5581 3.57559 27.207C4.64759 27.8559 5.48005 28.8361 5.94761 30H7.29016C6.83208 28.5796 5.93656 27.3411 4.73211 26.4621C3.52766 25.5831 2.07616 25.1088 0.585889 25.1073Z'
      fill='#FFA346'
    />
    <defs>
      <linearGradient
        id='paint0_linear_136:2604'
        x1='9.3097'
        y1='7.50013'
        x2='9.43545'
        y2='17.4196'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#01918E' />
        <stop offset='1' stopColor='#FFA346' />
      </linearGradient>
    </defs>
  </svg>
);
