import { useEffect, useState } from 'react';

import { useUpsertResidence } from '~src/hooks/services/useResidence';
import { MaybeId, ResidenceInput } from '~src/types';

export const useElectricityPrice = (
  setResidence?: (inputResidence?: ResidenceInput & MaybeId) => void,
  residence?: ResidenceInput & MaybeId,
  customerID?: number
) => {
  const [electricityPrice, setElectricityPrice] = useState(0);
  const [manuallySet, setManuallySet] = useState(false);

  const { upsertResidence } = useUpsertResidence();

  useEffect(
    function setDefaultElectricityPrice() {
      const { electricityPrice: residenceElectricityPrice } = residence ?? {};

      if (!manuallySet && residenceElectricityPrice && residenceElectricityPrice !== electricityPrice) {
        setElectricityPrice(residenceElectricityPrice);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [residence?.electricityPrice]
  );

  const updateElectricityPrice = async (updatedPrice: number) => {
    setElectricityPrice(updatedPrice);
    setManuallySet(true);

    if (!residence) {
      return;
    }

    setResidence?.({ ...residence, electricityPrice });

    if (!customerID) {
      return;
    }

    const updatedResidence = { ...residence, electricityPrice: updatedPrice };
    await upsertResidence(customerID, updatedResidence);
  };

  return { electricityPrice, updateElectricityPrice };
};
