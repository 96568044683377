/* eslint-disable react/no-unused-prop-types */

import clsx from 'clsx';
import { ReactNode } from 'react';

import { heading, paragraph } from './text.module.css';

type TextProps = { children?: ReactNode; className?: string; level?: number };

export const Heading = ({ children, className, level }: TextProps) => {
  const combinedClassName = clsx(heading, className);

  switch (level) {
    case 2: {
      return <h2 className={combinedClassName}>{children}</h2>;
    }

    case 3: {
      return <h3 className={combinedClassName}>{children}</h3>;
    }

    case 4: {
      return <h4 className={combinedClassName}>{children}</h4>;
    }

    case 5: {
      return <h5 className={combinedClassName}>{children}</h5>;
    }

    case 1:
    default: {
      return <h1 className={combinedClassName}>{children}</h1>;
    }
  }
};

export const Text = ({ children, className }: TextProps) => <p className={clsx(paragraph, className)}>{children}</p>;
