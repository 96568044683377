import { Heading, ImageThumbnail, Input, Radio, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionHeatSource = ({
  answerMap,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        4. {translate.SITE_VISIT_SECTION_FURNACE}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_FURNACE_DISMANTLE}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('canFurnaceBeDismantled', event?.target?.value);
          }}
          value={answerMap?.canFurnaceBeDismantled?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.canFurnaceBeDismantled?.answer === 'false'}
          text={translate.SITE_VISIT_DISCLAIMER_FURNACE_DISMANTLE}
          type='expense'
        />

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('heatSourceNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.heatSourceNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.heatSource)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
