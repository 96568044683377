import omit from 'lodash/omit';
import { ReactNode, useMemo, useState } from 'react';

import { capitalizeFirst } from '~src/utilities/format';

import { Card } from '../Card';
import { EditableCell } from './EditableCell';
import { EditableTableButtons } from './EditableTableButtons';
import { Table } from './Table';
import { TableItem } from './types';

type TableButton = {
  buttonText: string;
  loading?: boolean;
  onClick: (idList?: number[]) => void;
  requireSelection?: boolean;
  tooltip?: string;
  type?: 'default' | 'primary';
};

type EditableTableProps<T extends TableItem> = {
  buttonList?: TableButton[];
  columnTitleTranslations?: Record<string, string>;
  disabled?: boolean;
  editableList?: (keyof T)[];
  itemList: T[];
  onChangeItem?: (item: Omit<T, 'key'>) => void;
  onRowClick?: (item: T) => void;
  showHeader?: boolean;
  title?: string;
  isLoading?: boolean;
  extraHeaderContent?: ReactNode;
  rowKey?: keyof T;
};

export const EditableTable = <T extends TableItem>({
  buttonList,
  columnTitleTranslations,
  disabled = false,
  editableList = [],
  itemList,
  onChangeItem,
  onRowClick,
  showHeader = false,
  title,
  isLoading = false,
  extraHeaderContent,
  rowKey,
}: EditableTableProps<T>) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const columns = useMemo(
    () => getColumnsFromList<T>(itemList, editableList, columnTitleTranslations, onChangeItem),
    [columnTitleTranslations, editableList, itemList, onChangeItem]
  );

  const dataSource = useMemo(() => getDataSourceFromList<T>(itemList), [itemList]);

  const handleOnRowClick = onRowClick
    ? (item: T) => ({
        onClick: () => {
          onRowClick(item);
        },
      })
    : undefined;

  const tableComponents = disabled ? {} : { body: { cell: EditableCell<T> } };

  const selectButtonsDisabled = !selectedRows.length;

  return (
    <Card
      title={title}
      extra={
        <div>
          <EditableTableButtons
            buttonList={buttonList?.map(button => ({ ...button, onClick: () => button.onClick(selectedRows) }))}
            selectButtonsDisabled={selectButtonsDisabled}
            disabled={disabled}
            extraContent={extraHeaderContent}
          />
        </div>
      }
    >
      <Table
        loading={isLoading}
        components={tableComponents}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onRow={handleOnRowClick}
        rowKey={item => `${rowKey ? item[rowKey] : item.id}`}
        rowSelection={{
          hideSelectAll: true,
          onChange: selectedIdList => {
            setSelectedRows(selectedIdList.map(Number));
          },
          getCheckboxProps: row => ({ disabled: row.lockedRow }),
        }}
        showHeader={showHeader}
      />
    </Card>
  );
};

const getColumnsFromList = <T extends TableItem>(
  list: T[],
  editableList: (keyof T)[],
  columnTitleTranslations?: Record<string, string>,
  onSave?: (item: Omit<T, 'key'>) => void
) => {
  const keySet = new Set(
    list.flatMap(item =>
      Object.keys(item)
        .filter(key => !columnTitleTranslations || columnTitleTranslations?.[key])
        .map(key => key)
    )
  );

  const editableKeySet = new Set(editableList);

  return Array.from(keySet).map(key => ({
    title: capitalizeFirst(columnTitleTranslations?.[key] || key),
    dataIndex: key,
    onCell: (record: T) => ({
      record,
      editable: editableKeySet.has(key as keyof T) && !record.lockedRow,
      dataIndex: key,
      title: capitalizeFirst(columnTitleTranslations?.[key] || key),
      handleSave: (value: string) => {
        onSave?.(omit({ ...record, [key]: value }, 'key'));
      },
    }),
    key,
  }));
};

const getDataSourceFromList = <T extends TableItem>(list: T[]) => list.map(item => ({ ...item, key: item.id }));
