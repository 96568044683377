import { useFetchOfferQuery, useMarkOfferAsSentMutation } from '~src/gql';
import { useGraphQLClient, useQueryClient } from '~src/services/client';
import { Offer, OfferPdfTypeEnum } from '~src/types';

import { useToastMessageErrorHandler } from '../useToastMessage';

export const useMarkOfferAsSent = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useMarkOfferAsSentMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
    onMutate: ({ offerId, type }) => {
      const queryKey = useFetchOfferQuery.getKey({ offerId });
      const cache = queryClient.getQueryData<{ offer: Offer }>(queryKey);

      const updatedOffer = {
        ...cache?.offer,
        sentOffers: [...(cache?.offer?.sentOffers ?? []), { type, sentAt: Date.now() }],
      };

      return queryClient.setQueryData(queryKey, { offer: updatedOffer });
    },
  });

  const markOfferAsSent = async (offerId: number, type: OfferPdfTypeEnum) => {
    const result = await mutateAsync({ offerId, type });
    return result.markOfferAsSent;
  };

  return { markOfferAsSent, isLoading };
};
