import { Heading, ImageThumbnail, Input, Radio, Select, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { RADIATOR_PIPE_SIZE_OPTIONS, SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionRadiators = ({
  answerMap,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        3. {translate.SITE_VISIT_SECTION_RADIATOR}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_HEAT_SYSTEM}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('systemStringNumber', event?.target?.value);
          }}
          value={answerMap?.systemStringNumber?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='1'>{translate.SITE_VISIT_ANSWER_STRINGS_1}</Radio.Button>
          <Radio.Button value='2'>{translate.SITE_VISIT_ANSWER_STRINGS_2}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.systemStringNumber?.answer === '1'}
          text={translate.SITE_VISIT_DISCLAIMER_SYSTEM_STRINGS}
        />

        {answerMap?.systemStringNumber?.answer === '1' && (
          <>
            <Text>{translate.SITE_VISIT_QUESTION_HEAT_SYSTEM_BYPASS_VALVES}</Text>
            <Radio.Group
              onChange={event => {
                updateAnswer('bypassValves', event?.target?.value);
              }}
              value={answerMap?.bypassValves?.answer}
              disabled={isLoading}
            >
              <Radio.Button value='true'>{translate.YES}</Radio.Button>
              <Radio.Button value='false'>{translate.NO}</Radio.Button>
            </Radio.Group>
            <SiteVisitNote
              display={answerMap?.bypassValves?.answer === 'false'}
              text={translate.SITE_VISIT_DISCLAIMER_BYPASS_VALVES}
              type='expense'
            />
          </>
        )}

        <Text>{translate.SITE_VISIT_QUESTION_PIPE_DIMENSIONS}:</Text>
        <Select
          onSelect={answer => {
            updateAnswer('radiatorPipingSize', answer);
          }}
          options={RADIATOR_PIPE_SIZE_OPTIONS}
          value={answerMap?.radiatorPipingSize?.answer}
        />
        <SiteVisitNote
          display={['¼"', '10mm'].includes(answerMap?.radiatorPipingSize?.answer ?? '')}
          text={translate.SITE_VISIT_DISCLAIMER_PIPE_SIZE}
        />

        <Text>{translate.SITE_VISIT_QUESTION_REPLACE_RADIATORS}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('replaceRadiators', event?.target?.value);
          }}
          value={answerMap?.replaceRadiators?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.replaceRadiators?.answer === 'true'}
          text={translate.SITE_VISIT_DISCLAIMER_REPLACE_RADIATOR}
          type='expense'
        />

        <Text>{translate.SITE_VISIT_QUESTION_REPLACE_FURNACE_WITH_RADIATOR}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('replaceFurnaceWithRadiator', event?.target?.value);
          }}
          value={answerMap?.replaceFurnaceWithRadiator?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.replaceFurnaceWithRadiator?.answer === 'true'}
          text={translate.SITE_VISIT_DISCLAIMER_FURNACE_RADIATOR}
          type='expense'
        />

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('radiatorsNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.radiatorsNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.radiators)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
