import last from 'lodash/last';
import omit from 'lodash/omit';
import sumBy from 'lodash/sumBy';

import { CalculatorOutlined, CloudOutlined, DollarOutlined, TagsOutlined } from '~src/components/display/Icons';
import { InfoBox } from '~src/components/display/InfoBox';
import { useLocalization } from '~src/hooks';
import { useOfferSavings } from '~src/hooks/services';
import { Customer, mapOfferToOfferInput, OfferProducts, ResidenceInput } from '~src/types';
import { calculateOfferPrice, calculatePaybackTime } from '~src/utilities/calculationHelper';
import { consumptionProfiles, DEFAULT_CONSUMPTION_PROFILE_KEY } from '~src/utilities/constants';
import { formatCurrency, formatNumber, withVAT } from '~src/utilities/format';

import { SavingsBox } from './SavingsBox';

import { statisticsBox, statisticsHorizontal } from './offerStatistics.module.css';

type OfferStatisticsProps = {
  layout?: 'box' | 'horizontal';
  offerProducts?: OfferProducts & { price?: number };
  residence?: ResidenceInput;
  customer?: Pick<Customer, 'financingProvider'>;
};

export const OfferStatistics = ({
  layout = 'horizontal',
  offerProducts,
  residence,
  customer,
}: OfferStatisticsProps) => {
  const translate = useLocalization();

  const { consumptionProfile, address } = residence ?? {};

  const price = withVAT(offerProducts?.price ?? calculateOfferPrice(offerProducts));

  const priceWithoutFees =
    price -
    sumBy(
      offerProducts?.expenses?.filter(({ type }) => type === 'required'),
      ({ price: expensePrice }) => withVAT(expensePrice)
    );

  const offerInputForSavings = mapOfferToOfferInput(omit(offerProducts, 'expenses'));

  const { savings } = useOfferSavings(
    residence,
    offerInputForSavings,
    address,
    undefined,
    consumptionProfiles[consumptionProfile ?? DEFAULT_CONSUMPTION_PROFILE_KEY],
    residence?.customerID
  );

  const { emission, price: savedPerYear, priceByYear } = savings?.saved ?? {};

  const { paybackFormatted } = calculatePaybackTime(
    price ?? 0,
    years => priceByYear?.[years] ?? last(priceByYear) ?? 0
  );

  const wrapperClassName = layout === 'horizontal' ? statisticsHorizontal : statisticsBox;

  return (
    <div className={wrapperClassName}>
      <InfoBox
        title={translate.PRICE}
        icon={<TagsOutlined />}
        value={formatCurrency(price ?? 0, { decimals: 0 })}
        subtitle={`(${translate.WITHOUT_FEES}: ${formatCurrency(priceWithoutFees, { decimals: 0 })})`}
      />

      <SavingsBox
        financingProvider={customer?.financingProvider}
        productType={offerProducts?.heatPumpOutdoorUnit ? 'heatPump' : 'solar'}
        savedPerYear={savedPerYear}
        totalPrice={price}
      />

      {layout === 'horizontal' ? (
        <InfoBox
          title={`${translate.PROFIT} ${translate.AFTER.toLowerCase()} 15 ${translate.YEARS.toLowerCase()}`}
          icon={<DollarOutlined />}
          value={formatCurrency((savedPerYear ?? 0) * 15, { decimals: 0 })}
        />
      ) : null}

      <InfoBox title={translate.PAYBACK_TIME} icon={<CalculatorOutlined />} value={paybackFormatted} />

      <InfoBox
        title={translate.YEARLY_CO2_SAVINGS}
        icon={<CloudOutlined />}
        value={`${formatNumber(1e-3 * (emission ?? 0))} ${translate.TONNE}`}
      />
    </div>
  );
};
