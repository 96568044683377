import { useMemo, useState } from 'react';

import { useEnergyOptions } from '~src/hooks/services';
import { HeatPumpOutdoorUnit, MaybeId, ResidenceInput, WaterConsumptionDegreeEnum } from '~src/types';
import { calculateHeatPumpScores, calculateResidenceHeatingRequired } from '~src/utilities/calculationHelper';
import { MINUS_12_TO_MINUS_7_RATIO } from '~src/utilities/constants';
import { deepNullToUndefined } from '~src/utilities/convert';

export const useScoredHeatPumps = (
  heatPumpOutdoorUnits?: Array<HeatPumpOutdoorUnit>,
  residence?: ResidenceInput & MaybeId
) => {
  const [brand, setBrand] = useState('');
  const [chosenHeating, setChosenHeating] = useState(0);
  const [noiseWeight, setNoiseWeight] = useState<WaterConsumptionDegreeEnum>('small');
  const { energyOptions } = useEnergyOptions(residence?.id);

  const { heatPumpList, bestFit } = useMemo(() => {
    if (!residence || !heatPumpOutdoorUnits) {
      const heatPumps = heatPumpOutdoorUnits ?? [];

      return { heatPumpList: heatPumps, bestFit: heatPumps[0] };
    }

    const heatPumpsWithBrand = brand
      ? heatPumpOutdoorUnits.filter(heatPump => heatPump.brand === brand)
      : heatPumpOutdoorUnits;

    const calculatorResidence = { ...residence, noiseWeight };
    const heatingRequired = calculateResidenceHeatingRequired(calculatorResidence, energyOptions);

    const chosenHeatingMinus12 = chosenHeating ? chosenHeating / MINUS_12_TO_MINUS_7_RATIO : undefined;

    const { available } = calculateHeatPumpScores(
      heatPumpsWithBrand,
      calculatorResidence,
      chosenHeatingMinus12 || heatingRequired
    );

    const heatPumps = chosenHeating ? available.map(deepNullToUndefined) : heatPumpOutdoorUnits;
    const [first] = available;

    return {
      heatPumpList: heatPumps,
      bestFit: first ? (deepNullToUndefined(first) as HeatPumpOutdoorUnit) : undefined,
    };
  }, [brand, chosenHeating, heatPumpOutdoorUnits, noiseWeight, residence, energyOptions]);

  return {
    heatPumpList,
    setBrand,
    setChosenHeating,
    setNoiseWeight,
    bestFit,
  };
};
