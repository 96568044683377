import { OfferPdfTypeEnum, useRegeneratePreviewMutation } from '~src/gql';
import { useGraphQLClient, useQueryClient } from '~src/services/client';

import { useToastMessageErrorHandler } from '../useToastMessage';
import { updateOfferCache } from './useOffer';

export const useRegeneratePreview = () => {
  const queryClient = useQueryClient();
  const onError = useToastMessageErrorHandler();
  const { graphQLClient } = useGraphQLClient();
  const { mutateAsync, isLoading } = useRegeneratePreviewMutation(graphQLClient);

  const regeneratePreview = async (offerId: number, type: OfferPdfTypeEnum) => {
    const response = await mutateAsync(
      { offerId, type },
      {
        onError,
        onSuccess: data => {
          const url = data.regeneratePreview;

          updateOfferCache(queryClient, offerId, offer => ({
            ...offer,
            preliminaryPreview: type === 'preliminary' ? url : offer?.preliminaryPreview,
            finalPreview: type === 'final' ? url : offer?.finalPreview,
          }));
        },
      }
    );

    return response.regeneratePreview;
  };

  return { regeneratePreview, isLoading };
};
