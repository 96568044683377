import { Heading, ImageThumbnail } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideSectionProps } from './types';

import { imageRow, installationImage } from './installationGuide.module.css';

type InstallationGuideImagesProps = Pick<InstallationGuideSectionProps, 'imageList'>;

export const InstallationGuideImages = ({ imageList }: InstallationGuideImagesProps) => {
  const translate = useLocalization();

  if (imageList?.length === 0 || !imageList) {
    return null;
  }

  return (
    <div>
      <Heading level={2}>{translate.IMAGES}:</Heading>

      <div className={imageRow}>
        {imageList?.map(image => <ImageThumbnail className={installationImage} key={image.url} image={image} />)}
      </div>
    </div>
  );
};
