import { OfferPdfPreviewLink } from '~src/components/features/shared/offer/OfferPdfPreviewLink';
import { useRegeneratePreview } from '~src/hooks/services';
import { Customer, Offer, OFFER_BASE_KEYS } from '~src/types';

import { SiteVisitActions } from './stages/SiteVisitActions';

import { offerProgressControlsWrapper } from './offerActions.module.css';

type OfferActionsProps = {
  offer?: Pick<Offer, (typeof OFFER_BASE_KEYS)[number] | 'residenceID' | 'tag'> & {
    customer?: Pick<Customer, 'id' | 'firstName' | 'lastName' | 'norlysPromotion'>;
  };
};

export const OfferActions = ({ offer }: OfferActionsProps) => {
  const { regeneratePreview, isLoading } = useRegeneratePreview();

  if (!offer) {
    return null;
  }

  return (
    <>
      <div className={offerProgressControlsWrapper}>
        <OfferPdfPreviewLink
          isLoading={isLoading}
          onRegenerate={type => regeneratePreview(offer.id, type)}
          type='preliminary'
          offer={offer}
        />
      </div>

      <SiteVisitActions isDisabled={isLoading} offer={offer} />

      <div className={offerProgressControlsWrapper}>
        <OfferPdfPreviewLink
          isLoading={isLoading}
          onRegenerate={type => regeneratePreview(offer.id, type)}
          type='final'
          offer={offer}
        />
      </div>
    </>
  );
};
