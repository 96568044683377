import { AddressInput, ElectricCarInput, MaybeId, ResidenceHeatingInput, ResidenceInput } from '~src/types';
import { HEAT_PUMP_AIR_TO_AIR_EFFICIENCY, HEAT_PUMP_AIR_TO_WATER_EFFICIENCY } from '~src/utilities/constants';
import { convertRoofsFromMetersToCentimeters } from '~src/utilities/roof';

export type FetchProductParameters = {
  customerID?: number;
  email?: string;
  limit?: number;
};

export const convertResidenceUnitsAndApplyFallbackValues = (residence: ResidenceInput) => {
  const { roofs, primaryHeating, secondaryHeating, waterConsumption, electricCar, ...rest } = residence;

  const carHasNoValues = Object.values(electricCar ?? {}).filter(value => value).length === 0;

  return {
    id: undefined,
    ...rest,
    roofs: convertRoofsFromMetersToCentimeters(roofs),
    secondaryHeating: convertHeating(secondaryHeating),
    primaryHeating: convertHeating(primaryHeating) ?? { annualUsage: 0, fuelType: '' },
    waterConsumption: waterConsumption ?? 'small',
    electricCar: !electricCar || carHasNoValues ? undefined : toValidElectricCarInput(electricCar),
  };
};

const convertHeating = (heating?: ResidenceHeatingInput): ResidenceHeatingInput | undefined => {
  if (!heating || !heating?.fuelType) {
    return undefined;
  }

  return {
    ...heating,
    heatSource: convertHeatSource(heating),
  };
};

const convertHeatSource = (heating: ResidenceHeatingInput) => {
  const { fuelType, efficiency } = heating;

  if (
    fuelType !== 'electricity' ||
    ![HEAT_PUMP_AIR_TO_WATER_EFFICIENCY, HEAT_PUMP_AIR_TO_AIR_EFFICIENCY].includes(efficiency ?? 0)
  ) {
    return undefined;
  }

  if (efficiency === HEAT_PUMP_AIR_TO_AIR_EFFICIENCY) {
    return 'heatPump-air-to-air';
  }

  return 'heatPump-air-to-water';
};

export const toValidAddressInput = (input?: Partial<AddressInput>): AddressInput | undefined => {
  const { city, street, postalCode, houseNumber, floor, door } = input ?? {};
  if (!city || !street || !houseNumber || !postalCode) {
    return undefined;
  }

  return { city, street, postalCode, houseNumber, floor, door };
};

const toValidElectricCarInput = (electricCar: ElectricCarInput & MaybeId): ElectricCarInput => {
  const { annualKilometersDriven, percentageCharged, consumptionPerKilometer, isSubsidised, monthlySubscriptionPrice } =
    electricCar;

  return {
    annualKilometersDriven,
    percentageCharged,
    consumptionPerKilometer,
    isSubsidised,
    monthlySubscriptionPrice,
  };
};
