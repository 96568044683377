import { useHeatPumpIndoorUnitsQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { useLocaleState } from '~src/state/useLocaleState';
import { deepNullToUndefined } from '~src/utilities/convert';

import { getProductSorter } from './productHelper';

export const useHeatPumpIndoorUnits = (ids?: number[]) => {
  const [locale] = useLocaleState();
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useHeatPumpIndoorUnitsQuery(
    graphQLClient,
    { ids },
    { onError: useToastMessageErrorHandler() }
  );

  const heatPumpIndoorUnits = data?.heatPumpIndoorUnits?.map(deepNullToUndefined)?.sort(getProductSorter(locale)) ?? [];

  return { heatPumpIndoorUnits, isLoading: isFetching };
};
