import clsx from 'clsx';
import { ReactNode, useState } from 'react';

import { Button } from '../Button';
import { MoreOutlined } from '../Icons';

import { burgerMenu, burgerMenuContent, burgerMenuWrapper, left } from './menu.module.css';

type BurgerMenuProps = {
  align?: 'left' | 'right';
  children?: ReactNode;
};

export const BurgerMenu = ({ align = 'right', children }: BurgerMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={burgerMenuWrapper}>
      <Button type='default' onClick={() => setShowMenu(!showMenu)}>
        <MoreOutlined />
      </Button>

      {showMenu ? (
        <div className={clsx(burgerMenu, align === 'left' && left)}>
          <div className={clsx(burgerMenuContent, align === 'left' && left)}>{children}</div>
        </div>
      ) : null}
    </div>
  );
};
