import { create } from 'zustand';

import {
  Battery,
  HeatPumpIndoorUnit,
  HeatPumpOutdoorUnit,
  Inverter,
  OfferExpenseInput,
  OfferType,
  SolarPanel,
} from '~src/types';

type State = {
  selectedHeatPumpIndoorUnit?: HeatPumpIndoorUnit;
  selectedHeatPumpOutdoorUnit?: HeatPumpOutdoorUnit;

  selectedSolarPanel?: SolarPanel;
  selectedInverter?: Inverter;
  selectedBattery?: Battery;

  selectedOfferExpenses: Array<OfferExpenseInput>;
  selectedOfferType: OfferType;
};

type Actions = {
  selectHeatPumpIndoorUnit: (unit?: HeatPumpIndoorUnit) => void;
  selectHeatPumpOutdoorUnit: (unit?: HeatPumpOutdoorUnit) => void;

  selectSolarPanel: (panel?: SolarPanel) => void;
  selectInverter: (inverter?: Inverter) => void;
  selectBattery: (battery?: Battery) => void;

  setOfferExpenses: (expenseList: Array<OfferExpenseInput>) => void;
  setOfferType: (type: OfferType) => void;
};

type CreateOfferProductState = State & Actions;

const initialState: State = {
  selectedHeatPumpOutdoorUnit: undefined,

  selectedSolarPanel: undefined,
  selectedInverter: undefined,
  selectedBattery: undefined,

  selectedOfferExpenses: [],
  selectedOfferType: 'heatPump',
};

export const useOfferProductState = create<CreateOfferProductState>(set => ({
  ...initialState,

  selectHeatPumpIndoorUnit: (selectedHeatPumpIndoorUnit?: HeatPumpIndoorUnit) => set({ selectedHeatPumpIndoorUnit }),
  selectHeatPumpOutdoorUnit: (selectedHeatPumpOutdoorUnit?: HeatPumpOutdoorUnit) =>
    set({ selectedHeatPumpOutdoorUnit }),

  selectSolarPanel: (selectedSolarPanel?: SolarPanel) => set({ selectedSolarPanel }),
  selectInverter: (selectedInverter?: Inverter) => set({ selectedInverter }),
  selectBattery: (selectedBattery?: Battery) => set({ selectedBattery }),

  setOfferExpenses: (selectedOfferExpenses: Array<OfferExpenseInput>) => set({ selectedOfferExpenses }),
  setOfferType: (selectedOfferType: OfferType) => set({ selectedOfferType }),
}));
