import { Divider as AntdDivider, DividerProps as AntdDividerProps } from 'antd';
import clsx from 'clsx';

import { divider, dividerFullWidth, dividerMarginBottom } from './divider.module.css';

type DividerProps = AntdDividerProps & {
  fullWidth?: boolean;
  marginBottom?: boolean;
};

export const Divider = ({ children, fullWidth, marginBottom, ...rest }: DividerProps) => (
  <AntdDivider
    {...{ rest }}
    className={clsx(divider, fullWidth && dividerFullWidth, marginBottom && dividerMarginBottom)}
  >
    {children}
  </AntdDivider>
);
