import { useSolarPanelsQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { useLocaleState } from '~src/state';
import { deepNullToUndefined } from '~src/utilities/convert';

import { getProductSorter } from './productHelper';

export const useSolarPanels = (ids?: number[]) => {
  const [locale] = useLocaleState();
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useSolarPanelsQuery(graphQLClient, { ids }, { onError: useToastMessageErrorHandler() });

  return {
    solarPanels: data?.solarPanels?.map(deepNullToUndefined).sort(getProductSorter(locale)) ?? [],
    isLoading: isFetching,
  };
};
