import max from 'lodash/max';
import { useState } from 'react';

import { EditableTable, Form, Input, Modal, Select } from '~src/components/display';
import { getValueAsInteger } from '~src/components/display/Form/formHelper';
import { useLocalization } from '~src/hooks';
import { useLocaleState } from '~src/state';
import { withVAT } from '~src/utilities/format';

import { getExpenseItemSorter } from './convert';
import { ExpenseTableItem, OfferExpenseTableProps } from './types';
import { useColumnTitleTranslations } from './useColumnTitleTranslations';
import { useExpenseOptions } from './useExpenseOptions';

import { displayColumn } from '~src/style/shared.module.css';

import { expenseModalForm } from './OfferExpenseTable.module.css';

const EXPENSE_KEY = 'id';
const QUANTITY_KEY = 'quantity';
const PRICE_KEY = 'price';
const TITLE_KEY = 'title';

const TITLE_MAX_LENGTH = 255;

const CUSTOM_EXPENSE_ID = -1;

type ExpenseFormData = {
  [EXPENSE_KEY]?: number;
  [QUANTITY_KEY]: number;
  [PRICE_KEY]: number;
  [TITLE_KEY]?: string;
};

const EDITABLE_COLUMNS: (keyof ExpenseTableItem)[] = ['notes', 'price', 'quantity'];

type Props = OfferExpenseTableProps & {
  expenses?: ExpenseTableItem[];
  updateExpenses?: (expenses: ExpenseTableItem[]) => Promise<void>;
};

export const OfferExpenseTable = ({
  expenses,
  isLoading = false,
  tableTitle,
  updateExpenses,
  deleteExpenses,
  possibleTemplates,
  allowAdd = true,
}: Props) => {
  const translate = useLocalization();
  const [locale] = useLocaleState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomExpense, setIsCustomerExpense] = useState(false);
  const [denominator, setDenominator] = useState(translate.PIECE_DENOMINATOR);
  const [form] = Form.useForm<ExpenseFormData>();

  const { expenseOptions: expenseOptionList } = useExpenseOptions(possibleTemplates, expenses);
  const { columnTitleTranslations } = useColumnTitleTranslations();

  const expenseItemList = expenses ?? [];

  const handleAddItem = ({ id, price, quantity, title }: ExpenseFormData) => {
    const selectedTemplate = possibleTemplates.find(expense => expense.id === id);

    const newExpense: ExpenseTableItem = {
      id: (max(expenses?.map(expense => expense.id)) ?? 0) + 1,
      templateID: selectedTemplate?.id,
      title: selectedTemplate?.title ?? title ?? '',
      price,
      priceWithVAT: withVAT(price),
      quantity,
      notes: '',
    };

    const newList = [...expenseItemList, newExpense];
    updateExpenses?.(newList);

    setIsCustomerExpense(false);
    setIsModalOpen(false);
  };

  const handleChangeItem = (expense: ExpenseTableItem) => {
    const newExpense: ExpenseTableItem = {
      ...expense,
      quantity: Math.max(expense.quantity, 1),
      priceWithVAT: withVAT(expense.price),
    };

    const newList = [...expenseItemList.filter(({ id }) => id !== expense.id), newExpense];
    updateExpenses?.(newList);
  };

  const handleDeleteItems = async (idList?: number[]) => {
    if (!idList?.length) {
      return;
    }

    if (deleteExpenses) {
      await deleteExpenses(idList);
      return;
    }

    const newList = expenseItemList.filter(({ id }) => !idList.includes(id));
    await updateExpenses?.(newList);
  };

  const handleSelectOption = (id: number) => {
    if (id === CUSTOM_EXPENSE_ID) {
      setIsCustomerExpense(true);
      setDenominator('');
      return;
    }

    const selectedExpense = possibleTemplates.find(expense => expense.id === id);

    setDenominator(selectedExpense?.denominator || translate.PIECE_DENOMINATOR);

    form.setFieldValue(PRICE_KEY, selectedExpense?.price ?? 0);
  };

  const sortedExpenseItemList = [...expenseItemList].sort(getExpenseItemSorter(locale));

  const tableButtons = [
    { onClick: handleDeleteItems, buttonText: translate.DELETE },
    ...(allowAdd ? [{ onClick: () => setIsModalOpen(true), buttonText: translate.ADD }] : []),
  ];

  return (
    <div className={displayColumn}>
      <EditableTable<ExpenseTableItem>
        columnTitleTranslations={columnTitleTranslations}
        editableList={EDITABLE_COLUMNS}
        itemList={sortedExpenseItemList}
        isLoading={isLoading}
        buttonList={tableButtons}
        onChangeItem={handleChangeItem}
        showHeader
        title={tableTitle}
      />

      <Modal
        onCancel={() => {
          form.resetFields();
          setIsCustomerExpense(false);
          setIsModalOpen(false);
        }}
        onOk={() => {
          form.submit();
        }}
        open={isModalOpen}
        title={`${translate.ADD} ${translate.EXPENSE.toLowerCase()}`}
        width='90vw'
      >
        <Form
          form={form}
          layout='inline'
          className={expenseModalForm}
          initialValues={{ [QUANTITY_KEY]: 1 }}
          onFinish={handleAddItem}
        >
          {isCustomExpense ? (
            <Form.Item name={TITLE_KEY} rules={[{ required: true, max: TITLE_MAX_LENGTH }]}>
              <Input type='text' maxLength={TITLE_MAX_LENGTH} />
            </Form.Item>
          ) : (
            <Form.Item name={EXPENSE_KEY} rules={[{ required: true }]}>
              <Select options={expenseOptionList} onSelect={handleSelectOption} />
            </Form.Item>
          )}

          <Form.Item name={QUANTITY_KEY} getValueFromEvent={getValueAsInteger} rules={[{ required: true }]}>
            <Input type='number' addonAfter={denominator} />
          </Form.Item>

          <Form.Item name={PRICE_KEY} getValueFromEvent={getValueAsInteger} rules={[{ required: true }]}>
            <Input type='number' addonAfter='DKK' />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
