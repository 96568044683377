import { Divider, Heading } from '~src/components/display';
import { ProductPagination } from '~src/components/display/Pagination/ProductPagination';
import { SolarPanelProductCard } from '~src/components/display/ProductCard/SolarPanelProductCard';
import { useLocalization } from '~src/hooks';
import { useSolarPanels } from '~src/hooks/services/products/useSolarPanel';
import { useOfferProductState } from '~src/hooks/useOfferProductState';

import { displayColumn } from '~src/style/shared.module.css';

export const SolarPanelProductSelector = () => {
  const translate = useLocalization();

  const { selectSolarPanel, selectedSolarPanel, selectBattery, selectInverter } = useOfferProductState();

  const { solarPanels } = useSolarPanels();

  const handleOnSelect = (selectedId?: number | string) => {
    const solarPanel = solarPanels?.find(({ id }) => id === selectedId);

    if (!solarPanel) {
      return;
    }

    selectSolarPanel({ ...solarPanel, quantity: selectedSolarPanel?.quantity ?? 1 });
    selectBattery(undefined);
    selectInverter(undefined);
  };

  return (
    <div className={displayColumn}>
      <Heading>{translate.SOLAR_PANELS}</Heading>

      <Divider fullWidth />

      <ProductPagination
        itemList={solarPanels.map(item => (
          <SolarPanelProductCard
            key={item.model}
            onSelect={handleOnSelect}
            preferred={item.preferred}
            product={item}
            selected={item.id === selectedSolarPanel?.id}
          />
        ))}
      />
    </div>
  );
};
