import round from 'lodash/round';

import { useLocalization } from '~src/hooks';
import { HeatPumpOutdoorUnit, Residence } from '~src/types';
import { calculateHeatDistributionValue } from '~src/utilities/calculationHelper';

import { ProductCard, ProductCardProps } from './ProductCard';
import { getProductDimensionString } from './productCardHelper';

type HeatPumpProductCardProps = Pick<ProductCardProps, 'onSelect' | 'selected' | 'preferred' | 'type'> & {
  product: Pick<
    HeatPumpOutdoorUnit,
    | 'id'
    | 'brand'
    | 'price'
    | 'effect7'
    | 'model'
    | 'imageUrl'
    | 'scop'
    | 'noiseLevel'
    | 'size'
    | 'copHotWater'
    | 'notes'
  >;
  residence?: Pick<Residence, 'heatDistribution'>;
  showNotes?: boolean;
};

export const HeatPumpOutdoorProductCard = ({
  onSelect,
  product,
  selected,
  preferred,
  residence,
  type,
  showNotes,
}: HeatPumpProductCardProps) => {
  const translate = useLocalization();

  const effect = calculateHeatDistributionValue(product.effect7, residence);
  const scop = calculateHeatDistributionValue(product.scop, residence);

  const { copHotWater, noiseLevel, notes } = product;

  return (
    <ProductCard
      attributes={[
        { name: translate.SCOP, value: round(scop, 2) },
        { name: translate.HOT_WATER_COP, value: copHotWater ? round(copHotWater, 2) : '?' },
        { name: translate.SOUND_LEVEL, value: `${round(noiseLevel, 0)} dB` },
        getProductDimensionString(product.size, translate),
      ]}
      brand={product.brand}
      model={product.model}
      id={product.id}
      imageUrl={product.imageUrl ?? undefined}
      onSelect={onSelect}
      preferred={preferred}
      price={product.price}
      selected={selected}
      subtitle={`${translate.EFFECT} (-7°): ${round(effect, 2)}`}
      type={type}
      notes={showNotes ? notes : undefined}
    />
  );
};
