import { Heading, ImageThumbnail, Input, Radio, Select, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

type QuestionSectionPipingProps = {
  heatingIsMix?: boolean;
};

export const QuestionSectionPiping = ({
  answerMap,
  heatingIsMix = false,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps & QuestionSectionPipingProps) => {
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        5. {translate.SITE_VISIT_SECTION_PIPING}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_PIPING_MATERIAL}:</Text>
        <Select
          onSelect={answer => {
            updateAnswer('pipingMaterial', answer);
          }}
          options={[
            { value: 'copper', label: translate.COPPER },
            { value: 'steel', label: translate.STEEL },
            { value: 'aluminium', label: translate.ALUMINUM },
            { value: 'plastic', label: translate.PLASTIC },
          ]}
          value={answerMap?.pipingMaterial?.answer}
        />

        {heatingIsMix && (
          <>
            <Text>{translate.SITE_VISIT_QUESTION_PIPING_TWO_SYSTEMS}:</Text>
            <Radio.Group
              onChange={event => {
                updateAnswer('twoPipingSystems', event?.target?.value);
              }}
              value={answerMap?.twoPipingSystems?.answer}
              disabled={isLoading}
            >
              <Radio.Button value='true'>{translate.YES}</Radio.Button>
              <Radio.Button value='false'>{translate.NO}</Radio.Button>
            </Radio.Group>
            <SiteVisitNote
              display={answerMap?.twoPipingSystems?.answer === 'true'}
              text={translate.SITE_VISIT_DISCLAIMER_TWO_PIPE_SYSTEM}
            />
          </>
        )}

        <Text>{translate.SITE_VISIT_QUESTION_FLOOR_HEATING_EXTRA_SHUNT}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('floorHeatingExtraShunts', event?.target?.value);
          }}
          value={answerMap?.floorHeatingExtraShunts?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.floorHeatingExtraShunts?.answer === 'true'}
          text={translate.SITE_VISIT_DISCLAIMER_EXTRA_SHUNT}
          type='expense'
        />

        <Text>{translate.SITE_VISIT_QUESTION_CHANGE_CIRCULATION_PUMP}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('changeCirculationPump', event?.target?.value);
          }}
          value={answerMap?.changeCirculationPump?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.changeCirculationPump?.answer === 'true'}
          text={translate.SITE_VISIT_DISCLAIMER_CIRCULATION_PUMP}
          type='expense'
        />

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('pipingNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.pipingNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.piping)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
