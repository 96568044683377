import { Link } from '~src/components/router/Link';
import { useLocation } from '~src/hooks';

import { Text } from '../Text';

type RouterLinkProps = {
  title: string;
  href?: string;
};

export const RouterLink = ({ title, href }: RouterLinkProps) => {
  const { location } = useLocation();

  if (location.pathname === href) {
    return <Text>{title}</Text>;
  }

  return <Link to={href ?? ''}>{title}</Link>;
};
