import round from 'lodash/round';

import { useLocalization } from '~src/hooks';
import { HeatPumpIndoorUnit } from '~src/types';

import { ProductCard, ProductCardProps } from './ProductCard';
import { getProductDimensionString } from './productCardHelper';

type HeatPumpProductCardProps = Pick<ProductCardProps, 'onSelect' | 'selected' | 'preferred' | 'type'> & {
  product: Pick<
    HeatPumpIndoorUnit,
    'id' | 'brand' | 'price' | 'model' | 'imageUrl' | 'noiseLevel' | 'waterVolume' | 'buffer' | 'size' | 'notes'
  >;
  showNotes?: boolean;
};

export const HeatPumpIndoorProductCard = ({
  onSelect,
  product,
  selected,
  preferred,
  type,
  showNotes,
}: HeatPumpProductCardProps) => {
  const translate = useLocalization();

  const { noiseLevel, waterVolume, buffer, notes } = product;

  return (
    <ProductCard
      attributes={[
        { name: translate.SOUND_LEVEL, value: `${round(noiseLevel, 0)} dB` },
        { name: translate.HOT_WATER_TANK, value: `${round(waterVolume, 0)} L` },
        { name: translate.BUFFER, value: `${round(buffer, 0)} L` },
        getProductDimensionString(product.size, translate),
      ]}
      brand={product.brand}
      model={product.model}
      id={product.id}
      imageUrl={product.imageUrl ?? undefined}
      onSelect={onSelect}
      preferred={preferred}
      price={product.price}
      selected={selected}
      type={type}
      notes={showNotes ? notes : undefined}
    />
  );
};
