import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { TranslationRecord } from '~src/localization/translationKeys';
import {
  ConsumptionProfile,
  EnergyLabelEnum,
  HeatDistributionTypeEnum,
  RenovationDegreeEnum,
  Residence,
  ResidenceInput,
  ResidenceType,
  RoofShadows,
} from '~src/types';

export const ENERGY_LABEL_OPTIONS: { label?: string; value?: EnergyLabelEnum }[] = [
  { value: 'A2020' },
  { value: 'A2015' },
  { value: 'A2010' },
  { value: 'B' },
  { value: 'C' },
  { value: 'D' },
  { value: 'E' },
  { value: 'F' },
  { value: 'G' },
  { value: undefined, label: '-' },
];

const RESIDENCE_FORM_BASE_KEYS: readonly (keyof ResidenceInput)[] = [
  'address',
  'addressID',
  'area',
  'areaHeated',
  'areaPartiallyHeated',
  'areaNotHeated',
  'builtYear',
  'consumptionProfile',
  'customerID',
  'electricCar',
  'electricityPrice',
  'energyExpenditure',
  'energyLabel',
  'floors',
  'heatDistribution',
  'heatingCircuits',
  'primaryHeating',
  'radiatorFloorPercentage',
  'renovatedYear',
  'renovatedDegree',
  'residents',
  'type',
  'waterConsumption',
  'roofType',
] as const;

export const mapResidenceToResidenceInput = (
  residence?: Pick<
    Residence,
    (typeof RESIDENCE_FORM_BASE_KEYS)[number] | 'id' | 'roofs' | 'primaryHeating' | 'secondaryHeating' | 'electricCar'
  >
): (ResidenceInput & { id?: number }) | undefined =>
  residence
    ? {
        ...pick(residence, [...RESIDENCE_FORM_BASE_KEYS, 'id', 'roofs']),
        primaryHeating: residence.primaryHeating
          ? omit(residence.primaryHeating, ['id'])
          : { annualUsage: 0, fuelType: '' },
        secondaryHeating: residence.secondaryHeating ? omit(residence.secondaryHeating, ['id']) : undefined,
        electricCar: residence.electricCar ? omit(residence.electricCar, ['id']) : undefined,
      }
    : undefined;

export const getConsumptionProfileOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: ConsumptionProfile;
}> => [
  {
    value: 'homeInAfternoon',
    label: translate.I_COME_HOME_IN_THE_AFTERNOON,
  },
  {
    value: 'homeAt17',
    label: translate.I_COME_HOME_AT_17,
  },
  {
    value: 'homeInMorning',
    label: translate.I_AM_HOME_IN_THE_MORNING,
  },
  {
    value: 'homeMostDays',
    label: translate.I_AM_HOME_MOST_DAYS,
  },
  {
    value: 'homeEveryDay',
    label: translate.I_AM_HOME_EVERY_DAY,
  },
];

export const getHeatDistributionTypeOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: HeatDistributionTypeEnum;
}> => [
  { value: 'radiator', label: translate.RADIATOR },
  { value: 'floor', label: translate.FLOOR_HEATING },
  {
    value: 'radiatorAndFloor',
    label: `${translate.RADIATOR} ${translate.AND.toLowerCase()} ${translate.FLOOR_HEATING.toLowerCase()}`,
  },
];

export const getRenovationDegreeOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: RenovationDegreeEnum;
}> => [
  { value: 'asBuilt', label: translate.AS_BUILT },
  { value: 'medium', label: translate.MEDIUM },
  { value: 'maximum', label: translate.MAXIMUM },
];

export const getResidenceTypeOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: ResidenceType;
}> => [
  { value: 'villa', label: `${translate.VILLA}/${translate.DETACHED_HOUSE}` },
  { value: 'townhouse', label: translate.TERRACED_HOUSE },
  { value: 'apartment', label: translate.APARTMENT },
  { value: 'summerhouse', label: translate.SUMMER_HOUSE },
];

export const getRoofOrientationOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: number;
}> => [
  { label: translate.WEST, value: -90 },
  { label: `${translate.SOUTH_WEST}-75`, value: -75 },
  { label: `${translate.SOUTH_WEST}-60`, value: -60 },
  { label: `${translate.SOUTH_WEST}-45`, value: -45 },
  { label: `${translate.SOUTH_WEST}-30`, value: -30 },
  { label: `${translate.SOUTH_WEST}-15`, value: -15 },
  { label: translate.SOUTH, value: 0 },
  { label: `${translate.SOUTH_EAST}-15`, value: 15 },
  { label: `${translate.SOUTH_EAST}-30`, value: 30 },
  { label: `${translate.SOUTH_EAST}-45`, value: 45 },
  { label: `${translate.SOUTH_EAST}-60`, value: 60 },
  { label: `${translate.SOUTH_EAST}-75`, value: 75 },
  { label: translate.EAST, value: 90 },
];

export const getRoofTypeOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: string;
}> => [
  { label: `${translate.SITE_VISIT_ANSWER_ROOF_TYPE_BETON_TEGL}`, value: 'concreteTile' },
  { label: `${translate.SITE_VISIT_ANSWER_ROOF_TYPE_LER_TEGL}`, value: 'clayTile' },
  { label: `${translate.SITE_VISIT_ANSWER_ROOF_TYPE_METAL}`, value: 'metal' },
  { label: `${translate.SITE_VISIT_ANSWER_ROOF_TYPE_ETERNIT}`, value: 'fiberCement' },
  { label: `${translate.SITE_VISIT_ANSWER_ROOF_TYPE_FLAT}`, value: 'flat' },
  { label: `${translate.SITE_VISIT_ANSWER_ROOF_TYPE_TAGPAP}`, value: 'felt' },
  { label: `${translate.OTHER}`, value: 'other' },
  { label: `${translate.UNKNOWN}`, value: 'unknown' },
];

export const getRoofShadowOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: RoofShadows;
}> => [
  { value: 'none', label: translate.NO_SHADE },
  { value: 'some', label: translate.PARTIALLY_SHADY },
  { value: 'many', label: translate.VERY_SHADY },
];

export const tryParseStringFloat = (input: string | number | null | undefined) =>
  input ? parseFloat(input.toString().replaceAll(',', '.')) : undefined;
