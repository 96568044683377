import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';

import { useSiteVisit } from '~src/hooks/services';
import { Image, Offer, OfferType, Residence, SiteVisitAnswer, SiteVisitQuestion } from '~src/types';

import { SiteVisitSections } from '../siteVisit/utilities/siteVisitConstants';
import { InstallationGuideElectricity } from './InstallationGuideElectricity';
import { InstallationGuideElectricitySolar } from './InstallationGuideElectricitySolar';
import { InstallationGuideFloorPlan } from './InstallationGuideFloorPlan';
import { InstallationGuideHeatSource } from './InstallationGuideHeatSource';
import { InstallationGuideIndoor } from './InstallationGuideIndoor';
import { InstallationGuideInverterBattery } from './InstallationGuideInverterBattery';
import { InstallationGuideOther } from './InstallationGuideOther';
import { InstallationGuideOutdoor } from './InstallationGuideOutdoor';
import { InstallationGuidePiping } from './InstallationGuidePiping';
import { InstallerGuidePurchaseInformation } from './InstallationGuidePurchaseInformation';
import { InstallationGuideRadiators } from './InstallationGuideRadiators';
import { InstallationGuideRoof } from './InstallationGuideRoof';
import { InstallationGuideSolar } from './InstallationGuideSolar';

type QuestionInformationProps = {
  offer?: Pick<Offer, 'heatPumpOutdoorUnit' | 'tag'>;
  residence?: Residence;
};

export const InstallationGuide = ({ offer, residence }: QuestionInformationProps) => {
  const { siteVisitChecklist } = useSiteVisit(residence?.id);

  const offerType: OfferType = offer?.heatPumpOutdoorUnit ? 'heatPump' : 'solar';

  const { images } = residence ?? {};

  const answerMap = keyBy(siteVisitChecklist, 'question') as Record<SiteVisitQuestion, SiteVisitAnswer>;

  const imageMap = groupBy(images, ({ category }) => category ?? 'other') as Record<SiteVisitSections, Image[]>;

  return (
    <>
      {offerType === 'heatPump' && (
        <div>
          <InstallerGuidePurchaseInformation brand={offer?.heatPumpOutdoorUnit?.brand} tag={offer?.tag} />

          <InstallationGuideOutdoor imageList={imageMap.outdoorPlacement} answerMap={answerMap} />

          <InstallationGuideRadiators imageList={imageMap.radiators} answerMap={answerMap} />

          <InstallationGuideHeatSource imageList={imageMap.heatSource} answerMap={answerMap} />

          <InstallationGuidePiping imageList={imageMap.piping} answerMap={answerMap} />

          <InstallationGuideIndoor imageList={imageMap.indoorPlacement} answerMap={answerMap} />

          <InstallationGuideElectricity imageList={imageMap.electricity} answerMap={answerMap} />

          <InstallationGuideFloorPlan imageList={imageMap.floorPlan} answerMap={answerMap} />

          <InstallationGuideOther imageList={imageMap.other} answerMap={answerMap} />
        </div>
      )}

      {offerType === 'solar' && (
        <div>
          <InstallationGuideSolar imageList={imageMap.solarPlacement} answerMap={answerMap} />

          <InstallationGuideInverterBattery imageList={imageMap.inverterAndBatteryPlacement} answerMap={answerMap} />

          <InstallationGuideRoof residence={residence} imageList={imageMap.roofConstruction} answerMap={answerMap} />

          <InstallationGuideElectricitySolar imageList={imageMap.electricity} answerMap={answerMap} />

          <InstallationGuideFloorPlan imageList={imageMap.floorPlan} answerMap={answerMap} />

          <InstallationGuideOther imageList={imageMap.other} answerMap={answerMap} />
        </div>
      )}
    </>
  );
};
