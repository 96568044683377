import {
  useCrmCustomerFieldValuesQuery,
  useSearchCrmCustomersQuery,
  useSyncContactInformationWithCrmMutation,
  useSyncOfferInformationWithCrmMutation,
} from '~src/gql';
import { useGraphQLClient } from '~src/services/client';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useLocalization } from '../useLocalization';
import { useToastMessage, useToastMessageErrorHandler } from '../useToastMessage';

export const useSyncContactInformationWithCRM = () => {
  const translate = useLocalization();
  const showError = useToastMessageErrorHandler();
  const { showMessage } = useToastMessage();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useSyncContactInformationWithCrmMutation(graphQLClient, {});
  const syncContactInformationWithCRMAsync = async (residenceId?: number) => {
    if (!residenceId) {
      showMessage({
        type: 'error',
        message: `${translate.DID_NOT_SYNCHRONIZE}. ${translate.NO_CUSTOMER}/${translate.NO_RESIDENCE}`,
      });

      return;
    }

    mutateAsync(
      { residenceId },
      {
        onError: error => showError(error),
        onSuccess: () => showMessage({ message: translate.SAVED, type: 'success' }),
      }
    );
  };

  return { syncContactInformationWithCRM: syncContactInformationWithCRMAsync, isLoading };
};

export const useSearchCRMCustomers = (email: string) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useSearchCrmCustomersQuery(
    graphQLClient,
    { email },
    {
      enabled: email?.length > 1,
      onError: useToastMessageErrorHandler(),
    }
  );

  return { crmCustomerList: data?.searchCRMCustomers?.map(deepNullToUndefined), isLoading: isFetching };
};

export const useCRMCustomerFieldValues = (id?: string) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useCrmCustomerFieldValuesQuery(
    graphQLClient,
    { id: id ?? '' },
    { enabled: !!id, onError: useToastMessageErrorHandler() }
  );

  return { fields: deepNullToUndefined(data?.crmCustomerFieldValues ?? {}) ?? undefined, isLoading: isFetching };
};

export const useSyncOfferWithCrm = () => {
  const { graphQLClient } = useGraphQLClient();
  const { mutateAsync, isLoading } = useSyncOfferInformationWithCrmMutation(graphQLClient);

  const translate = useLocalization();
  const showError = useToastMessageErrorHandler();
  const { showMessage } = useToastMessage();

  const sync = async (offerId: number) => {
    await mutateAsync(
      { offerId },
      {
        onError: error => showError(error),
        onSuccess: () => showMessage({ message: translate.SAVED, type: 'success' }),
      }
    );

    return true;
  };

  return { syncOfferWithCrm: sync, isLoading };
};
