import { Form, Input } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { formatAddress } from '~src/utilities/format';

import { useCreateOfferState } from '../../state/createOfferState';

import { customerForm } from './offers.module.css';

export const OfferCustomerInfo = () => {
  const translate = useLocalization();
  const { customer, residence } = useCreateOfferState();
  const { address } = residence ?? {};
  const { email, firstName, lastName, phoneNumber } = customer ?? {};

  return (
    <Form className={customerForm} labelCol={{ span: 5 }}>
      <Form.Item label={translate.CUSTOMER}>
        <Input disabled value={`${firstName} ${lastName}`} />
      </Form.Item>

      <Form.Item label={translate.EMAIL}>
        <Input disabled value={email} />
      </Form.Item>

      <Form.Item label={translate.ADDRESS}>
        <Input disabled value={address ? formatAddress(address) : ''} />
      </Form.Item>

      <Form.Item label={translate.PHONE}>
        <Input disabled value={phoneNumber} />
      </Form.Item>
    </Form>
  );
};
