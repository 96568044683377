import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';
import { getPipePlacementTranslation } from './util';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideIndoor = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.SITE_VISIT_SECTION_INDOOR}
      </Heading>

      <div className={informationColumn}>
        {answerMap?.bufferRequired?.answer === 'true' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_INDOOR_NEED_BUFFER_TANK} value={translate.YES} />
        )}

        {!!answerMap?.pipingPlacement?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_INDOOR_PIPE_LOCATION}
            value={getPipePlacementTranslation(translate, answerMap?.pipingPlacement?.answer)}
          />
        )}

        {!!answerMap?.distanceInOut?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_INDOOR_PIPE_AMOUNT}
            value={answerMap?.distanceInOut?.answer}
          />
        )}

        {!!answerMap?.indoorPlacementNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.indoorPlacementNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
