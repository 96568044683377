import { useEffect, useState } from 'react';

import { useSearchCustomers } from '~src/hooks/services/useCustomer';

type Suggestion = {
  value: number;
  label: React.ReactNode;
};

export const useCustomerSuggestions = () => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const { customerList, isLoading } = useSearchCustomers(value);

  useEffect(
    function updateSuggestions() {
      const newSuggestions =
        customerList?.map(customer => ({
          value: customer.id,
          label: `${customer.email} - ${customer.phoneNumber} - ${customer.firstName} ${customer.lastName}`,
        })) ?? [];

      setSuggestions(newSuggestions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(customerList)]
  );

  return { customerList, isLoading, suggestions, setValue, value };
};
