import { TranslationRecord } from '~src/localization/translationKeys';

import { HeaderFilter } from '../components/HeaderFilter';
import { TooltipTitle } from '../components/TooltipTitle';
import { OverviewTableColumn } from '../types';

const getSortableColumn = (
  title: string,
  dataIndex: string,
  onSort: (column: OverviewTableColumn) => void,
  tooltip?: string
) => ({
  title: TooltipTitle({ title, tooltip }),
  dataIndex,
  sorter: true,
  onHeaderCell: (column: OverviewTableColumn) => ({ onClick: () => onSort(column) }),
});

const getFilterableColumn = (
  title: string | number | boolean,
  dataIndex: string,
  options: { value: string; label: string }[],
  onFilter: (value?: string) => void,
  currentFilter?: string,
  buttonText?: string
) => ({
  title,
  dataIndex,
  filterDropdown: HeaderFilter({ options, onFilter, currentFilter, buttonText }),
});

export const getOverviewTableColumns = (
  translations: TranslationRecord,
  onSort: (column: OverviewTableColumn) => void,
  onFilter: (value?: string) => void,
  currentFiltersRecord: Record<string, string | undefined>
) => {
  const {
    ACTIONS,
    CLICK_TO_SORT,
    CUSTOMER,
    EMAIL,
    PHONE_NUMBER,
    ADDRESS,
    LEAD_ORIGIN,
    PRODUCT_TYPE,
    HEAT_PUMP,
    SOLAR_CELL,
    RESET,
  } = translations;

  return [
    { title: ACTIONS, dataIndex: 'actions' },
    getSortableColumn(CUSTOMER, 'name', onSort, CLICK_TO_SORT),
    getSortableColumn(`${CUSTOMER} ${EMAIL}`, 'email', onSort, CLICK_TO_SORT),
    getSortableColumn(PHONE_NUMBER, 'phoneNumber', onSort, CLICK_TO_SORT),
    getSortableColumn(ADDRESS, 'address', onSort, CLICK_TO_SORT),
    getSortableColumn(LEAD_ORIGIN, 'leadOrigin', onSort, CLICK_TO_SORT),
    getFilterableColumn(
      PRODUCT_TYPE,
      'productType',
      [
        { label: HEAT_PUMP, value: 'heatPump' },
        { label: SOLAR_CELL, value: 'solar' },
      ],
      onFilter,
      currentFiltersRecord.productType,
      RESET
    ),
  ];
};
