import {
  CustomerFinancing,
  CustomerHeatPumpSubsidy,
  CustomerPriceInflation,
} from '~src/components/features/shared/customer';

import { useCreateOfferState } from '../../state/createOfferState';
import { OfferCustomerInfo } from './OfferCustomerInfo';
import { OfferList } from './OfferList';

import { overview, overviewRow } from './offers.module.css';

export const OfferOverview = () => {
  const { customer, setCustomer } = useCreateOfferState();

  return (
    <div className={overview}>
      <OfferCustomerInfo />
      <div className={overviewRow}>
        <OfferList type='heatpump' />

        <OfferList type='solar' />
      </div>
      <div className={overviewRow}>
        <CustomerFinancing customer={customer} updateCustomer={setCustomer} />

        <CustomerHeatPumpSubsidy customer={customer} updateCustomer={setCustomer} />

        <CustomerPriceInflation customer={customer} updateCustomer={setCustomer} />
      </div>
    </div>
  );
};
