import { useBodilAdvisorListQuery, useUserQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { deepNullToUndefined } from '~src/utilities/convert';

export const useUser = () => {
  const { graphQLClient } = useGraphQLClient();

  const { data, error, isLoading, status } = useUserQuery(graphQLClient, undefined, {
    onError: useToastMessageErrorHandler(),
  });

  if (status === 'error') {
    return { user: undefined, error, isLoading, status };
  }

  if (status === 'loading') {
    return { user: undefined, error, isLoading, status };
  }

  return { user: deepNullToUndefined(data.me), error, isLoading, status };
};

export const useBodilAdvisors = () => {
  const { graphQLClient } = useGraphQLClient();

  const { data, error, isLoading } = useBodilAdvisorListQuery(graphQLClient, undefined, {
    onError: useToastMessageErrorHandler(),
  });

  return { bodilAdvisors: deepNullToUndefined(data?.bodilAdvisors), error, isLoading };
};
