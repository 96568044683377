import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { header } from './overview.module.css';

export const OverviewPageHeader = () => {
  const translation = useLocalization();

  return (
    <header className={header}>
      <Heading level={1}>{translation.OVERVIEW_PAGE}</Heading>
    </header>
  );
};
