import { Heading, ImageThumbnail, Input, Radio, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionIndoor = ({
  answerMap,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  const pipeLength = Number(answerMap?.distanceInOut?.answer ?? '0');
  const extraPipeRequired = Number.isNaN(pipeLength) || pipeLength > 5;

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        6. {translate.SITE_VISIT_SECTION_INDOOR}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_INDOOR_ENTRANCE_SIZE}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('heatPumpEntryPossible', event?.target?.value);
          }}
          value={answerMap?.heatPumpEntryPossible?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.heatPumpEntryPossible?.answer === 'false'}
          text={translate.SITE_VISIT_DISCLAIMER_HEAT_PUMP_ENTRY}
        />

        <Text>{translate.SITE_VISIT_QUESTION_INDOOR_NEED_BUFFER_TANK}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('bufferRequired', event?.target?.value);
          }}
          value={answerMap?.bufferRequired?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.bufferRequired?.answer === 'true'}
          text={translate.SITE_VISIT_DISCLAIMER_REQUIRED_BUFFER}
        />
        <SiteVisitNote
          display={answerMap?.bufferRequired?.answer === 'false'}
          text={translate.SITE_VISIT_DISCLAIMER_REQUIRED_NO_BUFFER}
        />

        <Text>{translate.SITE_VISIT_QUESTION_INDOOR_ROOM_FOR_WORK}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('roomForWaterTank', event?.target?.value);
          }}
          value={answerMap?.roomForWaterTank?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        {answerMap?.roomForWaterTank?.answer === 'false' && (
          <>
            <Text>{translate.SITE_VISIT_QUESTION_INDOOR_ROOM_ALTERNATIVE}</Text>
            <Input.TextArea
              onChange={event => {
                updateAnswer('indoorAlternativeNotes', event?.target?.value);
              }}
              value={answerMap?.indoorAlternativeNotes?.answer}
            />
          </>
        )}

        <Text>{translate.SITE_VISIT_QUESTION_INDOOR_PIPE_LOCATION}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('pipingPlacement', event?.target?.value);
          }}
          value={answerMap?.pipingPlacement?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='ceiling'>{translate.CEILING}</Radio.Button>
          <Radio.Button value='wall'>{translate.WALL}</Radio.Button>
        </Radio.Group>

        <Text>{translate.SITE_VISIT_QUESTION_INDOOR_PIPE_AMOUNT}</Text>
        <Input
          onChange={event => {
            updateAnswer('distanceInOut', event?.target?.value);
          }}
          value={answerMap?.distanceInOut?.answer}
        />
        <SiteVisitNote display={extraPipeRequired} text={translate.SITE_VISIT_DISCLAIMER_EXTRA_PIPE} type='expense' />

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('indoorPlacementNotes', event.target.value);
          }}
          defaultValue={answerMap?.indoorPlacementNotes?.answer}
        />

        <Text>{translate.SITE_VISIT_QUESTION_INDOOR_CONDENSATION_PUMP}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('condensationPump', event?.target?.value);
          }}
          value={answerMap?.condensationPump?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.condensationPump?.answer === 'false'}
          text={translate.ADD_CONDENSATION_PUMP}
          type='expense'
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.indoorPlacement)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
