import { Form, Radio } from '~src/components/display';
import { ConsumptionProfileGraph } from '~src/components/display/graphs/ConsumptionProfileGraph';
import { useLocalization } from '~src/hooks';
import { ConsumptionProfile } from '~src/types';
import { consumptionProfiles, DEFAULT_CONSUMPTION_PROFILE_KEY } from '~src/utilities/constants';

import { consumptionProfileFieldKey } from '../constants';
import { getConsumptionProfileOptions } from '../helpers/residenceHelper';
import { ResidenceFormSection } from './ResidenceFormSection';

import { displayColumn } from '~src/style/shared.module.css';

import { consumptionProfileSection } from './residenceFormComponents.module.css';

export const ResidenceFormConsumptionProfile = () => {
  const translate = useLocalization();

  const form = Form.useFormInstance();

  const formConsumptionProfile = Form.useWatch(consumptionProfileFieldKey, form) as ConsumptionProfile | undefined;

  const consumptionProfileOptions = getConsumptionProfileOptions(translate);

  const data = consumptionProfiles[formConsumptionProfile ?? DEFAULT_CONSUMPTION_PROFILE_KEY].map(
    (energyExpenditure, index) => ({
      timeOfDay: `${index}:00`,
      energyExpenditure,
    })
  );

  return (
    <ResidenceFormSection title={translate.CONSUMPTION_PATTERN}>
      <div className={consumptionProfileSection}>
        <Form.Item name={consumptionProfileFieldKey}>
          <Radio.Group className={displayColumn} options={consumptionProfileOptions} />
        </Form.Item>

        <ConsumptionProfileGraph data={data} />
      </div>
    </ResidenceFormSection>
  );
};
