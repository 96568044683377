import { API_URL } from '~src/config';
import { useLocalization } from '~src/hooks';
import { useDownloadFile } from '~src/hooks/useDownloadFile';

export const useDownloadAllImagesByCode = (offerCode?: string) => {
  const translate = useLocalization();

  const dataSheetDownloadUrl = `${API_URL}/rest/v1/zip-images?code=${offerCode}`;

  const fileName = `${translate.SITE_VISIT_IMAGES}.zip`;

  return useDownloadFile(fileName, dataSheetDownloadUrl);
};
