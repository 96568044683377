import { Tabs, TabsProps } from '~src/components/display';
import { useLocalization, useOfferProductState } from '~src/hooks';

import { CreateOfferHeatPumpTab } from './CreateOfferHeatPumpTab';
import { CreateOfferSolarTab } from './CreateOfferSolarTab';

import { tabs } from './product.module.css';

export const CreateOfferProductTabs = () => {
  const translation = useLocalization();
  const { setOfferType, selectedOfferType } = useOfferProductState();

  const handleTabChange = (tabKey: string) => {
    if (tabKey === 'solar') {
      setOfferType('solar');
      return;
    }

    setOfferType('heatPump');
  };

  const tabItemList: TabsProps['items'] = [
    {
      key: 'heatPump',
      label: translation.HEAT_PUMP,
      children: <CreateOfferHeatPumpTab />,
    },
    {
      key: 'solar',
      label: translation.SOLAR_CELL,
      children: <CreateOfferSolarTab />,
    },
  ];

  return (
    <Tabs
      type='card'
      className={tabs}
      items={tabItemList}
      onChange={handleTabChange}
      defaultActiveKey={selectedOfferType}
      destroyInactiveTabPane
    />
  );
};
