import { useEffect } from 'react';

import { Form, FormInstance, Heading } from '~src/components/display';
import { useEnergyOptions } from '~src/hooks/services/useEnergyOptions';
import { ElectricCarInput, MaybeId, ResidenceInput } from '~src/types';
import { formatAddress } from '~src/utilities/format';

import { electricCarFieldKey } from './constants';
import { useCreateResidenceState } from './state/createResidenceState';
import { ResidenceFormConsumption } from './subcomponents/ResidenceFormConsumption';
import { ResidenceFormConsumptionProfile } from './subcomponents/ResidenceFormConsumptionProfile';
import { ResidenceFormElectricCar } from './subcomponents/ResidenceFormElectricCar';
import { ResidenceFormHeatDistribution } from './subcomponents/ResidenceFormHeatDistribution';
import { ResidenceFormResidenceInformation } from './subcomponents/ResidenceFormResidenceInformation';
import { ResidenceFormRoofs } from './subcomponents/ResidenceFormRoofs';

import { residenceForm } from './residenceForm.module.css';

type ResidenceFormProps = {
  customerId?: number;
  disabled?: boolean;
  form: FormInstance<ResidenceInput>;
  onSubmit: (data: ResidenceInput) => Promise<void> | undefined;
  residence?: ResidenceInput & MaybeId;
  setFormUpdated?: (boolean: boolean) => void;
};

export const ResidenceForm = ({
  customerId,
  disabled = false,
  form,
  onSubmit,
  residence,
  setFormUpdated,
}: ResidenceFormProps) => {
  const { creatingNewResidenceState } = useCreateResidenceState();

  const { energyOptions } = useEnergyOptions(residence?.id);

  const electricCar = residence?.electricCar;
  const formElectricCar = Form.useWatch(electricCarFieldKey, form);

  useEffect(
    function initializeFormWithResidence() {
      if (residence) {
        const formattedResidence = {
          ...residence,
          electricityPrice: residence.electricityPrice ?? energyOptions?.electricity.price,
          electricCar: (formElectricCar as ElectricCarInput) ?? electricCar,
        };

        form.resetFields();
        form.setFieldsValue(formattedResidence);
      } else if (!residence) {
        form.resetFields();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [residence, form]
  );

  useEffect(
    function detectChangeWhenElectricCarIsAddedOrRemoved() {
      if ((!electricCar && !formElectricCar) || (electricCar && formElectricCar)) {
        return;
      }

      setFormUpdated?.(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formElectricCar]
  );

  const { address } = residence ?? {};

  const isFormDisabled = disabled || creatingNewResidenceState === 'pending' || !address;

  return (
    <>
      {address && <Heading level={2}>{formatAddress(address)}</Heading>}

      <Form
        className={residenceForm}
        layout='vertical'
        disabled={isFormDisabled}
        form={form}
        onFinish={onSubmit}
        scrollToFirstError
        onValuesChange={() => setFormUpdated?.(true)}
      >
        <ResidenceFormResidenceInformation customerId={customerId || residence?.customerID} />

        <ResidenceFormRoofs residence={residence} />

        <ResidenceFormHeatDistribution />

        <ResidenceFormConsumption />

        <ResidenceFormConsumptionProfile />

        <ResidenceFormElectricCar residence={residence} />
      </Form>
    </>
  );
};
