import { Heading, Text } from '~src/components/display';

type InstallationGuideEntryProps = { label: string; value: string };

export const InstallationGuideEntry = ({ label, value }: InstallationGuideEntryProps) => (
  <>
    <Heading level={3}>{label}:</Heading>

    <Text>{value}</Text>
  </>
);
