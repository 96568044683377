import { useEffect, useState } from 'react';

import { Form, Input, Select, Switch, Text } from '~src/components/display';
import { getValueAsFloat, getValueAsInteger } from '~src/components/display/Form/formHelper';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { useLocalization } from '~src/hooks';
import { useEnergyOptions } from '~src/hooks/services';
import { getElectricHeatingOptions, getPrimaryHeatingOptions } from '~src/utilities/heatingType';

import {
  annualUsageFieldKey,
  efficiencyFieldKey,
  electricityPriceFieldKey,
  energyExpenditureFieldKey,
  fuelTypeFieldKey,
  priceFieldKey,
  primaryHeatingFieldKey,
} from '../constants';
import { updateFormFuelSubtypes } from '../helpers/updateFormFuelSubtypes';
import { useUpdateFormHeatingPricePerUnit } from '../hooks/useUpdateFormHeatingPricePerUnit';
import { ResidenceFormSection } from './ResidenceFormSection';

import { displayRow } from '~src/style/shared.module.css';

export const ResidenceFormPrimaryHeating = () => {
  const translate = useLocalization();

  const [annualPrice, setAnnualPrice] = useState<number | undefined>();
  const [annualPriceToggle, setAnnualPriceToggle] = useState(false);

  const form = Form.useFormInstance();

  const updatePricePerUnit = useUpdateFormHeatingPricePerUnit(form, 'primary');

  const formFuelType = Form.useWatch([primaryHeatingFieldKey, fuelTypeFieldKey], form);
  const formAnnualUsage = Form.useWatch([primaryHeatingFieldKey, annualUsageFieldKey], form);
  const formUnitPrice = Form.useWatch([primaryHeatingFieldKey, priceFieldKey], form);

  const { energyOptions } = useEnergyOptions();

  const primaryHeatingOptions = getPrimaryHeatingOptions(translate);

  const { efficiencyOptions } = primaryHeatingOptions.find(({ value }) => value === formFuelType) ?? {};

  const electricHeatingTypes = getElectricHeatingOptions(translate).map(({ value }) => value);

  const isElectricHeating = electricHeatingTypes.includes(formFuelType);

  const requiredFieldRules = useFormRequiredFieldRules();

  const fuelTypeDenominator =
    (energyOptions?.[formFuelType as keyof typeof energyOptions] as { denominator?: string })?.denominator ?? '';

  const updateAnnualUsage = () => {
    if (Number.isNaN(annualPrice) || !formUnitPrice) {
      return;
    }

    const calculatedUsage = annualPrice ? Math.round(annualPrice / formUnitPrice) : 0;

    form.setFieldValue([primaryHeatingFieldKey, annualUsageFieldKey], calculatedUsage);
    setAnnualPriceToggle(false);
  };

  useEffect(
    function updateAnnualPrice() {
      if (annualPriceToggle || !formAnnualUsage || !formUnitPrice) {
        return;
      }

      setAnnualPrice(Math.round(formAnnualUsage * formUnitPrice));
    },
    [annualPriceToggle, formAnnualUsage, formUnitPrice]
  );

  return (
    <>
      <ResidenceFormSection title={translate.ELECTRICITY_CONSUMPTION} columns noDivider>
        <Form.Item
          label={translate.YEARLY_ELECTRICITY_CONSUMPTION}
          getValueFromEvent={getValueAsInteger}
          name={energyExpenditureFieldKey}
          rules={requiredFieldRules}
        >
          <Input type='number' />
        </Form.Item>

        <Form.Item
          label={translate.CUSTOMERS_ELECTRICITY_PRICE}
          name={electricityPriceFieldKey}
          getValueFromEvent={getValueAsFloat}
        >
          <Input type='number' addonAfter={<Text>kr.</Text>} />
        </Form.Item>
      </ResidenceFormSection>

      <ResidenceFormSection columns noDivider title={translate.PRIMARY_HEATING}>
        <Form.Item
          label={translate.PRIMARY_HEATING}
          name={[primaryHeatingFieldKey, fuelTypeFieldKey]}
          rules={requiredFieldRules}
        >
          <Select
            options={primaryHeatingOptions}
            onSelect={value => {
              updateFormFuelSubtypes(translate, 'primary', value, form);
              updatePricePerUnit({ fuelType: value });
            }}
          />
        </Form.Item>

        <Form.Item
          label={translate.CONDITION}
          name={[primaryHeatingFieldKey, efficiencyFieldKey]}
          rules={requiredFieldRules}
        >
          <Select options={efficiencyOptions} disabled={efficiencyOptions?.length === 1} />
        </Form.Item>

        <Form.Item
          label={`${translate.PRICE}/${translate.UNIT}`}
          name={[primaryHeatingFieldKey, priceFieldKey]}
          getValueFromEvent={getValueAsFloat}
        >
          <Input type='number' disabled={isElectricHeating} addonAfter={`DKK/${fuelTypeDenominator}`} />
        </Form.Item>

        <div>
          <Form.Item
            label={
              <span className={displayRow}>
                {`${translate.CONSUMPTION}/${translate.YEAR}`}
                <Switch checked={annualPriceToggle} onChange={setAnnualPriceToggle} />
                {`${translate.PRICE}/${translate.YEAR}`}
              </span>
            }
            required
            style={{ display: annualPriceToggle ? 'block' : 'none' }}
          >
            <Input
              type='number'
              addonAfter='DKK'
              onBlur={updateAnnualUsage}
              onChange={({ target }) => {
                setAnnualPrice(target.value ? Number(target.value) : undefined);
              }}
              onKeyDown={({ code }) => {
                if (code === 'Enter') {
                  updateAnnualUsage();
                }
              }}
              value={annualPrice}
            />
          </Form.Item>

          <Form.Item
            label={
              <span className={displayRow}>
                {`${translate.CONSUMPTION}/${translate.YEAR}`}
                <Switch checked={annualPriceToggle} onChange={setAnnualPriceToggle} />
                {`${translate.PRICE}/${translate.YEAR}`}
              </span>
            }
            name={[primaryHeatingFieldKey, annualUsageFieldKey]}
            getValueFromEvent={getValueAsFloat}
            rules={requiredFieldRules}
            style={{ display: annualPriceToggle ? 'none' : 'block' }}
          >
            <Input type='number' addonAfter={fuelTypeDenominator} />
          </Form.Item>

          {formFuelType === 'wood' && <Text>1 m3 = 700kg (0.7t)</Text>}
        </div>
      </ResidenceFormSection>
    </>
  );
};
