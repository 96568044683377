import { TranslationRecord } from '~src/localization/translationKeys';
import { BoxSize, CylinderSize } from '~src/types';
import { sizeIsBox } from '~src/types/typeHelper';

export const getProductDimensionString = (size: BoxSize | CylinderSize, translate: TranslationRecord) =>
  sizeIsBox(size)
    ? {
        name: translate.DIMENSION_LABEL_BOX,
        value: `${size.width} x ${size.depth} x ${size.height}`,
      }
    : {
        name: translate.DIMENSION_LABEL_CYLINDER,
        value: `${size.diameter} x ${size.height}`,
      };
