import { useLocalization } from '~src/hooks';

export const useYesNoOptions = () => {
  const translate = useLocalization();

  return [
    { value: true, label: translate.YES },
    { value: false, label: translate.NO },
  ];
};
