import { ENV, SENTRY_DSN } from '~src/config';

import { logger } from './logger';

const TSR_STAGING = 1.0;
const TSR_PRODUCTION = 0.01;

export const captureException = (error: unknown) => {
  if (!SENTRY_DSN) {
    logger.warn('Sentry not initialized');
    logger.error(error);
    return;
  }

  import('@sentry/react').then(({ captureException: sentryCaptureException }) => {
    sentryCaptureException(error);
  });
};

export const initializeSentry = () => {
  if (!SENTRY_DSN) {
    logger.warn('Sentry not initialized');
    return;
  }

  import('@sentry/react').then(Sentry => {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENV,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate: ENV === 'production' ? TSR_PRODUCTION : TSR_STAGING,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
    });
  });
};
