import { Card, Spinner } from '~src/components/display';
import { DeleteOutlined } from '~src/components/display/Icons';
import { useLocalization } from '~src/hooks';
import { useDeleteSiteVisitFiles, useSiteVisitFiles, useUploadSiteVisitFile } from '~src/hooks/services';

import { FileUploadButton } from '../file';

import { displayColumn } from '~src/style/shared.module.css';

import { siteVisitFile } from './SiteVisit.module.css';

type FileSectionProps = { offerID: number };

export const FileSection = ({ offerID }: FileSectionProps) => {
  const translate = useLocalization();

  const { siteVisitFiles, isFetching } = useSiteVisitFiles(offerID);
  const { uploadSiteVisitFile, isLoading: isUploading } = useUploadSiteVisitFile(offerID);
  const { deleteSiteVisitFile, isLoading: isDeleting } = useDeleteSiteVisitFiles(offerID);

  if (isFetching) {
    return null;
  }

  return (
    <Card title={translate.MISCELLANEOUS_FILES}>
      <div className={displayColumn}>
        {siteVisitFiles.map(({ id, url, name }) => (
          <div key={url} className={siteVisitFile}>
            <DeleteOutlined onClick={() => deleteSiteVisitFile(id)} disabled={isDeleting} />

            <a href={url}>{name}</a>

            {isDeleting && <Spinner />}
          </div>
        ))}

        <FileUploadButton isLoading={isUploading} onUpload={files => uploadSiteVisitFile(files[0])} />
      </div>
    </Card>
  );
};
