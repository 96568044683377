import { Router } from './components/router/Router';
import { AntdContext } from './contexts/AntdContext';
import { DevelopmentContext } from './contexts/DevelopmentContext';
import { QueryContext } from './contexts/QueryContext';

import '~src/style/global.css';

export const CRMApplication = () => (
  <QueryContext>
    <DevelopmentContext>
      <AntdContext>
        <Router />
      </AntdContext>
    </DevelopmentContext>
  </QueryContext>
);
