import clsx from 'clsx';
import { ReactNode } from 'react';

import { TopMenu } from '~src/components/layouts/menus';
import { useToastMessage } from '~src/hooks/useToastMessage';

import { layout, layoutContent, layoutNoLeftMenu, layoutTopMenu } from './DefaultLayout.module.css';

type DefaultLayoutProps = {
  children?: ReactNode;
  type?: 'default' | 'minimal';
  className?: string;
};

export const DefaultLayout = ({ children, type = 'default', className }: DefaultLayoutProps) => {
  const { contextHolder } = useToastMessage();

  const topMenuType = type === 'minimal' ? 'logout' : 'default';

  return (
    <div className={clsx([layout, layoutNoLeftMenu, className])}>
      <TopMenu className={layoutTopMenu} type={topMenuType} />

      <main className={layoutContent}>
        {contextHolder}
        {children}
      </main>
    </div>
  );
};
