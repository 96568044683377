import { useEffect } from 'react';

import { Button, Divider, Form, Heading, Radio, Select, SkraafotoLink, Text } from '~src/components/display';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { useLocalization } from '~src/hooks';
import { ResidenceInput } from '~src/types';

import { roofsFieldKey, roofTypeFieldKey, shadowsFieldKey } from '../constants';
import { getRoofShadowOptions, getRoofTypeOptions } from '../helpers/residenceHelper';
import { ResidenceRoof } from './ResidenceFormRoof';

import { missingDataSection, roofHeaderSection } from './residenceFormComponents.module.css';

type ResidenceFormRoofsProps = {
  residence?: Pick<ResidenceInput, 'address' | 'roofs' | 'roofType'>;
};

export const ResidenceFormRoofs = ({ residence }: ResidenceFormRoofsProps) => {
  const translate = useLocalization();

  const form = Form.useFormInstance();

  const { address } = residence ?? {};

  const formShadows = Form.useWatch([roofsFieldKey, 0, shadowsFieldKey], form);

  const roofShadowOptions = getRoofShadowOptions(translate);

  const roofTypeOptions = getRoofTypeOptions(translate);

  const requiredFieldRules = useFormRequiredFieldRules();

  const formResidence = form.getFieldsValue();

  useEffect(
    function synchronizeShadowValues() {
      const { roofs: formRoofs } = formResidence ?? {};

      if (formRoofs?.length ?? 0 < 2) {
        return;
      }

      formRoofs?.map((_: never, index: number) =>
        form.setFieldValue([roofsFieldKey, index, shadowsFieldKey], formShadows)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formShadows]
  );

  return (
    <>
      <div className={roofHeaderSection}>
        <Heading level={3}>{translate.RESIDENCE_ROOF}</Heading>

        {address && <SkraafotoLink address={address} />}

        <Form.Item noStyle name={[roofsFieldKey, 0, shadowsFieldKey]}>
          <Radio.Group options={roofShadowOptions} optionType='button' buttonStyle='outline' />
        </Form.Item>
      </div>

      <Divider fullWidth />

      <Form.List name={roofsFieldKey}>
        {(roofs, { add, remove }) => (
          <>
            {roofs.length === 0 && (
              <div className={missingDataSection}>
                <Text>{translate.NO_ROOF_PLANE}</Text>

                <Button type='primary' onClick={() => add()}>
                  + {translate.ADD} {translate.ROOF_PLANE.toLowerCase()}
                </Button>
              </div>
            )}

            {roofs.map((roof, roofIndex) => (
              <ResidenceRoof
                key={roof.key}
                roof={roof}
                roofIndex={roofIndex}
                add={add}
                remove={remove}
                lastRoof={roofIndex + 1 === roofs.length}
              />
            ))}
          </>
        )}
      </Form.List>

      <Form.Item label={translate.ROOF_TYPE} name={[roofTypeFieldKey]} rules={requiredFieldRules}>
        <Select options={roofTypeOptions} value={residence?.roofType ?? 'unknown'} />
      </Form.Item>
    </>
  );
};
