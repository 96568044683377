import clsx from 'clsx';

import { getSizedImageUrl } from '~src/utilities/convert';

import { PLACEHOLDER_IMAGE_URL } from './imageConstants';

import { imageBox, placeholderImage } from './ImageThumbnail.module.css';

type ImageThumbnailProps = {
  className?: string;
  imageUrl?: string;
  alt?: string;
  width?: number;
  height?: number;
};

const DEFAULT_SIZE = 200;

export const ThumbnailWithUrl = ({ imageUrl, alt, className, width = DEFAULT_SIZE, height }: ImageThumbnailProps) => {
  if (!imageUrl) {
    return <PlaceHolderImage />;
  }

  return (
    <img
      alt={alt}
      className={clsx([imageBox, className])}
      decoding='async'
      loading='lazy'
      src={getSizedImageUrl(imageUrl, { width, height })}
    />
  );
};

const PlaceHolderImage = () => (
  <img alt='placeholder' className={placeholderImage} decoding='async' loading='lazy' src={PLACEHOLDER_IMAGE_URL} />
);
