import { create } from 'zustand';

type State = {
  effectPrice: number;
  efficiency: number;
  installationPrice: number;
  installationYear: number;
  investment: number;
  relayPrice: number;
  subscriptionPrice: number;
  unitPrice: number;
  yearlyPrice: number;
};

type Actions = {
  updateDistrictHeating: (update: Partial<Omit<State, 'yearlyPrice'>>) => void;
};

export type DistrictHeatingState = State & Actions;

const initialState: State = {
  effectPrice: 0,
  efficiency: 100,
  investment: 0,
  installationPrice: 0,
  installationYear: new Date().getUTCFullYear() + 1,
  relayPrice: 0,
  subscriptionPrice: 0,
  unitPrice: 0,
  yearlyPrice: 0,
};

export const useDistrictHeatingState = create<DistrictHeatingState>(set => ({
  ...initialState,

  updateDistrictHeating: update => set(old => ({ ...old, ...update })),
}));
