import { useState } from 'react';

import { Button, Card, Form } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useUpsertCustomer } from '~src/hooks/services/useCustomer';
import { useNavigation } from '~src/hooks/useNavigation';
import { CustomerInput } from '~src/types';

import { CreateOfferFooter } from '../components/CreateOfferFooter';
import { CRMCustomerSearch } from '../components/customer/CRMCustomerSearch';
import { CustomerForm } from '../components/customer/CustomerForm';
import { CustomerSearch } from '../components/customer/CustomerSearch';
import { useInitializeCreateOfferStateFromParameters } from '../hooks/useInitializeCreateOfferStateFromParameters';
import { useCreateOfferState } from '../state/createOfferState';

import { content } from '../createOffer.module.css';

export const CreateOfferCustomer = () => {
  const translate = useLocalization();
  const [form] = Form.useForm<CustomerInput>();
  const [isEditingCustomer, setIsEditingCustomer] = useState(false);

  const { navigate, navigateRaw } = useNavigation();

  const { customer, reset, setCustomer } = useCreateOfferState();
  useInitializeCreateOfferStateFromParameters();

  const { upsertCustomer, isLoading } = useUpsertCustomer();

  const handleFormSubmit = async (newCustomer: CustomerInput) => {
    const customerId = await upsertCustomer({ ...newCustomer, id: customer?.id });
    if (!customerId) {
      return;
    }

    reset();
    setCustomer({ id: customerId, ...newCustomer });

    // Replace browser history
    navigateRaw(`/create-offer/customer/${customerId}`, { replace: true });

    navigate('createOfferResidence');
  };

  const formEnabled = isEditingCustomer || customer?.id;

  const forwardButtonEnabled = !isLoading && (isEditingCustomer || customer?.id);
  const forwardButtonClick =
    customer?.id && !isEditingCustomer
      ? () => {
          navigate('createOfferResidence');
        }
      : form.submit;

  let forwardButtonText = translate.CREATE_CUSTOMER_AND_CONTINUE;
  if (customer?.id && isEditingCustomer) {
    forwardButtonText = translate.UPDATE_CUSTOMER_AND_CONTINUE;
  } else if (customer?.id) {
    forwardButtonText = translate.CONTINUE;
  }

  return (
    <>
      <Card className={content}>
        <CustomerSearch toggleEditing={setIsEditingCustomer} />

        <CRMCustomerSearch toggleEditing={setIsEditingCustomer} />

        <CustomerForm
          disabled={!formEnabled}
          form={form}
          onSubmit={handleFormSubmit}
          toggleEditing={setIsEditingCustomer}
        />
      </Card>

      <CreateOfferFooter>
        <Button onClick={forwardButtonClick} disabled={!forwardButtonEnabled} type='primary'>
          {forwardButtonText}
        </Button>
      </CreateOfferFooter>
    </>
  );
};
