import { Card, Switch, Text } from '~src/components/display';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useUpsertCustomer } from '~src/hooks/services';
import { CustomerInput } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';

type CustomerFinancingProps = {
  customer?: CustomerInput;
  updateCustomer?: (arg: CustomerInput) => void;
};

export const CustomerPriceInflation = ({ customer, updateCustomer }: CustomerFinancingProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const { upsertCustomer } = useUpsertCustomer();

  const setUseInflation = async (newValue: boolean) => {
    const updated: CustomerInput = {
      ...customer,
      usePriceInflation: newValue,
    };

    await upsertCustomer(updated);

    updateCustomer?.(deepNullToUndefined(updated));

    showMessage({ type: 'success', message: translate.SAVED });
  };

  const useInflation = customer?.usePriceInflation ?? true;

  return (
    <Card title={translate.INFLATION}>
      <Text>{translate.CUSTOMER_INFLATION_PROMPT}</Text>

      <Switch defaultChecked={useInflation} onChange={newValue => setUseInflation(newValue)} />
    </Card>
  );
};
