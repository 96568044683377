import { ModalButton, Switch } from '~src/components/display';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { OfferDistrictHeatingComparison, OfferStatistics } from '~src/components/features/shared/offer';
import {
  HeatPumpIndoorProductSelector,
  HeatPumpOutdoorProductSelector,
  RecommendedHeatEffectOverview,
} from '~src/components/features/shared/product';
import { useLocalization, useOfferState } from '~src/hooks';
import { useCustomer } from '~src/hooks/services';
import { OfferProducts, ResidenceInput } from '~src/types';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

type OfferEditHeatPumpProductsProps = {
  offerProducts?: OfferProducts;
  residence?: ResidenceInput;
};

export const OfferEditHeatPumpProducts = ({ offerProducts, residence }: OfferEditHeatPumpProductsProps) => {
  const translate = useLocalization();
  const { customer } = useCustomer(residence?.customerID);
  const { archiveExistingOffer, setArchiveExistingOffer } = useOfferState();

  return (
    <div className={displayColumn}>
      <div className={displayRow}>
        <div className={displayColumn}>
          <OfferStatistics customer={customer} offerProducts={offerProducts} residence={residence} />

          {!!residence && !!offerProducts?.heatPumpOutdoorUnit && (
            <ModalButton buttonChildren={translate.DISTRICT_HEATING_COMPARE}>
              <OfferDistrictHeatingComparison customer={customer} products={offerProducts} residence={residence} />
            </ModalButton>
          )}

          <RecommendedHeatEffectOverview residence={residence} />
        </div>

        <div className={displayColumn}>
          <CustomerNotes customer={customer} rows={12} />

          <div>
            {translate.ARCHIVE_EXISTING_OFFER}&nbsp;
            <Switch checked={archiveExistingOffer} onChange={value => setArchiveExistingOffer(value)} />
          </div>

          <OfferProfitMargins offer={offerProducts} />
        </div>
      </div>

      <HeatPumpOutdoorProductSelector residence={residence} />

      <HeatPumpIndoorProductSelector />
    </div>
  );
};
