import round from 'lodash/round';

export const getCorrectedHeatRequirement = (
  multiplier: number,
  primary: number,
  area: number,
  areaNotHeated?: number
): string => {
  if (!area) {
    return '-';
  }

  const isAreaNotHeatedANumber = !!areaNotHeated && !Number.isNaN(areaNotHeated);

  const adjustedHeatedArea = area - (areaNotHeated ?? 0);

  const heatedArea = isAreaNotHeatedANumber && adjustedHeatedArea !== 0 ? adjustedHeatedArea : area;

  const correctedHeatPerArea = primary / heatedArea;

  const correctedPrimary = area * correctedHeatPerArea;

  return `${round(correctedPrimary * multiplier, 2)} kW`;
};
