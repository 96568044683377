import round from 'lodash/round';

import { useLocalization, useOfferProductState } from '~src/hooks';
import { useOfferSavingsWithAndWithoutBattery } from '~src/hooks/services';
import { mapOfferToOfferInput, MaybeId, ResidenceInput } from '~src/types';
import { calculateAverageIntensity } from '~src/utilities/calculationHelper';
import { consumptionProfiles, DEFAULT_CONSUMPTION_PROFILE_KEY } from '~src/utilities/constants';
import { formatPercentage } from '~src/utilities/format';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useFormatSolarOfferProductionTableData = (residence?: ResidenceInput & MaybeId) => {
  const translate = useLocalization();

  const { consumptionProfile, address, roofs, customerID } = residence ?? {};
  const { selectedSolarPanel, selectedInverter, selectedBattery } = useOfferProductState();

  const solarProducts = mapOfferToOfferInput({
    solarPanel: selectedSolarPanel,
    inverter: selectedInverter,
    battery: selectedBattery,
  });

  const consumption = consumptionProfiles[consumptionProfile ?? DEFAULT_CONSUMPTION_PROFILE_KEY];

  const { withoutBattery, withBattery } = useOfferSavingsWithAndWithoutBattery(
    residence,
    solarProducts,
    address,
    undefined,
    consumption,
    customerID
  );

  const { after: afterWithBattery } = withBattery ?? {};
  const { after: afterWithoutBattery } = withoutBattery ?? {};

  const { annualProduction, quantity } = selectedSolarPanel ?? {};

  const production = (annualProduction ?? 0) * (quantity ?? 1);

  const solarIntensity = calculateAverageIntensity(roofs ?? [], selectedInverter, address?.postalCode);

  const productionColumns = [{ dataIndex: 'productionDescription' }, { dataIndex: 'value' }];

  const productionData = [
    {
      productionDescription: translate.TOTAL_ELECTRICITY_USAGE,
      value: `${(afterWithBattery?.bought ?? 0) + (afterWithBattery?.used ?? 0)} kWh`,
    },
    { productionDescription: translate.MAXIMUM_PRODUCTION, value: `${round(production)} kWh` },
    { productionDescription: translate.SOLAR_CELL_INTENSITY, secondary: true, value: formatPercentage(solarIntensity) },
    { productionDescription: translate.REAL_PRODUCTION, value: `${afterWithBattery?.produced} kWh` },
    {
      productionDescription: translate.SELF_USAGE,
      value: `${afterWithBattery?.used} kWh (${formatPercentage(afterWithBattery?.usedPercentage ?? 0, 1)})`,
    },
    { productionDescription: translate.REMAINING_ELECTRICITY_SOLD, value: `${afterWithBattery?.sold} kWh` },
    { productionDescription: translate.BOUGHT_ELECTRICITY, value: `${afterWithBattery?.bought} kWh` },
    {
      productionDescription: translate.EXTRA_CONSUMPTION_WITH_BATTERY,
      value: `${round((afterWithoutBattery?.bought ?? 0) - (afterWithBattery?.bought ?? 0))} kWh`,
    },
  ];

  return { productionColumns, productionData };
};
