import { ConfigProvider } from 'antd';
import { ReactNode } from 'react';

import { colorPrimary } from '~src/style/color.module.css';

export const AntdContext = ({ children }: { children: ReactNode }) => (
  <ConfigProvider
    theme={{
      token: {
        fontFamily: 'inherit',
        colorPrimary,
      },
    }}
  >
    {children}
  </ConfigProvider>
);
