import { clsx } from 'clsx';
import { Bar, BarChart, ResponsiveContainer, XAxis } from 'recharts';

import { colorPrimary } from '~src/style/color.module.css';

import { graphContainer } from './graphs.module.css';

type ConsumptionProfileGraphDataPoint = { timeOfDay: string; energyExpenditure: number };

type ConsumptionProfileGraphProps = {
  data?: ConsumptionProfileGraphDataPoint[];
  className?: string;
};

export const ConsumptionProfileGraph = ({ data = [], className }: ConsumptionProfileGraphProps) => (
  <div className={clsx([graphContainer, className])}>
    <ResponsiveContainer height='100%' width='100%'>
      <BarChart data={data}>
        <XAxis dataKey='timeOfDay' />

        <Bar dataKey='energyExpenditure' fill={colorPrimary} />
      </BarChart>
    </ResponsiveContainer>
  </div>
);
