import { Card } from '~src/components/display';
import { Link } from '~src/components/router/Link';
import { useLocalization } from '~src/hooks';
import { routes } from '~src/routes';
import { Offer } from '~src/types';
import { location } from '~src/utilities/global';

type OfferInstallerLinkProps = {
  offer?: Pick<Offer, 'installationAccessCode' | 'siteVisitAccessCode'>;
};

export const OfferInstallerLinks = ({ offer }: OfferInstallerLinkProps) => {
  const translate = useLocalization();

  if (!offer) {
    return null;
  }

  const { installationAccessCode, siteVisitAccessCode } = offer;

  const installerURL = `${location.origin}${routes.installationGuide}/${installationAccessCode}`;
  const siteVisitURL = `${location.origin}${routes.installerSiteVisit}/${siteVisitAccessCode}`;

  return (
    <Card>
      <Card title={translate.INSTALLER_LINK}>
        <Link target='_blank' to={installerURL}>
          {installerURL}
        </Link>
      </Card>

      <Card title={translate.SITE_VISIT_INSTALLER_LINK}>
        <Link target='_blank' to={siteVisitURL}>
          {siteVisitURL}
        </Link>
      </Card>
    </Card>
  );
};
