import { Card, Heading, Timeline } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { Offer } from '~src/types';
import { formatDate } from '~src/utilities/format';
import { sortSentOffers } from '~src/utilities/offer';

type SentOffersHistoryProps = {
  offer?: Pick<Offer, 'sentOffers'>;
};

export const SentOffersHistory = ({ offer }: SentOffersHistoryProps) => {
  const translate = useLocalization();

  const { sentOffers } = offer ?? {};

  if (!sentOffers?.length) {
    return <Card title={translate.OFFERS_SENT}>{translate.NO}</Card>;
  }

  const sortedSentOffers = sortSentOffers(offer);

  const items = sortedSentOffers.map(sentOffer => {
    const { sentAt, type, id } = sentOffer;

    return {
      children: (
        <Heading level={3} key={id}>
          {`${type === 'final' ? translate.FINAL_OFFER_SENT : translate.PRELIMINARY_OFFER_SENT} ${
            sentAt ? `(${formatDate(sentAt)})` : `(${translate.AWAITING})`
          }`}
        </Heading>
      ),
    };
  });

  return (
    <Card title={translate.OFFERS_SENT}>
      <Timeline items={items} />
    </Card>
  );
};
