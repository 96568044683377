import { clsx } from 'clsx';

import { Card, DisabledTextArea, Heading, Spinner } from '~src/components/display';
import { DefaultLayout } from '~src/components/layouts';
import { useLocalization } from '~src/hooks';
import { Customer, Offer, Residence } from '~src/types';
import { formatAddress, formatName } from '~src/utilities/format';

import { ProductInformation } from '../shared/product';
import { ResidenceInformation } from '../shared/residence';
import { BasicInformation } from './BasicInformation';
import { FileSection } from './FileSection';
import { QuestionInformation } from './QuestionInformation';

import { center, displayColumn, displayGrid, fullScreen } from '~src/style/shared.module.css';

import { siteVisitPage as siteVisitPageStyle } from './SiteVisit.module.css';

type SiteVisitProps = {
  offer?: Pick<
    Offer,
    'price' | 'battery' | 'heatPumpIndoorUnit' | 'heatPumpOutdoorUnit' | 'inverter' | 'solarPanel' | 'id'
  >;
  residence?: Residence;
  customer?: Pick<Customer, 'assignedAdvisor' | 'email' | 'name' | 'phoneNumber' | 'notes'>;
  isLoading?: boolean;
};

export const SiteVisit = ({ offer, residence, customer, isLoading }: SiteVisitProps) => {
  const translate = useLocalization();

  const { assignedAdvisor, email, name, phoneNumber, notes } = customer ?? {};

  if (isLoading) {
    return (
      <DefaultLayout type='minimal'>
        <div className={clsx(center, fullScreen, displayGrid)}>
          <Heading>{translate.SITE_VISIT}</Heading>

          <Spinner />
        </div>
      </DefaultLayout>
    );
  }

  if (!offer) {
    return (
      <DefaultLayout type='minimal'>
        <div className={clsx(center, fullScreen, displayGrid)}>
          <Heading>{translate.SITE_VISIT}</Heading>

          <Heading level={2}>{translate.NOT_FOUND}</Heading>
        </div>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout className={siteVisitPageStyle} type='minimal'>
      <div className={displayColumn}>
        <BasicInformation
          address={formatAddress(residence?.address ?? {})}
          advisor={formatName(assignedAdvisor)}
          email={email}
          name={name}
          phoneNumber={phoneNumber}
        />

        <Card title={translate.CUSTOMER_NOTES}>
          <DisabledTextArea text={notes ?? ''} />
        </Card>

        <ProductInformation offer={offer} residence={residence} />

        <FileSection offerID={offer.id} />

        <ResidenceInformation residence={residence} />

        <QuestionInformation offer={offer} residence={residence} />
      </div>
    </DefaultLayout>
  );
};
