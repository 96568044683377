import { useEffect } from 'react';

import { Card } from '~src/components/display';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { OfferStatistics } from '~src/components/features/shared/offer';
import { OfferExpenseTableNewOffer } from '~src/components/features/shared/offer/OfferExpenseTable';
import {
  SolarPackageSelector,
  SolarPanelProductSelector,
  SolarSavingsGraphWithElectricityInput,
  SolarSecondaryProductsSelector,
} from '~src/components/features/shared/product';
import { SolarOfferStatistics } from '~src/components/features/shared/product/solar/SolarOfferStatistics';
import { useLocalization, useOfferProductState, useSelectSolarPackage } from '~src/hooks';
import { useDefaultSolarProducts } from '~src/hooks/useDefaultSolarProducts';
import { OfferProducts } from '~src/types';
import { convertRoofFromMetersToCentimeters } from '~src/utilities/roof';
import { calculateMaximumNumberOfSolarPanels } from '~src/utilities/solarPanel';

import { useCombineOfferProductsWithExpenses } from '../../hooks/useCombineOfferProductsWithExpenses';
import { useSynchronizeStatefulCustomerNotes } from '../../hooks/useSynchronizeStatefulCustomerNotes';
import { useCreateOfferState } from '../../state/createOfferState';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import { tab } from './product.module.css';

const INTELLICHARGE_START_DATE = new Date('June 1, 2024 00:00:00');
const INTELLICHARGE_END_DATE = new Date('September 30, 2024 23:59:59');
const NOW = new Date();

const INTELLICHARGE_EXPENSE_TEMPLATE_IDS =
  NOW > INTELLICHARGE_START_DATE && NOW < INTELLICHARGE_END_DATE ? [86, 92] : [86];

export const CreateOfferSolarTab = () => {
  const translate = useLocalization();
  const { customer, residence, setResidence, offerList } = useCreateOfferState();
  useSynchronizeStatefulCustomerNotes();

  const { selectedSolarPanel, selectedInverter, selectedBattery, setOfferExpenses, selectedOfferExpenses } =
    useOfferProductState();

  const { selectSolarPackage } = useSelectSolarPackage();

  const offerProducts: OfferProducts = {
    solarPanel: selectedSolarPanel,
    inverter: selectedInverter,
    battery: selectedBattery,
  };

  const offerProductsWithExpenses = useCombineOfferProductsWithExpenses(offerProducts);
  useDefaultSolarProducts();

  const { withCoverage } = calculateMaximumNumberOfSolarPanels(
    selectedSolarPanel,
    residence?.roofs,
    convertRoofFromMetersToCentimeters
  );

  useEffect(
    function removeExistingExpenses() {
      setOfferExpenses([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerList.length]
  );

  const isSolplanet = selectedBattery?.brand.trim() === 'Solplanet';

  return (
    <Card className={tab}>
      <div className={displayRow}>
        <div className={displayColumn}>
          <OfferStatistics customer={customer} offerProducts={offerProductsWithExpenses} residence={residence} />

          <SolarSavingsGraphWithElectricityInput
            residence={residence}
            setResidence={setResidence}
            selectPackage={selectSolarPackage}
          />
        </div>

        <div className={displayColumn}>
          <CustomerNotes customer={customer} rows={12} />

          <OfferProfitMargins offer={offerProductsWithExpenses} />
        </div>
      </div>

      <SolarPackageSelector onSelect={selectSolarPackage} />

      <SolarPanelProductSelector />

      <SolarSecondaryProductsSelector maximumSolarPanels={withCoverage} />

      <SolarOfferStatistics residence={residence} />

      <br />
      <OfferExpenseTableNewOffer
        expenses={selectedOfferExpenses}
        offerType='solar'
        tableTitle={translate.EXPENSE}
        updateExpenses={setOfferExpenses}
        addExpenseTemplateIds={isSolplanet ? INTELLICHARGE_EXPENSE_TEMPLATE_IDS : []}
        removeExpenseTemplateIds={isSolplanet ? [] : INTELLICHARGE_EXPENSE_TEMPLATE_IDS}
      />
    </Card>
  );
};
