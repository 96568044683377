import { useEffect, useRef, useState } from 'react';

import { useLocalization } from '~src/hooks';

import { Form } from '../Form';
import { Input, InputRef } from '../Input';
import { TableItem } from './types';

type EditableCellProps<T extends TableItem> = {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof T;
  record: T;
  handleSave: (value: string) => void;
};

export const EditableCell = <T extends TableItem>({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
}: EditableCellProps<T>) => {
  const translate = useLocalization();

  const inputRef = useRef<InputRef>(null);

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState((record?.[dataIndex ?? ''] ?? '').toString());

  useEffect(
    function autoFocusOnEdit() {
      if (editing) {
        inputRef?.current?.focus();
      }
    },
    [editing]
  );

  const toggleEdit = () => {
    setEditing(!editing);
    setValue((record?.[dataIndex ?? ''] ?? '').toString());
  };

  const save = () => {
    handleSave(value);
    setEditing(!editing);
  };

  if (!editable) {
    return <td>{children}</td>;
  }

  return editing ? (
    <td>
      <Form>
        <Form.Item
          rules={[
            {
              required: true,
              message: `${title} ${translate.FORM_FIELD_REQUIRED}`,
            },
          ]}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={event => {
              setValue(event.target.value);
            }}
            value={value}
          />
        </Form.Item>
      </Form>
    </td>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <td onClick={toggleEdit} style={{ cursor: 'pointer' }}>
      {children}
    </td>
  );
};
