import { Divider, Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { ResidenceInput } from '~src/types';

import { RecommendedHeatEffect } from './RecommendedHeatEffect';

import { recommendedHeatEffectHeading } from './heatEffect.module.css';

type RecommendedHeatEffectsProps = {
  residence?: ResidenceInput;
};

export const RecommendedHeatEffectOverview = ({ residence }: RecommendedHeatEffectsProps) => {
  const translate = useLocalization();

  return (
    <>
      <Heading className={recommendedHeatEffectHeading}>{translate.RECOMMENDED_HEAT_EFFECTS}</Heading>

      <Divider fullWidth />

      <div>
        <Heading>{translate.RECOMMENDED_AT_MINUS_7_DEGREES}</Heading>

        <RecommendedHeatEffect residence={residence} temperature={-7} />

        <Heading>{translate.RECOMMENDED_AT_MINUS_12_DEGREES}</Heading>

        <RecommendedHeatEffect residence={residence} temperature={-12} />
      </div>
    </>
  );
};
