export enum SiteVisitSections {
  electricity = 'electricity',
  floorPlan = 'floorPlan',
  heatSource = 'heatSource',
  indoorPlacement = 'indoorPlacement',
  inverterAndBatteryPlacement = 'inverterAndBatteryPlacement',
  other = 'other',
  outdoorPlacement = 'outdoorPlacement',
  piping = 'piping',
  radiators = 'radiators',
  roofConstruction = 'roofConstruction',
  solarPlacement = 'solarPlacement',
}

export const RADIATOR_PIPE_SIZE_OPTIONS = [
  { label: '0,25" (6,3mm)', value: '¼"' },
  { label: '10mm', value: '10mm' },
  { label: '0,50" (12,7mm)', value: '½"' },
  { label: '0,75" (19mm)', value: '¾"' },
  { label: '20mm', value: '20mm' },
  { label: '20mm+', value: '20mm+' },
];

type NoiseLevelValues = Record<
  'noScreen' | 'withScreen',
  Record<'hardFoundation' | 'softFoundation', Record<number, number>>
>;
export const NOISE_LEVEL_VALUES: NoiseLevelValues = {
  noScreen: {
    hardFoundation: {
      45: 2.5,
      46: 2.6,
      47: 2.8,
      48: 2.9,
      49: 3.1,
      50: 3.2,
      51: 3.9,
      52: 4.6,
      53: 5.2,
      54: 5.9,
      55: 6.6,
      56: 7.5,
      57: 8.4,
      58: 9.2,
      59: 10.1,
      60: 11.0,
      61: 12.3,
      62: 13.6,
      63: 14.9,
      64: 16.2,
      65: 17.5,
      66: 18.4,
      67: 19.3,
      68: 20.2,
      69: 21.1,
      70: 22.0,
    },
    softFoundation: {
      45: 1.8,
      46: 1.8,
      47: 1.9,
      48: 1.9,
      49: 2.0,
      50: 2.0,
      51: 2.5,
      52: 2.9,
      53: 3.4,
      54: 3.8,
      55: 4.3,
      56: 4.9,
      57: 5.5,
      58: 6.1,
      59: 6.7,
      60: 7.3,
      61: 8.2,
      62: 9.2,
      63: 10.1,
      64: 11.1,
      65: 12.0,
      66: 13.4,
      67: 14.8,
      68: 16.2,
      69: 17.6,
      70: 19.0,
    },
  },
  withScreen: {
    hardFoundation: {
      45: 1.8,
      46: 2.0,
      47: 2.3,
      48: 2.5,
      49: 2.8,
      50: 3.0,
      51: 3.4,
      52: 3.8,
      53: 4.2,
      54: 4.6,
      55: 5.0,
      56: 5.6,
      57: 6.2,
      58: 6.8,
      59: 7.4,
      60: 8.0,
      61: 9.0,
      62: 10.0,
      63: 11.0,
      64: 12.0,
      65: 13.0,
      66: 13.4,
      67: 13.8,
      68: 14.2,
      69: 14.6,
      70: 15.0,
    },
    softFoundation: {
      45: 1.6,
      46: 1.7,
      47: 1.8,
      48: 1.8,
      49: 1.9,
      50: 2.0,
      51: 2.3,
      52: 2.6,
      53: 3.0,
      54: 3.3,
      55: 3.6,
      56: 4.0,
      57: 4.4,
      58: 4.7,
      59: 5.1,
      60: 5.5,
      61: 6.2,
      62: 6.9,
      63: 7.6,
      64: 8.3,
      65: 9.0,
      66: 10.0,
      67: 11.0,
      68: 12.0,
      69: 13.0,
      70: 14.0,
    },
  },
} as const;
