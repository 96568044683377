import { Form, Input, Select } from '~src/components/display';
import { getValueAsInteger } from '~src/components/display/Form/formHelper';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { useLocalization } from '~src/hooks';
import { useCustomer } from '~src/hooks/services';
import { mapCustomerToCustomerInput } from '~src/types';

import {
  areaFieldKey,
  areaHeatedFieldKey,
  areaNotHeatedFieldKey,
  areaPartiallyHeatedFieldKey,
  builtYearFieldKey,
  energyLabelFieldKey,
  floorsFieldKey,
  renovatedDegreeFieldKey,
  renovatedYearFieldKey,
  residentsFieldKey,
  typeFieldKey,
} from '../constants';
import { ENERGY_LABEL_OPTIONS, getRenovationDegreeOptions, getResidenceTypeOptions } from '../helpers/residenceHelper';
import { ResidenceFormSection } from './ResidenceFormSection';

import { displayRow } from '~src/style/shared.module.css';

type ResidenceFormResidenceInformationProps = {
  customerId?: number;
};

export const ResidenceFormResidenceInformation = ({ customerId }: ResidenceFormResidenceInformationProps) => {
  const translate = useLocalization();

  const { customer } = useCustomer(customerId);

  const requiredFieldRules = useFormRequiredFieldRules();

  const residenceTypeOptions = getResidenceTypeOptions(translate);

  const renovationDegreeOptions = getRenovationDegreeOptions(translate);

  return (
    <div className={displayRow}>
      <ResidenceFormSection title={translate.RESIDENCE_INFORMATION} columns>
        <Form.Item label={translate.RESIDENCE_TYPE} name={typeFieldKey} rules={requiredFieldRules}>
          <Select options={residenceTypeOptions} />
        </Form.Item>

        <Form.Item
          getValueFromEvent={getValueAsInteger}
          label={translate.NUMBER_OF_FLOORS}
          name={floorsFieldKey}
          rules={requiredFieldRules}
        >
          <Input type='number' />
        </Form.Item>

        <Form.Item getValueFromEvent={getValueAsInteger} label={translate.NUMBER_OF_RESIDENTS} name={residentsFieldKey}>
          <Input type='number' />
        </Form.Item>

        <Form.Item
          getValueFromEvent={getValueAsInteger}
          label={translate.BUILT_YEAR}
          name={builtYearFieldKey}
          rules={requiredFieldRules}
        >
          <Input type='number' />
        </Form.Item>

        <Form.Item
          getValueFromEvent={getValueAsInteger}
          label={translate.LIVING_AREA}
          name={areaFieldKey}
          rules={requiredFieldRules}
        >
          <Input type='number' />
        </Form.Item>

        <Form.Item
          getValueFromEvent={getValueAsInteger}
          label={translate.HEATED_LIVING_AREA}
          name={areaHeatedFieldKey}
          rules={requiredFieldRules}
        >
          <Input type='number' />
        </Form.Item>

        <Form.Item
          getValueFromEvent={getValueAsInteger}
          label={translate.PARTIALLY_HEATED_AREA}
          name={areaPartiallyHeatedFieldKey}
        >
          <Input type='number' />
        </Form.Item>

        <Form.Item getValueFromEvent={getValueAsInteger} label={translate.NOT_HEATED_AREA} name={areaNotHeatedFieldKey}>
          <Input type='number' />
        </Form.Item>

        <Form.Item getValueFromEvent={getValueAsInteger} label={translate.RENOVATION_YEAR} name={renovatedYearFieldKey}>
          <Input type='number' />
        </Form.Item>

        <Form.Item label={translate.RENOVATION_DEGREE} name={renovatedDegreeFieldKey}>
          <Select options={renovationDegreeOptions} />
        </Form.Item>

        <Form.Item label={translate.ENERGY_LABEL} name={energyLabelFieldKey}>
          <Select options={ENERGY_LABEL_OPTIONS} />
        </Form.Item>
      </ResidenceFormSection>

      <CustomerNotes customer={mapCustomerToCustomerInput(customer)} rows={11} />
    </div>
  );
};
