import { useInvertersQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { deepNullToUndefined } from '~src/utilities/convert';

export const useInverters = (ids?: number[]) => {
  const { graphQLClient } = useGraphQLClient();
  const { data, isFetching } = useInvertersQuery(graphQLClient, { ids }, { onError: useToastMessageErrorHandler() });

  return { inverters: data?.inverters?.map(deepNullToUndefined) ?? [], isLoading: isFetching };
};
