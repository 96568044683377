import { useNewOfferExpensesQuery } from '~src/gql';
import { useGraphQLClient } from '~src/services/client';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useToastMessageErrorHandler } from '../useToastMessage';

export const useNewOfferExpenses = () => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useNewOfferExpensesQuery(graphQLClient, undefined, {
    onError: useToastMessageErrorHandler(),
  });

  return { newOfferExpenses: deepNullToUndefined(data?.newOfferExpenses), isLoading: isFetching };
};
