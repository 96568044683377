import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';

import { informationColumn } from './installationGuide.module.css';

type PurchaseInformation = {
  buyLocation: string;
  cvr: string;
  hideRequisitionNumber?: boolean;
};

const PURCHASE_INFORMATION_BY_BRAND: Record<string, PurchaseInformation> = {
  Samsung: { buyLocation: 'Dansk Klima Center ApS', cvr: '25518071' },
  Fujitsu: { buyLocation: 'Klimalux A/S', cvr: '27049907' },
  Toshiba: { buyLocation: 'GIDEX', cvr: '32559395', hideRequisitionNumber: true },
  Mitsubishi: { buyLocation: 'Kinnan A/S', cvr: '29837961' },
  Daikin: { buyLocation: 'BKF-KLIMA A/S', cvr: '18297094' },
  Vølund: { buyLocation: 'Vølund Varmeteknik A/S', cvr: '32938108' },
};

type InstallerGuidePurchaseInformationProps = {
  brand?: string;
  tag?: string;
};

export const InstallerGuidePurchaseInformation = ({ brand, tag }: InstallerGuidePurchaseInformationProps) => {
  const translate = useLocalization();

  const { buyLocation, cvr } = PURCHASE_INFORMATION_BY_BRAND[brand ?? ''] ?? {};

  return (
    <div className={informationColumn}>
      <InstallationGuideEntry label={translate.PURCHASE_HEAT_PUMP_AT} value={buyLocation} />

      <InstallationGuideEntry label={translate.CVR_NUMBER} value={cvr} />

      <InstallationGuideEntry label={translate.REQUISITION_NUMBER} value={`BODIL + ${tag}`} />
    </div>
  );
};
