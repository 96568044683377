import { clampValueBetween } from '~src/utilities/format';

export const getValueAsInteger = (event: { target?: { value?: string } }) => {
  const { value } = event?.target ?? {};

  return value ? parseInt(value ?? '0', 10) : undefined;
};

export const getValueAsFloat = (event: { target?: { value?: string } }) => {
  const { value } = event?.target ?? {};

  return value ? parseFloat(value ?? '0') : undefined;
};

export const getClampedValueAsInteger = (
  event: { target?: { value?: string; valueAsNumber?: number } },
  min = 0,
  max = 100
) => clampValueBetween(getValueAsInteger(event) ?? min, min, max);
