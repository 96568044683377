import { Button, Card } from '~src/components/display';
import { useLocalization, useOfferProductState } from '~src/hooks';
import { useSolarPackages } from '~src/hooks/services';
import { formatCurrency } from '~src/utilities/format';

import { solarPackage, solarPackageDiscount, solarPackageProduct, solarPackageTitle } from './solarPackage.module.css';

type SolarPackageProps = {
  solarProductPackage: ReturnType<typeof useSolarPackages>['packages'][0];
  onSelect: (packageID: number) => void;
};

export const SolarPackage = ({ solarProductPackage, onSelect }: SolarPackageProps) => {
  const translate = useLocalization();
  const { selectedBattery, selectedSolarPanel, selectedInverter } = useOfferProductState();

  const { packageID, title, solarPanel, inverter, batteries, discount } = solarProductPackage;

  const battery = batteries?.[0];

  const onSelectPackage = () => onSelect(packageID);

  const selected =
    selectedSolarPanel?.id === solarPanel?.id &&
    selectedSolarPanel?.quantity === solarPanel?.quantity &&
    selectedInverter?.id === inverter?.id &&
    selectedBattery?.id === battery?.id;

  return (
    <Card key={packageID}>
      <div className={solarPackage}>
        <h1 className={solarPackageTitle}>{title ?? `Pakke ${packageID}`}</h1>

        <div className={solarPackageProduct}>
          {solarPanel.quantity} x {solarPanel.brand} {solarPanel.model}
        </div>

        <div className={solarPackageProduct}>
          1 x {inverter.brand} {inverter.model}
        </div>

        {battery && (
          <div className={solarPackageProduct}>
            1 x {battery.brand} {battery.model}
          </div>
        )}

        <span className={solarPackageDiscount}>
          {translate.DISCOUNT}: {formatCurrency(discount)}
        </span>

        <Button disabled={selected} onClick={onSelectPackage}>
          {selected ? translate.SELECTED : translate.SELECT}
        </Button>
      </div>
    </Card>
  );
};
