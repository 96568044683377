import { Card } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { Offer, useCreateOfferState } from '../../state/createOfferState';
import { OfferDetails } from './OfferDetails';

type OfferListProps = {
  type: 'heatpump' | 'solar';
};

export const OfferList = ({ type }: OfferListProps) => {
  const translate = useLocalization();
  const { offerList } = useCreateOfferState();

  const title = type === 'heatpump' ? translate.HEAT_PUMP : translate.SOLAR_CELL;

  const offersOfMatchingType = getOffersOfMatchingType(type, offerList);

  return (
    <Card title={`${title} ${translate.OFFER}`}>
      {offersOfMatchingType?.map(({ id }) => <OfferDetails key={id} offerId={id ?? 0} />)}
    </Card>
  );
};

const getOffersOfMatchingType = (type: OfferListProps['type'], offerList?: Array<Offer>) =>
  offerList?.filter(offer => !!offer && (type === 'heatpump' ? !!offer.heatPumpOutdoorUnit : !!offer.solarPanel));
