import { Card, Text } from '~src/components/display';
import { BankOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '~src/components/display/Icons';
import { useLocalization } from '~src/hooks';

import { doubleRow, inline, layout } from './BasicInformation.module.css';

type BasicInformationProps = {
  address?: string;
  advisor?: string;
  email?: string;
  name?: string;
  phoneNumber?: string;
};

export const BasicInformation = ({ address, advisor, email, name, phoneNumber }: BasicInformationProps) => {
  const translate = useLocalization();

  return (
    <Card title={translate.CUSTOMER_INFORMATION}>
      <div className={layout}>
        <span>
          <UserOutlined />
          <Text className={inline}>{name}</Text>
        </span>

        <span>
          <BankOutlined />
          <Text className={inline}>{address}</Text>
        </span>

        <span>
          <MailOutlined />
          <Text className={inline}>{email}</Text>
        </span>

        <span>
          <PhoneOutlined />
          <Text className={inline}>{phoneNumber}</Text>
        </span>

        <span className={doubleRow}>
          <Text>{translate.ASSIGNED_ADVISOR}:</Text>
          <Text>{advisor ?? translate.NONE}</Text>
        </span>
      </div>
    </Card>
  );
};
