import { HeatPumpOutdoorUnit } from '~src/types';

type SortableProduct = Pick<HeatPumpOutdoorUnit, 'price' | 'brand' | 'model' | 'preferred'>;

const LAST_CHAR = 'z';

export const getProductSorter = (locale: string) => (a: SortableProduct, b: SortableProduct) => {
  if (a?.price !== b?.price) {
    return (a?.price ?? 0) - (b?.price ?? 0);
  }

  if (a?.preferred && !b?.preferred) {
    return -1;
  }

  if (b?.preferred && !a?.preferred) {
    return 1;
  }

  if (a?.brand !== b?.brand) {
    return (a?.brand ?? LAST_CHAR).localeCompare(b?.brand ?? LAST_CHAR, locale, { sensitivity: 'base' });
  }

  return (a?.model ?? LAST_CHAR).localeCompare(b?.model ?? LAST_CHAR, locale, { sensitivity: 'base' });
};
