import { useQuery } from '~src/services/client';
import { fetchAddressSuggestions } from '~src/utilities/addressHelper';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useToastMessageErrorHandler } from '../useToastMessage';

export const useDawaAddressSuggestions = (searchAddress: string) => {
  const { data, isLoading } = useQuery(
    ['dawaAddressSuggestions', searchAddress],
    () => fetchAddressSuggestions(searchAddress),
    {
      enabled: searchAddress?.length > 2,
      onError: useToastMessageErrorHandler(),
    }
  );

  return { suggestions: deepNullToUndefined(data) ?? [], isLoading };
};
