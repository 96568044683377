import { useEffect, useState } from 'react';

import { useLogoutMutation, useRefreshLoginMutation } from '~src/gql';
import { useNavigation } from '~src/hooks/useNavigation';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { isAuthenticated } from '~src/utilities/auth';
import { clearCookie, getCookie } from '~src/utilities/cookies';
import { captureException } from '~src/utilities/sentry';

export const useRefreshLogin = () => {
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync: login } = useRefreshLoginMutation(graphQLClient);

  const loginAsync = async (vars: { refreshToken: string }) => {
    try {
      return await login(vars);
    } catch (error) {
      return undefined;
    }
  };

  return { login: loginAsync };
};

export const useLogout = () => {
  const { navigate } = useNavigation();
  const errorHandler = useToastMessageErrorHandler();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync: logoutMutate } = useLogoutMutation(graphQLClient, { onError: errorHandler });

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(function setIsLoggedInState() {
    if (isAuthenticated()) {
      setIsLoggedIn(true);
    }
  }, []);

  const logout = async () => {
    try {
      const refreshToken = getCookie('refreshToken');
      if (refreshToken) {
        await logoutMutate({ refreshToken });
      }
    } catch (caughtError) {
      captureException(caughtError);
    } finally {
      clearCookie('accessToken');
      clearCookie('refreshToken');

      setIsLoggedIn(false);

      navigate('root');
    }
  };

  return {
    logout,
    isLoggedIn,
  };
};
