import { Heading, ImageThumbnail, Input, Radio, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionElectricity = ({
  answerMap,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  const cableLength = Number(answerMap?.distanceToInstallation?.answer ?? '0');
  const extraCableRequired = Number.isNaN(cableLength) || cableLength > 5;

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        7. {translate.ELECTRICITY_PANEL}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_ELECTRICITY_PANEL_DISTANCE}:</Text>
        <Input
          onChange={event => {
            updateAnswer('distanceToInstallation', event?.target?.value);
          }}
          value={answerMap?.distanceToInstallation?.answer}
        />
        <SiteVisitNote display={extraCableRequired} text={translate.SITE_VISIT_DISCLAIMER_EXTRA_CABLE} type='expense' />

        <Text>{translate.SITE_VISIT_QUESTION_ELECTRICITY_WIRE_OVER_CEILING}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('wiringInCeiling', event?.target?.value);
          }}
          value={answerMap?.wiringInCeiling?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{translate.SITE_VISIT_QUESTION_ELECTRICITY_PANEL_UPGRADE}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('electricRelayWork', event?.target?.value);
          }}
          value={answerMap?.electricRelayWork?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='existingRelay'>{translate.SITE_VISIT_ANSWER_EXISTING_PANEL}</Radio.Button>
          <Radio.Button value='upgradeRelay'>{translate.SITE_VISIT_ANSWER_UPGRADE_PANEL}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.electricRelayWork?.answer === 'upgradeRelay'}
          text={translate.SITE_VISIT_DISCLAIMER_UPGRADE_RELAY}
          type='expense'
        />

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('electricityNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.electricityNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.electricity)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
