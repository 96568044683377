import { Outlet } from 'react-router-dom';

import { CreateOfferHeader } from '~src/components/features/createOffer';
import { DefaultLayout } from '~src/components/layouts';

import { stackedGrid } from './pages.module.css';

export const CreateOfferPage = () => (
  <DefaultLayout>
    <section className={stackedGrid}>
      <CreateOfferHeader />

      <Outlet />
    </section>
  </DefaultLayout>
);
