import omit from 'lodash/omit';

import { QueryClient } from '@tanstack/react-query';

import { DeepNullToUndefined, findOfferValidProductType } from '@bodilenergy/domain';

import {
  OfferInput,
  OfferProductType,
  useArchiveOfferMutation,
  useCopyOfferMutation,
  useFetchOfferByTagQuery,
  useFetchOfferListQuery,
  useFetchOfferQuery,
  useFetchOverviewOfferListQuery,
  useInsertOfferListMutation,
  useOfferByInstallationCodeQuery,
  useOfferBySiteVisitCodeQuery,
  useUpdateOfferNotesMutation,
  useUpdateOfferProductPriceMutation,
  useUpsertReservationMutation,
} from '~src/gql';
import { useGraphQLClient, useQueryClient } from '~src/services/client';
import { Offer } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useToastMessageErrorHandler } from '../useToastMessage';

export const useArchiveOffer = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useArchiveOfferMutation(graphQLClient, { onError: useToastMessageErrorHandler() });

  const archiveOffer = async (offerId: number) => {
    const { archiveOffer: success } = await mutateAsync(
      { offerId },
      {
        onSuccess: () =>
          Promise.all([
            queryClient.invalidateQueries({ queryKey: useFetchOfferQuery.getKey({ offerId }) }),
            queryClient.invalidateQueries({ queryKey: useFetchOfferListQuery.getKey() }),
            queryClient.invalidateQueries({ queryKey: useFetchOverviewOfferListQuery.getKey() }),
          ]),
      }
    );

    return success;
  };

  return { archiveOffer, isLoading };
};

export const useCreateOffers = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useInsertOfferListMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
  });

  const createOffers = async (
    offerList: Array<DeepNullToUndefined<OfferInput>>,
    residenceId: number,
    customerId: number
  ) => {
    const heatPumpOfferList = offerList.filter(offer => findOfferValidProductType(offer) === 'heatPump');
    const solarOfferList = offerList.filter(offer => findOfferValidProductType(offer) === 'solar');

    const { insertOffers } = await mutateAsync(
      { heatPumpOfferList, solarOfferList, residenceId, customerId },
      {
        onSuccess: () =>
          Promise.all([
            queryClient.invalidateQueries({ queryKey: useFetchOfferListQuery.getKey({ customerId, residenceId }) }),
            queryClient.invalidateQueries({ queryKey: useFetchOverviewOfferListQuery.getKey() }),
          ]),
      }
    );
    return insertOffers.map(({ id }) => id);
  };

  return { createOffers, isLoading };
};

export const useOffer = (offerId?: number) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, error, isFetching } = useFetchOfferQuery(
    graphQLClient,
    { offerId: offerId ?? 0 },
    { enabled: !!offerId, onError: useToastMessageErrorHandler() }
  );

  return {
    offer: deepNullToUndefined(data?.offer),
    error,
    isLoading: isFetching,
  };
};

export const useOfferByTag = (offerTag?: string) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useFetchOfferByTagQuery(
    graphQLClient,
    { offerTag: offerTag ?? '' },
    { enabled: !!offerTag, onError: useToastMessageErrorHandler() }
  );

  return { offer: deepNullToUndefined(data?.offerByTag), isLoading: isFetching };
};

export const useOfferByInstallationCode = (offerCode?: string) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useOfferByInstallationCodeQuery(
    graphQLClient,
    { code: offerCode ?? '' },
    { enabled: !!offerCode, onError: useToastMessageErrorHandler() }
  );

  return {
    offer: deepNullToUndefined(omit(data?.offerByCode, 'residence')),
    residence: deepNullToUndefined(omit(data?.offerByCode?.residence, 'customer')),
    customer: deepNullToUndefined(data?.offerByCode?.residence?.customer),
    isLoading: isFetching,
  };
};

export const useOfferBySiteVisitCode = (offerCode?: string) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useOfferBySiteVisitCodeQuery(
    graphQLClient,
    { code: offerCode ?? '' },
    { enabled: !!offerCode, onError: useToastMessageErrorHandler() }
  );

  return {
    offer: deepNullToUndefined(omit(data?.offerByCode, 'residence')),
    residence: deepNullToUndefined(omit(data?.offerByCode?.residence, 'customer')),
    customer: deepNullToUndefined(data?.offerByCode?.residence?.customer),
    isLoading: isFetching,
  };
};

export const useSearchOffers = (customerId?: number, residenceId?: number, showArchived = false) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, error, isFetching } = useFetchOfferListQuery(
    graphQLClient,
    { customerId, residenceId, archived: showArchived },
    {
      enabled: !!customerId || !!residenceId,
      onError: useToastMessageErrorHandler(),
      queryKey: useFetchOfferListQuery.getKey({ customerId, residenceId }),
    }
  );

  return {
    offerList: deepNullToUndefined(data?.offers),
    error,
    isLoading: isFetching,
  };
};

export const useCopyOffer = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useCopyOfferMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
  });

  const copyOffer = async (offer: OfferInput, oldOfferId: number, archiveExisting?: boolean) => {
    const { copyOffer: data } = await mutateAsync(
      { archiveExisting, offer, oldOfferId },
      {
        onSuccess: () =>
          Promise.all([
            queryClient.invalidateQueries({ queryKey: useFetchOfferQuery.getKey({ offerId: oldOfferId }) }),
            queryClient.invalidateQueries({ queryKey: useFetchOverviewOfferListQuery.getKey() }),
            queryClient.invalidateQueries({ queryKey: useFetchOfferListQuery.getKey() }),
          ]),
      }
    );
    return deepNullToUndefined(data);
  };

  return { copyOffer, isLoading };
};

export const useUpdateOfferNotes = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useUpdateOfferNotesMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
    onMutate: async ({ notes, offerId }) => {
      await queryClient.cancelQueries({ queryKey: useFetchOfferQuery.getKey({ offerId }) });

      const offer = queryClient.getQueryCache().find(useFetchOfferQuery.getKey({ offerId }));

      return { ...offer, notes };
    },
  });

  const updateOfferNotes = async (offerId: number, notes: string) => {
    const response = await mutateAsync({ offerId, notes });
    return response.updateOfferNotes.id;
  };

  return { updateOfferNotes, isLoading };
};

export const useUpdateOfferReservations = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useUpsertReservationMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
    onMutate: async ({ reservation, offerId }) => {
      await queryClient.cancelQueries({ queryKey: useFetchOfferQuery.getKey({ offerId }) });

      const offer = queryClient.getQueryCache().find(useFetchOfferQuery.getKey({ offerId }));

      return { ...offer, reservation };
    },
  });

  const updateOfferReservation = async (offerId: number, reservation: string) => {
    const response = await mutateAsync({ offerId, reservation });
    return response.upsertReservation.id;
  };

  return { updateOfferReservation, isLoading };
};

type UpdatePriceParameters = {
  offerId: number;
  unitId: number;
  price: number;
  type: OfferProductType;
};

export const useUpdateOfferProductPrice = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useUpdateOfferProductPriceMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
  });

  const updateOfferProductPrice = async ({ offerId, unitId, price, type }: UpdatePriceParameters) => {
    const result = await mutateAsync(
      { offerID: offerId, unitID: unitId, price, type },
      {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: useFetchOfferQuery.getKey({ offerId }) }),
      }
    );
    return result.updateOfferProductPrice;
  };

  return { updateOfferProductPrice, isLoading };
};

export const updateOfferCache = async (
  queryClient: QueryClient,
  offerId: number,
  updater: (offer?: Partial<Offer>) => Partial<Offer> | undefined
) => {
  const queryKey = useFetchOfferQuery.getKey({ offerId });

  await queryClient.cancelQueries({ queryKey });

  const { offer } = queryClient.getQueryData<{ offer: Offer }>(queryKey) ?? {};

  const updatedOffer = updater(offer);

  if (updatedOffer) {
    queryClient.setQueryData(queryKey, { offer: updatedOffer });
  }

  return updatedOffer;
};
