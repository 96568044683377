import { Card, Radio, Text } from '~src/components/display';
import { CustomerFinancingProvider } from '~src/gql';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useUpsertCustomer } from '~src/hooks/services';
import { CustomerInput } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';

import { financingRadio } from '../../createOffer/components/offers/offers.module.css';

type CustomerFinancingProps = {
  customer?: CustomerInput;
  updateCustomer?: (arg: CustomerInput) => void;
};

export const CustomerFinancing = ({ customer, updateCustomer }: CustomerFinancingProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();

  const { upsertCustomer } = useUpsertCustomer();

  const setFinancingProvider = async (newValue: CustomerFinancingProvider | false) => {
    const updated = {
      ...customer,
      financingProvider: newValue === false ? null : newValue,
    };

    await upsertCustomer(updated);

    updateCustomer?.(deepNullToUndefined(updated));

    showMessage({ type: 'success', message: translate.SAVED });
  };

  const financingProvider = customer?.financingProvider ?? false;

  return (
    <Card title={translate.FINANCING}>
      <Text>{translate.CUSTOMER_FINANCING_PROMPT}</Text>

      <Radio.Group
        className={financingRadio}
        value={financingProvider}
        options={[
          {
            value: 'nordea',
            label: `${translate.FINANCING_AFFILATION} Nordea`,
          },
          {
            value: 'jyskebank',
            label: `${translate.FINANCING_AFFILATION} Jyske Bank`,
          },
        ]}
        onChange={event => setFinancingProvider(event.target.value)}
      />
    </Card>
  );
};
