import keyBy from 'lodash/keyBy';

import { Card, Divider, Heading, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useSiteVisit } from '~src/hooks/services';
import { Residence, SiteVisitAnswer, SiteVisitQuestion } from '~src/types';

import { siteVisitSummary, siteVisitSummarySection } from './siteVisitSummary.module.css';

type SolarSiteVisitSummaryProps = {
  residenceID?: number;
  residence?: Residence;
};

export const SolarSiteVisitSummary = ({ residence, residenceID }: SolarSiteVisitSummaryProps) => {
  const translate = useLocalization();

  const { siteVisitChecklist } = useSiteVisit(residenceID);

  const answerMap = keyBy(siteVisitChecklist, 'question') as Record<SiteVisitQuestion, SiteVisitAnswer>;

  return (
    <Card>
      <div className={siteVisitSummary}>
        <Heading>{translate.SITE_VISIT_SUMMARY}</Heading>

        <Divider fullWidth />

        <NoteSection residence={residence} answerMap={answerMap} />

        <ConditionSection answerMap={answerMap} />

        <ExpenseSection residence={residence} answerMap={answerMap} />
      </div>
    </Card>
  );
};

type SectionProps = {
  answerMap?: Record<SiteVisitQuestion, SiteVisitAnswer>;
  residence?: Residence;
};

const NoteSection = ({ answerMap, residence }: SectionProps) => {
  const translate = useLocalization();

  const controlQuestionAnswerList = [
    answerMap?.correctColdRooms?.answer,
    answerMap?.correctConsumption?.answer,
    answerMap?.correctConsumptionSecondary?.answer,
    answerMap?.correctHeatedArea?.answer,
  ];

  return (
    <div className={siteVisitSummarySection}>
      <Heading level={2}>{translate.NOTES}</Heading>

      {controlQuestionAnswerList.includes('false') && <Text>{translate.SITE_VISIT_DISCLAIMER_CONTROL_QUESTIONS}</Text>}

      {residence?.roofType === 'other' && <Text>{translate.SITE_VISIT_DISCLAIMER_ROOF_OTHER}</Text>}

      {answerMap?.hasUnderRoof?.answer === 'true' && <Text>{translate.SITE_VISIT_DISCLAIMER_UNDER_ROOF}</Text>}

      {answerMap?.easyAvailableForScaffolding?.answer === 'false' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_SCAFFOLDING}</Text>
      )}

      {answerMap?.bufferRequiredSolar?.answer === 'outsideHouse' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_SOLAR_CABLE_PICTURES}</Text>
      )}
    </div>
  );
};

const ConditionSection = ({ answerMap }: SectionProps) => {
  const translate = useLocalization();

  return (
    <div className={siteVisitSummarySection}>
      <Heading level={2}>{translate.SITE_VISIT_CONDITION}</Heading>

      {answerMap?.solarEntryPossible?.answer === 'false' && <Text>{translate.SITE_VISIT_DISCLAIMER_MAKE_ROOM}</Text>}
    </div>
  );
};

const ExpenseSection = ({ answerMap, residence }: SectionProps) => {
  const translate = useLocalization();

  const cableLength = Number(answerMap?.distanceToSolar?.answer ?? '0');
  const extraCableRequired = Number.isNaN(cableLength) || cableLength > 10;

  return (
    <div className={siteVisitSummarySection}>
      <Heading level={2}>{translate.EXPENSES}</Heading>

      {answerMap?.electricRelayAutoSafetyAndExtra?.answer === 'upgradeRelay' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_UPGRADE_RELAY}</Text>
      )}

      {extraCableRequired && <Text>{translate.SITE_VISIT_DISCLAIMER_EXTRA_CABLE_SOLAR}</Text>}

      {residence?.roofType === 'flat' && <Text>{translate.SITE_VISIT_DISCLAIMER_ROOF_FLAT}</Text>}

      {answerMap?.providerForFoundationSolar?.answer === 'installer' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_FOUNDATION}</Text>
      )}
    </div>
  );
};
