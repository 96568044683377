import clsx from 'clsx';
import { ChangeEvent, useRef } from 'react';

import { Button } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { thumbnail } from './FileUploadButton.module.css';

type FileUploadButtonProps = {
  buttonText?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  type?: 'default' | 'thumbnail';
};

export const FileUploadButton = ({
  buttonText,
  className,
  disabled = false,
  isLoading = false,
  multiple = false,
  onUpload,
  type = 'default',
}: FileUploadButtonProps) => {
  const translate = useLocalization();
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const buttonContent = (
    <>
      {type === 'thumbnail' && (
        <>
          +
          <br />
        </>
      )}

      {buttonText ?? translate.ATTACH}
    </>
  );

  return (
    <>
      <input
        disabled={disabled}
        style={{ display: 'none' }}
        type='file'
        ref={uploadInputRef}
        multiple={multiple}
        onChange={uploadFilesFromInput(onUpload)}
      />

      <Button
        className={clsx(className, { [thumbnail]: type === 'thumbnail' })}
        loading={isLoading}
        onClick={() => uploadInputRef.current?.click()}
      >
        {buttonContent}
      </Button>
    </>
  );
};

const uploadFilesFromInput =
  (onUpload: (files: File[]) => void) =>
  ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = target;
    if (!validity.valid || !files?.length) {
      return;
    }

    onUpload(Array.from(files));
    setTimeout(() => {
      // eslint-disable-next-line no-param-reassign
      (target.value as string | null) = null;
    });
  };
