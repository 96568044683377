import { TranslationRecord } from '~src/localization/translationKeys';

export const formatYesOrNo = (translate: TranslationRecord, answer: string) =>
  answer === 'true' ? translate.YES : translate.NO;

export const getRelayTranslation = (translate: TranslationRecord, relay: string) =>
  ({
    existingRelay: translate.SITE_VISIT_ANSWER_EXISTING_PANEL,
    upgradeRelay: translate.SITE_VISIT_ANSWER_UPGRADE_PANEL,
  })[relay] ?? relay;

export const getPipePlacementTranslation = (translate: TranslationRecord, placement: string) =>
  ({
    ceiling: translate.CEILING,
    wall: translate.WALL,
  })[placement] ?? placement;

export const getProviderTranslation = (translate: TranslationRecord, provider: string) =>
  ({
    customer: translate.CUSTOMER,
    installer: translate.INSTALLER,
  })[provider] ?? provider;

export const getPipeTypesTranslation = (translate: TranslationRecord, type: string) =>
  ({
    copper: translate.COPPER,
    steel: translate.STEEL,
    aluminium: translate.ALUMINUM,
    plastic: translate.PLASTIC,
  })[type] ?? type;

export const getRoofTypeTranslation = (translate: TranslationRecord, roofType: string) =>
  ({
    concreteTile: translate.SITE_VISIT_ANSWER_ROOF_TYPE_BETON_TEGL,
    clayTile: translate.SITE_VISIT_ANSWER_ROOF_TYPE_LER_TEGL,
    metal: translate.SITE_VISIT_ANSWER_ROOF_TYPE_METAL,
    fiberCement: translate.SITE_VISIT_ANSWER_ROOF_TYPE_ETERNIT,
    flat: translate.SITE_VISIT_ANSWER_ROOF_TYPE_FLAT,
    felt: translate.SITE_VISIT_ANSWER_ROOF_TYPE_TAGPAP,
    other: translate.OTHER,
    unknown: translate.UNKNOWN,
  })[roofType] ?? roofType;

export const getSolarBufferRequiredTranslation = (translate: TranslationRecord, buffer: string) =>
  ({
    throughRoof: translate.SITE_VISIT_ANSWER_SOLAR_CABLE_THROUGH_ROOF,
    outsideHouse: translate.SITE_VISIT_ANSWER_SOLAR_CABLE_OUTSIDE_HOUSE,
  })[buffer] ?? buffer;
