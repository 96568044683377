import { useSolarProductPackagesQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { deepNullToUndefined } from '~src/utilities/convert';

export const useSolarPackages = () => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useSolarProductPackagesQuery(
    graphQLClient,
    {},
    { onError: useToastMessageErrorHandler() }
  );

  return {
    packages: data?.solarProductPackages?.map(deepNullToUndefined) ?? [],
    isLoading: isFetching,
  };
};
