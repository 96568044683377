import { useEffect } from 'react';

import { useOfferProductState } from '~src/hooks';

import { useCreateOfferState } from '~src/components/features/createOffer/state/createOfferState';

import { useRecommendedSolarPackage } from './services/products/useRecommendedSolarPackage';

export const ACCEPTED_INVERTER_IDS = [46, 43] as const;

export const useDefaultSolarProducts = () => {
  const { residence } = useCreateOfferState();

  const { selectedSolarPanel, selectSolarPanel, selectedInverter, selectInverter, selectedBattery, selectBattery } =
    useOfferProductState();

  const { solarProducts } = useRecommendedSolarPackage(residence);

  const { solarPanel, inverter, battery } = solarProducts ?? {};

  useEffect(
    function setDefaultSolarProducts() {
      if (selectedSolarPanel || selectedInverter || selectedBattery) {
        return;
      }

      if (!solarPanel || !inverter) {
        return;
      }

      selectSolarPanel(solarPanel);
      selectInverter(inverter);
      selectBattery(battery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [solarPanel, inverter, battery]
  );
};
