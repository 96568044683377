import { CrmCustomer as ApiCRMCustomer } from '~src/gql';

import { CustomerInput } from './customer';
import { DeepNullToUndefined } from './util';

export type CRMCustomer = DeepNullToUndefined<ApiCRMCustomer>;

export const mapCRMCustomerToCustomerInput = ({
  email,
  firstName,
  lastName,
  phoneNumber,
}: CRMCustomer): CustomerInput | undefined => ({ email, firstName, lastName, phoneNumber });
