import { useEffect } from 'react';

import { Modal, Spinner } from '~src/components/display';
import { useLocalization, useOfferProductState, useOfferState } from '~src/hooks';
import { useCopyOffer } from '~src/hooks/services/useOffer';
import { useNavigation } from '~src/hooks/useNavigation';
import { useToastMessage } from '~src/hooks/useToastMessage';
import { mapOfferToOfferInput, MaybeId, OfferProducts, OfferType, ResidenceInput } from '~src/types';

import { OfferEditHeatPumpProducts } from './OfferEditHeatPumpProducts';
import { OfferEditSolarProducts } from './OfferEditSolarProducts';

type OfferEditProductsModalProps = {
  isOpen?: boolean;
  offer?: OfferProducts & MaybeId;
  residence?: ResidenceInput;
  toggleOpen?: (arg: boolean) => void;
};

export const OfferEditProductsModal = ({ isOpen, offer, residence, toggleOpen }: OfferEditProductsModalProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();
  const { navigateRaw } = useNavigation();

  const {
    selectBattery,
    selectedBattery,
    selectHeatPumpIndoorUnit,
    selectedHeatPumpIndoorUnit,
    selectHeatPumpOutdoorUnit,
    selectedHeatPumpOutdoorUnit,
    selectInverter,
    selectedInverter,
    selectSolarPanel,
    selectedSolarPanel,
  } = useOfferProductState();
  const { archiveExistingOffer } = useOfferState();

  const { copyOffer, isLoading } = useCopyOffer();

  useEffect(
    function setOfferProductsOnInit() {
      selectBattery(offer?.battery);
      selectHeatPumpIndoorUnit(offer?.heatPumpIndoorUnit);
      selectHeatPumpOutdoorUnit(offer?.heatPumpOutdoorUnit);
      selectInverter(offer?.inverter);
      selectSolarPanel(offer?.solarPanel);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offer?.battery, offer?.heatPumpIndoorUnit, offer?.heatPumpOutdoorUnit, offer?.inverter, offer?.solarPanel]
  );

  const handleOk = async () => {
    if (isLoading || !offer?.id) {
      return;
    }

    const inputOffer = mapOfferToOfferInput({
      battery: selectedBattery,
      heatPumpIndoorUnit: selectedHeatPumpIndoorUnit,
      heatPumpOutdoorUnit: selectedHeatPumpOutdoorUnit,
      inverter: selectedInverter,
      solarPanel: selectedSolarPanel,
    });

    if (!inputOffer) {
      return;
    }

    const { id: newOfferId } = await copyOffer(inputOffer, offer.id, archiveExistingOffer);

    showMessage({ type: 'success', message: translate.SAVED });

    toggleOpen?.(false);

    navigateRaw(`/offer/${newOfferId}`);
  };

  const offerProducts: OfferProducts = {
    battery: selectedBattery ?? offer?.battery,
    heatPumpIndoorUnit: selectedHeatPumpIndoorUnit ?? offer?.heatPumpIndoorUnit,
    heatPumpOutdoorUnit: selectedHeatPumpOutdoorUnit ?? offer?.heatPumpOutdoorUnit,
    inverter: selectedInverter ?? offer?.inverter,
    solarPanel: selectedSolarPanel ?? offer?.solarPanel,
    expenses: offer?.expenses,
  };

  const offerType: OfferType = offer?.heatPumpOutdoorUnit ? 'heatPump' : 'solar';

  const editComponent =
    offerType === 'heatPump' ? (
      <OfferEditHeatPumpProducts offerProducts={offerProducts} residence={residence} />
    ) : (
      <OfferEditSolarProducts offerProducts={offerProducts} residence={residence} />
    );

  return (
    <Modal
      onCancel={() => {
        if (isLoading) {
          return;
        }

        toggleOpen?.(false);
      }}
      onOk={handleOk}
      open={isOpen}
      width='90vw'
    >
      {isLoading ? <Spinner /> : editComponent}
    </Modal>
  );
};
