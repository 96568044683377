import keyBy from 'lodash/keyBy';

import { Card, Divider, Heading, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useSiteVisit } from '~src/hooks/services';
import { SiteVisitAnswer, SiteVisitQuestion } from '~src/types';

import { siteVisitSummary, siteVisitSummarySection } from './siteVisitSummary.module.css';

type HeatPumpSiteVisitSummaryProps = { residenceID?: number };

export const HeatPumpSiteVisitSummary = ({ residenceID }: HeatPumpSiteVisitSummaryProps) => {
  const translate = useLocalization();

  const { siteVisitChecklist } = useSiteVisit(residenceID);

  const answerMap = keyBy(siteVisitChecklist, 'question') as Record<SiteVisitQuestion, SiteVisitAnswer>;

  return (
    <Card>
      <div className={siteVisitSummary}>
        <Heading>{translate.SITE_VISIT_SUMMARY}</Heading>

        <Divider fullWidth />

        <NoteSection answerMap={answerMap} />

        <ConditionSection answerMap={answerMap} />

        <ExpenseSection answerMap={answerMap} />
      </div>
    </Card>
  );
};

type SectionProps = { answerMap?: Record<SiteVisitQuestion, SiteVisitAnswer> };

const NoteSection = ({ answerMap }: SectionProps) => {
  const translate = useLocalization();

  const controlQuestionAnswerList = [
    answerMap?.correctColdRooms?.answer,
    answerMap?.correctConsumption?.answer,
    answerMap?.correctConsumptionSecondary?.answer,
    answerMap?.correctHeatedArea?.answer,
  ];

  return (
    <div className={siteVisitSummarySection}>
      <Heading level={2}>{translate.NOTES}</Heading>

      {controlQuestionAnswerList.includes('false') && <Text>{translate.SITE_VISIT_DISCLAIMER_CONTROL_QUESTIONS}</Text>}

      {answerMap?.heatPumpEntryPossible?.answer === 'false' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_HEAT_PUMP_ENTRY}</Text>
      )}

      {answerMap?.bufferRequired?.answer === 'true' && <Text>{translate.SITE_VISIT_DISCLAIMER_REQUIRED_BUFFER}</Text>}

      {answerMap?.bufferRequired?.answer === 'false' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_REQUIRED_NO_BUFFER}</Text>
      )}

      {answerMap?.noiseLimitObserved?.answer === 'false' && <Text>{translate.SITE_VISIT_ANSWER_NOISE_LIMIT_NOTE}</Text>}

      {answerMap?.twoPipingSystems?.answer === 'true' && <Text>{translate.SITE_VISIT_DISCLAIMER_TWO_PIPE_SYSTEM}</Text>}

      {answerMap?.condensationPump?.answer === 'false' && <Text>{translate.ADD_CONDENSATION_PUMP}</Text>}

      {answerMap?.systemStringNumber?.answer === '1' && <Text>{translate.SITE_VISIT_DISCLAIMER_SYSTEM_STRINGS}</Text>}

      {['¼"', '10mm'].includes(answerMap?.radiatorPipingSize?.answer ?? '') && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_PIPE_SIZE}</Text>
      )}
    </div>
  );
};

const ConditionSection = ({ answerMap }: SectionProps) => {
  const translate = useLocalization();

  return (
    <div className={siteVisitSummarySection}>
      <Heading level={2}>{translate.SITE_VISIT_CONDITION}</Heading>

      {answerMap?.noiseLimitObserved?.answer === 'withScreen' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_NOISE_LIMIT}</Text>
      )}

      {answerMap?.roomForMaterials?.answer === 'false' && <Text>{translate.SITE_VISIT_DISCLAIMER_MAKE_ROOM}</Text>}
    </div>
  );
};

const ExpenseSection = ({ answerMap }: SectionProps) => {
  const translate = useLocalization();

  const cableLength = Number(answerMap?.distanceToInstallation?.answer ?? '0');
  const extraCableRequired = Number.isNaN(cableLength) || cableLength > 5;

  const pipeLength = Number(answerMap?.distanceInOut?.answer ?? '0');
  const extraPipeRequired = Number.isNaN(pipeLength) || pipeLength > 5;

  return (
    <div className={siteVisitSummarySection}>
      <Heading level={2}>{translate.EXPENSES}</Heading>

      {extraCableRequired && <Text>{translate.SITE_VISIT_DISCLAIMER_EXTRA_CABLE} </Text>}

      {answerMap?.electricRelayWork?.answer === 'upgradeRelay' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_UPGRADE_RELAY}</Text>
      )}

      {answerMap?.canFurnaceBeDismantled?.answer === 'false' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_FURNACE_DISMANTLE}</Text>
      )}

      {extraPipeRequired && <Text>{translate.SITE_VISIT_DISCLAIMER_EXTRA_PIPE}</Text>}

      {answerMap?.providerForFoundation?.answer === 'installer' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_FOUNDATION}</Text>
      )}

      {answerMap?.digDownPipes?.answer === 'true' && <Text>{translate.SITE_VISIT_DISCLAIMER_DIG_DOWN_PIPES}</Text>}

      {answerMap?.floorHeatingExtraShunts?.answer === 'true' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_EXTRA_SHUNT}</Text>
      )}

      {answerMap?.changeCirculationPump?.answer === 'true' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_CIRCULATION_PUMP}</Text>
      )}

      {answerMap?.bypassValves?.answer === 'false' && <Text>{translate.SITE_VISIT_DISCLAIMER_BYPASS_VALVES}</Text>}

      {answerMap?.replaceRadiators?.answer === 'true' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_REPLACE_RADIATOR}</Text>
      )}

      {answerMap?.replaceFurnaceWithRadiator?.answer === 'true' && (
        <Text>{translate.SITE_VISIT_DISCLAIMER_FURNACE_RADIATOR}</Text>
      )}
    </div>
  );
};
