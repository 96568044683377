import { useState } from 'react';

import { Button, Card, Divider, Form, Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { CustomerInput } from '~src/types';

import { CustomerForm } from './components/CustomerForm';
import { CustomerInformation } from './components/CustomerInformation';
import { CustomerInformationCardProps } from './types';

import { customerInformationHeaderSection } from './customer.module.css';

export const CustomerInformationCard = ({ customer }: CustomerInformationCardProps) => {
  const translate = useLocalization();

  const [isEditingCustomer, setIsEditingCustomer] = useState(false);

  const [form] = Form.useForm<CustomerInput>();

  return (
    <Card>
      <div className={customerInformationHeaderSection}>
        <Heading>{translate.CUSTOMER_INFORMATION}</Heading>

        <Button
          type='link'
          onClick={() => {
            if (isEditingCustomer) {
              form.submit();
            }

            setIsEditingCustomer(!isEditingCustomer);
          }}
        >
          {isEditingCustomer ? translate.SAVE : translate.EDIT}
        </Button>
      </div>

      <Divider fullWidth />

      {isEditingCustomer ? (
        <CustomerForm customer={customer} form={form} />
      ) : (
        <CustomerInformation customer={customer} />
      )}
    </Card>
  );
};
