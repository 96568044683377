import { Select, Text } from '~src/components/display';
import { MailOutlined, PhoneOutlined, UserOutlined } from '~src/components/display/Icons';
import { LEAD_ORIGIN_OPTIONS } from '~src/components/features/shared/customer/LeadOriginOptions';
import { useLocalization } from '~src/hooks';
import { formatDate } from '~src/utilities/format';

import { CustomerInformationCardProps } from '../types';

import { customerInformation, customerInformationEntry } from '../customer.module.css';

export const CustomerInformation = ({ customer }: CustomerInformationCardProps) => {
  const translate = useLocalization();

  const { financialAdvisor, firstName, lastName, email, phoneNumber, createdAt, assignedAdvisor, leadOrigin } =
    customer ?? {};

  return (
    <div className={customerInformation}>
      <div className={customerInformationEntry}>
        <UserOutlined />

        <Text>{`${firstName} ${lastName}`}</Text>
      </div>

      <div className={customerInformationEntry}>
        <Text>{translate.CUSTOMER_CREATED}:</Text>

        <Text>{createdAt && formatDate(createdAt)}</Text>
      </div>

      <div className={customerInformationEntry}>
        <MailOutlined />

        <Text>{email}</Text>
      </div>

      <div className={customerInformationEntry}>
        <Text>{translate.ASSIGNED_ADVISOR}:</Text>

        <Text>{assignedAdvisor ? `${assignedAdvisor?.firstName} ${assignedAdvisor?.lastName}` : translate.NONE}</Text>
      </div>

      <div className={customerInformationEntry}>
        <PhoneOutlined />

        <Text>{phoneNumber}</Text>
      </div>

      <div className={customerInformationEntry}>
        <Text>{translate.LEAD_ORIGIN}:</Text>

        <Select disabled options={LEAD_ORIGIN_OPTIONS} value={leadOrigin} />
      </div>

      <div className={customerInformationEntry}>
        <Text>{translate.FINANCIAL_ADVISOR}:</Text>

        <Text>{financialAdvisor}</Text>
      </div>
    </div>
  );
};
