import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { formatCurrency, formatPercentage } from '~src/utilities/format';

import { tooltip } from './graphComponents.module.css';

type SolarSavingsGraphTooltipProps = Pick<TooltipProps<ValueType, NameType>, 'payload'>;

export const SolarSavingsGraphTooltip = ({ payload }: SolarSavingsGraphTooltipProps) => {
  const translate = useLocalization();

  if (!payload?.length) {
    return null;
  }

  const { quantity, savings, usedPercentage, totalPrice, totalSaved, title } = payload[0].payload;

  return (
    <div className={tooltip}>
      <Text>
        {translate.TITLE}: {title}
      </Text>

      <Text>
        {translate.NUMBER_OF_PANELS}: {quantity}
      </Text>

      <Text>
        {translate.PROFIT} {translate.AFTER.toLowerCase()} 15 {translate.YEARS.toLowerCase()}: {formatCurrency(savings)}
      </Text>

      <Text>
        {translate.SELF_USAGE}: {formatPercentage(usedPercentage, 1)}
      </Text>

      <Text>
        {translate.PRICE}: {formatCurrency(totalPrice)}
      </Text>

      <Text>
        {translate.SAVINGS}: {formatCurrency(totalSaved)}
      </Text>
    </div>
  );
};
