import { useLocalization, useNavigation } from '~src/hooks';

import { Heading, Text } from '../display';
import { Button } from '../display/Button';
import { DefaultLayout } from '../layouts/DefaultLayout';

import { centeredGrid } from './pages.module.css';

export const NotFoundPage = () => {
  const { navigate } = useNavigation();
  const translation = useLocalization();

  return (
    <DefaultLayout>
      <div className={centeredGrid}>
        <Button
          type='link'
          onClick={() => {
            navigate('root');
          }}
        >
          <Heading>{translation.BODIL_ADVISOR_APP}</Heading>
        </Button>

        <Text>{`${translation.PAGE_NOT_FOUND} - 404`}</Text>
      </div>
    </DefaultLayout>
  );
};
