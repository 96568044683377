import { Button, Card } from '~src/components/display';
import { useLocalization, useToastMessage } from '~src/hooks';
import { useCreateOffers } from '~src/hooks/services';
import { useNavigation } from '~src/hooks/useNavigation';
import { mapOfferToOfferInput, OfferInput } from '~src/types';
import { filterFalsy } from '~src/utilities/calculationHelper';

import { CreateOfferFooter } from '../components/CreateOfferFooter';
import { OfferOverview } from '../components/offers/OfferOverview';
import { useCreateOfferState } from '../state/createOfferState';

import { content } from '../createOffer.module.css';

export const CreateOfferOffers = () => {
  const translate = useLocalization();
  const { navigate, navigateRaw } = useNavigation();
  const { showMessage } = useToastMessage();

  const { customer, offerList, residence, reset } = useCreateOfferState();

  const { createOffers, isLoading: isCreatingOffers } = useCreateOffers();

  const selectedOfferList = offerList.filter(({ selected }) => !!selected);

  const areButtonsDisabled = isCreatingOffers || selectedOfferList.length < 1;

  const handleBackClick = () => {
    navigate('createOfferProducts');
  };

  const createOffersAndReturnIds = () => {
    if (!customer?.id) {
      showMessage({ message: `${translate.OFFERS_NOT_CREATED_WARNING} - ${translate.NO_CUSTOMER}`, type: 'warning' });
      return [];
    }

    if (!residence?.id) {
      showMessage({ message: `${translate.OFFERS_NOT_CREATED_WARNING} - ${translate.NO_RESIDENCE}`, type: 'warning' });
      return [];
    }

    const offerInputList: OfferInput[] = filterFalsy(selectedOfferList.map(mapOfferToOfferInput));

    showMessage({
      type: 'info',
      message: translate.CREATING_OFFERS,
    });

    return createOffers(offerInputList, residence.id, customer.id);
  };

  const handleCreateOffers = async () => {
    const ids = await createOffersAndReturnIds();

    showMessage({ message: translate.OFFERS_CREATED, type: 'success' });

    reset();

    if (ids.length === 1) {
      navigateRaw(`/offer/${ids[0]}`);
    }

    if (ids.length > 1) {
      navigateRaw(`/customer/${customer?.id}`);
    }
  };

  return (
    <>
      <Card className={content}>
        <OfferOverview />
      </Card>

      <CreateOfferFooter>
        <Button onClick={handleBackClick}>{translate.BACK}</Button>

        <Button disabled={areButtonsDisabled} type='primary' onClick={handleCreateOffers}>
          {translate.CREATE_CHOSEN_OFFERS}
        </Button>
      </CreateOfferFooter>
    </>
  );
};
