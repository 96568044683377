import { useOfferProductState } from '~src/hooks';
import { useNewOfferExpenses } from '~src/hooks/services';
import { OfferProducts } from '~src/types';
import { findInstallationExpensesForOffer } from '~src/utilities/offer';

export const useCombineOfferProductsWithExpenses = (offerProducts: OfferProducts): OfferProducts => {
  const { newOfferExpenses } = useNewOfferExpenses();
  const { selectedOfferExpenses } = useOfferProductState();

  const installationExpenses = findInstallationExpensesForOffer(offerProducts, newOfferExpenses);

  const { required = [] } = newOfferExpenses ?? {};

  const expenses: OfferProducts['expenses'] = [...installationExpenses, ...required, ...selectedOfferExpenses];

  return { ...offerProducts, expenses };
};
