import { useMemo } from 'react';

import { Heading, Radio, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { Residence } from '~src/types';
import { calculateHeatLoadPerArea } from '~src/utilities/calculationHelper';
import { getFuelTypeUnitAndName } from '~src/utilities/residence';

import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';

import { displayColumn } from '~src/style/shared.module.css';

import { questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

type QuestionSectionControlProps = {
  residence?: Pick<Residence, 'areaHeated' | 'areaNotHeated' | 'areaPartiallyHeated'> & {
    primaryHeating?: Pick<Required<Residence>['primaryHeating'], 'annualUsage' | 'fuelType'>;
    secondaryHeating?: Pick<Required<Residence>['secondaryHeating'], 'annualUsage' | 'fuelType' | 'volumeUnit'>;
  };
  secondaryHeatSource?: string;
};

export const QuestionSectionControl = ({
  answerMap,
  isLoading,
  residence,
  secondaryHeatSource,
  updateAnswer,
}: QuestionSectionControlProps & QuestionSectionProps) => {
  const translate = useLocalization();

  const { areaHeated, areaNotHeated, areaPartiallyHeated, primaryHeating, secondaryHeating } = residence ?? {};
  const { annualUsage: primaryAnnualUsage, fuelType: primaryType } = primaryHeating ?? {};
  const { annualUsage: secondaryAnnualUsage, fuelType: secondaryType, volumeUnit } = secondaryHeating ?? {};

  const heatLoadPerAreaFormatted = useMemo(
    () =>
      residence &&
      calculateHeatLoadPerArea({
        areaHeated: areaHeated ?? 0,
        areaPartiallyHeated: areaPartiallyHeated ?? 0,
        primaryHeating: primaryHeating ?? { annualUsage: 0, fuelType: '' },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [residence]
  );

  const primaryConsumption = `${primaryAnnualUsage} ${getFuelTypeUnitAndName(primaryType)}`;
  const primaryHeatLoss = `${heatLoadPerAreaFormatted} W/m2`;
  const secondaryConsumption = secondaryHeating
    ? `${translate.TYPE}: ${secondaryHeatSource}. ${
        translate.CONSUMPTION
      }: ${secondaryAnnualUsage} ${getFuelTypeUnitAndName(secondaryType, volumeUnit)}`
    : translate.NONE;

  const controlQuestionAnswerList = [
    answerMap?.correctColdRooms?.answer,
    answerMap?.correctConsumption?.answer,
    answerMap?.correctConsumptionSecondary?.answer,
    answerMap?.correctHeatedArea?.answer,
  ];

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        2. {translate.CONTROL_QUESTIONS}
      </Heading>
      <Text>{translate.SITE_VISIT_CONTROL_DISCLAIMER}</Text>
      <br />

      <div className={questionRows}>
        <Text>
          {PRIMARY_CONSUMPTION_QUESTION.replace('CONSUMPTION_PLACEHOLDER', primaryConsumption).replace(
            'HEAT_LOSS_PLACEHOLDER',
            primaryHeatLoss
          )}
          :
        </Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('correctConsumption', event?.target?.value);
          }}
          value={answerMap?.correctConsumption?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{SECONDARY_CONSUMPTION_QUESTION.replace('SECONDARY_PLACEHOLDER', secondaryConsumption)}</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('correctConsumptionSecondary', event?.target?.value);
          }}
          value={answerMap?.correctConsumptionSecondary?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{HEATED_AREA_QUESTION.replace('AREA_PLACEHOLDER', `${areaHeated ?? 0}`)}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('correctHeatedArea', event?.target?.value);
          }}
          value={answerMap?.correctHeatedArea?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{NOT_HEATED_AREA_QUESTION.replace('AREA_PLACEHOLDER', `${areaNotHeated ?? 0}`)}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('correctColdRooms', event?.target?.value);
          }}
          value={answerMap?.correctColdRooms?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <SiteVisitNote
          display={controlQuestionAnswerList.includes('false')}
          text={translate.SITE_VISIT_DISCLAIMER_CONTROL_QUESTIONS}
        />
      </div>
    </div>
  );
};

const PRIMARY_CONSUMPTION_QUESTION =
  'Kunden har bekræftet et nuværende forbrug på CONSUMPTION_PLACEHOLDER hvilket svarer til et varmetab på HEAT_LOSS_PLACEHOLDER som virker realistisk og tilstrækkeligt til at beboerne kan overholde varmen?';

const SECONDARY_CONSUMPTION_QUESTION =
  'Kunden har bekræftet at følgende oplysninger om den supplerende varmekilde er korrekte?: SECONDARY_PLACEHOLDER'; // Type: oliefyr Forbrug: 4000 kWh

const HEATED_AREA_QUESTION = 'Kunden har AREA_PLACEHOLDER m2 opvarmet areal?';

const NOT_HEATED_AREA_QUESTION = 'Kunden har AREA_PLACEHOLDER m2 ikke opvarmede rum?';
