import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';
import { getPipeTypesTranslation } from './util';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuidePiping = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.SITE_VISIT_SECTION_PIPING}
      </Heading>

      <div className={informationColumn}>
        {!!answerMap?.pipingMaterial?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_PIPING_MATERIAL}
            value={getPipeTypesTranslation(translate, answerMap?.pipingMaterial?.answer)}
          />
        )}

        {answerMap?.twoPipingSystems?.answer === 'true' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_PIPING_TWO_SYSTEMS} value={translate.YES} />
        )}

        {answerMap?.floorHeatingExtraShunts?.answer === 'true' && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_FLOOR_HEATING_EXTRA_SHUNT}
            value={translate.YES}
          />
        )}

        {answerMap?.changeCirculationPump?.answer === 'true' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_CHANGE_CIRCULATION_PUMP} value={translate.YES} />
        )}

        {!!answerMap?.pipingNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.pipingNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
