import { useState } from 'react';

import { Modal, Table, Text } from '~src/components/display';
import { QuestionCircleOutlined } from '~src/components/display/Icons';
import { useLocalization } from '~src/hooks';

import { NOISE_LEVEL_VALUES } from './utilities/siteVisitConstants';

import { noiseTable, noiseTableModal, withTooltip } from './NoiseTable.module.css';

type NoiseTableProps = {
  decibel?: number;
};

export const NoiseTable = ({ decibel }: NoiseTableProps) => {
  const translate = useLocalization();

  const [showFullTableTooltip, setShowFullTableTooltip] = useState(false);

  if (!decibel) {
    return null;
  }

  const decibelHeaderElement = (
    <div className={withTooltip}>
      <span>{decibel} dB</span>

      <QuestionCircleOutlined onClick={() => setShowFullTableTooltip(!showFullTableTooltip)} />
    </div>
  );

  return (
    <>
      <Table
        bordered
        className={noiseTable}
        columns={[
          { dataIndex: 'rowTitle', title: decibelHeaderElement, rowScope: 'row' },
          { dataIndex: 'noScreen', title: translate.SITE_VISIT_NO_NOISE_SCREEN },
          { dataIndex: 'withScreen', title: translate.SITE_VISIT_WITH_NOISE_SCREEN },
        ]}
        dataSource={[
          {
            rowTitle: translate.SITE_VISIT_FOUNDATION_HARD,
            noScreen: `${NOISE_LEVEL_VALUES.noScreen.hardFoundation[decibel]}m`,
            withScreen: `${NOISE_LEVEL_VALUES.withScreen.hardFoundation[decibel]}m`,
          },
          {
            rowTitle: translate.SITE_VISIT_FOUNDATION_SOFT,
            noScreen: `${NOISE_LEVEL_VALUES.noScreen.softFoundation[decibel]}m`,
            withScreen: `${NOISE_LEVEL_VALUES.withScreen.softFoundation[decibel]}m`,
          },
        ]}
        pagination={false}
        rowKey={row => row.noScreen}
      />

      <Modal
        className={noiseTableModal}
        open={showFullTableTooltip}
        footer={null}
        onCancel={() => setShowFullTableTooltip(false)}
        width='90vw'
      >
        <Table
          bordered
          className={noiseTable}
          columns={[
            { dataIndex: 'rowTitle', title: 'dB', rowScope: 'row' },
            {
              dataIndex: 'noScreen.hardFoundation',
              title: (
                <div>
                  <Text>{translate.SITE_VISIT_NO_NOISE_SCREEN}</Text>
                  <Text>{translate.SITE_VISIT_FOUNDATION_HARD}</Text>
                </div>
              ),
            },
            {
              dataIndex: 'noScreen.softFoundation',
              title: (
                <div>
                  <Text>{translate.SITE_VISIT_NO_NOISE_SCREEN}</Text>
                  <Text>{translate.SITE_VISIT_FOUNDATION_SOFT}</Text>
                </div>
              ),
            },
            {
              dataIndex: 'withScreen.hardFoundation',
              title: (
                <div>
                  <Text>{translate.SITE_VISIT_WITH_NOISE_SCREEN}</Text>
                  <Text>{translate.SITE_VISIT_FOUNDATION_HARD}</Text>
                </div>
              ),
            },
            {
              dataIndex: 'withScreen.softFoundation',
              title: (
                <div>
                  <Text>{translate.SITE_VISIT_WITH_NOISE_SCREEN}</Text>
                  <Text>{translate.SITE_VISIT_FOUNDATION_SOFT}</Text>
                </div>
              ),
            },
          ]}
          dataSource={Object.keys(NOISE_LEVEL_VALUES.noScreen.hardFoundation).map(db => ({
            rowTitle: `${db}`,
            'noScreen.hardFoundation': `${NOISE_LEVEL_VALUES.noScreen.hardFoundation[Number(db)]}m`,
            'noScreen.softFoundation': `${NOISE_LEVEL_VALUES.noScreen.softFoundation[Number(db)]}m`,
            'withScreen.hardFoundation': `${NOISE_LEVEL_VALUES.withScreen.hardFoundation[Number(db)]}m`,
            'withScreen.softFoundation': `${NOISE_LEVEL_VALUES.withScreen.softFoundation[Number(db)]}m`,
          }))}
          pagination={false}
          rowKey={row => row.rowTitle}
        />
      </Modal>
    </>
  );
};
