import { useSolarPackages } from '~src/hooks/services';

import { SolarPackage } from './SolarPackage';

import { solarPackageList } from './solarPackage.module.css';

type SolarPackageSelectorProps = {
  onSelect: (packageID: number) => void;
};

export const SolarPackageSelector = ({ onSelect }: SolarPackageSelectorProps) => {
  const { packages } = useSolarPackages();

  return (
    <div className={solarPackageList}>
      {packages.map(value => (
        <SolarPackage onSelect={onSelect} key={value.packageID} solarProductPackage={value} />
      ))}
    </div>
  );
};
