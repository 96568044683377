import clsx from 'clsx';

import { Button, RouterLink, Text } from '~src/components/display';
import { BodilLogo } from '~src/components/display/Icons';
import { Link } from '~src/components/router/Link';
import { ADMIN_URL, LOGIN_URL } from '~src/config';
import { useLocalization } from '~src/hooks';
import { useLogout, useUser } from '~src/hooks/services';

import { alignRight, buttonGroup, topMenu } from './menus.module.css';

type TopMenuProps = {
  className?: string;
  type?: 'default' | 'logout';
};

const LoggedInMenu = () => {
  const translate = useLocalization();
  const { logout } = useLogout();
  const { user } = useUser();

  return (
    <>
      <Text className={alignRight}>{user?.name}</Text>

      <Button type='primary' onClick={logout}>
        {translate.LOG_OUT}
      </Button>
    </>
  );
};

export const TopMenu = ({ className, type = 'default' }: TopMenuProps) => {
  const translate = useLocalization();
  const { isLoggedIn } = useLogout();

  return (
    <nav className={clsx(className, topMenu)}>
      <Link to='/'>
        <BodilLogo />
      </Link>

      <div className={buttonGroup}>
        {type !== 'logout' && (
          <>
            <RouterLink href='/create-offer/customer' title={translate.CREATE_OFFER} />
            <RouterLink href='/overview' title={translate.OVERVIEW_PAGE} />
            <Link to={ADMIN_URL ?? ''} target='_blank'>
              {translate.ADMIN_PANEL}
            </Link>
          </>
        )}

        {isLoggedIn ? <LoggedInMenu /> : <RouterLink href={LOGIN_URL ?? ''} title={translate.LOG_IN} />}
      </div>
    </nav>
  );
};
