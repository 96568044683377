import clsx from 'clsx';
import { useState } from 'react';

import { Breadcrumb, BurgerMenu, Button, Form, Heading, Modal } from '~src/components/display';
import { AddressSearchNew } from '~src/components/features/shared/addressSearch/AddressSearchNew';
import { ResidenceForm } from '~src/components/features/shared/residence';
import { useLocalization } from '~src/hooks';
import { useUpsertResidence } from '~src/hooks/services/useResidence';
import { useNavigation } from '~src/hooks/useNavigation';
import { useToastMessage } from '~src/hooks/useToastMessage';
import { Customer, ResidenceInput } from '~src/types';

import { alignBaseline, clickable, displayColumn, displayRow } from '~src/style/shared.module.css';

import { addressSearch, headerRow } from './customer.module.css';

type CustomerPageHeaderProps = {
  customer?: Pick<Customer, 'id' | 'firstName' | 'lastName'>;
};

export const CustomerPageHeader = ({ customer }: CustomerPageHeaderProps) => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();
  const { navigate, navigateRaw } = useNavigation();

  const [showModal, setShowModal] = useState(false);
  const [residence, setResidence] = useState<ResidenceInput | undefined>();

  const [form] = Form.useForm<ResidenceInput>();

  const { upsertResidence } = useUpsertResidence();

  if (!customer) {
    return (
      <div className={displayColumn}>
        <Breadcrumb
          separator='>'
          items={[
            {
              className: clickable,
              onClick: () => {
                navigate('overview');
              },
              title: translate.OVERVIEW_PAGE,
            },
          ]}
        />
      </div>
    );
  }

  const handleSubmit = async (newResidence: ResidenceInput) => {
    const updatedResidence = {
      ...residence,
      ...newResidence,
    };

    await upsertResidence(customer.id, updatedResidence);

    setShowModal(false);
    showMessage({ type: 'success', message: `${translate.CREATE_OFFER}` });
  };

  const { firstName, lastName } = customer;
  const customerName = `${firstName} ${lastName}`;

  return (
    <div className={displayColumn}>
      <Breadcrumb
        separator='>'
        items={[
          {
            className: clickable,
            onClick: () => {
              navigate('overview');
            },
            title: translate.OVERVIEW_PAGE,
          },
          {
            className: clickable,
            onClick: () => {
              navigateRaw(`/customer/${customer.id}`);
            },
            title: customerName,
          },
        ]}
      />

      <div className={headerRow}>
        <Heading level={3}>{customerName}</Heading>

        <div className={clsx(displayRow, alignBaseline)}>
          <Button
            type='primary'
            onClick={() => {
              setShowModal(true);
            }}
          >
            {translate.ADD} {translate.ADDRESS}
          </Button>

          <BurgerMenu />
        </div>
      </div>

      <Modal
        cancelText={translate.CANCEL}
        okText={translate.CREATE}
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        onOk={() => {
          form.submit();
        }}
        width='90vw'
      >
        <AddressSearchNew className={addressSearch} onSelect={setResidence} />
        <ResidenceForm form={form} onSubmit={handleSubmit} residence={residence} />
      </Modal>
    </div>
  );
};
