export type RouteValue =
  | '/'
  | '/admin'
  | '/create-offer'
  | '/create-offer/customer'
  | '/create-offer/residence'
  | '/create-offer/products'
  | '/create-offer/offers'
  | '/customer'
  | '/forgot-password'
  | '/login'
  | '/my-page'
  | '/new-user'
  | '/news'
  | '/offer'
  | '/overview'
  | '/site-visit'
  | '/installation-guide';

export const routes = {
  admin: '/admin',
  createNewUser: '/new-user',
  createOffer: '/create-offer',
  createOfferCustomer: '/create-offer/customer',
  createOfferResidence: '/create-offer/residence',
  createOfferProducts: '/create-offer/products',
  createOfferOffers: '/create-offer/offers',
  customer: '/customer',
  forgotPassword: '/forgot-password',
  login: '/login',
  myPage: '/my-page',
  news: '/news',
  offer: '/offer',
  overview: '/overview',
  root: '/',
  siteVisit: '/site-visit',
  installerSiteVisit: '/installer-site-visit',
  installationGuide: '/installation-guide',
} as const;
