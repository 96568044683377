import { Button, Select, SelectOption } from '~src/components/display';

import { filterSelect, filterWrapper } from '~src/components/features/overview/components/HeaderFilter.module.css';

type HeaderFilterProps = {
  options: SelectOption[];
  onFilter: (value?: string) => void;
  currentFilter?: string;
  buttonText?: string;
};

export const HeaderFilter = ({ options, onFilter, currentFilter, buttonText }: HeaderFilterProps) => (
  <div className={filterWrapper}>
    <Select className={filterSelect} value={currentFilter} options={options} onChange={value => onFilter(value)} />

    <Button onClick={() => onFilter(undefined)}>{buttonText ?? 'Reset'}</Button>
  </div>
);
