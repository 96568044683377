import { SiteVisit } from '~src/components/features/siteVisit/SiteVisit';
import { useLocation } from '~src/hooks';
import { useOfferBySiteVisitCode } from '~src/hooks/services';

export const InstallerSiteVisitPage = () => {
  const { parameters } = useLocation();

  const { accessCode } = parameters;

  const { offer, residence, customer, isLoading } = useOfferBySiteVisitCode(accessCode);

  return <SiteVisit offer={offer} residence={residence} customer={customer} isLoading={isLoading} />;
};
