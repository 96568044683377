import { Switch } from '~src/components/display';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { OfferStatistics } from '~src/components/features/shared/offer';
import {
  SolarOfferStatistics,
  SolarPanelProductSelector,
  SolarSavingsGraphWithElectricityInput,
  SolarSecondaryProductsSelector,
} from '~src/components/features/shared/product';
import { useLocalization, useOfferState } from '~src/hooks';
import { useCustomer } from '~src/hooks/services';
import { mapCustomerToCustomerInput, OfferProducts, ResidenceInput } from '~src/types';
import { convertRoofFromMetersToCentimeters } from '~src/utilities/roof';
import { calculateMaximumNumberOfSolarPanels } from '~src/utilities/solarPanel';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

type OfferEditSolarProductsProps = {
  offerProducts?: OfferProducts;
  residence?: ResidenceInput;
};

export const OfferEditSolarProducts = ({ offerProducts, residence }: OfferEditSolarProductsProps) => {
  const { customer } = useCustomer(residence?.customerID);
  const { withCoverage } = calculateMaximumNumberOfSolarPanels(
    offerProducts?.solarPanel,
    residence?.roofs,
    convertRoofFromMetersToCentimeters
  );
  const { archiveExistingOffer, setArchiveExistingOffer } = useOfferState();
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <div className={displayRow}>
        <div className={displayColumn}>
          <OfferStatistics customer={customer} offerProducts={offerProducts} residence={residence} />

          <SolarSavingsGraphWithElectricityInput residence={residence} />
        </div>

        <div className={displayColumn}>
          <CustomerNotes customer={mapCustomerToCustomerInput(customer)} rows={18} />

          <div>
            {translate.ARCHIVE_EXISTING_OFFER}&nbsp;
            <Switch checked={archiveExistingOffer} onChange={value => setArchiveExistingOffer(value)} />
          </div>

          <OfferProfitMargins offer={offerProducts} />
        </div>
      </div>

      <SolarPanelProductSelector />

      <SolarSecondaryProductsSelector maximumSolarPanels={withCoverage} />

      <SolarOfferStatistics residence={residence} />
    </div>
  );
};
