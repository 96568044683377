import omit from 'lodash/omit';

import { useOfferSavingsQuery, useOfferSavingsWithAndWithoutBatteryQuery, useSolarPackageSavingsQuery } from '~src/gql';
import { useGraphQLClient } from '~src/services/client';
import { AddressInput, OfferInput, ResidenceInput } from '~src/types';
import { areOfferProductsValid } from '~src/utilities/calculationHelper';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useToastMessageErrorHandler } from '../useToastMessage';
import { convertResidenceUnitsAndApplyFallbackValues } from './serviceHelper';

export const useOfferSavings = (
  residence?: ResidenceInput,
  offer?: OfferInput,
  address?: AddressInput,
  chosenHeating?: number,
  consumptionProfile?: number[],
  customerID?: number
) => {
  const { graphQLClient } = useGraphQLClient();
  const residenceWithDefaultData = residence
    ? omit(convertResidenceUnitsAndApplyFallbackValues(residence), 'id')
    : undefined;

  const hasValidProducts = areOfferProductsValid(offer);

  const { data, isFetching } = useOfferSavingsQuery(
    graphQLClient,
    { residence: residenceWithDefaultData!, offer: offer!, address, chosenHeating, consumptionProfile, customerID },
    { enabled: !!residence && !!hasValidProducts, onError: useToastMessageErrorHandler() }
  );

  return { savings: deepNullToUndefined(data?.offerSavings), isFetching };
};

export const useSolarPackageSavings = (residence?: ResidenceInput) => {
  const { graphQLClient } = useGraphQLClient();
  const residenceWithDefaultData = residence
    ? omit(convertResidenceUnitsAndApplyFallbackValues(residence), 'id')
    : undefined;

  const { data, isFetching } = useSolarPackageSavingsQuery(
    graphQLClient,
    { residence: residenceWithDefaultData! },
    { enabled: !!residenceWithDefaultData, onError: useToastMessageErrorHandler() }
  );

  return { data: deepNullToUndefined(data?.solarPackageSavings), isFetching };
};

export const useOfferSavingsWithAndWithoutBattery = (
  residence?: ResidenceInput,
  offer?: OfferInput,
  address?: AddressInput,
  chosenHeating?: number,
  consumptionProfile?: number[],
  customerID?: number
) => {
  const { graphQLClient } = useGraphQLClient();
  const residenceWithDefaultData = residence
    ? omit(convertResidenceUnitsAndApplyFallbackValues(residence), 'id')
    : undefined;

  const hasValidProducts = areOfferProductsValid(offer);

  const { data, isFetching } = useOfferSavingsWithAndWithoutBatteryQuery(
    graphQLClient,
    {
      residence: residenceWithDefaultData!,
      offerWithBattery: offer!,
      offerWithoutBattery: omit(offer!, ['batteryProduct']),
      address,
      chosenHeating,
      consumptionProfile,
      customerID,
    },
    { enabled: !!residence && !!hasValidProducts, onError: useToastMessageErrorHandler() }
  );

  return {
    withBattery: deepNullToUndefined(data?.withBattery),
    withoutBattery: deepNullToUndefined(data?.withoutBattery),
    isFetching,
  };
};
