import { useState } from 'react';

import { API_URL } from '~src/config';
import { useDownloadFile } from '~src/hooks/useDownloadFile';
import { Offer } from '~src/types';

const FILENAME = `GrønVarme&Solceller.pdf`;

export const useDownloadCombinedOffersPdf = () => {
  const [pdfDownloadUrl, setPdfDownloadUrl] = useState('');

  const { downloadFile, isLoading } = useDownloadFile(FILENAME, pdfDownloadUrl);

  const downloadCombinedOffersPdf = (offers: Required<Pick<Offer, 'id' | 'tag'>>[]) => {
    const offerIDs = offers.map(({ id }) => id).join(',');

    setPdfDownloadUrl(`${API_URL}/rest/v1/combined-offer-pdfs?offerIDs=${offerIDs}`);
    downloadFile();
  };

  return { downloadCombinedOffersPdf, isLoading };
};
