import uniqBy from 'lodash/uniqBy';

import { Button, Card, Spinner } from '~src/components/display';
import { OfferInformationCard } from '~src/components/display/OfferInformationCard';
import { OfferNotes, OfferPageHeader, OfferProgress } from '~src/components/features/offer';
import { HeatPumpSiteVisitSummary } from '~src/components/features/offer/HeatPumpSiteVisitSummary';
import { OfferInstallerLinks } from '~src/components/features/offer/OfferInstallerLinks';
import { OfferReservations } from '~src/components/features/offer/OfferReservations';
import { SentOffersHistory } from '~src/components/features/offer/SentOffersHistory';
import { SolarSiteVisitSummary } from '~src/components/features/offer/SolarSiteVisitSummary';
import { CustomerFinancing, CustomerNotes, CustomerPriceInflation } from '~src/components/features/shared/customer';
import { OfferStatistics } from '~src/components/features/shared/offer';
import { OfferExpenseTableExistingOffer } from '~src/components/features/shared/offer/OfferExpenseTable';
import { OfferProductTable } from '~src/components/features/shared/offer/OfferProductTable';
import { mapResidenceToResidenceInput } from '~src/components/features/shared/residence/helpers/residenceHelper';
import { DefaultLayout } from '~src/components/layouts';
import { OfferProductType } from '~src/gql';
import { useLocalization } from '~src/hooks';
import {
  useCustomer,
  useDeleteOfferExpenses,
  useOffer,
  useSetOfferExpenses,
  useUpdateOfferProductPrice,
} from '~src/hooks/services';
import { useSyncContactInformationWithCRM, useSyncOfferWithCrm } from '~src/hooks/services/useCRM';
import { useResidence } from '~src/hooks/services/useResidence';
import { useLocation } from '~src/hooks/useLocation';
import { mapCustomerToCustomerInput, OfferExpenseInput, OfferType, toOfferExpenseInput } from '~src/types';
import { offerHasDisabledProducts } from '~src/utilities/calculationHelper';
import { divideOfferExpenses } from '~src/utilities/offer';

import { OfferProfitMargins } from '../display/OfferProfitMargins';
import { CustomerHeatPumpSubsidy } from '../features/shared/customer';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import { disabledOfferPage, disabledProductsInner, noteContainer, offerPage, syncButton } from './pages.module.css';

export const OfferPage = () => {
  const translate = useLocalization();
  const { parameters } = useLocation();
  const { offerId } = parameters;

  const { offer, isLoading: isLoadingOffer } = useOffer(Number(offerId));

  const { setOfferExpenses, isLoading: isUpdatingExpenses } = useSetOfferExpenses();
  const { deleteOfferExpenses, isLoading: isDeletingExpenses } = useDeleteOfferExpenses();
  const { updateOfferProductPrice, isLoading: isUpdatingProductPrices } = useUpdateOfferProductPrice();

  const { residence, isLoading: isLoadingResidence } = useResidence(offer?.residence?.id);

  const { customer, isLoading: isLoadingCustomer } = useCustomer(offer?.customer?.id);

  const { syncContactInformationWithCRM, isLoading: isSyncingContact } = useSyncContactInformationWithCRM();
  const { syncOfferWithCrm, isLoading: isSyncingOffer } = useSyncOfferWithCrm();

  const { heatPumpOutdoorUnit } = offer ?? {};

  const offerType: OfferType = heatPumpOutdoorUnit ? 'heatPump' : 'solar';

  const residenceInput = mapResidenceToResidenceInput(residence);
  const customerInput = mapCustomerToCustomerInput(customer);

  const updateOfferExpenses = async (expenses: OfferExpenseInput[]) => {
    if (!offer?.id) {
      return;
    }

    const expensesAsInput: OfferExpenseInput[] = (offer?.expenses ?? []).map(toOfferExpenseInput);
    const allExpenses = expenses.concat(expensesAsInput);

    await setOfferExpenses(
      offer?.id,
      uniqBy(allExpenses, ({ templateID, title }) => `${templateID}-${title}`)
    );
  };

  const updateProductPrice = async (unitId: number, price: number, type: OfferProductType) => {
    if (!offer?.id) {
      return;
    }

    await updateOfferProductPrice({ offerId: offer.id, unitId, price, type });
  };

  const isLoading = isLoadingOffer || isLoadingResidence || isLoadingCustomer;

  const { added: addedExpenses, other: otherExpenses } = divideOfferExpenses(offer?.expenses);

  if (isLoading) {
    return (
      <DefaultLayout>
        <Spinner />
      </DefaultLayout>
    );
  }

  const hasDisabledProducts = offerHasDisabledProducts(offer ?? {});

  if (hasDisabledProducts) {
    return (
      <DefaultLayout>
        <OfferPageHeader offer={offer} residence={residenceInput} />

        <div className={disabledOfferPage}>
          <Card>
            <div className={disabledProductsInner}>{translate.OFFER_HAS_DISABLED_PRODUCTS}</div>
          </Card>
        </div>

        <div className={displayColumn}>
          <OfferProductTable
            offer={offer}
            updatePrice={() => {}}
            title={translate.PRODUCTS}
            loading={isUpdatingProductPrices}
          />
        </div>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <OfferPageHeader offer={offer} residence={residenceInput} />

      <div className={offerPage}>
        <div className={displayColumn}>
          <Card>
            <Button
              type='primary'
              className={syncButton}
              loading={isSyncingContact}
              onClick={() => syncContactInformationWithCRM(residence?.id ?? offer?.residence?.id)}
            >
              {translate.SYNCHRONIZE_CONTACT_WITH_CRM}
            </Button>

            <Button
              type='primary'
              className={syncButton}
              loading={isSyncingOffer}
              onClick={() => syncOfferWithCrm(Number(offerId))}
            >
              {translate.SYNCHRONIZE_OFFER_WITH_CRM}
            </Button>
          </Card>

          <CustomerFinancing customer={customerInput} />

          <CustomerHeatPumpSubsidy customer={customerInput} />

          <CustomerPriceInflation customer={customerInput} />

          <OfferProgress offer={offer} />

          <SentOffersHistory offer={offer} />
        </div>

        <div className={displayColumn}>
          <div className={displayRow}>
            <div className={displayColumn}>
              <OfferInformationCard offer={offer} address={residence?.address} />

              <OfferInstallerLinks offer={offer} />
            </div>

            <div className={displayColumn}>
              <OfferStatistics customer={customer} layout='box' offerProducts={offer} residence={residenceInput} />

              <OfferProfitMargins offer={offer} />
            </div>
          </div>

          <OfferProductTable
            offer={offer}
            updatePrice={updateProductPrice}
            title={translate.PRODUCTS}
            loading={isUpdatingProductPrices}
          />

          <OfferExpenseTableExistingOffer
            expenses={otherExpenses}
            offerType={offerType}
            updateExpenses={updateOfferExpenses}
            deleteExpenses={async ids => {
              deleteOfferExpenses(Number(offerId))(ids);
            }}
            tableTitle={translate.EXPENSES}
            isLoading={isUpdatingExpenses || isDeletingExpenses}
            allowAdd={false}
          />

          <OfferExpenseTableExistingOffer
            expenses={addedExpenses}
            offerType={offerType}
            updateExpenses={updateOfferExpenses}
            deleteExpenses={async ids => {
              deleteOfferExpenses(Number(offerId))(ids);
            }}
            tableTitle={translate.ADDED_EXPENSES}
            isLoading={isUpdatingExpenses || isDeletingExpenses}
          />

          <div className={noteContainer}>
            <OfferReservations offer={offer} />

            <OfferNotes offer={offer} />

            <CustomerNotes customer={customerInput} rows={10} title={translate.CUSTOMER_NOTES} />
          </div>

          {offerType === 'solar' ? (
            <SolarSiteVisitSummary residence={residence} residenceID={residence?.id} />
          ) : (
            <HeatPumpSiteVisitSummary residenceID={residence?.id} />
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};
