import { Button, Input, SwitchWithLabel } from '~src/components/display';
import { SearchOutlined } from '~src/components/display/Icons';
import { useLocalization } from '~src/hooks';
import { useNavigation } from '~src/hooks/useNavigation';

import { wrapper } from './OverviewPageInput.module.css';

type OfferOverviewInputProps = {
  isLoadingExport?: boolean;
  onExport: () => Promise<void> | void;
  search: string;
  setSearch: (search: string) => void;
  setShowArchived: (value: boolean) => void;
  showArchived: boolean;
};

export const OverviewPageInput = ({
  isLoadingExport,
  onExport,
  search,
  setSearch,
  setShowArchived,
  showArchived,
}: OfferOverviewInputProps) => {
  const translations = useLocalization();
  const { navigate } = useNavigation();

  return (
    <div className={wrapper}>
      <Button
        type='primary'
        onClick={() => {
          navigate('createOffer');
        }}
      >
        {translations.CREATE_OFFER}
      </Button>

      <Input
        size='middle'
        onChange={event => setSearch(event.target.value)}
        value={search}
        addonAfter={<SearchOutlined />}
      />

      <span />

      <SwitchWithLabel onChange={() => setShowArchived(!showArchived)} label={translations.SHOW_ARCHIVED} />

      <Button loading={isLoadingExport} onClick={onExport} type='primary'>
        {translations.EXPORT}
      </Button>
    </div>
  );
};
