import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';
import { formatYesOrNo, getRelayTranslation } from './util';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideElectricity = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.ELECTRICITY_PANEL}
      </Heading>

      <div className={informationColumn}>
        {!!answerMap?.wiringInCeiling?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_ELECTRICITY_WIRE_OVER_CEILING}
            value={formatYesOrNo(translate, answerMap.wiringInCeiling.answer)}
          />
        )}

        {!!answerMap?.electricRelayWork?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_ELECTRICITY_PANEL_UPGRADE}
            value={getRelayTranslation(translate, answerMap.electricRelayWork.answer)}
          />
        )}

        {!!answerMap?.electricityNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.electricityNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
