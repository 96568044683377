import { useEffect } from 'react';

import { useCustomer } from '~src/hooks/services';

import { useCreateOfferState } from '../state/createOfferState';

export const useSynchronizeStatefulCustomerNotes = () => {
  const { customer, setCustomer } = useCreateOfferState();
  const { customer: apiCustomer } = useCustomer(customer?.id);

  useEffect(
    function synchronizeStatefulCustomerNotes() {
      if (apiCustomer && customer && apiCustomer?.notes !== customer?.notes) {
        setCustomer({ ...customer, notes: apiCustomer.notes });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiCustomer?.notes]
  );
};
