import type { Customer as ApiCustomer, CustomerInput as ApiCustomerInput } from '../gql';
import { DeepNullToUndefined, DeepPartial, MaybeId } from './util';

export type Customer = DeepNullToUndefined<
  Omit<ApiCustomer, 'assignedAdvisor'> & DeepPartial<Pick<ApiCustomer, 'assignedAdvisor'>>
>;
export type CustomerInput = DeepNullToUndefined<ApiCustomerInput>;

export const mapCustomerToCustomerInput = (
  customer?: Pick<Customer, keyof Omit<CustomerInput, 'googleClickId' | 'utmFields'> | 'id'>
): (CustomerInput & MaybeId) | undefined =>
  customer
    ? {
        id: customer.id,
        assignedAdvisorId: customer.assignedAdvisorId,
        email: customer.email,
        financialAdvisor: customer.financialAdvisor,
        financingProvider: customer.financingProvider,
        firstName: customer.firstName,
        lastName: customer.lastName,
        leadOrigin: customer.leadOrigin,
        newsletter: customer.newsletter,
        norlysPromotion: customer.norlysPromotion,
        notes: customer.notes,
        phoneNumber: customer.phoneNumber,
        includeHeatPumpSubsidy: customer.includeHeatPumpSubsidy,
        usePriceInflation: customer.usePriceInflation,
      }
    : undefined;
