import { useLocalization } from '~src/hooks';

import { ExpenseTableItem } from './types';

export const useColumnTitleTranslations = () => {
  const translate = useLocalization();

  const columnTitleTranslations: Record<keyof Omit<ExpenseTableItem, 'id' | 'templateID' | 'type'>, string> = {
    price: translate.PRICE,
    quantity: translate.QUANTITY,
    notes: translate.NOTES,
    priceWithVAT: translate.PRICE_WITH_VAT,
    title: translate.EXPENSES,
  };

  return { columnTitleTranslations };
};
