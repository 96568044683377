import { useEffect, useState } from 'react';

import { Divider, Heading, Input, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useEnergyOptions } from '~src/hooks/services/useEnergyOptions';
import { calculateElectricitySellPrice } from '~src/utilities/calculationHelper';

import { inputSubtext, solarGraphEditSection } from './solarProduct.module.css';

type SolarSavingsGraphInputWidgetProps = {
  electricityPrice?: number;
  updateElectricityPrice?: (price: number) => void;
};

export const SolarSavingsGraphInputWidget = ({
  electricityPrice: electricityPriceInput,
  updateElectricityPrice,
}: SolarSavingsGraphInputWidgetProps) => {
  const [electricityPrice, setElectricityPrice] = useState(electricityPriceInput);
  const translate = useLocalization();

  const { energyOptions } = useEnergyOptions();
  const { electricity } = energyOptions ?? {};
  const { sellPriceReduction } = electricity ?? {};

  useEffect(
    function setElectricityPriceFromInput() {
      if (electricityPriceInput !== electricityPrice) {
        setElectricityPrice(electricityPriceInput);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [electricityPriceInput]
  );

  return (
    <div className={solarGraphEditSection}>
      <Heading level={3}>
        {translate.EDIT} {translate.GRAPH.toLowerCase()}
      </Heading>

      <Divider fullWidth />

      <Text>{translate.PRICE_OF} 1 kWh</Text>
      <div>
        <Input
          type='number'
          onChange={event => setElectricityPrice(Number(event.target.value))}
          value={electricityPrice}
          onBlur={() => updateElectricityPrice?.(electricityPrice ?? 0)}
        />
        <div className={inputSubtext}>{translate.DESELECT_TO_UPDATE}</div>
      </div>

      <Text>{translate.PROFIT}/kWh</Text>
      <Input disabled value={calculateElectricitySellPrice(electricityPrice ?? 0, sellPriceReduction ?? 0)} />
    </div>
  );
};
