import { useEffect, useState } from 'react';

import { Form, Select } from '~src/components/display';
import { mapResidenceToResidenceInput } from '~src/components/features/shared/residence/helpers/residenceHelper';
import { useLocalization } from '~src/hooks';
import { useResidence } from '~src/hooks/services';
import { useNavigation } from '~src/hooks/useNavigation';
import { formatAddress } from '~src/utilities/format';

import { useCustomerResidenceList } from '../../hooks/useCustomerResidenceList';
import { useCreateOfferState } from '../../state/createOfferState';

import { dropdown } from '../components.module.css';

export const AddressSearchExisting = () => {
  const translate = useLocalization();

  const [selectedResidenceId, setSelectedResidenceId] = useState<number | undefined>();

  const { navigateRaw } = useNavigation();

  const { residence } = useResidence(selectedResidenceId);

  const { residenceList } = useCustomerResidenceList();

  const { setResidence } = useCreateOfferState();

  useEffect(
    function preselectSingleResidence() {
      if (!selectedResidenceId && residenceList.length === 1) {
        setSelectedResidenceId(residenceList[0].id);
      }
    },
    [residenceList, selectedResidenceId]
  );

  useEffect(
    function synchronizeResidenceState() {
      if (residence?.id) {
        setResidence(mapResidenceToResidenceInput(residence));
        navigateRaw(`/create-offer/residence/${residence.id}`, { replace: true });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [residence?.id]
  );

  const residenceOptions = residenceList.map(({ address, id }) => ({
    label: address ? formatAddress(address) : '',
    value: id,
  }));

  return (
    <Form.Item label={translate.SELECT_RESIDENCE}>
      <Select<number>
        popupClassName={dropdown}
        onSelect={setSelectedResidenceId}
        options={residenceOptions}
        value={selectedResidenceId}
      />
    </Form.Item>
  );
};
