import { Pagination, Table } from '~src/components/display';
import { useExportOverviewCSV } from '~src/components/features/overview/hooks/useExportOverviewCsv';
import { useLocalization } from '~src/hooks';
import { useNavigation } from '~src/hooks/useNavigation';
import { useSearchForOverviewOffers } from '~src/hooks/useSearchForOverviewOffers';
import { OfferType } from '~src/types';

import { OverviewPageInput } from './components/OverviewPageInput';
import { getOverviewTableColumns } from './OverviewPageTableUtil/columns';
import { useFormatOverviewTableData } from './OverviewPageTableUtil/data';
import type { OverviewTableColumn } from './types';

import { scrollTable, tableWrapper } from './overview.module.css';

export const OverviewPageTable = () => {
  const { navigateRaw } = useNavigation();

  const translations = useLocalization();

  const {
    offers,
    count,
    search,
    setSearch,
    page,
    pageSize,
    orderBy,
    orderDirection,
    productType,
    setPage,
    setPageSize,
    setOrderBy,
    setOrderDirection,
    setProductType,
    isLoading,
    showArchived,
    setShowArchived,
  } = useSearchForOverviewOffers();

  const dataSource = useFormatOverviewTableData(offers);

  const { downloadFile, isLoading: isLoadingCSV } = useExportOverviewCSV(search, productType);

  const onHeaderSort = (column: OverviewTableColumn) => {
    const { dataIndex } = column;
    if (dataIndex === orderBy) {
      setOrderDirection(orderDirection === 'ascending' ? 'descending' : 'ascending');
      return;
    }

    setOrderDirection('ascending');
    if (dataIndex) {
      setOrderBy(dataIndex?.toString());
    }
  };

  const onHeaderFilter = (value?: string) => setProductType(value as OfferType);

  const currentFiltersRecord = { productType };

  return (
    <section className={tableWrapper}>
      <OverviewPageInput
        isLoadingExport={isLoadingCSV}
        onExport={downloadFile}
        search={search}
        setSearch={setSearch}
        setShowArchived={setShowArchived}
        showArchived={showArchived}
      />

      <Table
        className={scrollTable}
        bordered
        loading={isLoading}
        pagination={false}
        columns={getOverviewTableColumns(translations, onHeaderSort, onHeaderFilter, currentFiltersRecord)}
        dataSource={dataSource}
        onRow={item => ({ onClick: () => navigateRaw(`/offer/${item.id}`) })}
        rowKey={item => item.id}
      />

      <Pagination
        total={count}
        pageSize={pageSize}
        current={page}
        onChange={(newPage: number, newPageSize: number) => {
          setPage?.(newPage);
          setPageSize?.(newPageSize);
        }}
        pageSizeOptions={[10, 25, 50]}
      />
    </section>
  );
};
