import { useEffect } from 'react';

import { Form, Input, Select, Text } from '~src/components/display';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { MailOutlined, PhoneOutlined, UserOutlined } from '~src/components/display/Icons';
import { LEAD_ORIGIN_OPTIONS } from '~src/components/features/shared/customer/LeadOriginOptions';
import { useLocalization } from '~src/hooks';
import { useUpsertCustomer } from '~src/hooks/services/useCustomer';
import { useBodilAdvisors } from '~src/hooks/services/useUser';
import { useToastMessage } from '~src/hooks/useToastMessage';
import { CustomerInput } from '~src/types';
import { formatDate } from '~src/utilities/format';

import { CustomerInformationCardProps } from '../types';

import { customerInformation, customerInformationEntry, customerNameInput, dropdown } from '../customer.module.css';

const assignedAdvisorFieldKey: keyof CustomerInput = 'assignedAdvisorId';
const emailFieldKey: keyof CustomerInput = 'email';
const financialAdvisorFieldKey: keyof CustomerInput = 'financialAdvisor';
const firstNameFieldKey: keyof CustomerInput = 'firstName';
const lastNameFieldKey: keyof CustomerInput = 'lastName';
const leadOriginFieldKey: keyof CustomerInput = 'leadOrigin';
const phoneFieldKey: keyof CustomerInput = 'phoneNumber';

const FINANCIAL_ADVISOR_FIELD_MAX_LENGTH = 255;

type CustomerFormProps = CustomerInformationCardProps & { form: ReturnType<typeof Form.useForm<CustomerInput>>[0] };

export const CustomerForm = ({ customer, form }: CustomerFormProps) => {
  const translate = useLocalization();

  const { showMessage } = useToastMessage();

  const { upsertCustomer } = useUpsertCustomer();

  const { bodilAdvisors } = useBodilAdvisors();

  const requiredFieldRules = useFormRequiredFieldRules();

  useEffect(
    function initializeFormWithCustomer() {
      if (customer?.id) {
        form.setFieldsValue(customer);
      } else {
        form.resetFields();
      }
    },
    [customer, form]
  );

  const handleFormSubmit = async (newCustomer: CustomerInput) => {
    const customerId = await upsertCustomer({ ...newCustomer, id: customer?.id });

    if (customerId) {
      showMessage({ type: 'success', message: translate.SAVED });
    }
  };

  const advisorOptions = bodilAdvisors?.map(({ id, firstName, lastName }) => ({
    value: id,
    label: `${firstName} ${lastName}`,
  }));

  const { createdAt } = customer ?? {};

  return (
    <div className={customerInformation}>
      <Form component={false} form={form} onFinish={handleFormSubmit}>
        <div className={customerInformationEntry}>
          <UserOutlined />

          <div className={customerNameInput}>
            <Form.Item name={firstNameFieldKey} rules={requiredFieldRules}>
              <Input />
            </Form.Item>

            <Form.Item name={lastNameFieldKey} rules={requiredFieldRules}>
              <Input />
            </Form.Item>
          </div>
        </div>

        <div className={customerInformationEntry}>
          <Text>{translate.CUSTOMER_CREATED}:</Text>

          <Text>{createdAt && formatDate(createdAt)}</Text>
        </div>

        <div className={customerInformationEntry}>
          <MailOutlined />

          <Form.Item name={emailFieldKey} rules={requiredFieldRules}>
            <Input />
          </Form.Item>
        </div>

        <div className={customerInformationEntry}>
          <Text>{translate.ASSIGNED_ADVISOR}:</Text>

          <Form.Item name={assignedAdvisorFieldKey}>
            <Select options={advisorOptions} className={dropdown} />
          </Form.Item>
        </div>

        <div className={customerInformationEntry}>
          <PhoneOutlined />

          <Form.Item name={phoneFieldKey} rules={requiredFieldRules}>
            <Input />
          </Form.Item>
        </div>

        <div className={customerInformationEntry}>
          <Text>{translate.LEAD_ORIGIN}:</Text>

          <Form.Item name={leadOriginFieldKey}>
            <Select options={LEAD_ORIGIN_OPTIONS} />
          </Form.Item>
        </div>

        <div className={customerInformationEntry}>
          <Text>{translate.FINANCIAL_ADVISOR}:</Text>

          <Form.Item
            name={financialAdvisorFieldKey}
            rules={[
              {
                max: FINANCIAL_ADVISOR_FIELD_MAX_LENGTH,
                message: `${translate.FORM_FIELD_MAX_LENGTH} ${FINANCIAL_ADVISOR_FIELD_MAX_LENGTH}`,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
