import { FetchOverviewOfferListQueryVariables, OffersWithCount, useFetchOverviewOfferListQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { DeepNullToUndefined } from '~src/types/util';
import { deepNullToUndefined } from '~src/utilities/convert';

type Offers = DeepNullToUndefined<OffersWithCount['offers']>;

export const useFetchOverviewOffers = (variables?: FetchOverviewOfferListQueryVariables) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, error, isLoading, status } = useFetchOverviewOfferListQuery(graphQLClient, variables, {
    onError: useToastMessageErrorHandler(),
  });

  if (status === 'error' || status === 'loading') {
    return {
      offers: [] as Offers,
      count: 0,
      error,
      isLoading,
      status,
    };
  }

  const { offers = [] as Offers, count = 0 } = deepNullToUndefined(data?.dashboardOffers) ?? {};

  return { offers: offers as Offers, count, error, isLoading, status };
};
