import { Button, Heading } from '~src/components/display';
import { LogoLayout } from '~src/components/layouts';
import { useGlobalNavigation, useLocalization, useNavigation } from '~src/hooks';
import { useUser } from '~src/hooks/services';

import { landingPage } from './pages.module.css';

export const LandingPage = () => {
  const translate = useLocalization();

  const { navigate } = useNavigation();
  const { navigateToAdminPanel } = useGlobalNavigation();
  const { user } = useUser();

  const { isAdmin } = user ?? {};

  return (
    <LogoLayout>
      <Heading>{translate.WELCOME_TO_BODIL}</Heading>
      <Heading level={2}>{translate.LANDING_PAGE_SUBTITLE}</Heading>

      <div className={landingPage}>
        <Button
          onClick={() => {
            navigate('overview');
          }}
        >
          {translate.BODIL_ADVISOR_APP}
        </Button>

        <Button disabled={!isAdmin} onClick={navigateToAdminPanel}>
          {translate.ADMIN_PANEL}
        </Button>
      </div>
    </LogoLayout>
  );
};
