import { Fragment } from 'react';

import { Card } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { Offer } from '~src/types';
import { formatCurrency } from '~src/utilities/format';

import { expensesSection, gridHeading } from './InstallerExpenseInformation.module.css';

type ProductInformationProps = {
  offer?: Pick<Offer, 'expenses'>;
};

export const InstallerExpenseInformation = ({ offer }: ProductInformationProps) => {
  const translate = useLocalization();

  const { expenses } = offer ?? {};

  const discounts = expenses?.filter(({ price, type }) => !type && price < 0);
  const other = expenses?.filter(({ type, price }) => (!type || type === 'included') && price >= 0);

  return (
    <>
      {!!other?.length && (
        <Card title={translate.OTHER_EXPENSES}>
          <div className={expensesSection}>
            <span className={gridHeading}>{translate.QUANTITY}</span>
            <span className={gridHeading}>{translate.PRICE}</span>
            <span className={gridHeading}>{translate.PRODUCT}</span>
            {other?.map(value => (
              <Fragment key={value.title}>
                <span>{value.quantity}</span>
                <span>-</span>
                <span>{value.title}</span>
              </Fragment>
            ))}
          </div>
        </Card>
      )}

      {!!discounts?.length && (
        <Card title={translate.DISCOUNTS}>
          <div className={expensesSection}>
            <span className={gridHeading}>{translate.QUANTITY}</span>
            <span className={gridHeading}>{translate.DISCOUNT}</span>
            <span className={gridHeading}>{translate.DESCRIPTION}</span>
            {discounts?.map(value => (
              <Fragment key={value.title}>
                <span>{value.quantity}</span>
                <span>{formatCurrency(-value.price)}</span>
                <span>{value.title}</span>
              </Fragment>
            ))}
          </div>
        </Card>
      )}
    </>
  );
};
