import { useEffect } from 'react';

import { Card, ModalButton } from '~src/components/display';
import { OfferProfitMargins } from '~src/components/display/OfferProfitMargins';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { OfferDistrictHeatingComparison, OfferStatistics } from '~src/components/features/shared/offer';
import { OfferExpenseTableNewOffer } from '~src/components/features/shared/offer/OfferExpenseTable';
import { RecommendedHeatEffectOverview } from '~src/components/features/shared/product';
import { HeatPumpIndoorProductSelector } from '~src/components/features/shared/product/HeatPumpIndoorProductSelector';
import { HeatPumpOutdoorProductSelector } from '~src/components/features/shared/product/HeatPumpOutdoorProductSelector';
import { useLocalization, useOfferProductState } from '~src/hooks';
import { OfferProducts } from '~src/types';

import { useCombineOfferProductsWithExpenses } from '../../hooks/useCombineOfferProductsWithExpenses';
import { useSynchronizeStatefulCustomerNotes } from '../../hooks/useSynchronizeStatefulCustomerNotes';
import { useCreateOfferState } from '../../state/createOfferState';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import { tab } from './product.module.css';

const AQUAREA_HEAT_PUMP_IDS = [96, 97, 98, 99, 124];
const AQUAREA_EXPENSE_TEMPLATE_IDS = [106];
const CURRENT_MONTH = new Date().getMonth();

export const CreateOfferHeatPumpTab = () => {
  const translate = useLocalization();

  const { customer, residence, offerList } = useCreateOfferState();
  useSynchronizeStatefulCustomerNotes();

  const { selectedHeatPumpOutdoorUnit, selectedHeatPumpIndoorUnit, setOfferExpenses, selectedOfferExpenses } =
    useOfferProductState();

  useEffect(
    function removeExistingExpenses() {
      setOfferExpenses([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offerList.length]
  );

  const offerProducts: OfferProducts = {
    heatPumpOutdoorUnit: selectedHeatPumpOutdoorUnit,
    heatPumpIndoorUnit: selectedHeatPumpIndoorUnit,
  };

  const offerProductsWithExpenses = useCombineOfferProductsWithExpenses(offerProducts);

  const isSamsungAquarea = AQUAREA_HEAT_PUMP_IDS.includes(selectedHeatPumpOutdoorUnit?.id ?? 0);
  const shouldAddExpenses = isSamsungAquarea && CURRENT_MONTH <= 9;

  return (
    <Card className={tab}>
      <div className={displayRow}>
        <div className={displayColumn}>
          <OfferStatistics customer={customer} offerProducts={offerProductsWithExpenses} residence={residence} />

          {!!residence && !!offerProducts?.heatPumpOutdoorUnit && (
            <ModalButton buttonChildren={translate.DISTRICT_HEATING_COMPARE}>
              <OfferDistrictHeatingComparison
                customer={customer}
                products={offerProductsWithExpenses}
                residence={residence}
              />
            </ModalButton>
          )}

          <RecommendedHeatEffectOverview residence={residence} />
        </div>

        <div className={displayColumn}>
          <CustomerNotes customer={customer} rows={12} />

          <OfferProfitMargins offer={offerProductsWithExpenses} />
        </div>
      </div>

      <HeatPumpOutdoorProductSelector residence={residence} />

      <HeatPumpIndoorProductSelector />

      <br />
      <OfferExpenseTableNewOffer
        expenses={selectedOfferExpenses}
        offerType='heatPump'
        tableTitle={translate.EXPENSE}
        updateExpenses={setOfferExpenses}
        addExpenseTemplateIds={shouldAddExpenses ? AQUAREA_EXPENSE_TEMPLATE_IDS : []}
        removeExpenseTemplateIds={shouldAddExpenses ? [] : AQUAREA_EXPENSE_TEMPLATE_IDS}
      />
    </Card>
  );
};
