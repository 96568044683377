import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideInverterBattery = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.SITE_VISIT_SECTION_SOLAR_MISC_PLACEMENT}
      </Heading>

      <div className={informationColumn}>
        {!!answerMap?.inverterAndBatteryPlacementNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap.inverterAndBatteryPlacementNotes.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
