import omit from 'lodash/omit';
import { useRecommendedSolarPackageQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { ResidenceInput } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';
import { convertResidenceUnitsAndApplyFallbackValues } from '../serviceHelper';

export const useRecommendedSolarPackage = (residence?: ResidenceInput) => {
  const { graphQLClient } = useGraphQLClient();

  const residenceWithDefaultData = residence
    ? omit(convertResidenceUnitsAndApplyFallbackValues(residence), 'id')
    : undefined;

  const { data, isFetching } = useRecommendedSolarPackageQuery(
    graphQLClient,
    { residence: residenceWithDefaultData! },
    { onError: useToastMessageErrorHandler(), enabled: !!residence }
  );

  const solarProducts = data?.recommendedSolarPackage;

  return {
    solarProducts: deepNullToUndefined({ ...solarProducts, battery: solarProducts?.batteries?.[0] }),
    isLoading: isFetching,
  };
};
