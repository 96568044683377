import round from 'lodash/round';

import { useLocalization } from '~src/hooks';
import { Battery } from '~src/types';

import { ProductCard, ProductCardProps } from './ProductCard';
import { getProductDimensionString } from './productCardHelper';

type BatteryProductCardProps = Pick<ProductCardProps, 'onSelect' | 'selected' | 'preferred' | 'type'> & {
  product: Battery;
  showNotes?: boolean;
};

export const BatteryProductCard = ({
  onSelect,
  product,
  selected,
  preferred,
  type,
  showNotes,
}: BatteryProductCardProps) => {
  const translate = useLocalization();

  const { weight, capacity, notes } = product;

  return (
    <ProductCard
      attributes={[
        { name: translate.WEIGHT, value: `${round(weight, 0)} kg` },
        { name: translate.CAPACITY, value: `${round(capacity, 0)} kWh` },
        getProductDimensionString(product.size, translate),
      ]}
      brand={product.brand}
      id={product.id}
      imageUrl={product.imageUrl ?? undefined}
      model={product.model}
      onSelect={onSelect}
      preferred={preferred}
      price={product.price}
      selected={selected}
      type={type}
      notes={showNotes ? notes : undefined}
    />
  );
};
