import { ReactNode } from 'react';

import { useToastMessage } from '~src/hooks';

import { logoLayout } from './LogoLayout.module.css';

export const LogoLayout = ({ children }: { children: ReactNode }) => {
  const { contextHolder } = useToastMessage();

  return (
    <div className={logoLayout}>
      {contextHolder}

      <main>{children}</main>
    </div>
  );
};
