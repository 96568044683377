import { Button } from '~src/components/display';
import { useLocalization, useNavigation } from '~src/hooks';
import type { Offer } from '~src/types';
import { filterFalsy } from '~src/utilities/calculationHelper';
import { formatAddress, formatDate, SharedAddress } from '~src/utilities/format';

import { OverviewTableRecord } from '../types';

export const useFormatOverviewTableData = (offers: Array<Offer> | undefined = []): OverviewTableRecord[] => {
  const translate = useLocalization();
  const { navigateRaw } = useNavigation();

  return offers.map(({ id, tag, customer, solarPanel, residence }) => {
    const { firstName, lastName, email, phoneNumber, leadOrigin, createdAt, id: customerID } = customer ?? {};
    const { address, id: residenceID } = residence ?? {};

    const { SOLAR_CELL, HEAT_PUMP } = translate;

    const name = filterFalsy([firstName, lastName]).join(' ');

    return {
      id,
      key: tag,
      name,
      email: email ?? '',
      phoneNumber: phoneNumber ? `+45 ${phoneNumber}` : '',
      address: address ? formatAddress(address as SharedAddress) : '',
      leadOrigin: leadOrigin ? `${leadOrigin}` : '',
      productType: solarPanel ? SOLAR_CELL : HEAT_PUMP,
      actions: (
        <Button
          onClick={event => {
            event.stopPropagation();
            navigateRaw(`/customer/${customerID}`);
          }}
        >
          {translate.GO_TO_CUSTOMER}
        </Button>
      ),
      customerCreatedAt: createdAt ? formatDate(createdAt) : '',
      residenceID,
    };
  });
};
