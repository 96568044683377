import { createRoot } from 'react-dom/client';

import { CRMApplication } from './CrmApplication';
import { initializeSentry } from './utilities/sentry';

const rootElement = document.querySelector('#advisor-app-root');

if (rootElement) {
  initializeSentry();
  createRoot(rootElement).render(<CRMApplication />);
}
