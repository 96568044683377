import { useMemo } from 'react';

import { useHeatPumpOutdoorUnitsQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { useLocaleState } from '~src/state/useLocaleState';
import { deepNullToUndefined } from '~src/utilities/convert';

import { getProductSorter } from './productHelper';

export const useHeatPumpOutdoorUnits = (ids?: number[]) => {
  const [locale] = useLocaleState();
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useHeatPumpOutdoorUnitsQuery(
    graphQLClient,
    { ids },
    { onError: useToastMessageErrorHandler() }
  );

  const heatPumpOutdoorUnits = useMemo(
    () => data?.heatPumpOutdoorUnits?.map(deepNullToUndefined)?.sort(getProductSorter(locale)) ?? [],
    [data?.heatPumpOutdoorUnits, locale]
  );

  return { heatPumpOutdoorUnits, isLoading: isFetching };
};
