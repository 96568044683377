import { Spinner, Tabs, TabsProps } from '~src/components/display';
import {
  CustomerInformationCard,
  CustomerOfferTable,
  CustomerPageHeader,
  CustomerResidence,
} from '~src/components/features/customer';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { DefaultLayout } from '~src/components/layouts';
import { useLocalization } from '~src/hooks';
import { useSyncContactInformationWithCRM } from '~src/hooks/services/useCRM';
import { useCustomer } from '~src/hooks/services/useCustomer';
import { useResidenceList } from '~src/hooks/services/useResidence';
import { useLocation } from '~src/hooks/useLocation';
import { mapCustomerToCustomerInput } from '~src/types';
import { GetElementType } from '~src/types/typeHelper';
import { formatAddress } from '~src/utilities/format';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

export const CustomerPage = () => {
  const translate = useLocalization();
  const { parameters } = useLocation();
  const { customerId } = parameters;

  const { customer, isLoading } = useCustomer(Number(customerId));

  const { syncContactInformationWithCRM } = useSyncContactInformationWithCRM();

  const customerInput = mapCustomerToCustomerInput(customer);

  const { residenceList } = useResidenceList(customer?.id);

  const initialTabItem: GetElementType<TabsProps['items']> = {
    key: '0',
    label: translate.CUSTOMER_INFORMATION,
    children: (
      <div className={displayRow}>
        <div className={displayColumn}>
          <CustomerInformationCard customer={customer} />

          <CustomerOfferTable customer={customer} />

          <CustomerNotes customer={customerInput} rows={6} />
        </div>
      </div>
    ),
  };

  const residenceTabList =
    residenceList?.reduce<TabsProps['items']>(
      (list, { address, id }) =>
        address && customer?.id
          ? [
              ...(list ?? []),
              {
                key: id.toString(),
                label: formatAddress(address),
                children: (
                  <CustomerResidence
                    syncCRMContact={syncContactInformationWithCRM}
                    customerId={customer.id}
                    residenceId={id}
                  />
                ),
              },
            ]
          : list,
      []
    ) ?? [];

  return isLoading ? (
    <DefaultLayout>
      <Spinner />
    </DefaultLayout>
  ) : (
    <DefaultLayout>
      <CustomerPageHeader customer={customer} />

      <Tabs items={[initialTabItem, ...residenceTabList]} destroyInactiveTabPane />
    </DefaultLayout>
  );
};
