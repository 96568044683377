import { useEffect, useState } from 'react';

import { Divider, Form, Heading, Input, Radio, Switch, Text, Tooltip } from '~src/components/display';
import { getClampedValueAsInteger, getValueAsInteger } from '~src/components/display/Form/formHelper';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { QuestionCircleOutlined } from '~src/components/display/Icons';
import { useYesNoOptions } from '~src/components/display/Select/useYesNoOptions';
import { useLocalization } from '~src/hooks';
import { ElectricCarInput, ResidenceInput } from '~src/types';
import { calculateAnnualConsumptionForElectricCar } from '~src/utilities/calculationHelper';
import { DEFAULT_ELECTRIC_CAR } from '~src/utilities/constants';
import { roundToNearest } from '~src/utilities/format';

import {
  annualKilometersDrivenFieldKey,
  consumptionPerKilometerFieldKey,
  electricCarFieldKey,
  isSubsidisedFieldKey,
  monthlySubscriptionPriceFieldKey,
  percentageChargedFieldKey,
} from '../constants';

import { electricCarForm, electricCarHeaderSection } from './residenceFormComponents.module.css';

type ResidenceFormElectricCarProps = {
  residence?: Pick<ResidenceInput, 'electricCar'>;
};

export const ResidenceFormElectricCar = ({ residence }: ResidenceFormElectricCarProps) => {
  const translate = useLocalization();

  const form = Form.useFormInstance();

  const requiredFieldRules = useFormRequiredFieldRules();

  const { electricCar } = residence ?? {};

  const [hasCar, setHasCar] = useState<boolean | undefined>();
  const [annualConsumption, setAnnualConsumption] = useState<number>();

  const yesNoOptions = useYesNoOptions();

  const formElectricCar = Form.useWatch(electricCarFieldKey, form);

  const calculateAnnualConsumption = (newCar?: ElectricCarInput) => {
    const car: ElectricCarInput = {
      annualKilometersDriven: newCar?.annualKilometersDriven || electricCar?.annualKilometersDriven || 0,
      consumptionPerKilometer: newCar?.consumptionPerKilometer || electricCar?.consumptionPerKilometer || 0,
      percentageCharged: newCar?.percentageCharged || electricCar?.percentageCharged || 0,
    };

    return roundToNearest(calculateAnnualConsumptionForElectricCar(car), 1);
  };

  useEffect(
    function setHasCarOnLoad() {
      if (hasCar === undefined && electricCar) {
        setHasCar(!!electricCar);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [electricCar]
  );

  useEffect(
    function updateAnnualConsumption() {
      if (formElectricCar) {
        setAnnualConsumption(calculateAnnualConsumption(formElectricCar));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formElectricCar]
  );

  const addElectricCar = () => {
    const formResidence = form.getFieldsValue();

    if (!formResidence) {
      return;
    }

    form.setFieldsValue({ ...formResidence, electricCar: DEFAULT_ELECTRIC_CAR });
    setHasCar(true);
  };

  const removeElectricCar = () => {
    const formResidence = form.getFieldsValue();

    if (!formResidence) {
      return;
    }

    form.setFieldsValue({ ...formResidence, electricCar: undefined });
    setHasCar(false);
  };

  const isSubsidised = formElectricCar?.isSubsidised ?? electricCar?.isSubsidised ?? true;

  return (
    <>
      <div className={electricCarHeaderSection}>
        <Heading level={3}>{translate.ELECTRIC_CAR}</Heading>

        <span>{translate.DO_YOU_HAVE_OR_PLAN_ON_GETTING_AN_ELECTRIC_CAR}</span>

        <Radio.Group
          options={yesNoOptions}
          optionType='button'
          buttonStyle='outline'
          value={hasCar ?? false}
          onChange={event => {
            if (!event?.target?.value) {
              removeElectricCar();
              return;
            }

            addElectricCar();
          }}
        />
      </div>

      <Divider fullWidth />

      {hasCar && (
        <div className={electricCarForm}>
          <Form.Item
            label={translate.EXPECTED_KILOMETERS_PER_YEAR}
            name={[electricCarFieldKey, annualKilometersDrivenFieldKey]}
            getValueFromEvent={getValueAsInteger}
            rules={requiredFieldRules}
          >
            <Input type='number' />
          </Form.Item>

          <Form.Item
            label={translate.CONSUMPTION_OF_WH_PER_KM}
            name={[electricCarFieldKey, consumptionPerKilometerFieldKey]}
            getValueFromEvent={getValueAsInteger}
            rules={requiredFieldRules}
          >
            <Input type='number' />
          </Form.Item>

          <Form.Item
            label={translate.HOW_MUCH_DO_YOU_CHARGE_AT_HOME}
            name={[electricCarFieldKey, percentageChargedFieldKey]}
            getValueFromEvent={getClampedValueAsInteger}
            rules={requiredFieldRules}
          >
            <Input type='number' addonAfter={<Text>%</Text>} />
          </Form.Item>

          <Form.Item
            label={
              <div>
                {translate.ELECTRIC_CAR_YEARLY_KWH_CONSUMPTION}{' '}
                <Tooltip title={translate.NOTE_THAT_THIS_IS_ADDED_TO_CONSUMPTION}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            }
          >
            <Input type='number' disabled value={annualConsumption} />
          </Form.Item>

          <Form.Item
            label={translate.ELECTRIC_CAR_IS_SUBSIDISED}
            name={[electricCarFieldKey, isSubsidisedFieldKey]}
            initialValue={isSubsidised}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label={translate.ELECTRIC_CAR_MONTHLY_SUBSCRIPTION}
            name={[electricCarFieldKey, monthlySubscriptionPriceFieldKey]}
            getValueFromEvent={getValueAsInteger}
            initialValue={DEFAULT_ELECTRIC_CAR.monthlySubscriptionPrice}
          >
            <Input type='number' disabled={!isSubsidised} />
          </Form.Item>
        </div>
      )}
    </>
  );
};
