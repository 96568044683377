import round from 'lodash/round';

import { BankOutlined } from '~src/components/display/Icons';
import { InfoBox } from '~src/components/display/InfoBox';
import { useLocalization } from '~src/hooks';
import { CustomerFinancingProvider } from '~src/types';
import { findLoanCharacteristics } from '~src/utilities/calculationHelper';
import {
  JYSKE_HEATPUMP_LOAN,
  JYSKE_SOLAR_LOAN,
  NORDEA_HEATPUMP_LOAN,
  NORDEA_SOLAR_LOAN,
} from '~src/utilities/constants';
import { formatCurrency } from '~src/utilities/format';

type ProductType = 'heatPump' | 'solar';

type AnnualSavingsBoxProps = {
  savedPerYear?: number;
  financingProvider?: CustomerFinancingProvider;
  productType?: ProductType;
  totalPrice?: number;
};

export const SavingsBox = ({
  savedPerYear = 0,
  totalPrice = 0,
  financingProvider = 'jyskebank',
  productType = 'heatPump',
}: AnnualSavingsBoxProps) => {
  const translate = useLocalization();

  const { paymentPerMonth } = findLoan(totalPrice, financingProvider, productType);

  const savedPerMonth = round(savedPerYear / 12, 0);
  const profitPerMonth = savedPerMonth - paymentPerMonth;

  return (
    <InfoBox
      title={translate.SAVINGS}
      icon={<BankOutlined />}
      value={`${formatCurrency(savedPerYear, { decimals: 0 })} ${translate.YEARLY.toLowerCase()}`}
      subtitle={
        <div>
          <div>
            {translate.MONTHLY_SAVINGS}: {formatCurrency(savedPerMonth)}
          </div>
          <div>
            {translate.MONTHLY_LOAN_REPAYMENT}: {formatCurrency(paymentPerMonth, { decimals: 0 })}
          </div>
          <div>
            {translate.MONTHLY_PROFIT}: {formatCurrency(profitPerMonth, { decimals: 0 })}
          </div>
        </div>
      }
    />
  );
};

const findLoan = (totalPrice: number, provider: CustomerFinancingProvider, productType: ProductType) => {
  if (provider === 'jyskebank') {
    const loan = productType === 'solar' ? JYSKE_SOLAR_LOAN : JYSKE_HEATPUMP_LOAN;
    return findLoanCharacteristics(totalPrice, loan);
  }

  const loan = productType === 'solar' ? NORDEA_SOLAR_LOAN : NORDEA_HEATPUMP_LOAN;
  return findLoanCharacteristics(totalPrice, loan);
};
