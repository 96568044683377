import { useEnergyOptionsQuery } from '~src/gql';
import { useGraphQLClient } from '~src/services/client';
import { mapConstantsToEnergyOptions } from '~src/utilities/calculationHelper';
import { fuelOptions } from '~src/utilities/constants';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useToastMessageErrorHandler } from '../useToastMessage';

const ONE_HOUR = 60 * 60 * 1000;

export const useEnergyOptions = (residenceId?: number) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, error } = useEnergyOptionsQuery(
    graphQLClient,
    { residenceId },
    {
      onError: useToastMessageErrorHandler(),
      staleTime: ONE_HOUR,
    }
  );

  if (!data?.energyOptions) {
    return { energyOptions: fuelOptions, error };
  }

  return { energyOptions: mapConstantsToEnergyOptions(deepNullToUndefined(data.energyOptions)), error };
};
