import { Heading, ImageThumbnail, Input, Radio, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionSolar = ({
  answerMap,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        1. {translate.SITE_VISIT_SECTION_SOLAR}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_SOLAR_MATERIALS}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('solarEntryPossible', event?.target?.value);
          }}
          value={answerMap?.solarEntryPossible?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.solarEntryPossible?.answer === 'false'}
          text={translate.SITE_VISIT_DISCLAIMER_MAKE_ROOM}
          type='condition'
        />

        <Text>{translate.SITE_VISIT_QUESTION_SOLAR_SCAFFOLDING}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('easyAvailableForScaffolding', event?.target?.value);
          }}
          value={answerMap?.easyAvailableForScaffolding?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.easyAvailableForScaffolding?.answer === 'false'}
          text={translate.SITE_VISIT_DISCLAIMER_SCAFFOLDING}
        />

        <Text>{translate.SITE_VISIT_QUESTION_SOLAR_FOUNDATION_PROVIDER}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('providerForFoundationSolar', event?.target?.value);
          }}
          value={answerMap?.providerForFoundationSolar?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='customer'>{translate.CUSTOMER}</Radio.Button>
          <Radio.Button value='installer'>{translate.INSTALLER}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.providerForFoundationSolar?.answer === 'installer'}
          text={translate.SITE_VISIT_DISCLAIMER_FOUNDATION}
          type='expense'
        />

        <Text>{translate.SITE_VISIT_QUESTION_SOLAR_CABLE_LOCATION}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('bufferRequiredSolar', event?.target?.value);
          }}
          value={answerMap?.bufferRequiredSolar?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='throughRoof'>{translate.SITE_VISIT_ANSWER_SOLAR_CABLE_THROUGH_ROOF}</Radio.Button>
          <Radio.Button value='outsideHouse'>{translate.SITE_VISIT_ANSWER_SOLAR_CABLE_OUTSIDE_HOUSE}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.bufferRequiredSolar?.answer === 'outsideHouse'}
          text={translate.SITE_VISIT_DISCLAIMER_SOLAR_CABLE_PICTURES}
        />

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('solarPlacementNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.solarPlacementNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.solarPlacement)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
