import { Card, Heading, Tag, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { Address, Offer } from '~src/types';
import { formatAddress, formatDate } from '~src/utilities/format';

import { offerInformationCard, spanTwoColumns } from './offerInformationCard.module.css';

type OfferInformationCardProps = {
  offer?: Pick<Offer, 'id' | 'tag' | 'createdAt'>;
  address?: Address;
};

export const OfferInformationCard = ({ offer, address }: OfferInformationCardProps) => {
  const translate = useLocalization();

  const { tag, createdAt } = offer ?? {};

  return (
    <Card>
      <div className={offerInformationCard}>
        {address && (
          <Heading level={2} className={spanTwoColumns}>
            {formatAddress(address)}
          </Heading>
        )}

        <Text>{translate.OFFER_ID}</Text>
        <Tag>{tag}</Tag>

        <Text>{translate.OFFER_CREATED}</Text>
        <Tag>{createdAt && formatDate(createdAt)}</Tag>
      </div>
    </Card>
  );
};
