import { ReactNode } from 'react';

import { Tooltip } from '~src/components/display';

import { Button } from '../Button';

import { displayRow } from '~src/style/shared.module.css';

type TableButton = {
  buttonText: string;
  loading?: boolean;
  onClick: () => void;
  requireSelection?: boolean;
  tooltip?: string;
  type?: 'default' | 'primary';
};

type TableButtonsProps = {
  buttonList?: TableButton[];
  selectButtonsDisabled?: boolean;
  disabled?: boolean;
  extraContent?: ReactNode;
};

export const EditableTableButtons = ({
  buttonList,
  selectButtonsDisabled = false,
  disabled = false,
  extraContent,
}: TableButtonsProps) => {
  if (!buttonList?.length) {
    return null;
  }

  return (
    <div className={displayRow}>
      {extraContent}

      {buttonList.map(({ buttonText, loading, onClick, requireSelection, tooltip, type }) => (
        <Tooltip key={buttonText} title={tooltip}>
          <Button
            disabled={disabled || (requireSelection && selectButtonsDisabled)}
            loading={loading}
            onClick={onClick}
            type={type}
          >
            {buttonText}
          </Button>
        </Tooltip>
      ))}
    </div>
  );
};
