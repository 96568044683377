import { getCookie } from '~src/utilities/cookies';

const decodeBase64String = (data: string) => atob(data);

export const parseJWT = (token: string, index = 0) => {
  const jwt = token.split('.')[index];
  const decodedJWT = decodeBase64String(jwt);

  return JSON.parse(decodedJWT);
};

export const isJWTValid = (payload: { iat: number; exp: number }) => {
  const { iat, exp } = payload;
  const now = Math.round(Date.now() * 1e-3);

  return iat <= now && exp > now;
};

export const isAuthenticated = () => {
  const accessToken = getCookie('accessToken');

  if (!accessToken) {
    return false;
  }

  const payload = parseJWT(accessToken, 1);

  if (!payload) {
    return false;
  }

  return isJWTValid(payload);
};
