import { Form, Input, Radio, Text } from '~src/components/display';
import { getClampedValueAsInteger } from '~src/components/display/Form/formHelper';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { useLocalization } from '~src/hooks';
import { HeatDistributionTypeEnum } from '~src/types';

import { heatCircuitFieldKey, heatDistributionFieldKey, radiatorFloorPercentageFieldKey } from '../constants';
import { getHeatDistributionTypeOptions } from '../helpers/residenceHelper';
import { ResidenceFormSection } from './ResidenceFormSection';

import { heatDistributionSection } from './residenceFormComponents.module.css';

export const ResidenceFormHeatDistribution = () => {
  const translate = useLocalization();

  const requiredFieldRules = useFormRequiredFieldRules();

  const form = Form.useFormInstance();

  const formHeatDistribution = Form.useWatch<HeatDistributionTypeEnum>(heatDistributionFieldKey, form);

  const heatDistributionTypeOptions = getHeatDistributionTypeOptions(translate);

  return (
    <ResidenceFormSection title={translate.HEAT_DISTRIBUTION}>
      <div className={heatDistributionSection}>
        <Form.Item name={heatDistributionFieldKey} rules={requiredFieldRules}>
          <Radio.Group options={heatDistributionTypeOptions} optionType='button' buttonStyle='outline' />
        </Form.Item>

        {formHeatDistribution === 'radiatorAndFloor' && (
          <>
            <Form.Item getValueFromEvent={getClampedValueAsInteger} name={radiatorFloorPercentageFieldKey}>
              <Input type='number' addonAfter={<Text>{translate.PERCENTAGE_FLOOR_HEATING}</Text>} />
            </Form.Item>

            <Form.Item name={heatCircuitFieldKey}>
              <Radio.Group name={heatCircuitFieldKey}>
                <Radio.Button value={1}>{translate.SITE_VISIT_ANSWER_STRINGS_1}</Radio.Button>
                <Radio.Button value={2}>{translate.SITE_VISIT_ANSWER_STRINGS_2}</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </>
        )}
      </div>
    </ResidenceFormSection>
  );
};
