import { useLocalization } from '~src/hooks';

import { ResidenceFormPrimaryHeating } from './ResidenceFormPrimaryHeating';
import { ResidenceFormSecondaryHeating } from './ResidenceFormSecondaryHeating';
import { ResidenceFormSection } from './ResidenceFormSection';

export const ResidenceFormConsumption = () => {
  const translate = useLocalization();

  return (
    <ResidenceFormSection title={translate.CONSUMPTION_INFORMATION}>
      <ResidenceFormPrimaryHeating />

      <ResidenceFormSecondaryHeating />
    </ResidenceFormSection>
  );
};
