import clamp from 'lodash/clamp';
import round from 'lodash/round';
import { useEffect, useMemo, useState } from 'react';

import { Divider, Form, Heading, HeatPumpOutdoorProductCard, Input, Select } from '~src/components/display';
import { ProductPagination } from '~src/components/display/Pagination/ProductPagination';
import { useLocalization, useOfferProductState } from '~src/hooks';
import { useHeatPumpOutdoorUnits } from '~src/hooks/services/products/useHeatPumpOutdoorUnit';
import { ResidenceInput } from '~src/types';
import { aggregateHeatingOutput, calculateHeatLoadPerArea } from '~src/utilities/calculationHelper';
import { DEGREE_DAYS_WEIGTED, MINUS_12_TO_MINUS_7_RATIO } from '~src/utilities/constants';

import { useScoredHeatPumps } from './hooks/useScoredHeatPumps';

import { displayColumn, displayRow } from '~src/style/shared.module.css';

import { effectFilter, heatPumpFilters } from './sharedProduct.module.css';

type ProductSelectorProps = {
  residence?: ResidenceInput;
};

const MAX_EFFECT = 30;
const MIN_EFFECT = 1;

export const HeatPumpOutdoorProductSelector = ({ residence }: ProductSelectorProps) => {
  const translate = useLocalization();

  const [jumpToItemWithIndex, setJumpToItemWithIndex] = useState<number | undefined>();
  const [chosenHeating, updateChosenHeating] = useState<number | undefined>(getInitialChosenHeating(residence));

  const { selectHeatPumpOutdoorUnit, selectedHeatPumpOutdoorUnit } = useOfferProductState();

  const { heatPumpOutdoorUnits } = useHeatPumpOutdoorUnits();

  const { heatPumpList, bestFit, setBrand, setChosenHeating, setNoiseWeight } = useScoredHeatPumps(
    heatPumpOutdoorUnits,
    residence
  );

  useEffect(
    function initializeChosenHeating() {
      if (!residence?.primaryHeating && !residence?.secondaryHeating) {
        return;
      }

      const residenceEffect =
        aggregateHeatingOutput([residence?.primaryHeating, residence?.secondaryHeating]) / DEGREE_DAYS_WEIGTED;

      setChosenHeating(round(residenceEffect * MINUS_12_TO_MINUS_7_RATIO, 2));
    },
    [residence?.primaryHeating, residence?.secondaryHeating, setChosenHeating]
  );

  useEffect(
    function preselectFirstValidHeatPumpOutdoorUnitOnLoad() {
      if (selectedHeatPumpOutdoorUnit?.id && heatPumpList?.some(({ id }) => id === selectedHeatPumpOutdoorUnit?.id)) {
        return;
      }

      if (!heatPumpList.length) {
        return;
      }

      selectHeatPumpOutdoorUnit(bestFit ?? heatPumpList[0]);
    },
    [heatPumpList, selectHeatPumpOutdoorUnit, selectedHeatPumpOutdoorUnit?.id, bestFit]
  );

  useEffect(
    function jumpToCurrentPage() {
      if (!selectedHeatPumpOutdoorUnit || !bestFit || !heatPumpList) {
        setJumpToItemWithIndex(0);
        return;
      }

      const unitIndex = heatPumpList.findIndex(({ id }) => id === (selectedHeatPumpOutdoorUnit?.id || bestFit.id)) ?? 0;

      setJumpToItemWithIndex(unitIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bestFit?.id, heatPumpList.length]
  );

  const handleOnSelect = (selectedId?: number | string) => {
    const selectedHeatPump = heatPumpOutdoorUnits?.find(({ id }) => id === selectedId);

    if (!selectedHeatPump) {
      return;
    }

    selectHeatPumpOutdoorUnit(selectedHeatPump);
  };

  const handleSelectBrand = (brand: string) => {
    if (brand === translate.ALL) {
      setBrand('');
      return;
    }

    setBrand(brand);
  };

  const availableHeatPumpBrands = useMemo(
    () => [
      { value: translate.ALL },
      ...Array.from(new Set(heatPumpOutdoorUnits?.map(({ brand }) => brand))).map(value => ({ value })),
    ],
    [heatPumpOutdoorUnits, translate.ALL]
  );

  const heatLoadPerAreaFormatted = useMemo(() => {
    const heatLoadPerArea = residence && chosenHeating ? calculateHeatLoadPerArea(residence, chosenHeating) : 0;
    return heatLoadPerArea ? `(${heatLoadPerArea} W/m2)` : '';
  }, [chosenHeating, residence]);

  return (
    <div className={displayColumn}>
      <div className={displayRow}>
        <Heading>{`${translate.HEAT_PUMP} ${translate.OUTSIDE_PART.toLowerCase()}`}</Heading>

        <div className={heatPumpFilters}>
          <Form layout='inline'>
            <Form.Item label={translate.BRAND}>
              <Select options={availableHeatPumpBrands} onSelect={handleSelectBrand} defaultValue={translate.ALL} />
            </Form.Item>

            <Form.Item
              label={`${translate.HEAT_EFFECT} (-7°)`}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              className={effectFilter}
            >
              <Input
                max={MAX_EFFECT}
                min={MIN_EFFECT}
                step={1}
                addonAfter={`kW ${heatLoadPerAreaFormatted}`}
                onChange={event => {
                  const newChosenHeating = clamp(parseFloat(event.target.value), MIN_EFFECT, MAX_EFFECT);
                  setChosenHeating(newChosenHeating);
                  updateChosenHeating(newChosenHeating);
                }}
                type='number'
                value={chosenHeating}
              />
            </Form.Item>

            <Form.Item label={translate.SOUND_LEVEL}>
              <Select
                options={[
                  { value: 'small', label: 'Lille' },
                  { value: 'medium', label: 'Mellem' },
                  { value: 'large', label: 'Stor' },
                ]}
                onSelect={setNoiseWeight}
              />
            </Form.Item>
          </Form>
        </div>
      </div>

      <Divider fullWidth />

      <ProductPagination
        itemIndex={jumpToItemWithIndex}
        itemList={heatPumpList?.map(item => (
          <HeatPumpOutdoorProductCard
            residence={residence}
            key={`${item.brand}-${item.model}`}
            onSelect={handleOnSelect}
            product={item}
            preferred={item.preferred}
            selected={item.id === selectedHeatPumpOutdoorUnit?.id}
          />
        ))}
      />
    </div>
  );
};

const getInitialChosenHeating = (
  { primaryHeating, secondaryHeating }: Pick<ResidenceInput, 'primaryHeating' | 'secondaryHeating'> = {
    primaryHeating: { annualUsage: 0, fuelType: '' },
  }
) => {
  if (!primaryHeating && !secondaryHeating) {
    return undefined;
  }

  const residenceEffect = aggregateHeatingOutput([primaryHeating, secondaryHeating]) / DEGREE_DAYS_WEIGTED;

  return round(residenceEffect * MINUS_12_TO_MINUS_7_RATIO, 2);
};
