import throttle from 'lodash/throttle';
import { useEffect, useRef, useState } from 'react';

const DEFAULT_PAGE_SIZE = 4;

const INTERVAL_60_FPS = 1000 / 60;

const MARGIN_CORRECTION = 100;

const MIN_CHILD_WIDTH = 230;

export const useDynamicPageSize = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const updatePageSize = () => {
    if (!ref.current) {
      return;
    }

    const { current } = ref;

    const parentWidth = Math.max(Math.min(globalThis.innerWidth, current.clientWidth) - MARGIN_CORRECTION, 1);
    const childWidth = current.children?.item(0)?.clientWidth || current.clientWidth || MIN_CHILD_WIDTH;

    setPageSize(Math.max(Math.floor(parentWidth / childWidth), 1));
  };

  useEffect(function addPageResizeListener() {
    const pageSizeUpdater = throttle(updatePageSize, INTERVAL_60_FPS);

    globalThis.addEventListener('resize', pageSizeUpdater, { passive: true });

    return () => globalThis.removeEventListener('resize', pageSizeUpdater);
  }, []);

  return { pageSize, ref, updatePageSize };
};

export const usePagination = (pageSize: number = DEFAULT_PAGE_SIZE) => {
  const [page, setPage] = useState(1);

  const firstElement = (page - 1) * pageSize;
  const lastElement = firstElement + pageSize;

  return { page, setPage, firstElement, lastElement };
};
