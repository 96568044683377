import { Card } from '~src/components/display';
import { Customer, Offer, OFFER_BASE_KEYS } from '~src/types';

import { OfferActions } from './OfferActions';

type OfferProgressProps = {
  offer?: Pick<Offer, (typeof OFFER_BASE_KEYS)[number] | 'residenceID' | 'tag'> & {
    customer?: Pick<Customer, 'id' | 'firstName' | 'lastName' | 'norlysPromotion'>;
  };
};

export const OfferProgress = ({ offer }: OfferProgressProps) => (
  <Card>
    <OfferActions offer={offer} />
  </Card>
);
