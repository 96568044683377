import { Form } from '~src/components/display';
import { TranslationRecord } from '~src/localization/translationKeys';
import { ResidenceHeatingInput, ResidenceInput } from '~src/types';
import { getFindFuelTypeEfficiencyOptions, getSecondaryHeatingOptions } from '~src/utilities/heatingType';

import {
  efficiencyFieldKey,
  fuelSubtypeFieldKey,
  primaryHeatingFieldKey,
  secondaryHeatingFieldKey,
  volumeUnitFieldKey,
} from '../constants';

export const updateFormFuelSubtypes = (
  translate: TranslationRecord,
  priority: ResidenceHeatingInput['priority'],
  fuelType: ResidenceHeatingInput['fuelType'],
  form: ReturnType<typeof Form.useForm<ResidenceInput>>[0]
) => {
  const heatingFieldKey = priority === 'primary' ? primaryHeatingFieldKey : secondaryHeatingFieldKey;
  const efficiencyOptions = getFindFuelTypeEfficiencyOptions(translate, priority, fuelType);

  const { fuelSubtypeOptions, fuelSubtypeUnitOptions } =
    getSecondaryHeatingOptions(translate).find(({ value }) => value === fuelType) ?? {};

  form.setFieldValue([heatingFieldKey, fuelSubtypeFieldKey], fuelSubtypeOptions?.[0].value);
  form.setFieldValue([heatingFieldKey, volumeUnitFieldKey], fuelSubtypeUnitOptions?.[0].value);
  form.setFieldValue([heatingFieldKey, efficiencyFieldKey], efficiencyOptions[0].value);
};
