import { deepNullToUndefined } from '@bodilenergy/util';

import { useBbrLookupByAddressQuery } from '~src/gql';
import { useGraphQLClient } from '~src/services/client';
import { AddressInput } from '~src/types';
import { convertRoofFromCentimetersToMeters } from '~src/utilities/roof';

import { useToastMessageErrorHandler } from '../useToastMessage';
import { toValidAddressInput } from './serviceHelper';

const ONE_HOUR = 60 * 60 * 1000;

export const useBbrUnit = (addressInput?: Partial<AddressInput>) => {
  const { graphQLClient } = useGraphQLClient();

  const address = toValidAddressInput(addressInput);

  const { data, isFetching } = useBbrLookupByAddressQuery(
    graphQLClient,
    { address: address! },
    {
      enabled: !!address,
      onError: useToastMessageErrorHandler(),
      staleTime: ONE_HOUR,
    }
  );

  const bbrUnit = deepNullToUndefined(data?.bbrLookupByAddress);

  return {
    bbrUnit: bbrUnit
      ? {
          ...bbrUnit,
          roofs: bbrUnit?.roofs?.map(convertRoofFromCentimetersToMeters),
        }
      : undefined,
    noBbrData: !isFetching && data?.bbrLookupByAddress === null,
    isFetching,
  };
};
