import { Card, Text } from '~src/components/display';
import { getHeatDistributionTypeOptions } from '~src/components/features/shared/residence/helpers/residenceHelper';
import { useLocalization } from '~src/hooks';
import { Residence } from '~src/types';
import { getPrimaryHeatingOptions, getSecondaryHeatingOptions } from '~src/utilities/heatingType';

import { informationColumn, questionRows } from './ResidenceInformation.module.css';

type ResidenceInformationProps = {
  residence?: Residence;
};

export const ResidenceInformation = ({ residence }: ResidenceInformationProps) => {
  const translate = useLocalization();

  const heatDistributionOptions = getHeatDistributionTypeOptions(translate);
  const heatingTypeOptions = [...getPrimaryHeatingOptions(translate), ...getSecondaryHeatingOptions(translate)];

  return (
    <Card title={translate.RESIDENCE_INFORMATION}>
      <div className={questionRows}>
        <div className={informationColumn}>
          <Text>{translate.NUMBER_OF_RESIDENTS}:</Text>
          <Text>{residence?.residents}</Text>

          <Text>{translate.NUMBER_OF_FLOORS}:</Text>
          <Text>{residence?.floors}</Text>

          <Text>{translate.BUILT_YEAR}:</Text>
          <Text>{residence?.builtYear}</Text>
        </div>

        <div className={informationColumn}>
          <Text>{translate.HEATED_LIVING_AREA}:</Text>
          <Text>{residence?.areaHeated}</Text>

          <Text>{translate.PARTIALLY_HEATED_AREA}:</Text>
          <Text>{residence?.areaPartiallyHeated}</Text>

          <Text>{translate.NOT_HEATED_AREA}:</Text>
          <Text>{residence?.areaNotHeated}</Text>
        </div>

        <div className={informationColumn}>
          <Text>{translate.YEARLY_ELECTRICITY_CONSUMPTION}:</Text>
          <Text>{residence?.energyExpenditure} kWh</Text>
        </div>

        <div className={informationColumn}>
          <Text>{translate.HEAT_DISTRIBUTION}:</Text>
          <Text>{heatDistributionOptions?.find(({ value }) => value === residence?.heatDistribution)?.label}</Text>
        </div>

        <div className={informationColumn}>
          <Text>{translate.PRIMARY_HEATING}:</Text>
          <Text>{heatingTypeOptions?.find(({ value }) => value === residence?.primaryHeating?.fuelType)?.label}</Text>

          <Text>{translate.SECONDARY_HEATING}:</Text>
          <Text>
            {heatingTypeOptions?.find(({ value }) => value === residence?.secondaryHeating?.fuelType)?.label ??
              translate.NONE}
          </Text>
        </div>
      </div>
    </Card>
  );
};
