import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { AutoComplete, Form, Input } from '~src/components/display';
import { findLeadOriginValue } from '~src/components/features/shared/customer/LeadOriginOptions';
import { useLocalization } from '~src/hooks';
import { useBodilAdvisors, useCRMCustomerFieldValues, useSearchCRMCustomers } from '~src/hooks/services';
import { CRMCustomer, mapCRMCustomerToCustomerInput, mapCustomerToCustomerInput } from '~src/types';

import { useCustomerSuggestions } from '../../hooks/useCustomerSuggestions';
import { useCreateOfferState } from '../../state/createOfferState';

import { dropdown } from '../components.module.css';
import { customerSearch } from './customer.module.css';

const SEARCH_DELAY = 500;

type CRMCustomerSearchProps = {
  toggleEditing: (arg: boolean) => void;
};

export const CRMCustomerSearch = ({ toggleEditing }: CRMCustomerSearchProps) => {
  const translate = useLocalization();

  const [emailInput, setEmailInput] = useState('');
  const [crmCustomerId, setCRMCustomerId] = useState<string | undefined>('');

  const { customer, reset, setCustomer, setCRMCustomerAddress } = useCreateOfferState();

  const { crmCustomerList } = useSearchCRMCustomers(emailInput);
  const { customerList, setValue } = useCustomerSuggestions();
  const { bodilAdvisors } = useBodilAdvisors();
  const { fields } = useCRMCustomerFieldValues(crmCustomerId);

  useEffect(
    function updateInformationWithCRMFields() {
      const { address, assignedAdvisor, financialAdvisor, leadOrigin, notes } = fields;

      if (address) {
        setCRMCustomerAddress(address);
      }

      if (!assignedAdvisor && !financialAdvisor && !leadOrigin && !notes) {
        return;
      }

      const assignedAdvisorId = tryMatchAssignedAdvisor(bodilAdvisors, assignedAdvisor);

      const shouldSkipUpdate =
        !customer ||
        ((!customer.assignedAdvisorId || customer.assignedAdvisorId === assignedAdvisorId) &&
          (!customer.financialAdvisor || customer.financialAdvisor === financialAdvisor) &&
          (!customer.leadOrigin || customer.leadOrigin === findLeadOriginValue(leadOrigin)) &&
          (!customer.notes || customer.notes === notes));
      if (shouldSkipUpdate) {
        return;
      }

      setCustomer({
        ...customer,
        assignedAdvisorId: assignedAdvisorId || customer?.assignedAdvisorId,
        financialAdvisor: financialAdvisor || customer?.financialAdvisor,
        leadOrigin: findLeadOriginValue(leadOrigin) || customer?.leadOrigin,
        notes: notes || customer?.notes,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bodilAdvisors, JSON.stringify(customer), fields]
  );

  useEffect(
    function useInternalCustomerIfPossible() {
      if (!customerList || !customer) {
        return;
      }

      const existingCustomer = customerList.find(({ email }) => email === customer.email);
      if (!existingCustomer || existingCustomer.id === customer.id) {
        return;
      }

      setCustomer(mapCustomerToCustomerInput(existingCustomer));
      toggleEditing(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(customer), customerList]
  );

  const updateEmailInput = debounce((email: string) => {
    setEmailInput(email);
    setValue(email);
  }, SEARCH_DELAY);

  const handleSelectCustomer = (selectedCustomer?: CRMCustomer) => {
    if (selectedCustomer) {
      reset();
      setEmailInput('');
      setValue('');
      setCustomer(mapCRMCustomerToCustomerInput(selectedCustomer));
      setCRMCustomerId(selectedCustomer.id);
    }
  };

  return (
    <Form.Item label={translate.SEARCH_FOR_CRM_CUSTOMER} className={customerSearch}>
      <AutoComplete
        onChange={(value: string) => {
          updateEmailInput(value);
        }}
        onSelect={value => {
          handleSelectCustomer(crmCustomerList?.find(customerItem => customerItem.email === value));
        }}
        options={crmCustomerList?.map(customerItem => ({ value: customerItem?.email }))}
        popupClassName={dropdown}
      >
        <Input.Search />
      </AutoComplete>
    </Form.Item>
  );
};

const tryMatchAssignedAdvisor = (
  bodilAdvisorList: ReturnType<typeof useBodilAdvisors>['bodilAdvisors'],
  crmAdvisorName?: string
) =>
  crmAdvisorName
    ? bodilAdvisorList?.find(({ firstName, lastName }) => {
        const bodilAdvisorName = `${firstName} ${lastName}`.toLowerCase();
        const crmAdvisorNameLower = crmAdvisorName?.toLowerCase() ?? '';

        return (
          bodilAdvisorName === crmAdvisorNameLower ||
          bodilAdvisorName.includes(crmAdvisorNameLower) ||
          crmAdvisorNameLower.includes(bodilAdvisorName)
        );
      })?.id
    : undefined;
