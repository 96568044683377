import { Heading } from '~src/components/display';
import { SolarSavingsGraph } from '~src/components/display/graphs/SolarSavingsGraph';
import { useLocalization } from '~src/hooks';
import { MaybeId, ResidenceInput } from '~src/types';

import { useElectricityPrice } from '../hooks/useElectricityPrice';
import { useSolarSavingGraph } from '../hooks/useSolarSavingsGraph';
import { SolarSavingsGraphInputWidget } from './SolarSavingsGraphInputWidget';

import { fullWidth, solarGraphContainer, solarGraphHeaderSection } from './solarProduct.module.css';

type SolarSavingsGraphWithInputProps = {
  residence?: ResidenceInput;
  setResidence?: (inputResidence?: ResidenceInput & MaybeId) => void;
  selectPackage?: (packageID: number) => void;
};

export const SolarSavingsGraphWithElectricityInput = ({
  residence,
  setResidence,
  selectPackage,
}: SolarSavingsGraphWithInputProps) => {
  const translate = useLocalization();

  const customerID = residence?.customerID;

  const { electricityPrice, updateElectricityPrice } = useElectricityPrice(setResidence, residence, customerID);

  const { data } = useSolarSavingGraph({
    residence,
    electricityPrice,
  });

  return (
    <div className={solarGraphContainer}>
      <SolarSavingsGraphInputWidget
        electricityPrice={electricityPrice}
        updateElectricityPrice={updateElectricityPrice}
      />

      <div className={solarGraphHeaderSection}>
        <Heading>{translate.SOLAR_PACKAGE_COMPARISON}</Heading>
      </div>

      <SolarSavingsGraph className={fullWidth} data={data} selectPackage={selectPackage} />
    </div>
  );
};
