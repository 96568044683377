import { Heading, Steps } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useLocation } from '~src/hooks/useLocation';

import { header } from './components.module.css';

export const CreateOfferHeader = () => {
  const translate = useLocalization();
  const { location } = useLocation();

  const { pathname } = location;

  const step = getStep(pathname);

  return (
    <header className={header}>
      <Heading level={3}>{translate.CREATE_OFFER}</Heading>

      <Steps
        current={step}
        items={[
          { title: translate.SELECT_CUSTOMER },
          { title: translate.SELECT_RESIDENCE },
          { title: translate.SELECT_PRODUCTS },
          { title: translate.CREATE_OFFER },
        ]}
      />
    </header>
  );
};

const getStep = (pathname: string) => {
  if (pathname.includes('residence')) {
    return 1;
  }

  if (pathname.includes('products')) {
    return 2;
  }

  if (pathname.includes('offers')) {
    return 3;
  }

  return 0;
};
