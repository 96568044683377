import noop from 'lodash/noop';
import throttle from 'lodash/throttle';
import { useEffect, useRef } from 'react';

const INTERVAL_60_FPS = 1000 / 60;

export const useAutoResize = () => {
  const autoResizeRef = useRef<HTMLTextAreaElement>(null);

  useEffect(function addAutoResizeListener() {
    if (!autoResizeRef?.current) {
      return noop;
    }

    const autoResizeListener = throttle(() => {
      if (!autoResizeRef?.current) {
        return;
      }

      autoResizeRef.current.style.height = '0px';
      autoResizeRef.current.style.height = `${autoResizeRef?.current.scrollHeight}px`;
    }, INTERVAL_60_FPS);

    // fire once on init
    autoResizeListener();

    globalThis?.window?.addEventListener('resize', autoResizeListener, { passive: true });
    return () => globalThis?.window?.removeEventListener('resize', autoResizeListener);
  }, []);

  return autoResizeRef;
};
