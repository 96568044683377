import { QueryClient } from '@tanstack/react-query';

import {
  RoofType,
  useDeleteResidenceImageMutation,
  useEnergyOptionsQuery,
  useOfferBySiteVisitCodeQuery,
  useResidenceListQuery,
  useResidenceQuery,
  useSetResidenceRoofTypeMutation,
  useUploadResidenceImagesMutation,
  useUpsertResidenceMutation,
} from '~src/gql';
import { useToastMessage, useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient, useQueryClient } from '~src/services/client';
import { MaybeId, Residence, ResidenceInput } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';
import { convertRoofsFromCentimetersToMeters } from '~src/utilities/roof';

import { useLocalization } from '../useLocalization';
import { convertResidenceUnitsAndApplyFallbackValues } from './serviceHelper';

export const useResidence = (residenceId?: number) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useResidenceQuery(
    graphQLClient,
    { residenceId },
    {
      enabled: !!residenceId,
      onError: useToastMessageErrorHandler(),
      staleTime: 0,
    }
  );

  return {
    residence: data?.residence
      ? {
          ...deepNullToUndefined(data.residence),
          roofs: convertRoofsFromCentimetersToMeters(deepNullToUndefined(data.residence.roofs)),
        }
      : undefined,
    isLoading: isFetching,
  };
};

export const useResidenceList = (customerId?: number) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useResidenceListQuery(
    graphQLClient,
    { customerId },
    {
      enabled: !!customerId,
      onError: useToastMessageErrorHandler(),
      staleTime: 0,
    }
  );

  return { residenceList: data?.residences?.map(deepNullToUndefined) ?? [], isLoading: isFetching };
};

export const useUploadResidenceImages = (residenceId?: number, code?: string) => {
  const translate = useLocalization();
  const onError = useToastMessageErrorHandler();
  const { showMessage } = useToastMessage();
  const { graphQLClient } = useGraphQLClient();

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useUploadResidenceImagesMutation(graphQLClient, undefined, {
    'apollo-require-preflight': 'true',
  });

  const uploadImages = async (images: File[], category?: string) => {
    if (!residenceId) {
      return [];
    }

    const response = await mutateAsync(
      { category, images, residenceId, code },
      {
        onError,
        onSuccess: async ({ uploadResidenceImages }) => {
          showMessage({ type: 'success', message: translate.SAVED });

          await updateResidenceCache(
            queryClient,
            residenceId,
            oldResidence => {
              if (!oldResidence) {
                return undefined;
              }

              return {
                ...oldResidence,
                images: deepNullToUndefined([...oldResidence.images, ...uploadResidenceImages]),
              };
            },
            code
          );
        },
      }
    );

    return response.uploadResidenceImages;
  };

  return { uploadResidenceImages: uploadImages, isLoading };
};

export const useDeleteResidenceImage = (residenceId?: number, code?: string) => {
  const { graphQLClient } = useGraphQLClient();
  const translate = useLocalization();
  const onError = useToastMessageErrorHandler();
  const { showMessage } = useToastMessage();

  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useDeleteResidenceImageMutation(graphQLClient);

  const deleteResidenceImage = async (imageUrl?: string) => {
    if (!residenceId || !imageUrl) {
      onError('Could not delete image, residence or image not found');
      return [];
    }

    const response = await mutateAsync(
      { residenceId, imageUrl },
      {
        onError,
        onSuccess: async () => {
          showMessage({ type: 'success', message: translate.SAVED });

          await updateResidenceCache(
            queryClient,
            residenceId,
            oldResidence => {
              if (!oldResidence) {
                return undefined;
              }

              return {
                ...oldResidence,
                images: deepNullToUndefined([...oldResidence.images.filter(({ url }) => url !== imageUrl)]),
              };
            },
            code
          );
        },
      }
    );

    return response.deleteResidenceImage;
  };

  return { deleteResidenceImage, isLoading };
};

export const useUpsertResidence = () => {
  const showError = useToastMessageErrorHandler();
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useUpsertResidenceMutation(graphQLClient, {
    onMutate: async ({ residence, residenceId }) => {
      if (!residenceId) {
        return residence;
      }

      await updateResidenceCache(queryClient, residenceId, oldResidence =>
        deepNullToUndefined({ ...oldResidence, ...residence })
      );

      return residence;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: useEnergyOptionsQuery.getKey() });
    },
  });

  const upsertResidenceAsync = async (customerId: number, residenceInput: ResidenceInput & MaybeId) => {
    const { id: residenceId, ...input } = residenceInput;

    const residence = convertResidenceUnitsAndApplyFallbackValues(input);

    const { upsertResidence } = await mutateAsync(
      { customerId, residence, residenceId },
      {
        onError: error => {
          showError(error);
          return queryClient.invalidateQueries({ queryKey: useResidenceQuery.getKey({ residenceId }) });
        },
        onSuccess: () => queryClient.invalidateQueries({ queryKey: useResidenceListQuery.getKey() }),
      }
    );

    return upsertResidence.id;
  };

  return { upsertResidence: upsertResidenceAsync, isLoading };
};

export const updateResidenceCache = async (
  queryClient: QueryClient,
  residenceId: number,
  residenceUpdater: (residence?: Residence) => Residence | undefined,
  code?: string
) => {
  if (code) {
    return updateOfferBySiteVisitCodeResidenceCache(queryClient, code, residenceUpdater);
  }

  const queryKey = useResidenceQuery.getKey({ residenceId });

  await queryClient.cancelQueries({ queryKey });

  const { residence } = queryClient.getQueryData<{ residence: Residence }>(queryKey) ?? {};

  const updatedResidence = residenceUpdater(residence);

  if (updatedResidence) {
    queryClient.setQueryData(queryKey, { residence: updatedResidence });
  }

  return updatedResidence;
};

const updateOfferBySiteVisitCodeResidenceCache = async (
  queryClient: QueryClient,
  code: string,
  residenceUpdater: (residence?: Residence) => Residence | undefined
) => {
  const queryKey = useOfferBySiteVisitCodeQuery.getKey({ code });

  await queryClient.cancelQueries({ queryKey });

  const { offerByCode } = queryClient.getQueryData<{ offerByCode: { residence: Residence } }>(queryKey) ?? {};

  const updatedResidence = residenceUpdater(offerByCode?.residence);

  if (updatedResidence) {
    queryClient.setQueryData(queryKey, { offerByCode: { ...offerByCode, residence: updatedResidence } });
  }

  return updatedResidence;
};

export const useSetResidenceRoofType = () => {
  const showError = useToastMessageErrorHandler();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useSetResidenceRoofTypeMutation(graphQLClient);

  const setRoofTypeAsync = async (residenceId: number, roofType: string) => {
    const { setResidenceRoofType } = await mutateAsync(
      { roofType: roofType as RoofType, residenceId },
      { onError: error => showError(error) }
    );

    return setResidenceRoofType;
  };

  return { setRoofType: setRoofTypeAsync, isLoading };
};
