import { AutoComplete, Button, Divider, Form, Heading, Input } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useNavigation } from '~src/hooks/useNavigation';
import { mapCustomerToCustomerInput } from '~src/types';

import { useCustomerSuggestions } from '../../hooks/useCustomerSuggestions';
import { useCreateOfferState } from '../../state/createOfferState';

import { dropdown } from '../components.module.css';
import { customerSearch } from './customer.module.css';

type CustomerSearchProps = {
  toggleEditing: (arg: boolean) => void;
};

export const CustomerSearch = ({ toggleEditing }: CustomerSearchProps) => {
  const translate = useLocalization();

  const { navigateRaw } = useNavigation();

  const { reset, setCustomer } = useCreateOfferState();
  const { customerList, suggestions, setValue, value } = useCustomerSuggestions();

  const handleCreateNewCustomer = () => {
    navigateRaw(`/create-offer/customer`, { replace: true });
    toggleEditing(true);
    reset();
  };

  const handleCustomerSelect = (customerId: string) => {
    const selectedCustomer = customerList?.find(({ id }) => id === Number(customerId));

    if (!selectedCustomer) {
      return;
    }

    setCustomer(mapCustomerToCustomerInput(selectedCustomer));
    toggleEditing(false);
    setValue('');

    navigateRaw(`/create-offer/customer/${selectedCustomer.id}`, { replace: true });
  };

  return (
    <div className={customerSearch}>
      <Form.Item label={translate.SEARCH_FOR_CUSTOMER}>
        <AutoComplete<string>
          options={suggestions}
          onChange={setValue}
          onSelect={handleCustomerSelect}
          popupClassName={dropdown}
          value={value}
        >
          <Input.Search />
        </AutoComplete>
      </Form.Item>

      <Divider orientation='center'>
        <Heading>{translate.OR}</Heading>
      </Divider>

      <span>
        <Button type='primary' onClick={handleCreateNewCustomer}>
          {translate.CREATE_NEW_CUSTOMER}
        </Button>
      </span>
    </div>
  );
};
