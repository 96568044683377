import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideFloorPlan = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.FLOOR_PLAN}
      </Heading>

      <div className={informationColumn}>
        {!!answerMap?.floorPlanNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.floorPlanNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
