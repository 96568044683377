import { GraphQLClient } from 'graphql-request';
import { useMemo } from 'react';

import { QueryClient } from '@tanstack/react-query';

import { API_URL } from '~src/config';
import { getCookie } from '~src/utilities/cookies';

export { QueryClientProvider, useQuery, useQueryClient } from '@tanstack/react-query';

const ONE_MINUTE = 60 * 1000;

const GRAPHQL_URL = `${API_URL}/graphql/v1`;

export const getBearerTokenAuthHeader = (token?: string) => ({ Authorization: `Bearer ${token}` });

export const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: ONE_MINUTE, retry: 1, refetchOnWindowFocus: false } },
});

const client = new GraphQLClient(GRAPHQL_URL, { keepalive: true });

export const useGraphQLClient = () => {
  const accessToken = getCookie('accessToken');

  const graphQLClient = useMemo(() => {
    const headers = getBearerTokenAuthHeader(accessToken);

    client.setHeaders(headers);

    return client;
  }, [accessToken]);

  return { graphQLClient };
};

export const FETCH_OPTIONS: RequestInit = { headers: { Connection: 'keep-alive' } };
