import round from 'lodash/round';

import { InfoBox } from '~src/components/display/InfoBox';
import { useLocalization } from '~src/hooks';
import { ResidenceInput } from '~src/types';
import { aggregateHeatingOutput, calculateNominalHeatingRequirements } from '~src/utilities/calculationHelper';
import { DEGREE_DAYS_WEIGTED, MINUS_12_TO_MINUS_7_RATIO } from '~src/utilities/constants';

import { getCorrectedHeatRequirement } from './heatEffectUtility';

import { horizontalBoxLayout } from './heatEffect.module.css';

type RecommendedHeatEffectProps = {
  residence?: ResidenceInput;
  temperature?: -7 | -12;
};

export const RecommendedHeatEffect = ({ residence, temperature }: RecommendedHeatEffectProps) => {
  const translate = useLocalization();

  const multiplier = temperature === -7 ? MINUS_12_TO_MINUS_7_RATIO : 1;

  const { byHeuristic, byLabel } = residence
    ? calculateNominalHeatingRequirements(residence)
    : { byHeuristic: 0, byLabel: 0 };

  const { primaryHeating, secondaryHeating, areaNotHeated, area } = residence ?? { area: 0 };

  const heuristic = round(byHeuristic * multiplier, 2);
  const label = round(byLabel * multiplier, 2);

  const primary = aggregateHeatingOutput([primaryHeating]) / DEGREE_DAYS_WEIGTED;
  const secondary = aggregateHeatingOutput([primaryHeating, secondaryHeating]) / DEGREE_DAYS_WEIGTED;

  const primaryOnly = round(primary * multiplier, 2);
  const primaryAndSecondary = round(secondary * multiplier, 2);

  const correctedHeatRequirement = getCorrectedHeatRequirement(multiplier, primary, area, areaNotHeated);

  return (
    <div className={horizontalBoxLayout}>
      <InfoBox title={translate.CURRENT_PRIMARY_USAGE} value={`${primaryOnly} kW`} />

      <InfoBox title={translate.CURRENT_PRIMARY_AND_SECONDARY_USAGE} value={`${primaryAndSecondary} kW`} />

      <InfoBox title={translate.CORRECTED_POTENTIAL_USAGE} value={correctedHeatRequirement} />

      <InfoBox title={translate.STATISTIC_ACCORDING_TO_ENERGY_LABEL} value={`${label} kW`} />

      <InfoBox title={translate.STATISTIC_ACCORDING_TO_BUILT_YEAR} value={`${heuristic} kW`} />
    </div>
  );
};
