import { Button, Card, Form } from '~src/components/display';
import { ResidenceForm } from '~src/components/features/shared/residence';
import { useLocalization } from '~src/hooks';
import { useUpsertResidence } from '~src/hooks/services/useResidence';
import { useNavigation } from '~src/hooks/useNavigation';
import { useToastMessage } from '~src/hooks/useToastMessage';
import { ResidenceInput } from '~src/types';

import { AddressSearch } from '../components/address/AddressSearch';
import { CreateOfferFooter } from '../components/CreateOfferFooter';
import { useInitializeCreateOfferStateFromParameters } from '../hooks/useInitializeCreateOfferStateFromParameters';
import { useInitializeCustomerFromSearchParameter } from '../hooks/useInitializeCustomerFromSearchParameter';
import { useSynchronizeStatefulCustomerNotes } from '../hooks/useSynchronizeStatefulCustomerNotes';
import { useCreateOfferState } from '../state/createOfferState';

import { content } from '../createOffer.module.css';

export const CreateOfferResidence = () => {
  const translate = useLocalization();
  const { showMessage } = useToastMessage();
  const [form] = Form.useForm<ResidenceInput>();

  const { navigate, navigateRaw } = useNavigation();

  const { customer, residence, setResidence } = useCreateOfferState();
  useInitializeCreateOfferStateFromParameters();
  useInitializeCustomerFromSearchParameter();
  useSynchronizeStatefulCustomerNotes();

  const { upsertResidence, isLoading } = useUpsertResidence();

  const handleBackClick = () => {
    navigateRaw(`/create-offer/customer/${customer?.id}`);
  };

  const handleFormSubmit = async (newResidence: ResidenceInput) => {
    if (!customer?.id) {
      showMessage({ type: 'warning', message: 'Cannot create residence. No customer selected.' });
      return;
    }

    const updatedResidence = {
      ...residence,
      ...newResidence,
    };

    const residenceId = await upsertResidence(customer?.id, updatedResidence);
    if (!residenceId) {
      showMessage({ type: 'error', message: 'Could not create residence.' });
      return;
    }

    setResidence({
      ...newResidence,
      address: updatedResidence.address,
      roofs: updatedResidence.roofs,
      id: residenceId,
    });

    // Replace browser history
    navigateRaw(`/create-offer/residence/${residenceId}`, { replace: true });

    navigate('createOfferProducts');
  };

  return (
    <>
      <Card className={content}>
        <AddressSearch />

        <ResidenceForm customerId={customer?.id} form={form} onSubmit={handleFormSubmit} residence={residence} />
      </Card>

      <CreateOfferFooter>
        <Button onClick={handleBackClick}>{translate.BACK}</Button>

        <Button disabled={isLoading} onClick={form.submit} type='primary'>
          {residence?.id ? translate.CONTINUE : translate.CREATE_RESIDENCE}
        </Button>
      </CreateOfferFooter>
    </>
  );
};
