import { ReactNode } from 'react';

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

// ErrorFallback with zero dependencies to avoid infinite error fallback cycle
const ErrorFallback = () => (
  <div
    style={{
      display: 'grid',
      gap: '1rem',
      minHeight: '100vh',
      placeContent: 'center',
      textAlign: 'center',
      width: '100%',
    }}
  >
    <h1>Bodil Rådgiverapp</h1>

    <p>An unexpected error has occurred. We apologize for the inconvenience.</p>

    <a href='/'>Try reloading the application.</a>
  </div>
);

export const ErrorBoundary = ({ children }: { children: ReactNode }) => (
  <SentryErrorBoundary fallback={<ErrorFallback />}>{children}</SentryErrorBoundary>
);
