import { Suspense } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import {
  CreateOfferCustomerSubPage,
  CreateOfferOffersSubPage,
  CreateOfferProductsSubPage,
  CreateOfferResidenceSubPage,
} from '~src/components/features/createOffer';
import { useRefreshLoginIfNecessary } from '~src/hooks/useRefreshLoginIfNecessary';
import { routes } from '~src/routes';

import { ErrorBoundary } from '../ErrorBoundary';
import {
  CreateOfferPage,
  CustomerPage,
  InstallationGuidePage,
  InstallerSiteVisitPage,
  LandingPage,
  NotFoundPage,
  OfferPage,
  OverviewPage,
  ProtectedRoute,
  SiteVisitPage,
} from './Pages';

const ErrorBoundaryWrapper = () => (
  <ErrorBoundary>
    <Outlet />
  </ErrorBoundary>
);

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryWrapper />,
    children: [
      {
        path: routes.root,
        element: <ProtectedRoute element={<LandingPage />} />,
      },

      {
        path: routes.createOffer,
        element: <ProtectedRoute element={<CreateOfferPage />} />,
        children: [
          { index: true, element: <CreateOfferCustomerSubPage /> },
          { path: `customer`, element: <CreateOfferCustomerSubPage /> },
          { path: `customer/:customerId`, element: <CreateOfferCustomerSubPage /> },
          { path: `residence`, element: <CreateOfferResidenceSubPage /> },
          { path: `residence/:residenceId`, element: <CreateOfferResidenceSubPage /> },
          { path: `products`, element: <CreateOfferProductsSubPage /> },
          { path: `offers`, element: <CreateOfferOffersSubPage /> },
        ],
      },

      {
        path: routes.customer,
        element: <ProtectedRoute element={<CustomerPage />} />,
      },
      {
        path: `${routes.customer}/:customerId`,
        element: <ProtectedRoute element={<CustomerPage />} />,
      },

      {
        path: routes.offer,
        element: <ProtectedRoute element={<OfferPage />} />,
      },
      {
        path: `${routes.offer}/:offerId`,
        element: <ProtectedRoute element={<OfferPage />} />,
      },

      {
        path: routes.overview,
        element: <ProtectedRoute element={<OverviewPage />} />,
      },

      {
        path: routes.siteVisit,
        element: <ProtectedRoute element={<SiteVisitPage />} />,
      },
      {
        path: `${routes.siteVisit}/:offerTag`,
        element: <ProtectedRoute element={<SiteVisitPage />} />,
      },
      {
        path: `${routes.installerSiteVisit}/:accessCode`,
        element: <InstallerSiteVisitPage />,
      },
      {
        path: `${routes.installationGuide}/:accessCode`,
        element: <InstallationGuidePage />,
      },

      { path: '*', element: <ProtectedRoute element={<NotFoundPage />} /> },
    ],
  },
]);

export const Router = () => {
  // periodically reauthenticates user to ensure API calls do not fail if you stay on the page until the token expires
  useRefreshLoginIfNecessary();

  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
};
