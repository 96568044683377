import { useBatteriesQuery } from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient } from '~src/services/client';
import { deepNullToUndefined } from '~src/utilities/convert';

export const useBatteries = (ids?: number[]) => {
  const { graphQLClient } = useGraphQLClient();
  const { data, isFetching } = useBatteriesQuery(graphQLClient, { ids }, { onError: useToastMessageErrorHandler() });

  return { batteries: data?.batteries?.map(deepNullToUndefined) ?? [], isLoading: isFetching };
};
