import { Tooltip } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { Address } from '~src/types';
import { formatAddress } from '~src/utilities/format';

const SKRAAFOTO_URL = 'https://skraafoto.dataforsyningen.dk';

type SkraafotoLinkProps = {
  address: Address;
};

export const SkraafotoLink = ({ address }: SkraafotoLinkProps) => {
  const translate = useLocalization();

  return (
    <Tooltip title={translate.NAVIGATE_TO_SKRAAFOTO_AND_COPY_ADDRESS} placement='topLeft'>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <a onClick={() => navigateToSkraafoto(address)}>Se {formatAddress(address)} på kort</a>
    </Tooltip>
  );
};

const navigateToSkraafoto = async (address?: Address) => {
  if (address) {
    await navigator.clipboard.writeText(formatAddress(address));
  }

  if (!window) {
    return;
  }

  window.open(SKRAAFOTO_URL, '_blank', 'noopener noreferrer');
};
