import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideHeatSource = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.SITE_VISIT_SECTION_FURNACE}
      </Heading>

      <div className={informationColumn}>
        {answerMap?.canFurnaceBeDismantled?.answer === 'false' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_FURNACE_DISMANTLE} value={translate.NO} />
        )}

        {!!answerMap?.heatSourceNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.heatSourceNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
