import { useState } from 'react';

import { API_URL } from '~src/config';
import { useDownloadFile } from '~src/hooks';
import { Offer, OfferPdfTypeEnum } from '~src/types';

export const useDownloadOffersPdfs = () => {
  const [pdfDownloadUrl, setPdfDownloadUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const { downloadFile, isLoading } = useDownloadFile(fileName, pdfDownloadUrl);

  const downloadOffersPdfs = (offers: Required<Pick<Offer, 'id' | 'tag'>>[], type: OfferPdfTypeEnum) => {
    const offerIDs = offers.map(({ id }) => id).join(',');

    setPdfDownloadUrl(`${API_URL}/rest/v1/offers-pdfs?offerIDs=${offerIDs}&type=${type}`);
    setFileName(`Tilbud-(${offers.map(({ tag }) => tag).join(',')}).zip`);

    downloadFile();
  };

  return { downloadOffersPdfs, isLoading };
};
