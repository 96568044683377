import { SHOW_PROFIT_MARGINS } from '~src/config';
import { useLocalization } from '~src/hooks';
import { OfferProducts } from '~src/types';
import { formatCurrency } from '~src/utilities/calculationHelper';

import { InfoBox } from '../InfoBox';
import { calculateOfferMargins } from './margin';

import { offerProfitMargins } from './offerProfitMargins.module.css';

const showProfitMargins = SHOW_PROFIT_MARGINS === 'true';

type OfferProfitMarginsProps = { offer?: OfferProducts };

export const OfferProfitMargins = ({ offer }: OfferProfitMarginsProps) => {
  const translate = useLocalization();

  const { margin, marginPercentage, discountedMargin, discountedMarginPercentage } = calculateOfferMargins(offer);

  if (!showProfitMargins) {
    return null;
  }

  return (
    <div className={offerProfitMargins}>
      {offer && <InfoBox title={translate.MARGIN} value={formatCurrency(margin)} subtitle={`(${marginPercentage}%)`} />}

      {offer && (
        <InfoBox
          title={translate.DISCOUNTED_MARGIN}
          value={formatCurrency(discountedMargin)}
          subtitle={`(${discountedMarginPercentage}%)`}
        />
      )}
    </div>
  );
};
