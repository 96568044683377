import { Heading, ImageThumbnail, Input, Radio, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionFloorPlanSolar = ({
  answerMap,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        5. {translate.FLOOR_PLAN}
      </Heading>

      <div className={questionRows}>
        <Text>{translate.SITE_VISIT_QUESTION_FLOOR_PLAN_ELECTRICITY_PANEL}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('installationOnFloorPlan', event?.target?.value);
          }}
          value={answerMap?.installationOnFloorPlan?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{translate.SITE_VISIT_QUESTION_SOLAR_INVERTER_BATTERY_ON_FLOOR_PLAN}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('inverterAndBatteryOnFloorPlan', event?.target?.value);
          }}
          value={answerMap?.inverterAndBatteryOnFloorPlan?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{translate.SITE_VISIT_QUESTION_SOLAR_ON_FLOOR_PLAN}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('solarOnFloorPlan', event?.target?.value);
          }}
          value={answerMap?.solarOnFloorPlan?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{translate.SITE_VISIT_QUESTION_SOLAR_CABLES_ON_FLOOR_PLAN}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('solarCableOnFloorPlan', event?.target?.value);
          }}
          value={answerMap?.solarCableOnFloorPlan?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('floorPlanNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.floorPlanNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.floorPlan)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
