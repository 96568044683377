/**
 * Never call services directly from components. Always use hooks with react-query wrappers to enable
 * error handling, caching, and loading state.
 *
 * In this instance: src/hooks/useDownloadFile.ts
 */

import { getCookie } from '~src/utilities/cookies';

import { getBearerTokenAuthHeader } from './client';

export const fetchDataAsBlob = async (dataUrl: string): Promise<Blob | undefined> => {
  if (!dataUrl) {
    return undefined;
  }

  const response = await fetch(dataUrl, {
    method: 'GET',
    headers: { ...getBearerTokenAuthHeader(getCookie('accessToken')), responseType: 'blob' },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.blob();
};
