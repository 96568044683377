import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';

import { Card, Divider } from '~src/components/display';
import { UPDATE_DELAY } from '~src/config';
import { useLocalization, useLocation } from '~src/hooks';
import { useSiteVisit, useUpdateSiteVisit, useUploadResidenceImages } from '~src/hooks/services';
import { Image, Offer, OfferType, Residence, SiteVisitAnswer, SiteVisitQuestion } from '~src/types';
import { getPrimaryHeatingOptions, getSecondaryHeatingOptions } from '~src/utilities/heatingType';

import { QuestionSectionControl } from './QuestionSectionControl';
import { QuestionSectionElectricity } from './QuestionSectionElectricity';
import { QuestionSectionElectricitySolar } from './QuestionSectionElectricitySolar';
import { QuestionSectionFloorPlan } from './QuestionSectionFloorPlan';
import { QuestionSectionFloorPlanSolar } from './QuestionSectionFloorPlanSolar';
import { QuestionSectionHeatSource } from './QuestionSectionHeatSource';
import { QuestionSectionIndoor } from './QuestionSectionIndoor';
import { QuestionSectionInverterBattery } from './QuestionSectionInverterBattery';
import { QuestionSectionOutdoor } from './QuestionSectionOutdoor';
import { QuestionSectionPiping } from './QuestionSectionPiping';
import { QuestionSectionRadiators } from './QuestionSectionRadiators';
import { QuestionSectionRoof } from './QuestionSectionRoof';
import { QuestionSectionSolar } from './QuestionSectionSolar';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { questionSection } from './QuestionInformation.module.css';

type QuestionInformationProps = {
  offer?: Pick<Offer, 'heatPumpOutdoorUnit'>;
  residence?: Residence;
};

export const QuestionInformation = ({ offer, residence }: QuestionInformationProps) => {
  const translate = useLocalization();

  const { parameters } = useLocation();

  const { accessCode } = parameters;

  const { id: residenceId, images } = residence ?? {};

  const { siteVisitChecklist, isLoading: isLoadingChecklist } = useSiteVisit(residence?.id);
  const { updateSiteVisitAnswerList, isLoading: isUpdatingAnswers } = useUpdateSiteVisit(residenceId ?? 0);
  const { uploadResidenceImages, isLoading: isUploadingImages } = useUploadResidenceImages(residenceId, accessCode);

  const heatSourceOptions = [...getPrimaryHeatingOptions(translate), ...getSecondaryHeatingOptions(translate)];
  const secondaryHeatSource = heatSourceOptions.find(
    ({ value }) => value === residence?.secondaryHeating?.fuelType
  )?.label;

  const handleUploadImages = (newImages: File[], category?: string) => uploadResidenceImages(newImages, category);

  const handleUpdate = debounce(
    async (question: SiteVisitQuestion, answer: string) => {
      if (!residenceId) {
        return;
      }

      await updateSiteVisitAnswerList([{ answer, residenceId, question }]);
    },
    Number(UPDATE_DELAY),
    { leading: true }
  );

  const answerMap = keyBy(siteVisitChecklist, 'question') as Record<SiteVisitQuestion, SiteVisitAnswer>;

  const imageMap = groupBy(images, ({ category }) => category ?? 'other') as Record<SiteVisitSections, Image[]>;

  const offerType: OfferType = offer?.heatPumpOutdoorUnit ? 'heatPump' : 'solar';

  const isLoading = isLoadingChecklist || isUpdatingAnswers || isUploadingImages;

  if (isLoadingChecklist) {
    return null;
  }

  return (
    <Card className={questionSection} title={translate.SITE_VISIT}>
      {offerType === 'heatPump' && (
        <>
          <QuestionSectionOutdoor
            answerMap={answerMap}
            heatPumpOutdoorUnit={offer?.heatPumpOutdoorUnit}
            imageList={imageMap.outdoorPlacement}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionControl
            answerMap={answerMap}
            isLoading={isLoading}
            residence={residence}
            secondaryHeatSource={secondaryHeatSource}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionRadiators
            answerMap={answerMap}
            imageList={imageMap.radiators}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionHeatSource
            answerMap={answerMap}
            imageList={imageMap.heatSource}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionPiping
            answerMap={answerMap}
            heatingIsMix={residence?.heatDistribution === 'radiatorAndFloor'}
            imageList={imageMap.piping}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionIndoor
            answerMap={answerMap}
            imageList={imageMap.indoorPlacement}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionElectricity
            answerMap={answerMap}
            imageList={imageMap.electricity}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionFloorPlan
            imageList={imageMap.floorPlan}
            answerMap={answerMap}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
        </>
      )}

      {offerType === 'solar' && (
        <>
          <QuestionSectionSolar
            imageList={imageMap.solarPlacement}
            answerMap={answerMap}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionInverterBattery
            answerMap={answerMap}
            imageList={imageMap.inverterAndBatteryPlacement}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionRoof
            answerMap={answerMap}
            imageList={imageMap.roofConstruction}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
            residence={residence}
          />
          <Divider fullWidth />

          <QuestionSectionElectricitySolar
            answerMap={answerMap}
            imageList={imageMap.electricity}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
          <Divider fullWidth />

          <QuestionSectionFloorPlanSolar
            answerMap={answerMap}
            imageList={imageMap.floorPlan}
            isLoading={isLoading}
            updateAnswer={handleUpdate}
            uploadImages={handleUploadImages}
            isUploadingImages={isUploadingImages}
          />
        </>
      )}
    </Card>
  );
};
