import keyBy from 'lodash/keyBy';

import {
  SiteVisitAnswerInput,
  SiteVisitFile,
  useDeleteSiteVisitFileMutation,
  useSiteVisitAnswerListQuery,
  useSiteVisitFilesQuery,
  useUpdateSiteVisitAnswerListMutation,
  useUploadSiteVisitFileMutation,
} from '~src/gql';
import { useGraphQLClient, useQueryClient } from '~src/services/client';
import { SiteVisitAnswer } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useLocalization } from '../useLocalization';
import { useToastMessage, useToastMessageErrorHandler } from '../useToastMessage';

export const useSiteVisit = (residenceId?: number) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useSiteVisitAnswerListQuery(
    graphQLClient,
    { residenceId: residenceId ?? 0 },
    { enabled: !!residenceId, onError: useToastMessageErrorHandler() }
  );

  return { siteVisitChecklist: data?.siteVisitAnswerList?.map(deepNullToUndefined), isLoading: isFetching };
};

export const useUpdateSiteVisit = (residenceId: number, code?: string) => {
  const translate = useLocalization();
  const queryClient = useQueryClient();
  const showError = useToastMessageErrorHandler();
  const { showMessage } = useToastMessage();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useUpdateSiteVisitAnswerListMutation(graphQLClient, {
    onMutate: async ({ answerList }) => {
      await queryClient.cancelQueries({ queryKey: useSiteVisitAnswerListQuery.getKey({ residenceId }) });

      const { siteVisitAnswerList } =
        queryClient.getQueryData<{ siteVisitAnswerList: SiteVisitAnswer[] }>(
          useSiteVisitAnswerListQuery.getKey({ residenceId })
        ) ?? {};

      const newAnswerMap = keyBy(answerList, 'question');
      const oldAnswerMap = keyBy(siteVisitAnswerList, 'question');

      const updatedSiteVisitAnswerList = Object.values({ ...oldAnswerMap, ...newAnswerMap });

      queryClient.setQueryData(useSiteVisitAnswerListQuery.getKey({ residenceId }), {
        siteVisitAnswerList: updatedSiteVisitAnswerList,
      });

      return updatedSiteVisitAnswerList;
    },
  });

  const updateSiteVisitAnswerList = async (answerList: SiteVisitAnswerInput[]) => {
    const response = await mutateAsync(
      { answerList, code },
      {
        onSuccess: () => {
          showMessage({ type: 'success', message: translate.SAVED });
        },
        onError: error => {
          showError(error);
          return queryClient.invalidateQueries({
            queryKey: useSiteVisitAnswerListQuery.getKey({ residenceId }),
          });
        },
      }
    );

    return response?.upsertSiteVisitAnswerList;
  };

  return { isLoading, updateSiteVisitAnswerList };
};

export const useSiteVisitFiles = (offerID?: number) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useSiteVisitFilesQuery(
    graphQLClient,
    { offerID: offerID ?? 0 },
    { enabled: !!offerID, onError: useToastMessageErrorHandler() }
  );

  return { siteVisitFiles: deepNullToUndefined(data?.siteVisitFiles) ?? [], isFetching };
};

export const useUploadSiteVisitFile = (offerID: number) => {
  const showError = useToastMessageErrorHandler();

  const { graphQLClient } = useGraphQLClient();

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useUploadSiteVisitFileMutation(graphQLClient, undefined, {
    'apollo-require-preflight': 'true',
  });

  const uploadSiteVisitFile = async (file: File) => {
    const response = await mutateAsync(
      { offerID, file },
      {
        onSuccess: async result => {
          const queryKey = useSiteVisitFilesQuery.getKey({ offerID });

          await queryClient.cancelQueries({ queryKey });

          const { siteVisitFiles } = queryClient.getQueryData<{ siteVisitFiles: SiteVisitFile[] }>(queryKey) ?? {};

          queryClient.setQueryData(queryKey, {
            siteVisitFiles: [...(siteVisitFiles ?? []), { name: file.name, url: result.uploadSiteVisitFile }],
          });
        },
        onError: showError,
      }
    );

    return response.uploadSiteVisitFile;
  };

  return { uploadSiteVisitFile, isLoading };
};

export const useDeleteSiteVisitFiles = (offerID: number) => {
  const showError = useToastMessageErrorHandler();

  const { graphQLClient } = useGraphQLClient();

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useDeleteSiteVisitFileMutation(graphQLClient);

  const deleteSiteVisitFile = async (fileID: number) => {
    const response = await mutateAsync(
      { id: fileID },
      {
        onSuccess: async () => {
          const queryKey = useSiteVisitFilesQuery.getKey({ offerID });

          await queryClient.cancelQueries({ queryKey });

          const { siteVisitFiles } = queryClient.getQueryData<{ siteVisitFiles: SiteVisitFile[] }>(queryKey) ?? {};

          queryClient.setQueryData(queryKey, { siteVisitFiles: siteVisitFiles?.filter(({ id }) => id !== fileID) });
        },
        onError: showError,
      }
    );

    return response.deleteSiteVisitFile;
  };

  return { deleteSiteVisitFile, isLoading };
};
