import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideRadiators = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.SITE_VISIT_SECTION_RADIATOR}
      </Heading>

      <div className={informationColumn}>
        {answerMap?.systemStringNumber?.answer === '1' && answerMap?.bypassValves?.answer === 'false' && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_HEAT_SYSTEM_BYPASS_VALVES}
            value={translate.NO}
          />
        )}

        {!!answerMap?.radiatorPipingSize?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_PIPE_DIMENSIONS}
            value={answerMap.radiatorPipingSize.answer}
          />
        )}

        {answerMap?.replaceRadiators?.answer === 'true' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_REPLACE_RADIATORS} value={translate.YES} />
        )}

        {answerMap?.replaceFurnaceWithRadiator?.answer === 'true' && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_REPLACE_FURNACE_WITH_RADIATOR}
            value={translate.YES}
          />
        )}

        {!!answerMap?.radiatorsNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.radiatorsNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
