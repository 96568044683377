import { Popconfirm } from '~src/components/display';
import { DeleteOutlined, ZoomInOutlined, ZoomOutOutlined } from '~src/components/display/Icons';
import { useLocalization } from '~src/hooks';

import { imagePreviewToolbar } from './ImageThumbnail.module.css';

type PreviewToolbarProps = {
  actions?: {
    onZoomOut: () => void;
    onZoomIn: () => void;
  };
  transform?: { scale: number };
  onDelete?: () => void;
};

export const PreviewToolbar = ({ actions, transform, onDelete }: PreviewToolbarProps) => {
  const { onZoomOut, onZoomIn } = actions ?? {};
  const { scale } = transform ?? {};

  const translate = useLocalization();

  return (
    <div className={imagePreviewToolbar}>
      {onDelete && (
        <Popconfirm
          title={translate.DELETE_IMAGE}
          description={translate.ARE_YOU_SURE}
          onConfirm={onDelete}
          okText={translate.YES}
          cancelText={translate.NO}
        >
          <DeleteOutlined />
        </Popconfirm>
      )}

      {scale && actions && (
        <>
          <ZoomOutOutlined disabled={scale <= 1} onClick={onZoomOut} />

          <ZoomInOutlined disabled={scale >= 50} onClick={onZoomIn} />
        </>
      )}
    </div>
  );
};
