import { Residence } from '~src/types';

export const getFuelTypeUnitAndName = (
  fuelType?: Required<Residence>['primaryHeating']['fuelType'],
  volumeUnit?: Required<Residence>['secondaryHeating']['volumeUnit']
): string => {
  switch (fuelType) {
    case 'oil': {
      return 'liter olie';
    }

    case 'gas': {
      return 'm3 gas';
    }

    case 'stoveCertified':
    case 'stoveUncertified':
    case 'tiledStove':
    case 'fireplace':
    case 'wood':
    case 'wood_1':
    case 'wood_2': {
      return `${volumeUnit || 'm3'} træ`;
    }

    case 'straw': {
      return 'ton strå';
    }

    case 'pallet': {
      return 'ton piller';
    }

    case 'districtHeating': {
      return 'kWh fjernvarme';
    }

    case 'electricity':
    case 'heatPump-air-to-air':
    case 'heatPump-air-to-water':
    default: {
      return 'kWh el';
    }
  }
};
