import {
  OfferExpenseInput,
  OfferType,
  useDeleteOfferExpensesMutation,
  useExpenseTemplateListQuery,
  useFetchOfferQuery,
  useSetOfferExpensesMutation,
} from '~src/gql';
import { useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient, useQueryClient } from '~src/services/client';
import { deepNullToUndefined } from '~src/utilities/convert';

export const usePossibleExpenseTemplates = (type: OfferType) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useExpenseTemplateListQuery(
    graphQLClient,
    { type },
    { onError: useToastMessageErrorHandler() }
  );

  return {
    possibleTemplates: data?.expenseTemplates?.map(deepNullToUndefined) ?? [],
    isLoading: isFetching,
  };
};

export const useSetOfferExpenses = () => {
  const { graphQLClient } = useGraphQLClient();
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useSetOfferExpensesMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
  });

  const setOfferExpensesAsync = async (offerId: number, expenses: Array<OfferExpenseInput>) => {
    const { setOfferExpenses } = await mutateAsync(
      { offerId, expenses },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: useFetchOfferQuery.getKey({ offerId }) });
        },
      }
    );
    return setOfferExpenses;
  };

  return { setOfferExpenses: setOfferExpensesAsync, isLoading };
};

export const useDeleteOfferExpenses = () => {
  const queryClient = useQueryClient();
  const { graphQLClient } = useGraphQLClient();

  const { mutateAsync, isLoading } = useDeleteOfferExpensesMutation(graphQLClient, {
    onError: useToastMessageErrorHandler(),
    onSuccess: async (_, { offerId }) => {
      await queryClient.invalidateQueries({ queryKey: useFetchOfferQuery.getKey({ offerId }) });
    },
  });

  const deleteOfferExpenses = (offerId: number) => async (expenseIds: number[]) => {
    const { deleteOfferExpenses: deleteExpenses } = await mutateAsync({ offerId, expenseIds });

    return deleteExpenses;
  };

  return {
    deleteOfferExpenses: (offerId?: number) => (!offerId ? async () => false : deleteOfferExpenses(offerId)),
    isLoading,
  };
};
