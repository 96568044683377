import { Button, Card, Heading, Tag, Text, ThumbnailWithUrl } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { formatCurrency } from '~src/utilities/format';

import { attributeRow, attributeTable, leftAlign, preferredBox, productCard } from './productCard.module.css';

type AttributeTableProps = {
  data: { name: string; value: string | number }[];
};

export type ProductCardProps = {
  attributes?: AttributeTableProps['data'];
  brand?: string;
  id?: string | number;
  imageUrl?: string;
  onSelect?: (id?: string | number) => void;
  price?: number;
  selected?: boolean;
  subtitle?: string;
  model: string;
  notes?: string;
  preferred?: boolean;
  type?: 'default' | 'display';
};

export const ProductCard = ({
  attributes,
  brand,
  model,
  id,
  imageUrl,
  onSelect,
  price,
  selected = false,
  subtitle,
  preferred = false,
  notes,
  type = 'default',
}: ProductCardProps) => {
  const translate = useLocalization();

  const handleOnSelect = () => {
    onSelect?.(id);
  };

  return (
    <>
      <Card className={productCard}>
        <ThumbnailWithUrl width={200} imageUrl={imageUrl} alt='product' />
        {preferred && (
          <Tag color='blue' className={preferredBox}>
            {translate.PREFERRED}
          </Tag>
        )}

        <div>
          {brand ? <Text className={leftAlign}>{brand}</Text> : null}

          <Heading level={3} className={leftAlign}>
            {model}
          </Heading>

          {subtitle ? <Text className={leftAlign}>{subtitle}</Text> : null}

          <AttributeTable data={attributes ?? []} />

          <Heading level={3}>{formatCurrency(price ?? 0, { addVAT: true, decimals: 0 })}</Heading>

          {type === 'default' ? (
            <Button type={selected ? 'primary' : 'default'} onClick={handleOnSelect}>
              {selected ? translate.SELECTED : translate.SELECT}
            </Button>
          ) : null}
        </div>
      </Card>
      {notes && (
        <Card>
          <Heading level={3}>
            {brand} {model}
          </Heading>
          <div>Noter til bestilling</div>
          {notes.split('\n').map(text => (
            <>
              <br />
              {text}
            </>
          ))}
        </Card>
      )}
    </>
  );
};

const AttributeTable = ({ data }: AttributeTableProps) =>
  data.length < 1 ? null : (
    <div className={attributeTable}>
      {data.map(({ name, value }) => (
        <div key={name} className={attributeRow}>
          <span>{name}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
