import last from 'lodash/last';

import { useCombineOfferProductsWithExpenses } from '~src/components/features/createOffer/hooks/useCombineOfferProductsWithExpenses';
import { useLocalization, useOfferProductState } from '~src/hooks';
import { useOfferSavings } from '~src/hooks/services';
import { mapOfferToOfferInput, MaybeId, ResidenceInput } from '~src/types';
import { calculateOfferPrice, calculatePaybackTime } from '~src/utilities/calculationHelper';
import { consumptionProfiles, DEFAULT_CONSUMPTION_PROFILE_KEY } from '~src/utilities/constants';
import { formatCurrency, withVAT } from '~src/utilities/format';

export const useFormatSolarOfferEconomyTableData = (residence?: ResidenceInput & MaybeId) => {
  const translate = useLocalization();

  const { consumptionProfile, address } = residence ?? {};
  const { selectedSolarPanel, selectedInverter, selectedBattery } = useOfferProductState();

  const offerProducts = {
    solarPanel: selectedSolarPanel,
    inverter: selectedInverter,
    battery: selectedBattery,
  };

  const { savings } = useOfferSavings(
    residence,
    mapOfferToOfferInput({
      solarPanel: selectedSolarPanel,
      inverter: selectedInverter,
      battery: selectedBattery,
    }),
    address,
    undefined,
    consumptionProfiles[consumptionProfile ?? DEFAULT_CONSUMPTION_PROFILE_KEY],
    residence?.customerID
  );

  const { saved, before, after } = savings ?? {};
  const { priceByYear } = saved ?? {};

  const offerProductsWithExpenses = useCombineOfferProductsWithExpenses(offerProducts);

  const priceWithVat = withVAT(calculateOfferPrice(offerProductsWithExpenses));
  const { paybackFormatted } = calculatePaybackTime(
    priceWithVat,
    years => priceByYear?.[years] ?? last(priceByYear) ?? 0
  );

  const economyColumns = [
    { title: '', dataIndex: 'economyDescription' },
    { title: translate.BEFORE, dataIndex: 'before' },
    { title: translate.AFTER, dataIndex: 'after' },
  ];

  const economyData = [
    {
      economyDescription: translate.BOUGHT_ELECTRICITY,
      before: formatCurrency(before?.boughtPrice ?? undefined),
      after: formatCurrency(after?.boughtPrice ?? undefined),
    },
    {
      economyDescription: translate.SOLD_ELECTRICITY,
      before: '-',
      after: formatCurrency(after?.soldPrice ?? undefined),
    },
    {
      economyDescription: translate.NET_EXPENSES,
      before: formatCurrency(before?.netSpentElectricity),
      after: formatCurrency(after?.netSpentElectricity),
    },
    {
      economyDescription: translate.PRICE,
      before: '-',
      after: formatCurrency(priceWithVat),
    },
    {
      economyDescription: translate.CORRESPONDING_TO_A_SAVING_OF,
      before: '-',
      after: saved?.price ? formatCurrency(saved.price) : '-',
    },
    {
      economyDescription: translate.PAYBACK_TIME,
      before: '-',
      after: paybackFormatted || '-',
    },
  ];

  return { economyColumns, economyData };
};
