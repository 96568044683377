import clsx from 'clsx';

import { Form, Heading, Input } from '~src/components/display';
import { getValueAsFloat, getValueAsInteger } from '~src/components/display/Form/formHelper';
import { CustomerNotes } from '~src/components/features/shared/customer';
import { useLocalization } from '~src/hooks';
import { Customer, OfferProducts, ResidenceInput } from '~src/types';

import { useDistrictHeatingData } from './hooks/useDistrictHeatingData';
import { useDistrictHeatingState } from './state/districtHeatingState';

import { displayColumn, displayRow, justifyItemsCenter } from '~src/style/shared.module.css';

import { columnGrid } from './OfferDistrictHeatingComparison.module.css';

type OfferDistrictHeatingComparisonProps = {
  customer?: Partial<Pick<Customer, 'id' | 'notes'>>;
  products?: OfferProducts;
  residence?: ResidenceInput;
};

export const OfferDistrictHeatingComparison = ({
  customer,
  products,
  residence,
}: OfferDistrictHeatingComparisonProps) => {
  const translate = useLocalization();

  const { updateDistrictHeating, ...districtHeating } = useDistrictHeatingState();

  const {
    currentHeatingYearlyPrice,
    districtHeatingYearlyPrice,
    heatPumpExtraCost,
    heatPumpPrice,
    heatPumpYearlyPrice,
    remainingYears,
    setHeatPumpExtraCost,
    yearsUntilDistrictHeating,
  } = useDistrictHeatingData(districtHeating, products, residence);

  return (
    <div className={displayColumn}>
      <Heading>{translate.DISTRICT_HEATING_INFORMATION}</Heading>

      <Form layout='vertical'>
        <div className={displayRow}>
          <div className={displayColumn}>
            <div className={columnGrid}>
              <Form.Item getValueFromEvent={getValueAsInteger} label={translate.DISTRICT_HEATING_CONNECTION_YEAR}>
                <Input
                  addonAfter={translate.YEAR}
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ installationYear: Number(value) });
                  }}
                  value={districtHeating.installationYear}
                />
              </Form.Item>

              <Form.Item getValueFromEvent={getValueAsFloat} label={translate.DISTRICT_HEATING_PRICE_INVESTMENT}>
                <Input
                  addonAfter={`kr./${translate.YEAR}`}
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ investment: Number(value) });
                  }}
                  value={districtHeating.investment}
                />
              </Form.Item>

              <Form.Item getValueFromEvent={getValueAsFloat} label={translate.DISTRICT_HEATING_EFFICIENCY}>
                <Input
                  addonAfter='%'
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ efficiency: Number(value) });
                  }}
                  value={districtHeating.efficiency}
                />
              </Form.Item>
            </div>

            <Heading level={2}>{translate.EXPENSES_YEARLY}</Heading>
            <div className={columnGrid}>
              <Form.Item getValueFromEvent={getValueAsFloat} label={translate.UNIT_PRICE}>
                <Input
                  addonAfter='kr./kWh'
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ unitPrice: Number(value) });
                  }}
                  value={districtHeating.unitPrice}
                />
              </Form.Item>

              <Form.Item getValueFromEvent={getValueAsFloat} label={translate.SUBSCRIPTION}>
                <Input
                  addonAfter={`kr./${translate.YEAR}`}
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ subscriptionPrice: Number(value) });
                  }}
                  value={districtHeating.subscriptionPrice}
                />
              </Form.Item>

              <Form.Item getValueFromEvent={getValueAsFloat} label={translate.DISTRICT_HEATING_PRICE_RELAY}>
                <Input
                  addonAfter={`kr./${translate.YEAR}`}
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ relayPrice: Number(value) });
                  }}
                  value={districtHeating.relayPrice}
                />
              </Form.Item>

              <Form.Item getValueFromEvent={getValueAsFloat} label={translate.DISTRICT_HEATING_PRICE_EFFECT}>
                <Input
                  addonAfter='kr./m2'
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ effectPrice: Number(value) });
                  }}
                  value={districtHeating.effectPrice}
                />
              </Form.Item>

              <Form.Item getValueFromEvent={getValueAsFloat} label={translate.DISTRICT_HEATING_PRICE_INSTALLATION}>
                <Input
                  addonAfter='kr./m2'
                  type='number'
                  onChange={({ target: { value } }) => {
                    updateDistrictHeating({ installationPrice: Number(value) });
                  }}
                  value={districtHeating.installationPrice}
                />
              </Form.Item>
            </div>

            <Heading level={2}>{translate.HEAT_PUMP_INFORMATION_SUPPLEMENTARY}</Heading>
            <div className={columnGrid}>
              <Form.Item
                getValueFromEvent={getValueAsFloat}
                label={translate.DISTRICT_HEATING_PRICE_HEAT_PUMP_SUPPLEMENTAL}
              >
                <Input
                  addonAfter={`kr./${translate.YEAR}`}
                  type='number'
                  onChange={({ target: { value } }) => {
                    setHeatPumpExtraCost(Number(value));
                  }}
                  value={heatPumpExtraCost}
                />
              </Form.Item>
            </div>
          </div>

          <CustomerNotes customer={customer} rows={16} />
        </div>

        <Heading level={2}>{translate.EXPENSES_ACCUMULATED}</Heading>

        <div className={clsx(displayRow, justifyItemsCenter)}>
          <Heading level={3}>{translate.DISTRICT_HEATING}</Heading>
          <Heading level={3}>{translate.HEAT_PUMP}</Heading>
        </div>

        <div className={columnGrid}>
          <div>
            <Form.Item label={translate.PRICE_PER_YEAR}>
              <Input addonAfter={`kr./${translate.YEAR}`} disabled value={districtHeatingYearlyPrice} />
            </Form.Item>

            <Form.Item label={translate.DISTRICT_HEATING_OLD_PRICE_PER_YEAR}>
              <Input addonAfter={`kr./${translate.YEAR}`} disabled value={currentHeatingYearlyPrice} />
            </Form.Item>
          </div>

          <Form.Item label={translate.DISTRICT_HEATING_TOTAL_AFTER_YEARS}>
            <Input
              addonAfter='kr.'
              disabled
              value={
                currentHeatingYearlyPrice * yearsUntilDistrictHeating +
                districtHeatingYearlyPrice * remainingYears +
                districtHeating.investment
              }
            />
          </Form.Item>

          <Form.Item label={translate.PRICE_PER_YEAR}>
            <Input addonAfter={`kr./${translate.YEAR}`} disabled value={heatPumpYearlyPrice} />
          </Form.Item>

          <Form.Item label={translate.DISTRICT_HEATING_TOTAL_AFTER_YEARS}>
            <Input addonAfter='kr.' disabled value={heatPumpYearlyPrice * 15 + heatPumpPrice} />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
