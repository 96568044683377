import { useEffect } from 'react';

import { useCustomer } from '~src/hooks/services/useCustomer';
import { useLocation } from '~src/hooks/useLocation';
import { mapCustomerToCustomerInput } from '~src/types';

import { useCreateOfferState } from '../state/createOfferState';

export const useInitializeCustomerFromSearchParameter = () => {
  const { searchParameters, setSearchParameters } = useLocation();

  const customerId = Number(searchParameters.get('customerId'));

  const { customer: initialCustomer } = useCustomer(customerId ?? undefined);

  const { customer, setCustomer } = useCreateOfferState();

  const shouldCustomerStateBeOverwritten = (!customer || customer.id !== customerId) && initialCustomer;

  useEffect(
    function initializeCustomerFromSearchParameters() {
      if (shouldCustomerStateBeOverwritten) {
        setCustomer(mapCustomerToCustomerInput(initialCustomer));
        setSearchParameters();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer, initialCustomer]
  );
};
