import { useEffect } from 'react';

import { isAuthenticated } from '~src/utilities/auth';
import { getCookie, setCookie } from '~src/utilities/cookies';
import { location } from '~src/utilities/global';

import { useRefreshLogin } from './services';

const TIMER_INTERVAL_MS = 10_000;

const getHostDomain = () =>
  location.hostname.includes('localhost') ? location.hostname : `.${location.hostname.split('.').slice(1).join('.')}`;

const cookieOption = {
  domain: getHostDomain(),
  expires: 1,
  path: '/',
  sameSite: 'Strict' as const,
  secure: true,
};

export const useRefreshLoginIfNecessary = () => {
  const { login } = useRefreshLogin();

  useEffect(function refreshLoginPeriodically() {
    const intervalId = setInterval(async () => refreshLoginIfNecessary(login), TIMER_INTERVAL_MS);

    return () => clearInterval(intervalId);
  });
};

export const refreshLoginIfNecessary = async (loginMutation: ReturnType<typeof useRefreshLogin>['login']) => {
  const loggedIn = isAuthenticated();

  if (loggedIn) {
    return false;
  }

  const refreshToken = getCookie('refreshToken');
  if (!refreshToken) {
    return false;
  }

  const { refreshLogin } = (await loginMutation({ refreshToken })) ?? {};
  const { accessToken, refreshToken: newRefreshToken } = refreshLogin ?? {};

  if (accessToken && newRefreshToken) {
    setCookie('accessToken', accessToken, cookieOption);
    setCookie('refreshToken', newRefreshToken, cookieOption);
  }

  return !!accessToken && !!refreshToken;
};
