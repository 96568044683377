import { useState } from 'react';

import { Button } from '../Button';
import { Modal } from './Modal';

export const ModalButton = ({
  buttonChildren,
  children,
}: {
  buttonChildren: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          setShowModal(true);
        }}
      >
        {buttonChildren}
      </Button>

      <Modal
        closable
        footer={null}
        onCancel={() => {
          setShowModal(false);
        }}
        open={showModal}
        width='90vw'
      >
        {children}
      </Modal>
    </>
  );
};
