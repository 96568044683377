import { useEffect, useState } from 'react';

import { Form, Input, Select, Switch } from '~src/components/display';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { LEAD_ORIGIN_OPTIONS } from '~src/components/features/shared/customer/LeadOriginOptions';
import { useLocalization } from '~src/hooks';
import { useBodilAdvisors, useUser } from '~src/hooks/services';
import { CustomerInput } from '~src/types';

import { useCreateOfferState } from '../../state/createOfferState';

import { createCustomerForm } from './customer.module.css';

type CustomerFormProps = {
  disabled?: boolean;
  form: ReturnType<typeof Form.useForm<CustomerInput>>[0];
  onSubmit: (data: CustomerInput) => Promise<void>;
  toggleEditing: (arg: boolean) => void;
};

const advisorIdFieldKey: keyof CustomerInput = 'assignedAdvisorId';
const emailFieldKey: keyof CustomerInput = 'email';
const subsidyFieldKey: keyof CustomerInput = 'includeHeatPumpSubsidy';
const financialAdvisorFieldKey: keyof CustomerInput = 'financialAdvisor';
const financingProviderFieldKey: keyof CustomerInput = 'financingProvider';
const firstNameFieldKey: keyof CustomerInput = 'firstName';
const lastNameFieldKey: keyof CustomerInput = 'lastName';
const leadOriginFieldKey: keyof CustomerInput = 'leadOrigin';
const notesFieldKey: keyof CustomerInput = 'notes';
const phoneFieldKey: keyof CustomerInput = 'phoneNumber';

const phoneNumberValidation = /^(\+\d{2})?\d{8}$/;

const FINANCIAL_ADVISOR_FIELD_MAX_LENGTH = 255;

export const CustomerForm = ({ disabled = false, form, onSubmit, toggleEditing }: CustomerFormProps) => {
  const translate = useLocalization();
  const requiredFieldRules = useFormRequiredFieldRules();

  const [selectedAdvisorId, setSelectedAdvisorId] = useState<number>();

  const { customer } = useCreateOfferState();

  const { bodilAdvisors } = useBodilAdvisors();
  const { user } = useUser();

  useEffect(
    function initializeFormWithCustomer() {
      if (!customer?.email) {
        form.resetFields();
        return;
      }

      form.resetFields();
      form.setFieldsValue(customer);

      if (!customer?.assignedAdvisorId && user?.id) {
        form.setFieldValue(advisorIdFieldKey, user.id);
        toggleEditing(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer, form, toggleEditing]
  );

  const advisorOptions = bodilAdvisors?.map(advisor => ({
    value: advisor.id,
    label: `${advisor.firstName} ${advisor.lastName}`,
    selected: advisor.id === selectedAdvisorId,
  }));

  const financingProviderOptions = [
    { value: 'jyskebank', label: 'Jyske Bank' },
    { value: 'nordea', label: 'Nordea' },
  ];

  const initialFinancingProvider =
    customer?.financingProvider ?? customer?.leadOrigin?.includes('nordea') ? 'nordea' : 'jyskebank';

  return (
    <Form
      autoComplete='off'
      className={createCustomerForm}
      disabled={disabled}
      form={form}
      labelCol={{ span: 12 }}
      onFinish={onSubmit}
      scrollToFirstError
      wrapperCol={{ span: 18 }}
    >
      <Form.Item label={translate.FIRST_NAME} name={firstNameFieldKey} rules={requiredFieldRules}>
        <Input type='text' placeholder={translate.FIRST_NAME} onChange={() => toggleEditing(true)} />
      </Form.Item>

      <Form.Item label={translate.LAST_NAME} name={lastNameFieldKey} rules={requiredFieldRules}>
        <Input type='text' placeholder={translate.LAST_NAME} onChange={() => toggleEditing(true)} />
      </Form.Item>

      <Form.Item
        label={translate.EMAIL}
        name={emailFieldKey}
        rules={[...requiredFieldRules, { type: 'email', message: translate.FORM_FIELD_FORMAT_EMAIL }]}
      >
        <Input type='email' placeholder={translate.EMAIL} onChange={() => toggleEditing(true)} />
      </Form.Item>

      <Form.Item
        label={translate.PHONE_NUMBER}
        name={phoneFieldKey}
        rules={[...requiredFieldRules, { pattern: phoneNumberValidation, message: translate.FORM_FIELD_FORMAT_NUMBER }]}
      >
        <Input type='tel' placeholder={translate.PHONE_NUMBER} onChange={() => toggleEditing(true)} />
      </Form.Item>

      <Form.Item
        label={translate.ASSIGNED_ADVISOR}
        name={advisorIdFieldKey}
        rules={requiredFieldRules}
        initialValue={selectedAdvisorId}
      >
        <Select
          onSelect={value => {
            setSelectedAdvisorId(value);
            toggleEditing(true);
          }}
          options={advisorOptions}
        />
      </Form.Item>

      <Form.Item
        label={translate.FINANCING_PROVIDER}
        name={financingProviderFieldKey}
        rules={requiredFieldRules}
        initialValue={initialFinancingProvider}
      >
        <Select
          onSelect={value => {
            setSelectedAdvisorId(value);
            toggleEditing(true);
          }}
          options={financingProviderOptions}
        />
      </Form.Item>

      <Form.Item
        label={translate.FINANCIAL_ADVISOR}
        name={financialAdvisorFieldKey}
        rules={[
          {
            max: FINANCIAL_ADVISOR_FIELD_MAX_LENGTH,
            message: `${translate.FORM_FIELD_MAX_LENGTH} ${FINANCIAL_ADVISOR_FIELD_MAX_LENGTH}`,
          },
        ]}
      >
        <Input onChange={() => toggleEditing(true)} />
      </Form.Item>

      <Form.Item name={leadOriginFieldKey} label={translate.LEAD_ORIGIN}>
        <Select onChange={() => toggleEditing(true)} options={LEAD_ORIGIN_OPTIONS} />
      </Form.Item>

      <Form.Item
        label={translate.HEAT_PUMP_SUBSIDY}
        name={subsidyFieldKey}
        rules={[]}
        initialValue={customer?.includeHeatPumpSubsidy ?? true}
      >
        <Switch />
      </Form.Item>

      <Form.Item label={translate.NOTES} name={notesFieldKey}>
        <Input.TextArea placeholder={translate.NOTES} onChange={() => toggleEditing(true)} />
      </Form.Item>
    </Form>
  );
};
