import debounce from 'lodash/debounce';
import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { fetchDataAsBlob } from '~src/services/blobService';

import { useToastMessageErrorHandler } from './useToastMessage';

export const useDownloadFile = (fileName: string, downloadURL?: string) => {
  const [downloadTriggered, setDownloadTriggered] = useState(false);

  const { data: blob, isFetching } = useQuery(['fetchDataAsBlob', downloadURL], () => fetchDataAsBlob(downloadURL!), {
    enabled: downloadTriggered && !!downloadURL?.length,
    onError: useToastMessageErrorHandler(),
  });

  if (blob && downloadTriggered) {
    setDownloadTriggered(false);
    downloadBlobAsFile(blob, fileName);
  }

  const downloadFile = () => {
    setDownloadTriggered(true);
  };

  return { downloadFile, isLoading: isFetching };
};

const downloadBlobAsFile = debounce((data: string | Buffer | Blob, name: string) => {
  const blob = data instanceof Blob ? data : new Blob([data]);
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', name);
  link.click();
}, 100);
