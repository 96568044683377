import { clsx } from 'clsx';
import { Bar, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { useLocalization } from '~src/hooks';

import { SolarSavingsGraphTooltip } from './components/SolarSavingsGraphTooltip';
import { GRAPH_MARGIN } from './constants';

import { colorPrimary, colorSecondary } from '~src/style/color.module.css';

import { graphContainer } from './graphs.module.css';

type SolarSavingsGraphDataPoint = {
  title?: string;
  savings: number;
  usedPercentage?: number;
  quantity: number;
  totalPrice: number;
  totalSaved: number;
};

type SolarSavingsGraphProps = {
  data?: SolarSavingsGraphDataPoint[];
  className?: string;
  selectPackage?: (packageID: number) => void;
};

export const SolarSavingsGraph = ({ className, data = [], selectPackage }: SolarSavingsGraphProps) => {
  const translate = useLocalization();

  return (
    <div className={clsx([graphContainer, className])}>
      <ResponsiveContainer height='100%' width='100%'>
        <ComposedChart data={data} margin={GRAPH_MARGIN}>
          <Tooltip content={<SolarSavingsGraphTooltip />} />

          <XAxis dataKey='quantity'>
            <Label value={translate.QUANTITY} position='bottom' />
          </XAxis>

          <Bar
            dataKey='savings'
            fill={colorPrimary}
            yAxisId='savingsAxis'
            onClick={({ packageID }) => selectPackage?.(packageID)}
          />

          <YAxis unit='kr.' dataKey='savings' orientation='left' yAxisId='savingsAxis'>
            <Label value={translate.PROFIT} position='top' offset={10} />
          </YAxis>

          <Line
            type='monotone'
            dataKey='usedPercentage'
            dot={false}
            strokeWidth={2}
            stroke={colorSecondary}
            yAxisId='usageAxis'
          />

          <YAxis unit='%' dataKey='usedPercentage' orientation='right' yAxisId='usageAxis' domain={[0, 100]}>
            <Label value={translate.SELF_USAGE} position='top' offset={10} />
          </YAxis>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
