import clamp from 'lodash/clamp';
import { useState } from 'react';

import { useEnergyOptions } from '~src/hooks/services';
import { EnergyOptions, OfferProducts, ResidenceInput } from '~src/types';
import {
  aggregateHeatingOutput,
  calculateHeatDistributionValue,
  calculateOfferPrice,
} from '~src/utilities/calculationHelper';
import { withVAT } from '~src/utilities/format';

import { DistrictHeatingState } from '../state/districtHeatingState';

const FUTURE_YEARS = 15;

const CURRENT_YEAR = new Date().getUTCFullYear();

type DistrictHeating = Omit<DistrictHeatingState, 'updateDistrictHeating'>;

export const useDistrictHeatingData = (
  districtHeating: DistrictHeating,
  products?: OfferProducts,
  residence?: ResidenceInput
) => {
  const [heatPumpExtraCost, setHeatPumpExtraCost] = useState(0);

  const { energyOptions } = useEnergyOptions();
  const { electricity } = energyOptions;

  const residenceHeatingRequirement = aggregateHeatingOutput(
    [residence?.primaryHeating, residence?.secondaryHeating],
    energyOptions
  );

  const { area } = residence ?? { area: 0 };

  const scop =
    (products?.heatPumpOutdoorUnit?.effect7
      ? calculateHeatDistributionValue(products.heatPumpOutdoorUnit.effect7, residence)
      : products?.heatPumpOutdoorUnit?.scop?.radiator) || 1;

  const electricityUsageOfHeatPump = residenceHeatingRequirement / scop;

  const heatPumpElectricityPrice = getDiscountedElectricityTotalPrice(
    electricityUsageOfHeatPump,
    electricity,
    residence
  );

  const heatPumpYearlyPrice = Math.round(heatPumpElectricityPrice + heatPumpExtraCost);

  const heatPumpPrice = withVAT(calculateOfferPrice(products));

  const currentHeatingYearlyPrice =
    (residence?.primaryHeating?.annualUsage ?? 0) * (residence?.primaryHeating?.price ?? 0) +
    (residence?.secondaryHeating?.annualUsage ?? 0) * (residence?.secondaryHeating?.price ?? 0);

  const grossTotalPrice = getDistrictHeatingTotalGrossPrice(districtHeating, area, residenceHeatingRequirement);

  const districtHeatingYearlyPrice = Math.round((grossTotalPrice * districtHeating.efficiency) / 100);

  const yearsUntilDistrictHeating = clamp(districtHeating.installationYear - CURRENT_YEAR, 0, FUTURE_YEARS);

  const remainingYears = FUTURE_YEARS - yearsUntilDistrictHeating;

  return {
    currentHeatingYearlyPrice,
    districtHeatingYearlyPrice,
    heatPumpExtraCost,
    heatPumpPrice,
    heatPumpYearlyPrice,
    remainingYears,
    setHeatPumpExtraCost,
    yearsUntilDistrictHeating,
  };
};

const getDiscountedElectricityTotalPrice = (
  electricityUsageOfHeatPump: number,
  electricity: Pick<EnergyOptions['electricity'], 'discount' | 'discountRequired' | 'price'>,
  residence?: Pick<ResidenceInput, 'electricityPrice' | 'energyExpenditure'>
) => {
  const heatPumpUsageAboveDiscountThreshold = clamp(
    (residence?.energyExpenditure ?? 0) + electricityUsageOfHeatPump - electricity.discountRequired,
    0,
    electricityUsageOfHeatPump
  );

  const heatPumpUsageBelowDiscountThreshold = Math.max(
    0,
    electricityUsageOfHeatPump - heatPumpUsageAboveDiscountThreshold
  );

  const electricityPrice = residence?.electricityPrice ?? electricity.price;

  const discountedElectricityPrice = electricityPrice - electricity.discount;

  return (
    heatPumpUsageBelowDiscountThreshold * electricityPrice +
    heatPumpUsageAboveDiscountThreshold * discountedElectricityPrice
  );
};

const getDistrictHeatingTotalGrossPrice = (
  districtHeating: DistrictHeating,
  area: number,
  residenceHeatingRequirement: number
) =>
  districtHeating.relayPrice +
  districtHeating.subscriptionPrice +
  districtHeating.effectPrice * area +
  districtHeating.installationPrice * area +
  districtHeating.unitPrice * residenceHeatingRequirement;
