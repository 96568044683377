import clsx from 'clsx';
import { ReactNode } from 'react';

import { Divider, Heading } from '~src/components/display';

import { residenceFormSection, withColumns } from './residenceFormComponents.module.css';

type ResidenceFormSectionProps = {
  children?: ReactNode;
  className?: string;
  columns?: boolean;
  noDivider?: boolean;
  title: string;
};

export const ResidenceFormSection = ({
  children,
  className,
  columns = false,
  noDivider = false,
  title,
}: ResidenceFormSectionProps) => (
  <section className={clsx([residenceFormSection, columns && withColumns, className])}>
    <Heading level={1}>{title}</Heading>

    {!noDivider && <Divider fullWidth />}

    <div>{children}</div>
  </section>
);
