import { create } from 'zustand';

type State = {
  archiveExistingOffer: boolean;
};

type Actions = {
  setArchiveExistingOffer: (value: boolean) => void;
};

type OfferState = State & Actions;

const initialState: State = {
  archiveExistingOffer: false,
};

export const useOfferState = create<OfferState>(set => ({
  ...initialState,

  setArchiveExistingOffer: (archiveExistingOffer: boolean) => set({ archiveExistingOffer }),
}));
