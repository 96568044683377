import clsx from 'clsx';
import round from 'lodash/round';
import { useEffect, useState } from 'react';

import { Button, Form, Heading, Input, Radio, Text } from '~src/components/display';
import { getClampedValueAsInteger, getValueAsFloat } from '~src/components/display/Form/formHelper';
import { useFormRequiredFieldRules } from '~src/components/display/Form/useFormRequiredFieldRules';
import { EditOutlined } from '~src/components/display/Icons';
import { useLocalization } from '~src/hooks';
import { Roof } from '~src/types';
import {
  calculateInclinedDepth,
  calculateRoofHeight,
  calculateRoofInclination,
} from '~src/utilities/calculationHelper';
import { BASE_SOLAR_PANEL_SIZE } from '~src/utilities/constants';
import { convertMetersToCentimeters } from '~src/utilities/convert';
import { calculateMaximumNumberOfSolarPanels } from '~src/utilities/solarPanel';

import {
  heightFieldKey,
  maxPanelCoverageFieldKey,
  orientationFieldKey,
  planeDepthFieldKey,
  planeWidthFieldKey,
  roofsFieldKey,
} from '../constants';
import { getRoofOrientationOptions, tryParseStringFloat } from '../helpers/residenceHelper';

import { colorBlack } from '~src/style/shared.module.css';

import { residenceFormSection, residenceRoofForm, withColumns } from './residenceFormComponents.module.css';

type ResidenceFormResidenceRoofProps = {
  roof: { name: number; key: number };
  add: (defaultValue?: Roof, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  roofIndex: number;
  lastRoof?: boolean;
};

export const ResidenceRoof = ({ roof, add, remove, roofIndex, lastRoof }: ResidenceFormResidenceRoofProps) => {
  const translate = useLocalization();

  const form = Form.useFormInstance();

  const [maxPanels, setMaxPanels] = useState<{ withCoverage: number; withoutCoverage: number }>();
  const [inclination, setInclination] = useState<number>();
  const [inclinationLocked, setInclinationLocked] = useState(true);

  const solarPanelSize = { size: BASE_SOLAR_PANEL_SIZE };

  const formPlaneDepth = Form.useWatch([roofsFieldKey, roofIndex, planeDepthFieldKey], form);
  const formPlaneWidth = Form.useWatch([roofsFieldKey, roofIndex, planeWidthFieldKey], form);
  const formHeight = Form.useWatch([roofsFieldKey, roofIndex, heightFieldKey], form);
  const formMaxPanelCoverage = Form.useWatch([roofsFieldKey, roofIndex, maxPanelCoverageFieldKey], form);

  const requiredFieldRules = useFormRequiredFieldRules();

  const roofOrientationOptions = getRoofOrientationOptions(translate);

  useEffect(
    function updateMaxPanelsAndInclination() {
      updateMaxPanels();

      const inclication = calculateRoofInclination({ planeDepth: formPlaneDepth, height: formHeight });

      setInclination(inclication);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formPlaneDepth, formPlaneWidth, formHeight, formMaxPanelCoverage]
  );

  const updateMaxPanels = () => {
    const roofMeasurements = {
      planeDepth: convertMetersToCentimeters(formPlaneDepth),
      planeWidth: convertMetersToCentimeters(formPlaneWidth),
      height: convertMetersToCentimeters(formHeight),
      maxPanelCoverage: formMaxPanelCoverage,
    };

    const result = calculateMaximumNumberOfSolarPanels(solarPanelSize, [roofMeasurements]);
    setMaxPanels(result);
  };

  const updateRoofHeightFromInclination = () => {
    const newHeight = round(calculateRoofHeight(formPlaneDepth, inclination), 0);

    form.setFieldValue([roofsFieldKey, roofIndex, heightFieldKey], newHeight);

    setInclinationLocked(true);
  };

  const inclinedDepth = calculateInclinedDepth({
    planeDepth: form.getFieldValue([roofsFieldKey, roofIndex, planeDepthFieldKey]),
    height: form.getFieldValue([roofsFieldKey, roofIndex, heightFieldKey]),
  });

  return (
    <div>
      <div className={residenceRoofForm}>
        <Heading level={3}>
          {translate.ROOF_PLANE} {roofIndex + 1}
        </Heading>

        <div>
          {lastRoof && (
            <Button type='primary' onClick={() => add()}>
              + {translate.ADD} {translate.ROOF_PLANE.toLowerCase()}
            </Button>
          )}

          <Button type='dashed' onClick={() => remove(roof.name)}>
            &#215; {translate.REMOVE} {translate.ROOF_PLANE.toLowerCase()}
          </Button>
        </div>
      </div>

      <Form.Item label={translate.COMPASS_DIRECTION} name={[roofIndex, orientationFieldKey]} rules={requiredFieldRules}>
        <Radio.Group options={roofOrientationOptions} optionType='button' buttonStyle='outline' />
      </Form.Item>

      <div className={clsx([residenceFormSection, withColumns])}>
        <div>
          <Form.Item
            label={translate.WIDTH}
            name={[roofIndex, planeWidthFieldKey]}
            getValueFromEvent={getValueAsFloat}
            rules={requiredFieldRules}
          >
            <Input type='number' addonAfter={<Text>m</Text>} />
          </Form.Item>

          <Form.Item label={translate.HEIGHT} name={[roofIndex, heightFieldKey]} getValueFromEvent={getValueAsFloat}>
            <Input type='number' addonAfter={<Text>m</Text>} />
          </Form.Item>

          <Form.Item
            label={translate.DEPTH}
            name={[roofIndex, planeDepthFieldKey]}
            getValueFromEvent={getValueAsFloat}
            rules={requiredFieldRules}
          >
            <Input type='number' addonAfter={<Text>m</Text>} />
          </Form.Item>

          <Form.Item label={translate.ROOF_SLOPE_IN_DEGREES}>
            <Input
              disabled={inclinationLocked}
              value={inclination}
              onChange={event => setInclination(tryParseStringFloat(event.target.value))}
              onBlur={updateRoofHeightFromInclination}
              addonAfter={
                <EditOutlined
                  className={colorBlack}
                  onClick={() => {
                    setInclinationLocked(!inclinationLocked);
                  }}
                />
              }
            />
          </Form.Item>

          <Form.Item
            label={translate.PERCENTAGE_OF_ROOF_DEDICATED_TO_SOLAR_PANELS}
            name={[roofIndex, maxPanelCoverageFieldKey]}
            getValueFromEvent={getClampedValueAsInteger}
          >
            <Input type='number' addonAfter={<Text>%</Text>} />
          </Form.Item>

          <Form.Item label={translate.MAXIMUM_NUMBER_OF_PANELS}>
            <Input disabled value={maxPanels?.withoutCoverage || undefined} />
          </Form.Item>

          <Form.Item label={translate.INCLINED_DEPTH}>
            <Input disabled value={inclinedDepth} />
          </Form.Item>

          <Form.Item label={translate.POSSIBLE_NUMBER_OF_PANELS}>
            <Input disabled value={maxPanels?.withCoverage || undefined} />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};
