import { useSolarPackages } from './services';
import { useOfferProductState } from './useOfferProductState';

export const useSelectSolarPackage = () => {
  const { selectBattery, selectSolarPanel, selectInverter } = useOfferProductState();

  const { packages } = useSolarPackages();

  const selectSolarPackage = (selectedID: number) => {
    const selectedPackage = packages.find(({ packageID }) => packageID === selectedID);

    const { solarPanel, inverter, batteries, defaultBatteryID } = selectedPackage ?? {};

    const battery = batteries?.find(({ id }) => id === defaultBatteryID);

    if (solarPanel && inverter && battery) {
      selectSolarPanel(solarPanel);
      selectInverter(inverter);
      selectBattery(battery);
    }
  };

  return { selectSolarPackage };
};
