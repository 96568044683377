import { ChangeEvent } from 'react';

import { Input, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { Offer, useCreateOfferState } from '../../state/createOfferState';

import { offerDetail } from './offers.module.css';

type OfferDetailsProps = {
  offerId: number;
};

export const OfferDetails = ({ offerId }: OfferDetailsProps) => {
  const translate = useLocalization();
  const { offerList, toggleOfferSelected } = useCreateOfferState();

  const offer = offerList.find(({ id }) => id === offerId);

  const handleOfferSelected = (event: ChangeEvent<HTMLInputElement>) => {
    toggleOfferSelected(offerId, event.target.checked);
  };

  return offer ? (
    <div className={offerDetail}>
      <Input type='checkbox' checked={offer.selected} onChange={handleOfferSelected} />

      <Text>{getProductString(offer, translate.ONLY_OUTDOOR_UNIT)}</Text>
    </div>
  ) : (
    <Text>
      {translate.OFFER} {translate.NOT_FOUND.toLowerCase()}
    </Text>
  );
};

const getProductString = (offer: Offer, onlyOutdoorUnitLabel: string): string => {
  if (offer.heatPumpOutdoorUnit) {
    const indoorUnitLabel = offer.heatPumpIndoorUnit
      ? `+ ${offer.heatPumpIndoorUnit?.model}`
      : `(${onlyOutdoorUnitLabel})`;

    return `${offer.heatPumpOutdoorUnit?.model} ${indoorUnitLabel}`;
  }

  return `${offer.solarPanel?.model}`;
};
