import { useState } from 'react';

import { API_URL } from '~src/config';
import { useDownloadFile } from '~src/hooks';
import { Offer } from '~src/types';

export const useDownloadOffersDataSheets = () => {
  const [dataSheetDownloadUrl, setDataSheetDownloadUrl] = useState('');
  const [fileName, setFileName] = useState('');

  const { downloadFile, isLoading } = useDownloadFile(fileName, dataSheetDownloadUrl);

  const downloadOffersDataSheets = (offers: Required<Pick<Offer, 'id' | 'tag'>>[]) => {
    const offerIDs = offers.map(({ id }) => id).join(',');

    setFileName(`datasheets-(${offers.map(({ tag }) => tag).join(',')}).zip`);

    setDataSheetDownloadUrl(`${API_URL}/rest/v1/offers-product-datasheets?offerIDs=${offerIDs}`);

    downloadFile();
  };

  return { downloadOffersDataSheets, isLoading };
};
