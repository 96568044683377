/* eslint-disable @typescript-eslint/no-explicit-any */ // any allowed since it's directly from 'typeof console'
/* eslint-disable no-console */

export const logger: Pick<typeof console, 'error' | 'warn' | 'log' | 'info' | 'debug'> = {
  debug(data: any[]): void {
    console.debug(data);
  },
  error(data: any[]): void {
    console.error(data);
  },
  info(data: any[]): void {
    console.info(data);
  },
  log(data: any[]): void {
    console.log(data);
  },
  warn(data: any[]): void {
    console.warn(data);
  },
};
