import { Form } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useEnergyOptions } from '~src/hooks/services/useEnergyOptions';
import { ResidenceHeatingInput, ResidenceInput } from '~src/types';
import { findConstantForHeating } from '~src/utilities/calculationHelper';
import { getElectricHeatingOptions } from '~src/utilities/heatingType';

import {
  electricityPriceFieldKey,
  fuelSubtypeFieldKey,
  fuelTypeFieldKey,
  priceFieldKey,
  primaryHeatingFieldKey,
  secondaryHeatingFieldKey,
  volumeUnitFieldKey,
} from '../constants';

type UpdateFormHeatingPricePerUnitArguments = Partial<
  Pick<ResidenceHeatingInput, 'fuelType' | 'fuelSubtype' | 'volumeUnit'>
>;

export const useUpdateFormHeatingPricePerUnit = (
  form: ReturnType<typeof Form.useForm<ResidenceInput>>[0],
  priority: ResidenceHeatingInput['priority']
) => {
  const translate = useLocalization();
  const heatingFieldKey = priority === 'primary' ? primaryHeatingFieldKey : secondaryHeatingFieldKey;

  const { energyOptions } = useEnergyOptions();

  const formFuelType = Form.useWatch([heatingFieldKey, fuelTypeFieldKey], form);
  const formFuelSubType = Form.useWatch([heatingFieldKey, fuelSubtypeFieldKey], form);
  const formVolumeUnit = Form.useWatch([heatingFieldKey, volumeUnitFieldKey], form);

  const formElectricityPrice = Form.useWatch(electricityPriceFieldKey, form);

  const electricHeatingTypeOptions = getElectricHeatingOptions(translate).map(({ value }) => value);

  return ({ fuelType, fuelSubtype, volumeUnit }: UpdateFormHeatingPricePerUnitArguments) => {
    const heating = findConstantForHeating(
      {
        fuelType: fuelType ?? formFuelType,
        fuelSubtype: fuelSubtype ?? formFuelSubType,
        volumeUnit: volumeUnit ?? formVolumeUnit,
      },
      energyOptions
    );

    const heatingPrice = electricHeatingTypeOptions.includes(fuelType ?? formFuelType)
      ? formElectricityPrice ?? heating?.price ?? 0
      : heating?.price ?? 0;

    form.setFieldValue([heatingFieldKey, priceFieldKey], heatingPrice);
  };
};
