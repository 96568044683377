import round from 'lodash/round';

import { Divider, Heading, Input, Select, Text } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { useBatteries } from '~src/hooks/services/products/useBattery';
import { useInverters } from '~src/hooks/services/products/useInverter';
import { useOfferProductState } from '~src/hooks/useOfferProductState';
import { SolarPanel } from '~src/types';
import { formatCurrency } from '~src/utilities/format';

import { displayColumn } from '~src/style/shared.module.css';

import { solarSecondaryProductsSelector } from './solarProduct.module.css';

type SolarSecondaryProductsSelectorProps = {
  maximumSolarPanels?: number;
};

export const SolarSecondaryProductsSelector = ({ maximumSolarPanels }: SolarSecondaryProductsSelectorProps) => {
  const translate = useLocalization();

  const { selectInverter, selectBattery, selectSolarPanel, selectedBattery, selectedInverter, selectedSolarPanel } =
    useOfferProductState();

  const { inverters } = useInverters();
  const { batteries } = useBatteries();

  const filteredInverters = selectedSolarPanel
    ? inverters.filter(({ id }) => selectedSolarPanel.compatibleInverters?.includes(id))
    : [];

  const filteredBatteries = selectedInverter
    ? batteries.filter(({ id }) => selectedInverter.compatibleBatteries?.includes(id))
    : [];

  const handleOnSelectBattery = (selectedID: number) => {
    const battery = batteries?.find(({ id }) => id === selectedID);

    if (!battery) {
      return;
    }

    selectBattery(battery);
  };

  const handleOnSelectInverter = (selectedID: number) => {
    const inverter = inverters?.find(({ id }) => id === selectedID);

    if (!inverter) {
      return;
    }

    selectInverter(inverter);
  };

  return (
    <div className={displayColumn}>
      <Heading>{translate.SOLAR_CELL_INSTALLATION}</Heading>

      <Divider fullWidth />
      <div className={solarSecondaryProductsSelector}>
        <Text>{translate.NUMBER_OF_PANELS}</Text>

        <Text>{translate.INVERTER}</Text>

        <Text>{translate.BATTERY}</Text>

        <Input
          value={selectedSolarPanel?.quantity}
          onChange={event => {
            if (selectedSolarPanel) {
              selectSolarPanel({ ...selectedSolarPanel, quantity: Number(event.target.value ?? 0) });
            }
          }}
          addonAfter={maximumSolarPanels ? `${translate.MAXIMUM}: ${maximumSolarPanels}` : undefined}
        />

        <Select
          options={filteredInverters?.map(({ brand, model, id, price }) => ({
            label: `${brand} - ${model} - ${formatCurrency(price ?? 0, { addVAT: true, decimals: 0 })}`,
            value: id,
          }))}
          onSelect={handleOnSelectInverter}
          value={selectedInverter?.id}
        />

        <Select
          options={filteredBatteries?.map(({ brand, model, id, price }) => ({
            label: `${brand} - ${model} - ${formatCurrency(price ?? 0, { addVAT: true, decimals: 0 })}`,
            value: id,
          }))}
          onSelect={handleOnSelectBattery}
          allowClear
          onClear={() => selectBattery(undefined)}
          value={selectedBattery?.id}
        />

        <Text>{translate.TOTAL_AREA}</Text>

        <Text>{translate.TOTAL_WEIGHT}</Text>

        <Text>{translate.PRODUCTION_CAPACITY}</Text>

        <Input disabled value={calculateSolarPanelArea(selectedSolarPanel)} />

        <Input disabled value={calculateSolarPanelWeight(selectedSolarPanel)} />

        <Input disabled value={calculateSolarPanelProductionCapacity(selectedSolarPanel)} addonAfter='kWp' />
      </div>
    </div>
  );
};

const calculateSolarPanelArea = (solarPanel?: SolarPanel) => {
  if (!solarPanel) {
    return 0;
  }

  return round(((solarPanel.size.depth * solarPanel.size.width) / 10_000) * (solarPanel.quantity ?? 1), 2);
};

const calculateSolarPanelWeight = (solarPanel?: SolarPanel) => {
  if (!solarPanel) {
    return 0;
  }

  return round(solarPanel.weight * (solarPanel?.quantity ?? 1), 2);
};

const calculateSolarPanelProductionCapacity = (solarPanel?: SolarPanel) => {
  if (!solarPanel) {
    return 0;
  }

  return round(solarPanel.capacity * (solarPanel?.quantity ?? 1), 2);
};
