export {
  consumptionProfiles,
  DEFAULT_CONSUMPTION_PROFILE,
  DEFAULT_CONSUMPTION_PROFILE_KEY,
  DEGREE_DAYS_WEIGTED,
  fuelOptions,
  HEAT_PUMP_AIR_TO_AIR_EFFICIENCY,
  HEAT_PUMP_AIR_TO_WATER_EFFICIENCY,
  JYSKE_HEATPUMP_LOAN,
  JYSKE_SOLAR_LOAN,
  MINUS_12_TO_MINUS_7_RATIO,
  NORDEA_HEATPUMP_LOAN,
  NORDEA_SOLAR_LOAN,
} from '@bodilenergy/domain';

export const BASE_SOLAR_PANEL_SIZE = { height: 3, width: 113.4, depth: 172.2 };

export const DEFAULT_ELECTRIC_CAR = {
  annualKilometersDriven: 15000,
  percentageCharged: 90,
  consumptionPerKilometer: 230,
  monthlySubscriptionPrice: 89,
};
