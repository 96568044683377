import { useAutoResize } from './useAutoResize';

import { disabledTextArea } from './DisabledTextArea.module.css';

type DisabledTextArea = {
  text: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DisabledTextArea = ({ text }: DisabledTextArea) => {
  const autoResizeRef = useAutoResize();

  return <textarea disabled className={disabledTextArea} ref={autoResizeRef} value={text} />;
};
