import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';
import { formatYesOrNo, getProviderTranslation } from './util';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideOutdoor = ({ answerMap, imageList }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.OUTSIDE_PART}
      </Heading>

      <div className={informationColumn}>
        {answerMap?.noiseLimitObserved?.answer === 'withScreen' && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_NOISE_LIMIT_OBSERVED}
            value={translate.SITE_VISIT_ANSWER_NOISE_LIMIT_WITH_SCREEN}
          />
        )}

        {!!answerMap?.roomForMaterials?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_ROOM_FOR_MATERIALS}
            value={formatYesOrNo(translate, answerMap?.roomForMaterials?.answer)}
          />
        )}

        {!!answerMap?.providerForFoundation?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_PROVIDER_FOR_FOUNDATION}
            value={getProviderTranslation(translate, answerMap?.providerForFoundation?.answer)}
          />
        )}

        {answerMap?.digDownPipes?.answer === 'true' && (
          <InstallationGuideEntry label={translate.SITE_VISIT_QUESTION_DIG_DOWN_PIPES} value={translate.YES} />
        )}

        {!!answerMap?.outdoorPlacementNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap?.outdoorPlacementNotes?.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
