import { Heading, ImageThumbnail, Input, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';

import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionInverterBattery = ({
  answerMap,
  imageList,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        2. {translate.SITE_VISIT_SECTION_SOLAR_MISC_PLACEMENT}
      </Heading>
      <div className={questionRows}>
        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('inverterAndBatteryPlacementNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.inverterAndBatteryPlacementNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.inverterAndBatteryPlacement)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
