import { useState } from 'react';

import { useSolarPackageSavings } from '~src/hooks/services';
import { ResidenceInput } from '~src/types';

type UseSolarSavingsGraphProps = {
  residence?: ResidenceInput;
  electricityPrice?: number;
};

export const useSolarSavingGraph = ({ residence, electricityPrice }: UseSolarSavingsGraphProps) => {
  const [withBattery, setWithBattery] = useState<boolean>(true);

  const residenceInput = residence ? { ...residence, electricityPrice } : undefined;
  const { data } = useSolarPackageSavings(residenceInput);

  return { withBattery, setWithBattery, data };
};
