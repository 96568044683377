import { ClientError } from 'graphql-request';

import { CustomerInput, useCustomerQuery, useSearchCustomersQuery, useUpsertCustomerMutation } from '~src/gql';
import { useToastMessage, useToastMessageErrorHandler } from '~src/hooks/useToastMessage';
import { useGraphQLClient, useQueryClient } from '~src/services/client';
import { MaybeId } from '~src/types';
import { deepNullToUndefined } from '~src/utilities/convert';

import { useLocalization } from '../useLocalization';

const MIN_CUSTOMER_SEARCH_LENGTH = 2;

export const useCustomer = (id?: number) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, error, isFetching } = useCustomerQuery(
    graphQLClient,
    { id },
    { enabled: !!id, onError: useToastMessageErrorHandler() }
  );

  return { customer: deepNullToUndefined(data?.customer), error, isLoading: isFetching };
};

export const useUpsertCustomer = () => {
  const queryClient = useQueryClient();
  const { showMessage } = useToastMessage();
  const showError = useToastMessageErrorHandler();
  const { graphQLClient } = useGraphQLClient();
  const translate = useLocalization();

  const { mutateAsync, isLoading } = useUpsertCustomerMutation(graphQLClient, {
    onMutate: async ({ customer, id }) => {
      const queryKey = useCustomerQuery.getKey({ id });

      await Promise.all([
        queryClient.cancelQueries({ queryKey }),
        queryClient.cancelQueries({ queryKey: useSearchCustomersQuery.getKey({ search: '' }).slice(0, 1) }),
        queryClient.invalidateQueries(['offerSavings']),
      ]);

      queryClient.setQueryData<Record<'customer', CustomerInput>>(queryKey, cache => ({
        customer: { ...cache?.customer, ...customer },
      }));

      return { ...customer, id };
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['offerSavings']);
    },
  });

  const upsertCustomerAsync = async (customerInput: CustomerInput & MaybeId) => {
    const { id: customerId, ...customer } = customerInput;

    try {
      const { upsertCustomer } = await mutateAsync(
        { customer, id: customerId },
        {
          onError: error => {
            if (!(error instanceof ClientError)) {
              return;
            }

            const clientError = error as ClientError;
            const innerError = (clientError.response.errors?.[0] ?? {}) as { type: string };

            if (innerError.type === 'ENTITY_EXISTS') {
              showMessage({ type: 'warning', message: translate.CUSTOMER_EMAIL_EXISTS });
              return;
            }

            showError(error);
          },
        }
      );

      return upsertCustomer.id;
    } catch (error) {
      return undefined;
    }
  };

  return { upsertCustomer: upsertCustomerAsync, isLoading };
};

export const useSearchCustomers = (search: string) => {
  const { graphQLClient } = useGraphQLClient();

  const { data, isFetching } = useSearchCustomersQuery(
    graphQLClient,
    { search },
    {
      enabled: search.length >= MIN_CUSTOMER_SEARCH_LENGTH,
      onError: useToastMessageErrorHandler(),
      staleTime: 0,
    }
  );

  return { customerList: data?.searchCustomers?.map(deepNullToUndefined), isLoading: isFetching };
};
