import { useMemo } from 'react';

import { useLocalization } from '~src/hooks';
import { usePossibleExpenseTemplates } from '~src/hooks/services';
import { useLocaleState } from '~src/state';
import { withVAT } from '~src/utilities/format';

const CUSTOM_EXPENSE_ID = -1;

export const useExpenseOptions = (
  possibleTemplates: ReturnType<typeof usePossibleExpenseTemplates>['possibleTemplates'],
  currentExpenses: { id: number }[] = []
) => {
  const [locale] = useLocaleState();
  const translate = useLocalization();

  const expenseOptionList = useMemo(() => {
    const existingExpenseIdList = currentExpenses.map(({ id }) => id);

    const optionList = possibleTemplates
      .filter(({ id }) => !existingExpenseIdList.includes(id))
      .map(expense => ({ value: expense.id, label: expense.title }))
      .sort((a, b) => a.label.localeCompare(b.label, locale, { sensitivity: 'base' }));

    optionList.unshift({ label: translate.CUSTOM_EXPENSE_LABEL, value: CUSTOM_EXPENSE_ID });

    return optionList;
  }, [currentExpenses, locale, possibleTemplates, translate.CUSTOM_EXPENSE_LABEL]);

  return { expenseOptions: expenseOptionList };
};

export const toDefaultExpense = (id: number, templateID: number, title?: string, price?: number) => ({
  id,
  templateID,
  title: title ?? '',
  price: price ?? 0,
  priceWithVAT: withVAT(price),
  quantity: 1,
  notes: '',
});
