import { Heading } from '~src/components/display';
import { useLocalization } from '~src/hooks';

import { InstallationGuideEntry } from './InstallationGuideEntry';
import { InstallationGuideImages } from './InstallationGuideImages';
import { InstallationGuideSectionProps } from './types';
import { formatYesOrNo, getRoofTypeTranslation } from './util';

import { informationColumn, installationGuideSectionHeader } from './installationGuide.module.css';

export const InstallationGuideRoof = ({ answerMap, imageList, residence }: InstallationGuideSectionProps) => {
  const translate = useLocalization();

  return (
    <div>
      <Heading className={installationGuideSectionHeader} level={2}>
        {translate.SITE_VISIT_SECTION_ROOF}
      </Heading>

      <div className={informationColumn}>
        {!!residence?.roofType && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_ROOF_TYPE}
            value={getRoofTypeTranslation(translate, residence.roofType)}
          />
        )}

        {!!answerMap?.hasUnderRoof?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_ROOF_UNDER_ROOF}
            value={formatYesOrNo(translate, answerMap.hasUnderRoof.answer)}
          />
        )}

        {!!answerMap?.atticOrRaisedCelling?.answer && (
          <InstallationGuideEntry
            label={translate.SITE_VISIT_QUESTION_ROOF_LIVING_SPACE}
            value={formatYesOrNo(translate, answerMap.atticOrRaisedCelling.answer)}
          />
        )}

        {!!answerMap?.roofConstructionNotes?.answer && (
          <InstallationGuideEntry label={translate.NOTES} value={answerMap.roofConstructionNotes.answer} />
        )}

        <InstallationGuideImages imageList={imageList} />
      </div>
    </div>
  );
};
