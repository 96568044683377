import { Switch, SwitchProps } from 'antd';

import { switchWrapper } from './switch.module.css';

type SwitchWithLabelProps = SwitchProps & { label?: string };

export const SwitchWithLabel = ({ onClick, onChange, label }: SwitchWithLabelProps) => (
  <div className={switchWrapper}>
    <span>{label}</span>
    <Switch onClick={onClick} onChange={onChange} />
  </div>
);
