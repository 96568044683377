import clsx from 'clsx';
import { useEffect } from 'react';

import { Heading, Spinner } from '~src/components/display';
import { LOGIN_URL } from '~src/config';
import { useLocalization } from '~src/hooks';
import { useRefreshLogin } from '~src/hooks/services';
import { refreshLoginIfNecessary } from '~src/hooks/useRefreshLoginIfNecessary';
import { isAuthenticated } from '~src/utilities/auth';
import { clearCookie } from '~src/utilities/cookies';
import { location } from '~src/utilities/global';

import { center, displayGrid, fullScreen } from '~src/style/shared.module.css';

type ProtectedRouteProps = {
  element: JSX.Element;
};

export const ProtectedRoute = ({ element }: ProtectedRouteProps) => {
  const isLoggedIn = isAuthenticated();
  const { login } = useRefreshLogin();

  useEffect(
    function redirectToLogin() {
      async function refreshOrRedirect() {
        if (isLoggedIn) {
          return;
        }

        const refreshed = await refreshLoginIfNecessary(login);

        if (!refreshed && LOGIN_URL && !isAuthenticated()) {
          clearCookie('accessToken');
          clearCookie('refreshToken');
          location.href = `${LOGIN_URL}?redirectUrl=${location.href}`;
        }
      }

      const handle = setTimeout(() => refreshOrRedirect());

      return () => clearTimeout(handle);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoggedIn]
  );

  return isLoggedIn ? element : <AuthorizationSpinner />;
};

const AuthorizationSpinner = () => {
  const translate = useLocalization();

  if (!LOGIN_URL) {
    return (
      <div className={clsx(center, fullScreen, displayGrid)}>
        <Heading>Missing environment LOGIN_URL</Heading>
      </div>
    );
  }

  return (
    <div className={clsx(center, fullScreen, displayGrid)}>
      <Heading>{translate.AUTHENTICATING}</Heading>
      <Spinner />
    </div>
  );
};
