import upperFirst from 'lodash/upperFirst';

export {
  formatAddress,
  formatCurrency,
  formatDate,
  formatNumber,
  formatPercentage,
  roundToNearest,
  type Address as SharedAddress,
  withVAT,
} from '@bodilenergy/util';

const MAX_VALUE = 1e9;
const MIN_VALUE = -MAX_VALUE;

const clampUpper = (value: number, max = MAX_VALUE) => (max !== undefined ? Math.min(value, max) : value);
const clampLower = (value: number, min = MIN_VALUE) => (min !== undefined ? Math.max(value, min) : value);

export const clampValueBetween = (value: number, min = MIN_VALUE, max = MAX_VALUE) =>
  clampUpper(clampLower(value, min), max);

export const capitalizeFirst = upperFirst;

type FormatNameProps = {
  firstName?: string;
  lastName?: string;
};
export const formatName = ({ firstName, lastName }: FormatNameProps = {}): string =>
  `${firstName ?? ''} ${lastName ?? ''}`.trim();
