import { TranslationRecord } from '~src/localization/translationKeys';
import { ResidenceHeatingInput } from '~src/types';

import { HEAT_PUMP_AIR_TO_AIR_EFFICIENCY, HEAT_PUMP_AIR_TO_WATER_EFFICIENCY } from './constants';

type DropdownOption = { value?: string | number; label?: string | number };

type HeatingOption = {
  value: string;
  label: string;
  efficiencyOptions: DropdownOption[];
  fuelSubtypeOptions?: DropdownOption[];
  fuelSubtypeUnitOptions?: DropdownOption[];
};

export const getElectricHeatingOptions = (translate: TranslationRecord): HeatingOption[] => [
  {
    value: 'electricity',
    label: translate.ELECTRIC_HEATING,
    efficiencyOptions: [
      { value: 1, label: `${translate.STANDARD} (100%)` },
      {
        value: HEAT_PUMP_AIR_TO_WATER_EFFICIENCY,
        label: `${translate.AIR_TO_WATER} ${translate.HEAT_PUMP.toLowerCase()} (200%)`,
      },
      {
        value: HEAT_PUMP_AIR_TO_AIR_EFFICIENCY,
        label: `${translate.AIR_TO_AIR} ${translate.HEAT_PUMP.toLowerCase()} (400%)`,
      },
    ],
  },
];

const getWoodHeatingOptions = (translate: TranslationRecord): HeatingOption[] => [
  {
    value: 'wood',
    label: translate.PILL_FIRE,
    efficiencyOptions: [
      { value: 0.85, label: `Nyere end 2010` },
      { value: 0.75, label: '2000-2010' },
      { value: 0.65, label: 'Ældre end 2000' },
    ],
  },
];

// TODO: Use actual heating type from api after https://bodilenergi.atlassian.net/browse/BD-1827 has been completed
export const getPrimaryHeatingOptions = (translate: TranslationRecord): HeatingOption[] => [
  {
    value: 'oil',
    label: translate.OIL_BOILER,
    efficiencyOptions: [
      { value: 0.99, label: 'Under 10 år med kondensator' },
      { value: 0.96, label: 'Over 10 år med kondensator' },
      { value: 0.8, label: 'Uden kondensator' },
      { value: 0.91, label: `Isoleret kedel '70-'00` },
      { value: 0.87, label: `Uisoleret kedel '70-'90` },
      { value: 0.6, label: `Støbejernskedel` },
    ],
  },
  {
    value: 'gas',
    label: translate.GAS_BOILER,
    efficiencyOptions: [
      { value: 0.87, label: `Før '90` },
      { value: 0.94, label: 'Kondensator over 10 år' },
      { value: 0.97, label: 'Kondensator under 10 år' },
    ],
  },
  ...getWoodHeatingOptions(translate),
  {
    value: 'straw',
    label: translate.STRAW_FIRE,
    efficiencyOptions: [
      { value: 0.88, label: `Nyt` },
      { value: 0.75, label: 'Gammelt' },
    ],
  },
  {
    value: 'districtHeating',
    label: translate.DISTRICT_HEATING,
    efficiencyOptions: [{ value: 1, label: `${translate.STANDARD} (100%)` }],
  },
  ...getElectricHeatingOptions(translate),
];

const getWoodSubtypeOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: string;
}> => [
  { value: 'wood_1', label: translate.BIOCLASS_1 },
  { value: 'wood_2', label: translate.BIOCLASS_2 },
  { value: 'pallet', label: translate.PALLET_WOOD },
];

const getWoodSubtypeUnitOptions = (
  translate: TranslationRecord
): Array<{
  label: string;
  value: string;
}> => [
  { value: 'kasserummeter', label: translate.M3_STACKED_WOOD },
  { value: 'skovrummeter', label: translate.M3_LOOSE_WOOD },
];

export const getSecondaryHeatingOptions = (translate: TranslationRecord): HeatingOption[] => [
  {
    value: 'stoveCertified',
    label: `${translate.WOOD_STOVE} ${translate.WITH.toLowerCase()} ${translate.CERTIFICATION.toLowerCase()}`,
    efficiencyOptions: [{ value: 0.8, label: `${translate.STANDARD} (80%)` }],
    fuelSubtypeOptions: getWoodSubtypeOptions(translate),
    fuelSubtypeUnitOptions: getWoodSubtypeUnitOptions(translate),
  },
  {
    value: 'stoveUncertified',
    label: `${translate.WOOD_STOVE} ${translate.WITHOUT.toLowerCase()} ${translate.CERTIFICATION.toLowerCase()}`,
    efficiencyOptions: [{ value: 0.5, label: `${translate.STANDARD} (50%)` }],
    fuelSubtypeOptions: getWoodSubtypeOptions(translate),
    fuelSubtypeUnitOptions: getWoodSubtypeUnitOptions(translate),
  },
  {
    value: 'tiledStove',
    label: translate.TILED_STOVE,
    efficiencyOptions: [{ value: 0.5, label: `${translate.STANDARD} (50%)` }],
    fuelSubtypeOptions: getWoodSubtypeOptions(translate),
    fuelSubtypeUnitOptions: getWoodSubtypeUnitOptions(translate),
  },
  {
    value: 'fireplace',
    label: translate.FIREPLACE,
    efficiencyOptions: [{ value: 0.3, label: `${translate.STANDARD} (30%)` }],
    fuelSubtypeOptions: getWoodSubtypeOptions(translate),
    fuelSubtypeUnitOptions: getWoodSubtypeUnitOptions(translate),
  },
  ...getElectricHeatingOptions(translate),
  ...getWoodHeatingOptions(translate),
];

export const getFindFuelTypeEfficiencyOptions = (
  translate: TranslationRecord,
  priority: ResidenceHeatingInput['priority'],
  fuelType?: string
): DropdownOption[] => {
  const noValue = [{ value: undefined, label: translate.NONE }];

  const primaryOptions = getPrimaryHeatingOptions(translate);
  const secondaryOptions = getSecondaryHeatingOptions(translate);

  switch (priority) {
    case 'primary': {
      return primaryOptions.find(({ value }) => value === fuelType)?.efficiencyOptions ?? noValue;
    }
    case 'secondary': {
      return secondaryOptions.find(({ value }) => value === fuelType)?.efficiencyOptions ?? noValue;
    }
    default:
      return noValue;
  }
};
