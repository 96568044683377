import { Heading, ImageThumbnail, Input, Radio, Select, Text } from '~src/components/display';
import { FileUploadButton } from '~src/components/features/file';
import { useLocalization } from '~src/hooks';
import { useSetResidenceRoofType } from '~src/hooks/services';

import { getRoofTypeOptions } from '../shared/residence/helpers/residenceHelper';
import { SiteVisitNote } from './SiteVisitNote';
import { QuestionSectionProps } from './types';
import { SiteVisitSections } from './utilities/siteVisitConstants';

import { displayColumn } from '~src/style/shared.module.css';

import { imageUpload, questionRows, siteVisitSectionHeader } from './SiteVisit.module.css';

export const QuestionSectionRoof = ({
  residence,
  answerMap,
  imageList,
  isLoading,
  updateAnswer,
  uploadImages,
  isUploadingImages,
}: QuestionSectionProps) => {
  const translate = useLocalization();

  const { setRoofType, isLoading: isLoadingRoofType } = useSetResidenceRoofType();
  const roofTypeOptions = getRoofTypeOptions(translate);

  const { id: residenceId } = residence ?? {};

  return (
    <div className={displayColumn}>
      <Heading className={siteVisitSectionHeader} level={2}>
        3. {translate.SITE_VISIT_SECTION_ROOF}
      </Heading>

      <div className={questionRows}>
        {!!residenceId && (
          <>
            <Text>{translate.SITE_VISIT_QUESTION_ROOF_TYPE}:</Text>
            <Select
              onSelect={answer => setRoofType(residenceId, answer)}
              options={roofTypeOptions}
              defaultValue={residence?.roofType}
              loading={isLoading || isLoadingRoofType}
            />
          </>
        )}

        <SiteVisitNote
          display={residence?.roofType === 'flat'}
          text={translate.SITE_VISIT_DISCLAIMER_ROOF_FLAT}
          type='expense'
        />
        <SiteVisitNote display={residence?.roofType === 'other'} text={translate.SITE_VISIT_DISCLAIMER_ROOF_OTHER} />

        <Text>{translate.SITE_VISIT_QUESTION_ROOF_UNDER_ROOF}:</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('hasUnderRoof', event?.target?.value);
          }}
          value={answerMap?.hasUnderRoof?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>
        <SiteVisitNote
          display={answerMap?.hasUnderRoof?.answer === 'true'}
          text={translate.SITE_VISIT_DISCLAIMER_UNDER_ROOF}
        />

        <Text>{translate.SITE_VISIT_QUESTION_ROOF_LIVING_SPACE}</Text>
        <Radio.Group
          onChange={event => {
            updateAnswer('atticOrRaisedCelling', event?.target?.value);
          }}
          value={answerMap?.atticOrRaisedCelling?.answer}
          disabled={isLoading}
        >
          <Radio.Button value='true'>{translate.YES}</Radio.Button>
          <Radio.Button value='false'>{translate.NO}</Radio.Button>
        </Radio.Group>

        <Text>{translate.NOTES}</Text>
        <Input.TextArea
          onBlur={event => {
            updateAnswer('roofConstructionNotes', event?.target?.value);
          }}
          defaultValue={answerMap?.roofConstructionNotes?.answer}
        />

        <Text>{translate.IMAGES}</Text>
        <div className={imageUpload}>
          {imageList?.map(image => <ImageThumbnail key={image.url} image={image} />)}

          <FileUploadButton
            isLoading={isUploadingImages}
            buttonText={translate.UPLOAD}
            multiple
            onUpload={(images: File[]) => uploadImages?.(images, SiteVisitSections.roofConstruction)}
            type='thumbnail'
          />
        </div>
      </div>
    </div>
  );
};
