export const LEAD_ORIGIN_OPTIONS = [
  { label: 'Ingen', value: '' },
  { label: 'Bodil - Beregner', value: 'bodil' },
  { label: 'Bodil - Booking', value: 'bodil-booking' },
  { label: 'EDC - Beregner', value: 'edc' },
  { label: 'EDC - Booking', value: 'edc-booking' },
  { label: 'Jyske Bank - Beregner', value: 'jyskebank' },
  { label: 'Jyske Bank - Booking', value: 'jyskebank-booking' },
  { label: 'Nordea - Beregner', value: 'nordea' },
  { label: 'Nordea - Booking', value: 'nordea-booking' },
  { label: 'Nordea - Lead Portal', value: 'nordea-lead-portal' },
  { label: 'Norlys - Beregner', value: 'norlys' },
  { label: 'Norlys - Booking', value: 'norlys-booking' },
  { label: 'Norlys - Kundecenter', value: 'norlys-kundecenter' },
  { label: 'MVF - Affiliate', value: 'mvf-affiliate' },
  { label: 'Meta - Marketing', value: 'meta-marketing' },
  { label: 'Varmepumpe.dk - Affiliate', value: 'varmepumpedk-affiliate' },
  { label: 'Tjenestetorvet - Affiliate', value: 'tjenestetorvet-affiliate' },
  { label: 'Solceller.nu - Affiliate', value: 'solcellernu-affiliate' },
  { label: 'Greenmatch.dk - Affiliate', value: 'greenmatchdk-affiliate' },
  { label: 'CDM - Beregner', value: 'cdm' },
  { label: 'CDM - Booking', value: 'cdm-booking' },
];

export const findLeadOriginValue = (labelToFind?: string) =>
  LEAD_ORIGIN_OPTIONS.find(({ label }) => label === labelToFind)?.value;
