import { create } from 'zustand';

export type CreatingNewResidenceState = 'none' | 'pending' | 'done';

type State = {
  creatingNewResidenceState: CreatingNewResidenceState;
};

type Action = {
  setCreatingNewResidenceState: (arg: CreatingNewResidenceState) => void;
};

type CreateResidenceState = State & Action;

const initialState: State = {
  creatingNewResidenceState: 'none',
};

export const useCreateResidenceState = create<CreateResidenceState>(set => ({
  ...initialState,

  setCreatingNewResidenceState: (toggle: CreatingNewResidenceState) => set({ creatingNewResidenceState: toggle }),
}));
