import { Heading, Table } from '~src/components/display';
import { useLocalization } from '~src/hooks';
import { ResidenceInput } from '~src/types';

import { useFormatSolarOfferEconomyTableData } from '../hooks/useFormatSolarOfferEconomyTableData';
import { useFormatSolarOfferProductionTableData } from '../hooks/useFormatSolarOfferProductionTableData';

import { solarOfferStatistics } from './solarProduct.module.css';

type SolarOfferStatisticsProps = {
  residence?: ResidenceInput;
};

export const SolarOfferStatistics = ({ residence }: SolarOfferStatisticsProps) => {
  const translate = useLocalization();

  const { economyColumns, economyData } = useFormatSolarOfferEconomyTableData(residence);
  const { productionColumns, productionData } = useFormatSolarOfferProductionTableData(residence);

  return (
    <div className={solarOfferStatistics}>
      <Heading>{translate.ECONOMY}</Heading>

      <Heading>{translate.YEARLY_PRODUCTION_AND_USAGE_INFORMATION}</Heading>

      <Table
        dataSource={economyData}
        columns={economyColumns}
        pagination={false}
        rowKey={item => item.economyDescription}
      />

      <Table
        dataSource={productionData}
        columns={productionColumns}
        pagination={false}
        rowKey={item => item.productionDescription}
      />
    </div>
  );
};
