/* eslint-disable @typescript-eslint/ban-ts-comment */

import { notification } from 'antd';
import { ArgsProps, IconType, NotificationInstance } from 'antd/es/notification/interface';
import { useEffect } from 'react';
import { create } from 'zustand';

type ContextHolder = React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>;

type ToastMessageError = {
  response: {
    errors: { message: string }[];
  };
};

const UNKNOWN_ERROR_MESSAGE = 'Der skete en uventet fejl. Prøv igen eller kontakt support. Vi beklager ulejligheden.';

const ONE_SECOND = 1;

const useToastMessageState = create<{
  stateApi: NotificationInstance;
  stateContextHolder: ContextHolder;

  setStateApi: (stateApi: NotificationInstance) => void;
  setStateContextHolder: (arg: ContextHolder) => void;
}>(set => ({
  // @ts-ignore
  stateApi: {},
  // @ts-ignore
  stateContextHolder: undefined,

  setStateApi: (stateApi: NotificationInstance) => set({ stateApi }),
  setStateContextHolder: (stateContextHolder: ContextHolder) => set({ stateContextHolder }),
}));

export const useToastMessage = () => {
  const { stateApi, stateContextHolder, setStateApi, setStateContextHolder } = useToastMessageState();
  const [api, contextHolder] = notification.useNotification();

  useEffect(function initializeContextHolderState() {
    if (stateContextHolder) {
      return;
    }

    setStateApi(api);
    setStateContextHolder(contextHolder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    contextHolder: stateContextHolder,
    showMessage: ({ message, description, duration, type = 'info' }: ArgsProps & { type: IconType }) => {
      const durationWithDefaults = duration ?? type === 'success' ? ONE_SECOND : undefined;
      return stateApi?.[type]({ message, description, duration: durationWithDefaults, placement: 'topRight' });
    },
  };
};

export const useToastMessageErrorHandler = () => {
  const { showMessage } = useToastMessage();

  return (error: unknown) => {
    const errorMessage =
      (error as ToastMessageError)?.response?.errors?.[0]?.message || (error as Error).message || UNKNOWN_ERROR_MESSAGE;

    showMessage({ message: errorMessage, type: 'error' });
  };
};
