import { ElectricCarInput, ResidenceHeatingInput, ResidenceInput, RoofInput } from '~src/types';

// ResidenceFormResidenceInformation keys
export const typeFieldKey: keyof ResidenceInput = 'type';
export const floorsFieldKey: keyof ResidenceInput = 'floors';
export const residentsFieldKey: keyof ResidenceInput = 'residents';
export const builtYearFieldKey: keyof ResidenceInput = 'builtYear';
export const areaFieldKey: keyof ResidenceInput = 'area';

export const areaHeatedFieldKey: keyof ResidenceInput = 'areaHeated';
export const areaPartiallyHeatedFieldKey: keyof ResidenceInput = 'areaPartiallyHeated';
export const areaNotHeatedFieldKey: keyof ResidenceInput = 'areaNotHeated';
export const renovatedYearFieldKey: keyof ResidenceInput = 'renovatedYear';
export const renovatedDegreeFieldKey: keyof ResidenceInput = 'renovatedDegree';
export const energyLabelFieldKey: keyof ResidenceInput = 'energyLabel';

// ResidenceFormRoof keys
export const roofsFieldKey: keyof ResidenceInput = 'roofs';

export const shadowsFieldKey: keyof RoofInput = 'shadows';
export const orientationFieldKey: keyof RoofInput = 'orientation';
export const maxPanelCoverageFieldKey: keyof RoofInput = 'maxPanelCoverage';

export const planeDepthFieldKey: keyof RoofInput = 'planeDepth';
export const planeWidthFieldKey: keyof RoofInput = 'planeWidth';
export const heightFieldKey: keyof RoofInput = 'height';

export const roofTypeFieldKey: keyof ResidenceInput = 'roofType';

// ResidenceFormHeatDistribution keys
export const heatCircuitFieldKey: keyof ResidenceInput = 'heatingCircuits';
export const heatDistributionFieldKey: keyof ResidenceInput = 'heatDistribution';
export const radiatorFloorPercentageFieldKey: keyof ResidenceInput = 'radiatorFloorPercentage';

// ResidenceFormConsumption keys
export const energyExpenditureFieldKey: keyof ResidenceInput = 'energyExpenditure';
export const electricityPriceFieldKey: keyof ResidenceInput = 'electricityPrice';

export const primaryHeatingFieldKey: keyof ResidenceInput = 'primaryHeating';
export const secondaryHeatingFieldKey: keyof ResidenceInput = 'secondaryHeating';

export const fuelTypeFieldKey: keyof ResidenceHeatingInput = 'fuelType';
export const efficiencyFieldKey: keyof ResidenceHeatingInput = 'efficiency';
export const priceFieldKey: keyof ResidenceHeatingInput = 'price';
export const annualUsageFieldKey: keyof ResidenceHeatingInput = 'annualUsage';
export const fuelSubtypeFieldKey: keyof ResidenceHeatingInput = 'fuelSubtype';
export const volumeUnitFieldKey: keyof ResidenceHeatingInput = 'volumeUnit';

// ResidenceFormConsumptionProfile keys
export const consumptionProfileFieldKey: keyof ResidenceInput = 'consumptionProfile';

// ResidenceFormElectricCar keys
export const electricCarFieldKey: keyof ResidenceInput = 'electricCar';

export const annualKilometersDrivenFieldKey: keyof ElectricCarInput = 'annualKilometersDriven';
export const isSubsidisedFieldKey: keyof ElectricCarInput = 'isSubsidised';
export const monthlySubscriptionPriceFieldKey: keyof ElectricCarInput = 'monthlySubscriptionPrice';
export const consumptionPerKilometerFieldKey: keyof ElectricCarInput = 'consumptionPerKilometer';
export const percentageChargedFieldKey: keyof ElectricCarInput = 'percentageCharged';
