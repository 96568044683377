import { ReactNode, useEffect, useState } from 'react';

import { useDynamicPageSize, usePagination } from '~src/hooks';

import { Pagination } from './Pagination';

import { carousel, pagination } from './pagination.module.css';

type ProductSelectorProps = {
  itemIndex?: number;
  itemList?: ReactNode[];
};

export const ProductPagination = ({ itemIndex, itemList }: ProductSelectorProps) => {
  const [jumpedItemIndex, setJumpedItemIndex] = useState<number | undefined>();

  const { pageSize, ref, updatePageSize } = useDynamicPageSize();
  const { firstElement, lastElement, page, setPage } = usePagination(pageSize);

  useEffect(
    function jumpToPageWithItemIndex() {
      if (!itemIndex || itemIndex === jumpedItemIndex) {
        return;
      }

      const newPage = Math.max(Math.ceil(itemIndex / pageSize), 1);

      setJumpedItemIndex(itemIndex);
      setPage(newPage);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemIndex]
  );

  useEffect(
    function setPageSizeOnProductLoad() {
      if (!itemList?.length) {
        return;
      }

      updatePageSize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemList?.length]
  );

  return (
    <>
      <div className={carousel} ref={ref}>
        {itemList?.slice(firstElement, lastElement)}
      </div>

      <Pagination
        className={pagination}
        onChange={setPage}
        pageSize={pageSize}
        total={itemList?.length}
        current={page}
      />
    </>
  );
};
