import { location } from '~src/utilities/global';

export const useLocaleState = (): [locale: string, setLocale: (newLocale: string) => void] => {
  const search = new URLSearchParams(location.search);
  const locale = search.get('lang') || 'da';

  const updateLocal = (newLocale: string) => {
    search.set('lang', newLocale);
    location.search = search.toString();
  };

  return [locale, updateLocal];
};
